import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, View, TouchableOpacity, ScrollView, Modal, ActivityIndicator, Alert, Image } from 'react-native';
import alert from '../hooks/useAlert.js'
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import ActivationIcon from '../../assets/images/activation.svg';
import PurchaseIcon from '../../assets/images/purchase.svg';
import useParkingApi from '../hooks/useParkingApi';
import Parse from 'parse';
import useExpiredSession from '../hooks/useExpiredSession';
import OfficialFooter from '../components/OfficialFooter';

const HistoryScreen = ({ navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const LOADING_STATE = {
    notLoaded: 0,
    loadedWithError: -1,
    loaded: 1
  };

  const [loadingState, setLoadingState] = useState(LOADING_STATE.notLoaded);

  const TRANSACTION_TYPE = {
    purchase: { id: 1, text: 'Compras' },
    activation: { id: 2, text: 'Ativações' }
  };

  const TRANSACTION_PERIOD = {
    lastDays: { id: 1, text: 'dos últimos 3 dias' },
    thisMonth: { id: 2, text: 'deste mês' },
    lastMonth: { id: 3, text: 'do mês passado' },
    lastYear: { id: 4, text: 'dos últimos 12 meses' },
  };

  const [selectedTransactionType, setSelectedTransactionType] = useState(TRANSACTION_TYPE.activation);
  const [selectedTransactionPeriod, setSelectedTransactionPeriod] = useState(TRANSACTION_PERIOD.lastDays);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [transactions, setAllTransactions] = useState([]);
  const [lastTransactionsGroup, setLastTransactionsGroup] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAllTransactions, setSelectAllTransactions] = useState(false);
  const [isGettingMoreTransactions, setIsGettingMoreTransactions] = useState(false);
  const [skip, setSkip] = useState(0);

  const [, , , , , , getHistoryTransactions, sendReceiptMail] = useParkingApi();

  const MAX_TRANSACTIONS_PER_LOAD = 100;

  const getTransactions = async (clearAllTransactions) => {

    const startOfDay = (date) => {
      let currentDate = null;

      if (date) currentDate = new Date(date);
      else currentDate = new Date();

      const timeZoneOffsetInHours = currentDate.getTimezoneOffset() / 60;
      currentDate.setUTCHours(timeZoneOffsetInHours, 0, 0, 0);
      return currentDate;
    };

    const getDaysAgoDate = (date, daysAgo) => {
      const currentDate = new Date(date);
      currentDate.setUTCDate(currentDate.getUTCDate() - daysAgo);
      return currentDate;
    };

    const startOfMonth = (date) => {
      const currentDate = new Date(date);
      currentDate.setUTCDate(1);
      return startOfDay(currentDate);
    };

    const startOfLastMonth = (date) => {
      const lastMonth = getMonthsAgoDate(date, 1);
      return startOfMonth(lastMonth);
    };

    const endOfLastMonth = (date) => {
      const startOfCurrentMonth = new Date(date);
      startOfCurrentMonth.setUTCDate(1);

      const d = startOfDay(startOfCurrentMonth);

      return new Date(d.getTime() - 1);
    };

    const getMonthsAgoDate = (date, monthsAgo) => {
      const currentDate = new Date(date);
      const endDate = currentDate.setUTCMonth(
        currentDate.getUTCMonth() - monthsAgo
      );
      return new Date(endDate);
    };

    try {
      let operationSkip = 0;

      if (clearAllTransactions) {
        setLoadingState(LOADING_STATE.notLoaded);
        setAllTransactions([]);
        setSelectedTransactions([]);
        operationSkip = 0;
        setSkip(0);
      }
      else {
        operationSkip = skip + MAX_TRANSACTIONS_PER_LOAD;
        setSkip(skip + MAX_TRANSACTIONS_PER_LOAD);
        setIsGettingMoreTransactions(true);
      }

      setLastTransactionsGroup([]);

      let purchaseQuery = false;
      if (selectedTransactionType.id === TRANSACTION_TYPE.purchase.id) {
        purchaseQuery = true;
      }

      let iniDate = null;
      let endDate = null;
      const todayStartOfDay = startOfDay();

      if (selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastDays.id) {
        iniDate = getDaysAgoDate(todayStartOfDay, 3);

      }
      else if (selectedTransactionPeriod.id === TRANSACTION_PERIOD.thisMonth.id) {
        iniDate = startOfMonth(todayStartOfDay);
      }
      else if (selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastMonth.id) {
        iniDate = startOfLastMonth(todayStartOfDay);
        endDate = endOfLastMonth(todayStartOfDay);
      }
      else { //selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastYear.id
        iniDate = getMonthsAgoDate(todayStartOfDay, 12);
      }

      const resultTransactions = await getHistoryTransactions(iniDate, endDate, operationSkip, purchaseQuery, MAX_TRANSACTIONS_PER_LOAD);
      console.log('got resultTransactions', resultTransactions);

      setLastTransactionsGroup(resultTransactions);

      if (clearAllTransactions) {
        setAllTransactions(resultTransactions);
        setLoadingState(LOADING_STATE.loaded);
      }
      else {
        setAllTransactions([...transactions, ...resultTransactions]);
        setIsGettingMoreTransactions(false);
      }

    }
    catch (e) {
      console.log('error loading transactions', e);
      setLoadingState(LOADING_STATE.loadedWithError);
      setIsGettingMoreTransactions(false);
      checkIfSessionExpired(e);
    }
  };

  const init = () => {
    setSelectedTransactionType(TRANSACTION_TYPE.activation);
    setSelectedTransactionPeriod(TRANSACTION_PERIOD.lastDays);
  };

  const beautifulPrice = (price) => {
    if (!price) return 0;

    price = price.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  const beautifulDate = (dateTime) => {
    const date = new Date(dateTime);

    const addLeadingZero = (num) => {
      if (num < 10) return `0${num}`;
      return num;
    }
    return `${addLeadingZero(date.getDate())}/${addLeadingZero(date.getMonth() + 1)}/${addLeadingZero(date.getYear() - 100)} às ${addLeadingZero(date.getHours())}h${addLeadingZero(date.getMinutes())}`;
  };

  const beautifulLicensePlate = (licensePlate) => {
    if (licensePlate) {
      if (licensePlate.match(/^[A-Za-z]{3}[0-9]{4}$/)) {
        return licensePlate.replace(/(\w{3})/, function (x) {
          return (x.toUpperCase() + "-");
        });
      } else {
        return licensePlate.replace(/(\w{3})/, function (x) {
          return (x.toUpperCase() + " ");
        });
      }
    }
    return licensePlate;
  };

  const sendMail = () => {

    sendReceiptMail(selectedTransactions)

    let textReceipt = '';

    if (selectedTransactions.length === 1) {
      textReceipt = 'O recibo de transação foi enviado';
    } else {
      textReceipt = 'O recibo de transações foi enviado';
    }

    alert(
      'Pronto!',
      `${textReceipt} ao seu e-mail ${Parse.User.current().get('email')}\n\nPode demorar alguns minutos até o e-mail chegar. Caso você não encontre a mensagem, sempre verifique o spam.`,
      null,
      true
    );

    // Alert.alert(
    //   'Pronto!',
    //   `${textReceipt} ao seu e-mail ${Parse.User.current().get('email')}\n\nPode demorar alguns minutos até o e-mail chegar. Caso você não encontre a mensagem, sempre verifique o spam.`,
    //   [
    //     { text: 'OK' },
    //   ],
    //   { cancelable: true }
    // );

  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      init();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={{ flex: 1 }}>

      <ScrollView>
        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Escolha o tipo de transação:</Text>
          </View>
          <View style={styles.optionsBlock}>
            <TouchableOpacity
              onPress={() => setSelectedTransactionType(TRANSACTION_TYPE.activation)}
              style={selectedTransactionType.id === TRANSACTION_TYPE.activation.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionType.id === TRANSACTION_TYPE.activation.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Ativação
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setSelectedTransactionType(TRANSACTION_TYPE.purchase)}
              style={selectedTransactionType.id === TRANSACTION_TYPE.purchase.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionType.id === TRANSACTION_TYPE.purchase.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Compra
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Escolha o período:</Text>
          </View>
          <View style={styles.optionsBlock}>
            <TouchableOpacity
              onPress={() => setSelectedTransactionPeriod(TRANSACTION_PERIOD.lastDays)}
              style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastDays.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastDays.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Últimos 3 dias
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setSelectedTransactionPeriod(TRANSACTION_PERIOD.thisMonth)}
              style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.thisMonth.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.thisMonth.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Este mês
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.optionsBlock}>
            <TouchableOpacity
              onPress={() => setSelectedTransactionPeriod(TRANSACTION_PERIOD.lastMonth)}
              style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastMonth.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastMonth.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Mês passado
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setSelectedTransactionPeriod(TRANSACTION_PERIOD.lastYear)}
              style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastYear.id ? styles.touchableOptionActive : styles.touchableOption}
            >
              <Text style={selectedTransactionPeriod.id === TRANSACTION_PERIOD.lastYear.id ? styles.touchableOptionTextActive : styles.touchableOptionText}>
                Últimos 12 meses
              </Text>
            </TouchableOpacity>
          </View>
        </View>

      </ScrollView>

      <Modal
        animationType="slide"
        transparent={false}
        visible={historyModalVisible}
        onRequestClose={() => {
          setHistoryModalVisible(false);
        }}
      >
        <SafeAreaView>
          <View style={styles.modalHeader}>
            <View style={{ width: 50 }} />
            <Text style={styles.cardModalTitle}>Histórico de Uso</Text>
            <TouchableOpacity
              onPress={() => {
                setHistoryModalVisible(false);
              }}
              style={styles.closeModalTouchable}>
              <FontAwesome
                name='times'
                style={styles.closeModalIcon}
              />
            </TouchableOpacity>
          </View>
        </SafeAreaView>


        {loadingState === LOADING_STATE.notLoaded ?
          <View style={styles.loadingView}>
            <ActivityIndicator size={50} color='#284D64' />
            <Text style={styles.loadingText}>Carregando transações...</Text>
          </View>
          : null}

        {loadingState === LOADING_STATE.loaded ?
          <View style={styles.fieldContainer}>
            <View style={styles.fieldTitleContainer}>
              <Text style={styles.fieldTitle}>{selectedTransactionType.text} {selectedTransactionPeriod.text}:</Text>
            </View>
          </View>
          : null}

        {loadingState === LOADING_STATE.loaded && transactions && transactions.length > 0 ?
          <View style={styles.modalMainContent}>
            <View style={styles.instructionsContainer}>
              <Text style={styles.instructionsText}>Para enviar qualquer comprovante para o seu e-mail, basta selecionar as transações abaixo e tocar em "Enviar Comprovantes".</Text>
            </View>
            <View>
              <TouchableOpacity
                style={[styles.transactionTouchable, styles.firstTransactionTouchable]}
                key='firstTransactionTouchable'
                onPress={() => {
                  if (selectAllTransactions) {
                    setSelectedTransactions([]);
                  }
                  else {
                    const selectedTransactionsArray = [];
                    for (let i = 0; i < transactions.length; i++) {
                      selectedTransactionsArray.push(transactions[i].objectId);
                    }
                    setSelectedTransactions(selectedTransactionsArray);
                  }
                  setSelectAllTransactions(!selectAllTransactions);
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <View style={selectAllTransactions ? styles.transactionTouchableSelectorActive : styles.transactionTouchableSelector}>
                    <FontAwesome
                      name='check'
                      style={selectAllTransactions ? styles.checkMarkIconActive : styles.checkMarkIcon}
                    />
                  </View>
                  <View style={[styles.transactionTouchableMainContent, styles.firstTransactionTouchableMainContent]}>
                    <View>
                      <Text style={[styles.transactionTouchableText, { fontSize: 16 }]}>
                        {selectAllTransactions ? 'Desmarcar todas' : 'Selecionar todas'}
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          : null}

        {loadingState === LOADING_STATE.loaded && transactions && transactions.length > 0 ?
          <ScrollView style={styles.transactionScrollView}>

            {transactions.map((item, index) => (
              <TouchableOpacity
                style={[styles.transactionTouchable,
                selectedTransactions.includes(item.objectId) ? styles.transactionTouchableActive : null
                ]}
                key={item.objectId}
                onPress={() => {
                  if (selectedTransactions.includes(item.objectId)) {
                    const newSelectedTransactions = [];
                    for (let i = 0; i < selectedTransactions.length; i++) {
                      if (selectedTransactions[i] !== item.objectId) newSelectedTransactions.push(selectedTransactions[i]);
                    }
                    setSelectedTransactions(newSelectedTransactions);
                  }
                  else {
                    setSelectedTransactions([...selectedTransactions, item.objectId])
                  }
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <View style={[styles.transactionTouchableSelector,
                  selectedTransactions.includes(item.objectId) ? styles.transactionTouchableSelectorActive : null
                  ]}>
                    <FontAwesome
                      name={selectedTransactions.includes(item.objectId) ? 'check' : 'square'}
                      style={selectedTransactions.includes(item.objectId) ? styles.checkMarkIconActive : styles.checkMarkIcon}
                    />
                  </View>
                  <View style={styles.transactionTouchableMainContent}>
                    <View style={styles.transactionTouchableContentLine}>
                      <Text style={styles.transactionTouchableMainText}>R$ {beautifulPrice(item.totalPrice)}
                      </Text>
                      <Text style={styles.transactionTouchableMainText}>
                        {selectedTransactionType.id === TRANSACTION_TYPE.activation.id ?
                          `${item.priceRuleName} - ${item.maxParkingMinutes / 60}h`
                          : 'Créditos da Zona Azul'}
                      </Text>
                    </View>

                    {selectedTransactionType.id === TRANSACTION_TYPE.activation.id ?
                      <View style={styles.transactionTouchableContentLine}>
                        <Text style={[styles.transactionTouchableText, styles.vehicleDescription]}>{item.vehicleDescription}</Text>
                        <View style={{ flexDirection: 'row' }}>
                          <FontAwesome
                            name={item.category === 1 ? 'car' : item.category === 2 ? 'motorcycle' : 'truck'}
                            style={[styles.vehicleIcon, item.category === 1 ? { fontSize: 14 } : { fontSize: 16 }]}
                          />
                          <Text style={styles.transactionTouchableMainText}>{beautifulLicensePlate(item.license)}</Text>
                        </View>
                      </View>
                      : null}

                    <View style={styles.transactionTouchableContentLine}>
                      <Text style={styles.transactionTouchableText}>
                        {selectedTransactionType.id === TRANSACTION_TYPE.activation.id ? `Início em ${beautifulDate(item.startDate)}` : `Realizada em ${beautifulDate(new Date(item.createdAt).getTime())}`}
                      </Text>
                    </View>

                    {selectedTransactionType.id === TRANSACTION_TYPE.activation.id && item.scheduled ?
                      <View style={styles.transactionTouchableContentLine}>
                        <Text style={styles.transactionTouchableText}>(agendada em {beautifulDate(item.createdAt)})</Text>
                      </View>
                      : null}

                    {item.extendedTransaction ?
                      <View style={styles.transactionTouchableContentLine}>
                        <Text style={styles.transactionTouchableText}>
                          Extensão de ativação anterior
                        </Text>
                      </View>
                      : null}

                    {item.toleranceCancellationDate ?
                      <View style={styles.transactionTouchableContentLine}>
                        <Text style={styles.transactionTouchableText}>
                          Cancelada por desistência {beautifulDate(item.toleranceCancellationDate)}
                        </Text>
                      </View>
                      : null}

                    {item.substitutionCancellationDate ?
                      <View style={styles.transactionTouchableContentLine}>
                        <Text style={styles.transactionTouchableText}>
                          Cancelada por substituição {beautifulDate(item.substitutionCancellationDate)}
                        </Text>
                      </View>
                      : null}


                    <View style={[styles.transactionTouchableContentLine, { marginBottom: 3 }]}>
                      <Text style={styles.transactionTouchableText}>Cód. Autenticação: {item.authCode}</Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            ))}

            {(lastTransactionsGroup && lastTransactionsGroup.length === MAX_TRANSACTIONS_PER_LOAD) || isGettingMoreTransactions ?
              <Button
                buttonStyle={styles.loadMoreButton}
                disabled={isGettingMoreTransactions}
                loading={isGettingMoreTransactions}
                loadingProps={{ color: '#7FCFAB' }}
                disabledStyle={styles.disabledLoadMoreButtonStyle}
                title='Carregar Mais...'
                titleStyle={styles.loadMoreButtonTitle}
                onPress={() => { getTransactions(false) }}
              />
              : null}
          </ScrollView>
          : null}

        {loadingState === LOADING_STATE.loadedWithError ?
          <View style={styles.loadingView}>
            <Text style={styles.loadingErrorText}>Ocorreu um erro ao carregar as transações.</Text>
          </View>
          : null}

        {selectedTransactionType.id === TRANSACTION_TYPE.purchase.id && loadingState === LOADING_STATE.loaded && (!transactions || transactions.length === 0) ?
          <View style={styles.loadingView}>
            {/* <PurchaseIcon style={styles.noTransactionsIcon} height={80} width={80} /> */}
            <Image
              source={require('../../assets/images/purchase.png')}
              style={{width: 80, height: 80, alignSelf: 'center'}}
            />

            <Text style={styles.noTransactionsText}>Nenhuma compra foi encontrada no período selecionado.</Text>
          </View>
          : null}

        {selectedTransactionType.id === TRANSACTION_TYPE.activation.id && loadingState === LOADING_STATE.loaded && (!transactions || transactions.length === 0) ?
          <View style={styles.loadingView}>
            {/* <ActivationIcon style={styles.noTransactionsIcon} height={80} width={80} /> */}
            <Image
              source={require('../../assets/images/activation.png')}
              style={{width: 80, height: 80, alignSelf: 'center'}}
            />

            <Text style={styles.noTransactionsText}>Nenhuma ativação foi encontrada no período selecionado.</Text>
          </View>
          : null}

        {loadingState === LOADING_STATE.loaded && transactions && transactions.length > 0 ?
          <SafeAreaView>
            <Button
              buttonStyle={styles.proceedButton}
              disabled={!selectedTransactions || selectedTransactions.length === 0}
              disabledStyle={styles.disabledButtonStyle}
              title='Enviar Comprovantes'
              titleStyle={styles.proceedButtonTitle}
              onPress={() => { sendMail() }}
            />
          </SafeAreaView>
          : null}

        {loadingState === LOADING_STATE.loadedWithError ?
          <SafeAreaView>
            <Button
              buttonStyle={styles.errorButton}
              title='Tentar Novamente'
              titleStyle={styles.proceedButtonTitle}
              onPress={() => { getTransactions(true) }}
            />
          </SafeAreaView>

          : null}
      </Modal>

      <Button
        buttonStyle={styles.proceedButton}
        title='Ver Histórico'
        titleStyle={styles.proceedButtonTitle}
        onPress={() => { setHistoryModalVisible(true); getTransactions(true); }}
      />
      <OfficialFooter />

    </View>
  );
};

const styles = StyleSheet.create({
  disabledButtonStyle: {
    backgroundColor: 'rgba(127,207,171,0.5)',
    borderRadius: 0
  },
  disabledLoadMoreButtonStyle: {
    backgroundColor: 'transparent'
  },
  transactionScrollView: {
    marginRight: 15,
    marginBottom: 15
  },
  loadingErrorText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center'
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  loadingView: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  vehicleDescription: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  firstTransactionTouchableMainContent: {
    paddingTop: 5,
    paddingBottom: 5
  },
  firstTransactionTouchable: {
    marginTop: 10,
    marginBottom: 15,
  },
  vehicleIcon: {
    color: '#284D64',
    alignSelf: 'center',
    marginRight: 5
  },
  transactionTouchableMainText: {
    fontSize: 14,
    fontFamily: 'defaultFont',
    color: '#284D64'
  },
  transactionTouchableText: {
    fontSize: 14,
    fontFamily: 'defaultFont',
    color: '#424242'
  },
  transactionTouchableMainContent: {
    paddingLeft: 5,
    marginRight: 5,
    flex: 1
  },
  transactionTouchableContentLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 3
  },
  checkMarkIcon: {
    fontSize: 15,
    textAlign: 'center',
    color: '#AAAAAA'
  },
  checkMarkIconActive: {
    fontSize: 15,
    textAlign: 'center',
    color: '#FFFFFF'
  },
  transactionTouchable: {
    marginLeft: 15,
    borderColor: '#EEEEEE',
    borderWidth: 1,
    marginTop: 5
  },
  transactionTouchableActive: {
    borderColor: '#7FCFAB'

  },
  transactionTouchableSelector: {
    backgroundColor: '#EEEEEE',
    width: 20,
    justifyContent: 'center'
  },
  transactionTouchableSelectorActive: {
    backgroundColor: '#7FCFAB',
    justifyContent: 'center',
    width: 20
  },
  noTransactionsText: {
    fontSize: 16,
    fontFamily: 'defaultFont',
    color: '#424242',
    textAlign: 'center'
  },
  noTransactionsIcon: {
    alignSelf: 'center',
    marginBottom: 10
  },
  modalMainContent: {
    marginRight: 15
  },
  instructionsContainer: {
    backgroundColor: '#EEEEEE',
    marginLeft: 15,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'stretch',
    padding: 5
  },
  instructionsText: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 16
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  cardModalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont'
  },
  closeModalTouchable: {
    justifyContent: 'flex-end'
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    width: 50,
    textAlign: 'center'
  },
  loadMoreButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#7FCFAB',
    marginLeft: 15,
    marginTop: 10
  },
  loadMoreButtonTitle: {
    fontFamily: 'defaultFont',
    textTransform: 'uppercase',
    color: '#7FCFAB'
  },
  proceedButton: {
    backgroundColor: '#7FCFAB'
  },
  proceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  fieldContainer: {
    marginTop: 15,
    marginRight: 15
  },
  fieldTitleContainer: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginLeft: 15
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 18,
    fontFamily: 'defaultFontBold'
  },
  optionsBlock: {
    flexDirection: 'row'
  },
  touchableOption: {
    flex: 1,
    marginLeft: 15,
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 10,
    borderBottomColor: 'transparent',
    borderBottomWidth: 4,
  },
  touchableOptionActive: {
    flex: 1,
    borderBottomColor: '#0992A5',
    borderBottomWidth: 4,
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 10
  },
  touchableOptionText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontFamily: 'defaultFontBold',
    fontSize: 18,
    marginBottom: 10
  },
  touchableOptionTextActive: {
    color: '#0992A5',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'defaultFontBold',
    marginBottom: 10
  }
});

export default HistoryScreen;
