import Parse from 'parse';
import appParams from '../../constants/AppParams';
import '../lib/moip.min.js'; //imports MoipSdkJs
import '../lib/pagseguro.min.js'; //imports PAGSSDK
import AsyncStorage from '@react-native-async-storage/async-storage';

export default () => {

  const getCreditCards = async () => {

    var CreditCard = Parse.Object.extend('PSCreditCard');

    var creditCardQuery = new Parse.Query(CreditCard);
    creditCardQuery.equalTo('user', Parse.User.current());

    try {
      const results = await creditCardQuery.find();
      if (results.length > 0) {
        results.sort(function (a, b) {
          if (a.get('finalNumbers') > b.get('finalNumbers')) return 1;
          else return -1;
        });
      }
      return results;
    } catch (e) {
      console.log('got error', e);
      throw e;
    }
  };

  const setCreditCard = (providedCreditCard) => {
    
    if (!providedCreditCard || !providedCreditCard.number) return null;

    const providedCreditCardNumber = providedCreditCard.number.replace(/ /g, '');

    const creditCard = PagSeguro.encryptCard({
      publicKey: appParams.encPSPubKey,
      holder: providedCreditCard.holder.toUpperCase(),
      number: providedCreditCardNumber,
      expMonth: providedCreditCard.expiryDate.split('/')[0],
      expYear: '20' + providedCreditCard.expiryDate.split('/')[1],
      securityCode: providedCreditCard.cvv
    });

    console.log('\n\nthis is creditCard', creditCard);

    if (creditCard && creditCard.encryptedCard && !creditCard.hasErrors) {
      return creditCard;
    }

    return null;

  }

  const saveCreditCard = async (providedCreditCard) => {
    try {
      const creditCard = setCreditCard(providedCreditCard);

      if (creditCard) {
        // const crediCardHash = await creditCard.hash();

        const crediCardHash = creditCard.encryptedCard;

        const providedCreditCardNumber = providedCreditCard.number.replace(/ /g, '');

        const CreditCard = Parse.Object.extend('PSCreditCard');
        const newCreditCard = new CreditCard({
          flag: MoipSdkJs.MoipValidator.cardType(providedCreditCardNumber).brand,
          finalNumbers: providedCreditCardNumber.substr(providedCreditCardNumber.length - 4),
          hashedCreditCard: crediCardHash
        });
        await newCreditCard.save();
      } else {
        throw "Cartão de Crédito Inválido.";
      }
    }
    catch (e) {
      console.log('got error', e);
      throw e;
    }

  };

  const removeCreditCard = async (creditCard) => {
    try {
      await creditCard.destroy();
    }
    catch (e) {
      console.log('error removing card', e);
      throw e;
    };
  };

  const getFavoriteCard = async () => {
    try {
      const cardId = await AsyncStorage.getItem('favoriteCardId');
      return cardId;
    } catch (e) {
      throw e;
    }
  };

  const setFavoriteCard = async (cardId) => {
    try {
      const currentFavoriteCardId = await getFavoriteCard();
      if (currentFavoriteCardId === cardId) return;
      await AsyncStorage.setItem('favoriteCardId', cardId);
    } catch (e) {
      throw e;
    }
  };

  const getCardsAndFavorite = async (setCreditCardSelected) => {
    try {
      let selectedCreditCard = null;
      const fullCreditCardList = await getCreditCards();

      if (fullCreditCardList && fullCreditCardList.length > 0) {
        const favoriteCardId = await getFavoriteCard();
        
        if (favoriteCardId) {
          for (let i = 0; i < fullCreditCardList.length; i++) {
            if (fullCreditCardList[i].id === favoriteCardId) {
              selectedCreditCard = fullCreditCardList[i];
              setCreditCardSelected(selectedCreditCard);
              break;
            }
          }
        }
        if (!selectedCreditCard) {
          selectedCreditCard = fullCreditCardList[0];
          setCreditCardSelected(selectedCreditCard);
        }
      }
      return fullCreditCardList;
    }
    catch (e) {
      throw e;
    }
  };

  return [saveCreditCard, getCreditCards, removeCreditCard, getFavoriteCard, setFavoriteCard, getCardsAndFavorite, setCreditCard];

};

