module.exports = {

  appName: 'FAZ Digital Salvador',

  applicationId: 'UxaAh2tedETscMZfLUSVBJ3WktMFGexNHEHup39y9ASd6fa6sUgbnKUbrkaFQ7VX5fxRsWWnSuUcBbJZb8y8wE8mKfeqNAhNkbczYYVB4LuUBj66uqNhgAmRw7UsrpCG',

  //prod
  // serverURL: 'https://zonaazuldigitalsalvador.com/parse',

  //tests
  serverURL: 'https://inovatestserver.com/parse',
  // serverURL: 'https://434b-177-45-173-38.ngrok.io/parse/',

  appVersion: '3.0.0',
  appReleaseDate: '01/Setembro/2024',

  //TEST KEY
  // encPubKey: "-----BEGIN PUBLIC KEY-----"+"\n"+
  // "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjRMOlDmBYGa8zz2w1C6c"+
  // "iCWzGq6KSziEouUTFcPSfeXA5BAK9mveeFWqvJ+KO9E0eU1f66KPoo1lCtwfcLRG"+
  // "EiozbLGajQn+G2Pp0y2zyZ2g7lJctTkyyQTZhxSgodLVodgOsQlaCwu0NeRWBOUl"+
  // "Y4affffT8CP/9Ck5W+hQERkRRxGorADeUQYMCK2u2rPjmzouU7xK78EKFU0nCfG5"+
  // "hJ03pAhtJiRkpKNLH5XqPDAMgJ3Bfw7n1t8cf2KYlwc+rOyKb+nBTQOxrtC0GgSs"+
  // "6rWderW728Uf9MBW9o32/cJGY/jGjlUOOvIC68Csp5DOrxXeIBGsCnmxpnNUwLmM"+
  // "mQIDAQAB"+"\n"+
  // "-----END PUBLIC KEY-----",

  //PROD KEY
  // encPubKey: "-----BEGIN PUBLIC KEY-----" + "\n" +
  //   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAomg9hez1DRChNQjNWeNN" +
  //   "/VWHKC1lhStTkFc66gascl41O/LrbmsvrwNB+Pj2YFVFGqQKFTDq54ybvmgKa7BV" +
  //   "ENSQQy+xFRg5ARZGBxA9IqcorhUiEg81T7wM+KpiU66sJe4TwBF85kR+Tj2Ajkyq" +
  //   "iiBxZOKf7HPTV4/zk8NB8MO16ne1vDjUDPyc+CWM4LSQpCFPPgwqTAbBQuX3xJGf" +
  //   "OX2QZpVXp0M0OTVNJTXhmn4Q/EtU5NRpIITbB9bg6KTH4IZSrxWWowpl770fwkZ0" +
  //   "WOenTFWgECHRI0tFQOJcyDkKtK5iteoaFW7VR3/Lw9GZOrA21whDHzSIDxGzpGzS" +
  //   "LwIDAQAB" + "\n" +
  //   "-----END PUBLIC KEY-----",

  encPSPubKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAumnMqWXZXgh3Wj94NaxqU6tnd2L8M5MKGc7P/WYESqRJJZjcVwN+rlkoC2hugkbNG7AKp8naeSO/H0gOqbWP/KpZSYzVpMAEZGBV1W/Z01ZXvAANRym89tsxVtFftvr42dFjqaXotUDeoh7U5Zq9K5CmAi0LS07K85rR3WA1bQV0dsdikx2YnIwoEEe3tPYeA0FPZgWXtUoe8fHoQX/aWc7RtVCSoDGTEGr+IJxOlDohQvZR/Es03+kdn3EEsDvBHffwpAV+uHSjzijp82JTNX4umsXtA/iUrCBcAtc5xBBcHzN2Lw+5WW7rsei8Eb8gvP1+ZPMVSzr2q/VhIaR/RQIDAQAB',

  contactPhone: '(71) 4042 2812',
  contactPhoneToCall: '+557140422812',
  contactMail: 'contato@zonaazuldigitalsalvador.com',
  developerName: 'Inova Soluções em Tecnologia e Gestão Ltda.',
  developerWebsite: 'http://www.inova.app',
  developerWebsiteSimplified: 'inova.app',

  webSite: 'https://www.zonaazuldigitalsalvador.com',
  webSiteSimplified: 'zonaazuldigitalsalvador.com',
  // webPortal: 'https://www.zonaazuldigitalsalvador.com/novoPortal',
  // webPortalSimplified: 'zonaazuldigitalsalvador.com/novoPortal',
  webPortal: 'https://inovatestserver.com/',
  webPortalSimplified: 'inovatestserver.com',

  // show Call Central option to reset password - BRT:
  // Mon-Fri 8h30 - 16h30
  // Saturday 9h - 11h30
  centralBusinessDayStartHourUTC: 11,
  centralBusinessDayStartMinutesUTC: 30,
  centralBusinessDayEndHourUTC: 19,
  centralBusinessDayEndMinutesUTC: 30,
  centralSaturdayStartHourUTC: 12,
  centralSaturdayStartMinutesUTC: 0,
  centralSaturdayEndHourUTC: 14,
  centralSaturdayEndMinutesUTC: 30,

  maxVehicleDescriptionLength: 15

}
