import Parse from "parse";

export default () => {

  const CONNECTION_FAILED = Parse.Error.CONNECTION_FAILED;
  const SESSION_MISSING = Parse.Error.SESSION_MISSING;
  const INVALID_SESSION_TOKEN = Parse.Error.INVALID_SESSION_TOKEN;
  const INTERNAL_SERVER_ERROR = Parse.Error.INTERNAL_SERVER_ERROR;

  const MESSAGES = {
    UNKNOWN: 'Um erro inesperado ocorreu. Por favor, tente novamente'
  };

  MESSAGES[CONNECTION_FAILED] = 'Sua internet falhou. Por favor, tente novamente';
  MESSAGES[SESSION_MISSING] = 'Sessão do usuário expirada ou não encontrada';
  MESSAGES[INVALID_SESSION_TOKEN] = 'Sua sessão expirou. Por favor, entre novamente.';
  MESSAGES[INTERNAL_SERVER_ERROR] = 'Erro interno do servidor';

  let EYSA_ERRORS = [];
  EYSA_ERRORS[2] = 'A operação foi recusada pelo mecanismo tarifário.';
  EYSA_ERRORS[5] = 'O PDV não foi encontrado ou não está associado à empresa credenciada.';
  EYSA_ERRORS[6] = 'A operação está fora do perímetro geográfico estabelecido para o ponto de venda.';
  EYSA_ERRORS[7] = 'O valor monetário da operação de inativação supera o valor consumido nos lotes de créditos do ticket.';
  EYSA_ERRORS[9] = 'A data de inativação é superior a atual.';
  EYSA_ERRORS[10] = 'Já existe um ticket vigente para o mesmo setor e tarifa.';
  EYSA_ERRORS[11] = 'O identificador de ticket fornecido não foi encontrado.';
  EYSA_ERRORS[12] = 'O identificador de validação fornecido não foi encontrado.';
  EYSA_ERRORS[13] = 'Erro ao executar a operação de criação.';
  EYSA_ERRORS[15] = 'Operação rejeitada porque os dados de validação não estão corretos.';
  EYSA_ERRORS[18] = 'O ticket não é mais válido. Provavelmente já foi cancelado. Em caso de dúvida contate o suporte.';

  const getErrorMsg = (error, defaultMessage, eysaError) => {
    let defaultMsg = defaultMessage;

    if (!defaultMsg || ((typeof defaultMsg !== 'string') && !(defaultMsg instanceof String))) defaultMsg = MESSAGES.UNKNOWN;
    if (!error) {
      return defaultMsg;
    } else if (MESSAGES[error.code]) {
      return MESSAGES[error.code];
    } else if (EYSA_ERRORS[eysaError]) {
      return EYSA_ERRORS[eysaError];
    } else if (!error.code) {
      if (error.message) return error.message;
      else return error;
    }
    console.log('Will return defaultMsg', defaultMsg);
    return defaultMsg;
  };

  const handleError = (error, defaultMessage, eysaError) => {
    console.log('this is ERROR to handle', error, defaultMessage, eysaError);

    //custom server error messages
    if(error && error.message && error.message.special && typeof error.message.special === 'string') {
      return error.message.special;
    }

    else if (error && error.code === 141 && error.message && !defaultMessage) {
      if (error.message.errorCode) {
        if (error.message.errorCode === -1) {
          defaultMessage = 'Ocorreu um erro ao comunicar com a URBS';
        } else {
          defaultMessage = error.message.message || error.message.error;
        }
      } else if (error.message.code) {
        if (EYSA_ERRORS[error.message.code]) {
          defaultMessage = EYSA_ERRORS[error.message.code];
        }
        else defaultMessage = null;
      } else {
        defaultMessage = error.message;
      }
      error = null;
    }
    return getErrorMsg(error, defaultMessage, eysaError);

  };



  return [handleError];

};