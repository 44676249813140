import Parse from 'parse';
import appParams from '../../constants/AppParams';
import useExpiredSession from '../hooks/useExpiredSession';

export default () => {

  const [checkIfSessionExpired] = useExpiredSession();

  const createOrder = async (amount, price, paymentTypeCode) => {
    if (!paymentTypeCode) throw 'paymentTypeCode must be specified';

    const requestOptions = {
      amount: amount,
      price: price,
      paymentTypeCode: paymentTypeCode,
      appVersion: appParams.appVersion
    };

    return await Parse.Cloud.run("createOrder", requestOptions);
  };

  const payOrder = async (orderId, hashedCreditCard) => {
    if (!orderId || !hashedCreditCard) throw 'orderId and hashedCreditCard must be specified';

    const requestOptions = {
      orderId,
      hashedCreditCard
    };

    return await Parse.Cloud.run("payOrder", requestOptions);
  };

  const createBankSlipOrder = async (orderId) => {
    if (!orderId) throw 'orderId must be specified';

    const requestOptions = {
      orderId: orderId
    };

    return await Parse.Cloud.run("payInvoice", requestOptions);
  };

  const createTransferOrder = async (price, bankData, cashOrder) => {
    if (!cashOrder) {
      cashOrder = false;
    }
    const requestOptions = {
      price: price,
      paymentTypeCode: 4,
      bankData: bankData,
      cashOrder: cashOrder,
      appVersion: appParams.appVersion
    };

    return await Parse.Cloud.run("createOrder", requestOptions);
  };

  const cancelTransferOrder = async () => {
    return await Parse.Cloud.run("cancelTransferOrder");
  };

  const cancelCashOrder = async () => {
    return await Parse.Cloud.run("cancelCashOrder");
  };

  const cancelBankSlipOrder = async () => {
    return await Parse.Cloud.run("cancelInvoiceOrder", "");
  };

  const sendBankSlipMail = async (params) => {
    return await Parse.Cloud.run("sendInvoiceMail", params);
  };

  const findTransferOrder = async (transferOrderId) => {    
    try {
      const TransferOrder = Parse.Object.extend('TransferOrder');
      const transferOrderQuery = new Parse.Query(TransferOrder);
      const transferOrder =  await transferOrderQuery.get(transferOrderId);

      return transferOrder;
    }
    catch(e){
      console.log('error finding TransferOrder', e);
      checkIfSessionExpired(e);
      return null;
    }
  };

  return [createTransferOrder, cancelTransferOrder, cancelCashOrder, createOrder, createBankSlipOrder, cancelBankSlipOrder, sendBankSlipMail, payOrder, findTransferOrder];

};
