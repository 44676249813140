import React, { useState, useEffect } from 'react';
import { Alert, View, StyleSheet, Keyboard } from 'react-native';
import alert from '../hooks/useAlert.js'
import { Input } from 'react-native-elements';
import '../lib/moip.min.js'; //imports MoipSdkJs

const CreditCard = ({ creditCard, onCreditCardChange, performFinalCheck, allSet, setAllSet }) => {

  const [creditCardError, setCreditCardError] = useState({});
  const [cardFieldBlurred, setCardFieldBlurred] = useState({});

  useEffect(() => {
    let errorMsg = null;
    if (performFinalCheck > 0) {

      if (!creditCard.number || !creditCard.expiryDate || !creditCard.cvv || !creditCard.holder || creditCard.holder.trim().length < 1) {
        errorMsg = 'Por favor, preencha todos os dados do cartão de crédito, incluindo titular, número, validade e cvv.';
      }
      else if (!validCreditCard('number', creditCard.number)) {
        errorMsg = 'Por favor, preencha um número de cartão de crédito válido.';
      } else if (!validCreditCard('expiryDate', creditCard.expiryDate)) {
        errorMsg = 'Por favor, preencha uma data de validade válida.';
      } else if (!validCreditCard('cvv', creditCard.number, creditCard.cvv)) {
        errorMsg = 'Por favor, preencha um CVV válido.';
      }

      if (errorMsg) {
        setAllSet(0);

        alert(
          'Erro nos dados do cartão',
          errorMsg,
          null,
          true
        );

        // Alert.alert(
        //   'Erro nos dados do cartão',
        //   errorMsg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false },
        // );
      } else {
        setAllSet(allSet + 1);
      }
    }
  }, [performFinalCheck]);

  let inputs = {};

  const focusNextField = (id) => {
    inputs[id].focus();
  };

  const validCreditCard = (attribute, v1, v2) => {
    switch (attribute) {
      case 'number':
        if (v1 && MoipSdkJs.MoipValidator.isValidNumber(v1)) {
          if (creditCardError.number) setCreditCardError({ ...creditCardError, number: null });
          return true;
        }
        else {
          if (!creditCardError.number) setCreditCardError({ ...creditCardError, number: 'Número de cartão de crédito inválido' });
          return false;
        }
      case 'expiryDate':
        if (v1) {
          const expiryDate = v1.split('/');
          if (expiryDate.length === 2 && expiryDate[0].length === 2 && expiryDate[1].length === 2) {
            v1 = expiryDate[0];
            v2 = expiryDate[1];
          }
        }

        if (v1 && MoipSdkJs.MoipValidator.isExpiryDateValid(v1, v2)) {
          if (creditCardError.expiryDate) setCreditCardError({ ...creditCardError, expiryDate: null });
          return true;
        }
        else {
          if (!creditCardError.expiryDate) setCreditCardError({ ...creditCardError, expiryDate: 'Data de validade inválida' });
          return false;
        }

      case 'cvv':
        if (v1 && v2 && MoipSdkJs.MoipValidator.isSecurityCodeValid(v1, v2)) {
          if (creditCardError.cvv) setCreditCardError({ ...creditCardError, cvv: null });
          return true;
        }
        else {
          if (!creditCardError.cvv) setCreditCardError({ ...creditCardError, cvv: 'CVV inválido' });
          return false;
        }
    }
  };

  const setCardFieldWithMask = (field, value, cardNumber) => {

    if (!value) return;

    switch (field) {
      case 'number':
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{4})/g, '$1 ').trim();
        value = value.substring(0, 23);
        if (validCreditCard('number', value)) {
          focusNextField('expiryDate');
        }
        return value;
      case 'expiryDate':
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{2})(\d)/, "$1/$2");
        value = value.substring(0, 5);
        if (validCreditCard('expiryDate', value)) {
          focusNextField('cvv');
        }
        return value;
      case 'cvv':
        value = value.replace(/\D/g, "");
        value = value.substring(0, 4);
        if (validCreditCard('cvv', cardNumber, value)) {
          Keyboard.dismiss();
        }
        return value;
    }
  };

  return (
    <View>

      <View>
        <Input
          ref={input => { inputs['holder'] = input }}
          renderErrorMessage={cardFieldBlurred.holder && creditCardError.holder ? true : false}
          containerStyle={styles.fieldContainer}
          inputContainerStyle={(cardFieldBlurred.holder && creditCardError.holder) ? styles.inputContainerError : styles.inputContainer}
          inputStyle={styles.input}
          label='Nome do Titular no Cartão:'
          labelStyle={styles.label}
          value={creditCard.holder}
          onChangeText={(holder) => onCreditCardChange({ ...creditCard, holder: holder.substring(0, 30) })}
          onBlur={() => { if (!cardFieldBlurred.holder) setCardFieldBlurred({ ...cardFieldBlurred, holder: true }) }}
          onSubmitEditing={() => { if (!cardFieldBlurred.holder) setCardFieldBlurred({ ...cardFieldBlurred, holder: true }); focusNextField('number'); }}
          autoCorrect={false}
          returnKeyType='next'
          blurOnSubmit={false}
          errorStyle={styles.errorMessage}
          errorMessage={cardFieldBlurred.holder ? creditCardError.holder : null}
        />
        <Input
          ref={input => { inputs['number'] = input }}
          renderErrorMessage={cardFieldBlurred.number && creditCardError.number ? true : false}
          containerStyle={styles.fieldContainer}
          inputContainerStyle={(cardFieldBlurred.number && creditCardError.number) ? styles.inputContainerError : styles.inputContainer}
          inputStyle={styles.input}
          label='Número do Cartão de Crédito:'
          labelStyle={styles.label}
          value={creditCard.number}
          onChangeText={(cardNumber) => onCreditCardChange({ ...creditCard, number: setCardFieldWithMask('number', cardNumber) })}
          onBlur={() => { if (!cardFieldBlurred.number) setCardFieldBlurred({ ...cardFieldBlurred, number: true }) }}
          onSubmitEditing={() => { if (!cardFieldBlurred.number) setCardFieldBlurred({ ...cardFieldBlurred, number: true }); focusNextField('expiryDate'); }}
          autoCorrect={false}
          keyboardType='numeric'
          returnKeyType='next'
          blurOnSubmit={false}
          errorStyle={styles.errorMessage}
          errorMessage={cardFieldBlurred.number ? creditCardError.number : null}
        />
        <View style={{ flexDirection: 'row' }}>
          <Input
            ref={input => { inputs['expiryDate'] = input }}
            renderErrorMessage={cardFieldBlurred.expiryDate && creditCardError.expiryDate ? true : false}
            containerStyle={[styles.fieldContainer, { flex: 2 }]}
            inputContainerStyle={(cardFieldBlurred.expiryDate && creditCardError.expiryDate) ? styles.inputContainerError : styles.inputContainer}
            inputStyle={styles.input}
            label='Validade (mm/aa):'
            labelStyle={styles.label}
            value={creditCard.expiryDate}
            onChangeText={(cardExpiryDate) => onCreditCardChange({ ...creditCard, expiryDate: setCardFieldWithMask('expiryDate', cardExpiryDate) })}
            onBlur={() => { if (!cardFieldBlurred.expiryDate) setCardFieldBlurred({ ...cardFieldBlurred, expiryDate: true }) }}
            onSubmitEditing={() => { if (!cardFieldBlurred.expiryDate) setCardFieldBlurred({ ...cardFieldBlurred, expiryDate: true }); focusNextField('cvv'); }}
            autoCorrect={false}
            keyboardType='numeric'
            returnKeyType='next'
            blurOnSubmit={false}
            errorStyle={styles.errorMessage}
            errorMessage={cardFieldBlurred.expiryDate ? creditCardError.expiryDate : null}
          />
          <Input
            ref={input => { inputs['cvv'] = input }}
            renderErrorMessage={cardFieldBlurred.cvv && creditCardError.cvv ? true : false}
            containerStyle={[styles.fieldContainer, { flex: 1 }]}
            inputContainerStyle={(cardFieldBlurred.cvv && creditCardError.cvv) ? styles.inputContainerError : styles.inputContainer}
            inputStyle={styles.input}
            label='CVV:'
            labelStyle={styles.label}
            value={creditCard.cvv}
            onChangeText={(cardCVV) => onCreditCardChange({ ...creditCard, cvv: setCardFieldWithMask('cvv', cardCVV, creditCard.number) })}
            onBlur={() => { if (!cardFieldBlurred.cvv) setCardFieldBlurred({ ...cardFieldBlurred, cvv: true }) }}
            onSubmitEditing={() => { if (!cardFieldBlurred.cvv) setCardFieldBlurred({ ...cardFieldBlurred, cvv: true }); Keyboard.dismiss() }}
            autoCorrect={false}
            keyboardType='numeric'
            errorStyle={styles.errorMessage}
            errorMessage={cardFieldBlurred.cvv ? creditCardError.cvv : null}
            blurOnSubmit={false}
          />
        </View>
      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  creditCardData: {
    marginTop: 10
  },
  errorMessage: {
    color: '#EF6F6C',
    fontFamily: 'defaultFont',
    fontSize: 14
  },
  fieldContainer: {
    marginBottom: 5
  },
  label: {
    color: '#0992A5',
    fontWeight: 'normal',
    fontFamily: 'defaultFont'
  },
  input: {
    color: '#424242',
    fontFamily: 'defaultFont',
    outlineStyle: 'none'
  },
  inputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE'
  },
  inputContainerError: {
    borderBottomWidth: 1,
    borderBottomColor: '#EF6F6C'
  }
});

export default CreditCard;
