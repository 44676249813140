import Parse from 'parse';
import { Platform } from 'react-native';

export default () => {

  const getWarningMessage = async () => {
    try {

      const config = await Parse.Config.get();
      const warningMessage = config.get('newGeneralPublicMessage');
      return warningMessage;

    }
    catch(e) {
      console.log('error getting server config', e);
      throw e;
    }
  };

  const getBalance = async () => {
    try {
      const Balance = Parse.Object.extend("Balance");
      const balanceQuery = new Parse.Query(Balance);
      balanceQuery.equalTo("user", Parse.User.current());
      const results = await balanceQuery.find();

      let balance = null;
      if (results && results[0]) {
        balance = results[0];
      }
      else {
        balance = {
          value: 0
        };
      }

      return balance;
    }
    catch (e) {
      console.log('error getting balance', e);
      throw e;
    }
  };

  return [getBalance, getWarningMessage];

};