import Parse from 'parse';
import appParams from '../../constants/AppParams';
import Constants from 'expo-constants';
import { getUniqueId } from 'react-native-device-info';

export default () => {

  const parkVehicle = async (extension,
    geoCoordinates,
    vehiclePlate,
    vehicleDescription,
    parkingCardTime,
    address,
    vehicleCategory,
    customStartDate,
    scheduled,
    alsoAcquisition) => {

    const timeNow = new Date();
    const timeNowTimestamp = timeNow.getTime();
    const timeNowISO = timeNow.toISOString();

    const evalAddress = (address) => {
      const result = {
        // displayName: (address && address.place_name) ? address.place_name.split('-')[0] : null,
        displayName: (address && address.text) ? address.text : null,
        coords: {
          latitude: (address && address.center) ? address.center[1] : null,
          longitude: (address && address.center) ? address.center[0] : null
        }
      }
      return result;
    };


    let transactionData = {
      uuid: getUniqueId(),
      latitude: geoCoordinates ? geoCoordinates.latitude : 0,
      longitude: geoCoordinates ? geoCoordinates.longitude : 0,
      placa: vehiclePlate.replace(/[^0-9a-z]/gi, ''),
      descricaoVeiculo: vehicleDescription,
      precoTotal: parkingCardTime.price,
      startDate: customStartDate && scheduled ? customStartDate : null,
      maxParkingMinutes: parkingCardTime.maxMinutes,
      locationIdTransalvador: address.locationIdTransalvador,
      appVersion: appParams.appVersion,
      categoria: vehicleCategory,
      ruleId: parkingCardTime.ruleId,
      scheduled: scheduled ? true : null,
      extension: extension ? true : null
    }

    try {
      let activation;
      if (alsoAcquisition) {
        transactionData.missingCredit = parkingCardTime.missingCredit;
        transactionData.hashedCreditCard = parkingCardTime.hashedCreditCard;
        transactionData.saveCreditCard = parkingCardTime.saveCard;
        transactionData.useHashAsCardId = parkingCardTime.useHashAsCardId;
        transactionData.usePSCardProvider = true;
        
        console.log('\n\n\nwill run activationAndAquisition with transactionData', transactionData);
        activation = await Parse.Cloud.run('acquisitionAndActivation', transactionData);
      }
      else {
        console.log('\n\n\nwill run activation with transactionData', transactionData);
        activation = await Parse.Cloud.run('activation', transactionData);
      }
      return activation;
    } catch (e) {
      console.log('\n\n\nprocess resulted in error', e);
      throw e;
    }

  };

  const selectQueryTransactionField = (parseQuery) => {
    parseQuery.select('expireDate');
    parseQuery.select('startDate');
    parseQuery.select('license');
    parseQuery.select('totalPrice');
    parseQuery.select('vehicle');
    parseQuery.select('vehicleDescription');
    parseQuery.select('confirmedAddress');
    parseQuery.select('locationIdTransalvador');
    parseQuery.select('category');
    parseQuery.select('geolocation');
    parseQuery.select('maxParkingMinutes');
    parseQuery.select('locationName');
    parseQuery.select('isMercosulPlate');
    parseQuery.select('extendedExpireDate');
    parseQuery.select('scheduled');
    parseQuery.select('priceRuleName');
    parseQuery.select('locationParkingRuleIdTransalvador');
  };

  const getActiveTransactions = async () => {

    const currentTimestamp = new Date().getTime();
    const TransactionCache = Parse.Object.extend('TransactionCache');
    const user = Parse.User.current();

    try {
      const TransactionCache = Parse.Object.extend('TransactionCache');
      const user = Parse.User.current();

      const expireQuery = new Parse.Query(TransactionCache);
      expireQuery.greaterThanOrEqualTo('expireDate', currentTimestamp);
      expireQuery.equalTo('user', user);
      expireQuery.notEqualTo('canceled', true);
      expireQuery.notEqualTo('terminated', true);
      expireQuery.doesNotExist('extendedTransaction');

      const extendedQuery = new Parse.Query(TransactionCache);
      extendedQuery.greaterThanOrEqualTo('extendedExpireDate', currentTimestamp);
      extendedQuery.equalTo('user', user);
      extendedQuery.notEqualTo('canceled', true);
      extendedQuery.notEqualTo('terminated', true);
      extendedQuery.doesNotExist('extendedTransaction');

      const mainQuery = Parse.Query.or(extendedQuery, expireQuery);
      selectQueryTransactionField(mainQuery);
      mainQuery.descending('createdAt');

      const activeTransactions = await mainQuery.find();

      console.log('GOT activeTransactions', activeTransactions);

      if (activeTransactions) {
        for (let i = 0; i < activeTransactions.length; i++) {
          activeTransactions[i] = activeTransactions[i]._toFullJSON();

          if (activeTransactions[i].extendedExpireDate) {
            activeTransactions[i].extensionDate = activeTransactions[i].expireDate;
            activeTransactions[i].expireDate = activeTransactions[i].extendedExpireDate;
          }
        }
      }

      return activeTransactions;

    } catch (e) {
      throw e;
    }
  };

  const toISOLocalDate = (date) => {
    const now = date,
      tzo = -now.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return now.getFullYear()
      + '-' + pad(now.getMonth() + 1)
      + '-' + pad(now.getDate())
      + 'T' + pad(now.getHours())
      + ':' + pad(now.getMinutes())
      + ':' + pad(now.getSeconds());
  };

  const purchaseCredits = async (opt) => {

    const uuid = getUniqueId();

    const options = {
      precoTotal: opt.price,
      uuid,
      appVersion: appParams.appVersion,
      hashedCreditCard: opt.hashedCreditCard,
      saveCreditCard: opt.saveCreditCard,
      conectCarPlate: opt.conectCarPlate,
      usePSCardProvider: true,
      useHashAsCardId: opt.useHashAsCardId
    };
    
    console.log('\n\n\nwill run acquisition with options', options);

    return await Parse.Cloud.run("acquisition", options);

  };

  const sendReceiptMail = (transactions) => {

    Parse.Cloud.run("sendFullReceiptMail", {
      transactions
    });

  }

  const getHistoryTransactions = async (iniDate, endDate, skip, fetchAcquisitions, limit) => {

    try {
      const Transaction = Parse.Object.extend("Transaction");
      let query;

      const getTransactionFormattedAddress = (tr) => {
        if (tr.address) {
          if (tr.address.displayName) {
            return tr.address.displayName;
          }
          else if (tr.address.components) {
            const addressComponents = tr.address.components;
            let address = "";
            if (addressComponents.street) {
              address += addressComponents.street;
              if (addressComponents.streetNumber) {
                address += ", " + addressComponents.streetNumber;
              }
            }
            else if (addressComponents.district) {
              address = addressComponents.district;
            }
            return address;
          }
        }
        return null;
      };

      const getTypeQuery = (type) => {
        const q = new Parse.Query(Transaction);
        q.equalTo("type", type);
        q.equalTo("user", Parse.User.current());
        q.greaterThanOrEqualTo("startDate", iniDate.getTime());
        if (endDate) {
          q.lessThanOrEqualTo("startDate", endDate.getTime());
        }
        return q;
      };

      if (fetchAcquisitions) {
        query = Parse.Query.or(getTypeQuery("1"), getTypeQuery("3"));
      } else {
        query = Parse.Query.or(getTypeQuery("2"), getTypeQuery("3"));
      }

      query.descending("createdAt");
      if (skip) {
        query.skip(skip);
      }
      if (limit) {
        query.limit(limit);
      }

      const results = await query.find();

      const transactionArray = [];
      for (const i in results) {
        const transaction = results[i]._toFullJSON();
        transaction.formattedAddress = getTransactionFormattedAddress(
          transaction
        );
        transactionArray.push(transaction);
      }
      return transactionArray;
    }
    catch (e) {
      console.log('error getting transactions for history', e);
      throw e;
    }

  };

  const sendSmsVerification = async () => {
    return await Parse.Cloud.run("sendSmsCode", {
      androidDevice: Constants.platform.android ? true : false
    });
  };

  const validateSmsCode = async (code) => {
    if (!code || code.length < 4) throw 'Código inválido.';
    try {
      return await Parse.Cloud.run("validateSmsCode", { code });
    }
    catch (e) {
      throw e;
    }
  };

  const sendEmailVerification = async () => {
    return await Parse.Cloud.run("sendEmailVerification");
  };

  return [parkVehicle, getActiveTransactions, purchaseCredits, sendSmsVerification, validateSmsCode, sendEmailVerification, getHistoryTransactions, sendReceiptMail];

};
