import React from 'react';
import OfficialFooter from '../components/OfficialFooter';
import TCComponent from '../components/TermsAndConditionsComponent';

const TermsAndConditionsScreen = ({ navigation }) => {
  return (
    <>
      <TCComponent
        navigation={navigation}
        loggedIn={true}
      />
      <OfficialFooter />
    </>
  );
};

export default TermsAndConditionsScreen;
