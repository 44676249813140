import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, ActivityIndicator, TouchableOpacity, Alert, Image } from 'react-native';
import alert from '../hooks/useAlert.js'
import { SafeAreaView } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import NoVehicle from '../../assets/images/no-vehicle.svg';
import constants from '../../constants/AppParams';
import useGetVehicles from '../hooks/useGetVehicles';
import NewVehicle from '../components/NewVehicle';
import useExpiredSession from '../hooks/useExpiredSession';
import Parse from "parse";

const VehicleList = ({ fromState, setVehicleToPark, setVehicleListModalVisible, navigation, modalView }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const NOT_LOADED = 0;
  const LOADED_WITH_ERROR = -1;
  const LOADED = 1;

  const MAX_VEHICLE_DESCRIPTION_LENGTH = constants.maxVehicleDescriptionLength;

  const [loadingState, setLoadingState] = useState(NOT_LOADED);
  const [vehicles, setVehicles] = useState(null);
  const [savingVehicle, setSavingVehicle] = useState(false);
  const [addVehicleModalVisible, setAddVehicleModalVisible] = useState(false);
  const [goGetVehicles, getFavoriteVehicle, goSetFavoriteVehicle] = useGetVehicles();

  const setFavoriteVehicle = async (vehicleId, updateVehicles) => {

    setSavingVehicle(true);
    try {
      await goSetFavoriteVehicle(vehicleId);

      if (updateVehicles) {
        for (let i = 0; i < vehicles.length; i++) {
          if (vehicles[i].id === vehicleId) vehicles[i].set('favoriteVehicle', true);
          else vehicles[i].set('favoriteVehicle', false);
        }
      }
      setSavingVehicle(false);
    } catch (e) {
      console.log('error setting favorite vehicle', e);
      setSavingVehicle(false);

      alert(
        'Oops',
        'Ocorreu um erro ao definir o veículo como favorito. Tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops',
      //   'Ocorreu um erro ao definir o veículo como favorito. Tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );

      checkIfSessionExpired(e);
    }
  };

  const sortAndFormat = async (vehicles) => {

    if (!vehicles) return;

    vehicles.sort(function (vehicleA, vehicleB) {
      const licensePlateA = vehicleA.get('vehicle').get('licensePlate');
      const licensePlateB = vehicleB.get('vehicle').get('licensePlate');
      if (licensePlateA < licensePlateB) {
        return -1;
      } else {
        return 1;
      }
    });

    let favoriteVehicleId = await getFavoriteVehicle();
    let shouldSetNewFavoriteVehicle = true;

    for (let i = 0; i < vehicles.length; i++) {

      if (vehicles[i].id === favoriteVehicleId) {
        vehicles[i].set('favoriteVehicle', true);
        shouldSetNewFavoriteVehicle = false;
      }

      let licensePlate = (vehicles[i].get('vehicle').get('licensePlate')).trim();

      if (licensePlate.trim().match(/^[A-Za-z0-9]{6}[0-9]{1}$/) || licensePlate.trim().match(/^[A-Za-z]{3}[0-9]{4}$/)) {
        let spacer = " ";
        if (/^\d+$/.test(licensePlate[4])) {
          spacer = "-"
        }
        licensePlate = licensePlate.substring(0, 3) + spacer + licensePlate.substring(3, 7);
      }

      vehicles[i].get('vehicle').set('licensePlate', licensePlate);
    }

    if (shouldSetNewFavoriteVehicle) {
      favoriteVehicleId = vehicles[0].id;
      vehicles[0].set('favoriteVehicle', true);
      await setFavoriteVehicle(favoriteVehicleId);
    }

    return vehicles;

  };

  const getVehicles = async () => {
    setLoadingState(NOT_LOADED);

    try {
      let vehicles = await goGetVehicles();

      if (vehicles && vehicles.length > 0) {
        vehicles = await sortAndFormat(vehicles);
      }
      setVehicles(vehicles);

      setLoadingState(LOADED);
    }
    catch (e) {
      console.log('got this error:', e);
      setLoadingState(LOADED_WITH_ERROR);
      checkIfSessionExpired(e);
    }
  };

  const removeVehicle = (userVehicle) => {

    setSavingVehicle(userVehicle.id);

    async function yesRemove() {

      try {
        const userVehicleId = userVehicle.id;
        await Parse.Cloud.run('deleteUserVehicle', { vehicleId: userVehicleId });

        const favoriteVehicle = await getFavoriteVehicle();
        if (favoriteVehicle === userVehicleId) {
          AsyncStorage.removeItem('favoriteVehicleId');
        }

        getVehicles();
        setSavingVehicle(null);

        alert(
          'Pronto!',
          'Veículo excluído com sucesso!',
          null,
          true
        );

        // Alert.alert(
        //   'Pronto!',
        //   'Veículo excluído com sucesso!',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
      }
      catch (e) {
        console.log('error removing vehicle', e);
        setSavingVehicle(null);

        alert(
          'Oops!',
          'Ocorreu um erro ao remover o veículo. Por favor, tente novamente.',
          null,
          true
        );

        // Alert.alert(
        //   'Oops!',
        //   'Ocorreu um erro ao remover o veículo. Por favor, tente novamente.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false }
        // );
        checkIfSessionExpired(e);
      }

    };

    alert(
      'Confirmação',
      'Tem certeza de que deseja remover o veículo de placa ' + userVehicle.get('vehicle').get('licensePlate') + '?',
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => setSavingVehicle(null)
        },
        {
          text: 'Sim',
          onPress: () => yesRemove()
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   'Tem certeza de que deseja remover o veículo de placa ' + userVehicle.get('vehicle').get('licensePlate') + '?',
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => setSavingVehicle(null)
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => yesRemove()
    //     }
    //   ],
    //   { cancelable: false }
    // );
  };

  const parkVehicle = (vehicle) => {
    if (fromState === 'parkingOptions') {
      setVehicleToPark(vehicle);
      setVehicleListModalVisible(false);
    }
    else {
      // nested navigators navigation with params
      navigation.navigate('ParkingStack', {
        screen: 'ParkingOptions',
        params: { vehicleId: vehicle.id, useVehicleAttributes: false }
      });
    }

  };

  useEffect(() => {
    if (modalView) {
      getVehicles();
    }
    else {
      const unsubscribe = navigation.addListener('focus', () => {
        getVehicles();
      });
      return unsubscribe;
    }
  }, [navigation]);

  return (
    <View style={styles.vehiclesContainer}>
      <View style={styles.mainViewContainer}>

        {/* Loading View */}
        {loadingState === NOT_LOADED ?
          <View style={styles.centerView}>
            <ActivityIndicator size={50} color='#284D64' />
            <Text style={styles.loadingText}>Carregando seus veículos...</Text>
          </View>
          : null}

        {/* Loaded With Error View */}
        {loadingState === LOADED_WITH_ERROR ?
          <View style={styles.centerView}>
            {/* <NoVehicle style={styles.noVehicleIcon} height='20%' width='40%' /> */}
            <Image
              source={require('../../assets/images/no-vehicle.png')}
              style={{ width: 80, height: 80, alignSelf: 'center' }}
            />
            <Text style={styles.noVehicleText}>Ocorreu um erro ao carregar seus veículos.</Text>
          </View>
          : null}

        {/* Regular View - with Vehicles  */}
        {vehicles && vehicles.length > 0 && loadingState !== NOT_LOADED && loadingState !== LOADED_WITH_ERROR ?
          <>
            {/* <Text style={styles.explanationText}>A placa com a estrela dourada será automaticamente carregada quando você for estacionar.</Text> */}
            <Text> </Text>
            <ScrollView>
              {vehicles ? vehicles.map((item, index) => (
                <View style={styles.vehicleItemContainer} key={item.id}>
                  {/* <View style={[styles.vehicleItem, (item.get('favoriteVehicle') ? styles.vehicleItemFavorite : null)]}> */}
                  <View style={styles.vehicleItem}>
                    <TouchableOpacity
                      onPress={() => {
                        setFavoriteVehicle(item.id, true)
                      }}
                      disabled={savingVehicle ? true : false}
                      style={styles.touchableVehicleItem}
                    >
                      {/* <FontAwesome
                        name={item.get('favoriteVehicle') ? 'star' : 'star-o'}
                        style={[styles.starIcon, (item.get('favoriteVehicle') ? styles.starIconFavorite : null)]}
                      /> */}
                      <FontAwesome
                        name={item.get('category') === 1 ? 'car' : item.get('category') === 2 ? 'motorcycle' : item.get('category') === 3 ? 'truck' : 'road'}
                        style={styles.carIcon}
                      />
                      <View style={styles.vehiclePlateContainer}>
                        <View style={styles.vehicleDescription}>
                          {(item.get('userDescription') && item.get('userDescription').trim().length) ?
                            <Text style={styles.vehicleDescriptionText}>{item.get('userDescription').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)}</Text>
                            : ((item.get('vehicle') && item.get('vehicle').get('description').trim().length) ?
                              <Text style={styles.vehicleDescriptionText}>{item.get('vehicle').get('description').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)}</Text> : null)}
                        </View>
                        <View style={styles.vehiclePlate}>
                          {item.get('vehicle') ? <Text style={styles.vehiclePlateText}>{item.get('vehicle').get('licensePlate')}</Text> : null}
                        </View>
                      </View>
                    </TouchableOpacity>

                    {/* <View style={{ flex: 1 }}>
                      <Button
                        buttonStyle={styles.parkVehicleButton}
                        title='Estacionar'
                        disabledStyle={styles.disabledButtonStyle}
                        titleStyle={styles.parkButtonTitle}
                        onPress={() => parkVehicle(item)}
                      />
                    </View> */}

                  </View>

                  <View style={styles.trashIconView}>
                    <TouchableOpacity
                      onPress={() => {
                        removeVehicle(item)
                      }}
                      style={styles.trashIconContainer}
                      disabled={savingVehicle ? true : false}
                    >
                      <FontAwesome
                        name='trash'
                        style={[styles.trashIcon, (savingVehicle === item.id ? { display: 'none' } : null)]}
                      />
                      <View style={savingVehicle !== item.id ? { display: 'none' } : null}>
                        <ActivityIndicator size={18} color='#EF6F6C' />
                      </View>
                    </TouchableOpacity>
                  </View>

                </View>
              )) : null}
            </ScrollView>
          </>
          : null}

        {/* Regular View - no Vehicles  */}
        {(!vehicles || vehicles.length === 0) && loadingState !== NOT_LOADED && loadingState !== LOADED_WITH_ERROR ?
          <View style={styles.centerView}>
            {/* <NoVehicle style={styles.noVehicleIcon} height='20%' width='40%' /> */}
            <Image
              source={require('../../assets/images/no-vehicle.png')}
              style={{ width: 80, height: 80, alignSelf: 'center' }}
            />

            <Text style={styles.noVehicleText}>Você ainda não possui veículos cadastrados.</Text>
          </View>
          : null}
      </View>

      {/* New Vehicle Button  */}
      {loadingState === LOADED ?
        (modalView ?
          <SafeAreaView>
            <Button
              buttonStyle={styles.addVehicleButton}
              title='Cadastrar Novo Veículo'
              disabledStyle={styles.disabledButtonStyle}
              titleStyle={styles.buttonTitle}
              onPress={() => setAddVehicleModalVisible(true)}
            />
          </SafeAreaView>
          :
          <Button
            buttonStyle={styles.addVehicleButton}
            title='Cadastrar Novo Veículo'
            disabledStyle={styles.disabledButtonStyle}
            titleStyle={styles.buttonTitle}
            onPress={() => setAddVehicleModalVisible(true)}
          />
        )
        : null}

      {/* Retry Loading Vehicles Button */}
      {loadingState === LOADED_WITH_ERROR ?
        (modalView ?
          <SafeAreaView>
            <Button
              buttonStyle={styles.errorButton}
              title='Tentar Novamente'
              disabledStyle={styles.disabledButtonStyle}
              titleStyle={styles.buttonTitle}
              onPress={() => getVehicles()}
            />
          </SafeAreaView>
          :
          <Button
            buttonStyle={styles.errorButton}
            title='Tentar Novamente'
            disabledStyle={styles.disabledButtonStyle}
            titleStyle={styles.buttonTitle}
            onPress={() => getVehicles()}
          />
        )
        : null}

      <NewVehicle
        addVehicleModalVisible={addVehicleModalVisible}
        setAddVehicleModalVisible={setAddVehicleModalVisible}
        getVehicles={getVehicles}
      />

    </View >
  );
};

const styles = StyleSheet.create({
  noVehicleIcon: {
    alignSelf: 'center'
  },
  disabledButtonStyle: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0,
    opacity: 0.5
  },
  centerView: {
    justifyContent: 'center',
    flex: 1
  },
  explanationText: {
    marginTop: 20,
    marginBottom: 20,
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 15
  },
  vehicleItemContainer: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  vehicleItem: {
    flex: 4,
    flexDirection: 'row',
    borderColor: '#F3F3F3',
    borderRadius: 0,
    borderWidth: 1,
    marginBottom: 10
  },
  vehicleItemFavorite: {
    borderColor: '#EFC008'
  },
  touchableVehicleItem: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  vehiclePlateContainer: {
    flex: 3,
    marginLeft: 5,
    marginRight: 5
  },
  vehicleDescription: {
    alignSelf: 'center'
  },
  vehicleDescriptionText: {
    fontFamily: 'defaultFont',
    fontSize: 12,
    color: '#284D64',
    textAlign: 'center'
  },
  vehiclePlate: {
    alignSelf: 'center'
  },
  vehiclePlateText: {
    fontFamily: 'defaultFontBold',
    fontSize: 14,
    color: '#284D64',
    textAlign: 'center'
  },
  mainViewContainer: {
    marginLeft: 15,
    marginRight: 15,
    flex: 1
  },
  trashIconView: {
    borderColor: 'transparent',
    borderWidth: 1,
    marginBottom: 10,
    flex: 1
  },
  trashIconContainer: {
    flex: 1,
    marginLeft: 5,
    borderWidth: 1,
    borderColor: '#EF6F6C',
    justifyContent: 'center',
    borderRadius: 0
  },
  trashIcon: {
    fontSize: 20,
    textAlign: 'center',
    color: '#EF6F6C'
  },
  starIconFavorite: {
    color: '#EFC008'
  },
  starIcon: {
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    marginLeft: 5,
    color: '#AAAAAA'
  },
  carIcon: {
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    color: '#424242'
  },
  noVehicleText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center'
  },
  addVehicleButton: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0
  },
  parkVehicleButton: {
    backgroundColor: '#7FCFAB',
    minHeight: 58,
    borderRadius: 0
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  parkButtonTitle: {
    fontFamily: 'defaultFontBold',
    fontSize: 14
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  vehiclesContainer: {
    flex: 1
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  }
});

export default VehicleList;