import Parse from "parse";
import axios from 'axios';

export default () => {

  const getAddress = async (zipCode) => {

    // var URL = "https://api.postmon.com.br/v1/cep/";
    const URL = "https://api.brasilaberto.com/v1/zipcode/";

    try {
      const response = await axios({
        method: 'get',
        url: `${URL}${zipCode}`,
        timeout: 1000 * 5 // Wait for 5 seconds at most
      });

      if(!response.data || !response.data.result) throw 'error finding address from zipcode';

      const parsedResponse = response.data.result;

      console.log('this is parsedResponse', parsedResponse);

      const address = {
        street: parsedResponse.street,
        neighborhood: parsedResponse.district,
        state: parsedResponse.stateShortname,
        city: parsedResponse.city,
        zipCode: zipCode
      };

      return address;
    }
    catch (e) {
      throw e;
    }

  };

  const updateUserAddress = async (address) => {

    var user = new Parse.User.current();

    user.set("address", {
      street: address.street,
      neighborhood: address.neighborhood,
      complement: address.complement,
      state: address.state,
      city: address.city,
      zipCode: address.zipCode,
      streetNumber: address.streetNumber
    });

    return await user.save();
  };

  return [getAddress, updateUserAddress];

};