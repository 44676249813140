import { Alert, Platform } from 'react-native'

const alertPolyfill = (title, description, options, alertOnly) => {
    if (alertOnly) {
        window.alert([title+"\n"+description]);
    }
    else {
        const result = window.confirm([title, description].filter(Boolean).join('\n'))

        if (result) {
            const confirmOption = options.find(({ style }) => style !== 'cancel')
            confirmOption && confirmOption.onPress()
        } else {
            const cancelOption = options.find(({ style }) => style === 'cancel')
            cancelOption && cancelOption.onPress()
        }
    }

}

// const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert
const alert = alertPolyfill;

export default alert
