import React from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';

const SaveCreditCardCheckBox = ({ saveCard, setSaveCard, cardListOptionVisible, setShowExchangeCardList }) => {
  return (
    <View style={styles.optionsContainer}>
      <CheckBox
        left
        size={25}
        checkedColor='#7FCFAB'
        containerStyle={styles.checkboxContainer}
        textStyle={styles.checkboxText}
        title='Salvar Cartão'
        checked={saveCard}
        onPress={() => { setSaveCard(!saveCard) }}
      />

      { cardListOptionVisible ?
      <TouchableOpacity
        onPress={() => {
          setShowExchangeCardList(true);
        }}
        key={0}
        style={styles.touchableContainer}
      >
          <Text style={styles.cardText}>Ver Cartões Salvos</Text>
            <FontAwesome
              name='credit-card'
              style={styles.cardIcon}
            />
      </TouchableOpacity>
      : null }
    </View>
  )
};

const styles = StyleSheet.create({
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  touchableContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkboxContainer: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    marginLeft: 0
  },
  checkboxText: {
    color: '#424242',
    fontFamily: 'defaultFont',
    fontWeight: 'normal',
    fontSize: 16,
    marginLeft: 2,
    textDecorationLine: 'underline'
  },
  cardText: {
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 16,
    marginRight: 5,
    textDecorationLine: 'underline'
  },
  cardIcon: {
    color: '#7FCFAB',
    fontSize: 22,
    marginRight: 12
  }
});

export default SaveCreditCardCheckBox;
