import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, ActivityIndicator, TouchableOpacity, Alert } from 'react-native';
import { Button, Input } from 'react-native-elements';
import useParkingApi from '../hooks/useParkingApi';
import useTimezones from '../hooks/useTimezones';
import ParkedVehicle from '../components/ParkedVehicle';
import NoActivationIcon from '../../assets/images/no-activation.svg';
import useExpiredSession from '../hooks/useExpiredSession';
import InAppReview from 'react-native-in-app-review';
import Parse from 'parse';
import { FontAwesome, Entypo } from '@expo/vector-icons';
import useAlarms from '../hooks/useAlarms';
import OfficialFooter from '../components/OfficialFooter';

const ActiveParkingScreen = ({ route, navigation }) => {

  const [setAlarmPreferences, getAlarmPreferences, scheduleNotifications, getNotificationsStatusForLicensePlate, changeNotificationsStatusForLicensePlate] = useAlarms();

  const [checkIfSessionExpired] = useExpiredSession();

  const NOT_LOADED = 0;
  const LOADED_WITH_ERROR = -1;
  const LOADED = 1;
  const [loadingState, setLoadingState] = useState(NOT_LOADED);
  const [activations, setActivations] = useState([]);
  const [, getActiveTransactions] = useParkingApi();
  const [getLocalTime] = useTimezones();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [reviewStep, setReviewStep] = useState(0);

  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  const requestReview = async () => {
    try {
      const inAppReviewAvaliable = InAppReview.isAvailable();
      console.log('InAppReview.isAvailable()', inAppReviewAvaliable);

      if (inAppReviewAvaliable) {
        const flowFinishedSuccessfully = await InAppReview.RequestInAppReview();
        if (flowFinishedSuccessfully) {
          console.log('flow ended successfully');
          markUserReview();
        }
      }
      else {
        markUserReview();
      }
    }
    catch (e) {
      console.log('error on request review process', e);
    }
  };

  const getTransactions = async () => {
    try {
      setReviewStep(0);
      setFeedbackMessage('');

      const activeTransactions = await getActiveTransactions();

      if (activeTransactions && activeTransactions.length > 0) {
        for (let i = 0; i < activeTransactions.length; i++) {
          // activeTransactions[i] = activeTransactions[i]._toFullJSON();
          delete activeTransactions[i].user;

          const localTime = await getLocalTime(activeTransactions[i].startDate, (activeTransactions[i].extendedExpireDate ? activeTransactions[i].extendedExpireDate : activeTransactions[i].expireDate), (activeTransactions[i].extendedExpireDate ? activeTransactions[i].expireDate : null));
          activeTransactions[i].startLocal = localTime.dateOne;
          activeTransactions[i].expireLocal = localTime.dateTwo;
          activeTransactions[i].dateDay = localTime.dateDay;
          activeTransactions[i].firstExpireLocal = localTime.firstExpireLocal;
          activeTransactions[i].firstExpireDay = localTime.firstExpireDay;

          console.log('activeTransaction', i, activeTransactions[i]);
        };

        setActivations(activeTransactions);

        //there are active transactions. eval ask user to review the app
        const askedToReview = Parse.User.current().get('askedToReview');
        if (!askedToReview) {
          setReviewStep(1);
        }
      }
      else {
        setActivations(activeTransactions);
      }
      setLoadingState(LOADED);
    }
    catch (e) {
      setLoadingState(LOADED_WITH_ERROR);
      checkIfSessionExpired(e);
      console.log('error getting active transactions', e);
    }
  };

  const markUserReview = () => {
    try {
      const user = Parse.User.current();
      user.set('askedToReview', true);
      user.save();
    }
    catch (e) {
      console.log('error saving user askedToReview', e);
    }
  };

  const sendFeedback = async () => {
    const showAlert = (title, message) => {
      Alert.alert(
        title,
        message,
        [
          { text: 'OK' },
        ],
        { cancelable: true }
      );
    };

    if (feedbackMessage.length === 0) {
      setIsSendingFeedback(false);
      showAlert(
        'Oops',
        'Por favor, digite algo antes de enviar a mensagem.'
      );
    }
    else {
      try {
        const params = {};
        params.msg = feedbackMessage;
        await Parse.Cloud.run('sendFeebackMail', params);
        showAlert(
          'Pronto!',
          'Obrigado pelo seu comentário, ele foi enviado para a nossa equipe.'
        );
        setIsSendingFeedback(false);
        markUserReview();
        setReviewStep(0);
      }
      catch (e) {
        console.log('error sending feedback mail', e);
        setIsSendingFeedback(false);
        showAlert(
          'Oops',
          'Ocorreu um erro ao enviar seu comentário. Por favor, tente novamente.'
        );
      }
    }
  };

  const advanceReviewProcess = (currentStep, positiveAnswer) => {
    switch (currentStep) {
      case 1:
        if (positiveAnswer) setReviewStep(2);
        else setReviewStep(3);
        return;
      case 2:
        if (positiveAnswer) requestReview();
        else markUserReview();
        setReviewStep(0);
        return;
      case 3:
        if (positiveAnswer) {
          if (!isSendingFeedback) {
            setIsSendingFeedback(true);
            sendFeedback();
          }
        }
        else {
          setReviewStep(0);
          markUserReview();
        }
        return;
    }
  };

  useEffect(() => {
    if (loadingState === NOT_LOADED) {
      getTransactions();
    }
  }, [loadingState]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      // getTransactions();
      setLoadingState(NOT_LOADED);
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={styles.activationsContainer}>
      <View style={styles.mainViewContainer}>
        {/* Loading View */}
        {loadingState === NOT_LOADED ?
          <View style={styles.centerView}>
            <ActivityIndicator size={50} color='#284D64' />
            <Text style={styles.loadingText}>Carregando ativações em curso...</Text>
          </View>
          : null}

        {/* Loaded With Error View */}
        {loadingState === LOADED_WITH_ERROR ?
          <View style={styles.centerView}>
            <NoActivationIcon style={styles.noActivationIcon} height={80} width={80} />
            <Text style={styles.noActivationText}>Ocorreu um erro ao carregar as ativações em curso.</Text>
          </View>
          : null}

        {/* App Review - Step 1 */}
        {loadingState === LOADED && reviewStep === 1 ? //removed ask for reviews for now
          <View style={styles.reviewContainer}>
            <Text style={styles.reviewText}>
              <Text style={styles.reviewTextBold}>
                {Parse.User.current().get('name').split(" ")[0]}
              </Text>
              , você está gostando do aplicativo?
            </Text>
            <View style={styles.reviewButtonContainer}>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(1, true);
                }}
                style={styles.reviewButton}
              >
                <FontAwesome
                  name='thumbs-o-up'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>Sim</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(1, false);
                }}
                style={[styles.reviewButton, { backgroundColor: '#EF6F6C' }]}
              >
                <FontAwesome
                  name='thumbs-o-down'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>Não</Text>
              </TouchableOpacity>
            </View>
          </View>
          : null}

        {/* App Review - Step 2 */}
        {loadingState === LOADED && reviewStep === 2 ? //removed ask for reviews for now
          <View style={styles.reviewContainer}>
            <Text style={styles.reviewText}>
              Uma avaliação de <Text style={styles.reviewTextBold}>5 estrelas</Text> significa muito para nós e ajuda outros usuários a descobrirem o APP
            </Text>
            <View style={styles.reviewButtonContainer}>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(2, true);
                }}
                style={styles.reviewButton}
              >
                <FontAwesome
                  name='star'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>Avaliar o APP</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(2, false);
                }}
                style={[styles.reviewButton, { backgroundColor: '#EF6F6C' }]}
              >
                <Entypo
                  name='emoji-sad'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>Não avaliar</Text>
              </TouchableOpacity>
            </View>
          </View>
          : null}

        {/* App Review - Step 3 */}
        {loadingState === LOADED && reviewStep === 3 ? //removed ask for reviews for now
          <View style={styles.reviewContainer}>
            <Text style={styles.reviewText}>
              Que tal nos contar como podemos melhorar?
            </Text>
            <Input
              containerStyle={styles.container}
              // inputContainerStyle={[styles.inputContainer(colors), (gotBlurred.name && errorMessages.name) ? styles.inputContainerError : null]}
              inputStyle={styles.feedbackText}
              placeholder='Digite aqui...'
              value={feedbackMessage}
              multiline={true}
              numberOfLines={3}
              onChangeText={(text) => {
                setFeedbackMessage(text);
              }}
              // onFocus={gotFocus}
              // onBlur={() => {
              //   setGotBlurred({ ...gotBlurred, name: true });
              //   if (!user.name) validField('name', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
              // }}
              returnKeyType='next'
              // onSubmitEditing={goToNextStep}
              blurOnSubmit={false}
              autoCorrect={false}
              errorStyle={styles.errorMessage}
            // errorMessage={gotBlurred.name ? errorMessages.name : null}
            />
            <View style={styles.reviewButtonContainer}>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(3, true);
                }}
                style={styles.reviewButton}
              >
                <FontAwesome
                  name='send'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>{isSendingFeedback ? 'Enviando...' : 'Enviar'}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  advanceReviewProcess(3, false);
                }}
                style={[styles.reviewButton, { backgroundColor: '#EF6F6C' }]}
              >
                <Entypo
                  name='emoji-sad'
                  style={styles.reviewButtonIcon}
                />
                <Text style={styles.reviewButtonText}>Não, obrigado</Text>
              </TouchableOpacity>
            </View>
          </View>
          : null}

        {/* Loaded View With No Activations */}
        {loadingState === LOADED && activations.length === 0 ?
          <View style={styles.centerView}>
            <NoActivationIcon style={styles.noActivationIcon} height={80} width={80} />
            <Text style={styles.noActivationText}>Não há ativações em sua conta no momento.</Text>
          </View>
          : null}

        {/* Loaded View With Activations */}
        {loadingState === LOADED && activations.length > 0 ?
          <ScrollView>
            {activations ? activations.map((item, index) => (
              <View key={item.license}>
                <ParkedVehicle
                  navigation={navigation}
                  activationData={item}
                  activations={activations}
                  setActivations={setActivations}
                />
              </View>
            )) : null}

          </ScrollView>
          : null}

      </View>

      {/* New Activation Button */}
      {loadingState === LOADED ?
        <Button
          buttonStyle={styles.parkVehicleButton}
          // title={`Estacionar ${activations.length === 0 ? 'Um' : 'Outro'} Veículo`}
          title='Fazer Nova Ativação'
          disabledStyle={styles.disabledButtonStyle}
          titleStyle={styles.buttonTitle}
          onPress={() => navigation.navigate('ParkingStack', {
            screen: 'ParkingOptions',
            params: { vehicleId: null, useVehicleAttributes: false }
          })}
        />
        : null}


      {/* Retry Loading Button */}
      {loadingState === LOADED_WITH_ERROR ?
        <Button
          buttonStyle={styles.errorButton}
          title='Tentar Novamente'
          disabledStyle={styles.disabledButtonStyle}
          titleStyle={styles.buttonTitle}
          onPress={() => getTransactions()}
        />
        : null}
      <OfficialFooter />

    </View>
  );
};

const styles = StyleSheet.create({
  reviewButtonContainer: {
    flexDirection: 'row',
    marginTop: 10
  },
  reviewButton: {
    backgroundColor: '#7FCFAB',
    padding: 10,
    margin: 10,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 5
  },
  reviewButtonIcon: {
    marginLeft: 5,
    fontSize: 16
  },
  reviewButtonText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242',
    marginLeft: 5,
    marginRight: 5
  },
  feedbackText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242',
  },
  reviewText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242',
    textAlign: 'center'
  },
  reviewTextBold: {
    fontFamily: 'defaultFontBold'
  },
  reviewContainer: {
    borderColor: '#EEEEEE',
    margin: 15,
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: '#EEEEEE',
    padding: 10,
    alignItems: 'center'
  },
  parkVehicleButton: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0
  },
  centerView: {
    justifyContent: 'center',
    flex: 1
  },
  activationsContainer: {
    flex: 1
  },
  mainViewContainer: {
    marginLeft: 15,
    marginRight: 15,
    flex: 1
  },
  noActivationIcon: {
    alignSelf: 'center',
    marginBottom: 10
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  noActivationText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center'
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  disabledButtonStyle: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0,
    opacity: 0.5
  },
});

export default ActiveParkingScreen;
