import React from 'react';
import { View, Text, StyleSheet, Image, ScrollView } from 'react-native';
import { Button } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import base64Images from '../../assets/images/base64/base64Images';
import OfficialFooter from '../components/OfficialFooter';

const DefaultLoggedOutScreen = ({ navigation }) => {

  const { colors } = useTheme();

  return (
    <View style={{flex: 1}}>

    <ScrollView style={{ backgroundColor: colors.white }}>
      <View style={styles.appLogoContainer}>
        <Image
          source={{ uri: base64Images.appLogo }}
          style={styles.appLogo}
        />
      </View>
      <Button
        buttonStyle={styles.signUpButton}
        titleStyle={styles.signUpTitle}
        icon={
          <FontAwesome
            name='user-plus'
            style={styles.signUpIcon}
          />
        }
        iconLeft
        title='CADASTRAR-ME'
        onPress={() => { navigation.navigate('SignUp') }}
      />
      <Text style={styles.hasAccount}>Já tem uma conta?</Text>
      <Button
        buttonStyle={styles.signInButton}
        titleStyle={styles.signInTitle}
        icon={
          <FontAwesome
            name='sign-in'
            style={styles.signInIcon}
          />
        }
        iconLeft
        title='ENTRAR'
        onPress={() => { navigation.navigate('SignIn') }}
      />
    </ScrollView>

    <OfficialFooter /> 
    </View>
  );
};

const styles = StyleSheet.create({
  appLogoContainer: {
    marginTop: 100,
    marginBottom: 40
  },
  appLogo: {
    height: 75,
    width: 188,
    alignSelf: 'center'
  },
  zaLogo: {
    height: 60,
    width: 140,
    alignSelf: 'center'
  },
  zaLogoContainer: {
    marginTop: 40,
    marginBottom: 50
  },
  hasAccount: {
    marginTop: 30,
    marginBottom: 10,
    textAlign: 'center',
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 16
  },
  signUpIcon: {
    color: '#0992A5',
    fontSize: 16,
    marginRight: 20
  },
  signUpButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#0992A5',
    marginLeft: 20,
    borderStyle: "solid",
    marginRight: 20,
  },
  signUpTitle: {
    color: 'rgb(9, 146, 165)',
    fontSize: 16,
    fontFamily: 'defaultFontBold'
  },
  signInIcon: {
    color: '#7FCFAB',
    fontSize: 16,
    marginRight: 20
  },
  signInButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#7fcfab',
    marginLeft: 20,
    marginRight: 20
  },
  signInTitle: {
    color: '#7fcfab',
    fontSize: 16,
    fontFamily: 'defaultFontBold'
  }
});

export default DefaultLoggedOutScreen;

