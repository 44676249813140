import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, Image, SafeAreaView, Linking } from 'react-native';
import { Button } from 'react-native-elements';
import Parse from "parse";
import { FontAwesome } from '@expo/vector-icons';
import useBalanceAndPriceApi from '../hooks/useBalanceAndPriceApi';
import useExpiredSession from '../hooks/useExpiredSession';
import useRevertedTransactions from '../hooks/useRevertedTransactions';
import base64Images from '../../assets/images/base64/base64Images';
import appParams from '../../constants/AppParams';
import OfficialFooter from '../components/OfficialFooter';

const DefaultLoggedInScreen = ({ navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const [userName, setUserName] = useState(null);
  const [isPOS, setIsPOS] = useState(false);
  const [balance, setBalance] = useState(null);
  const [getBalance, getWarningMessage] = useBalanceAndPriceApi();
  const [getRevertedPurchases] = useRevertedTransactions();
  const [warningMessage, setWarningMessage] = useState('-');
  const [revertedCredits, setRevertedCredits] = useState(0);

  const ERROR_GETTING_BALANCE = -999;

  const getInitialData = async () => {

    const getUserName = async () => {

      let user = await Parse.User.currentAsync();

      if (!user) {
        navigation.navigate('SignIn');
      }
      else {
        user = user._toFullJSON();
        setUserName(user.name.split(" ")[0]);
        setIsPOS(user.isPOS);
      }
    };

    const getBalanceFromApi = async () => {
      try {

        const [balance, warningMessage] = await Promise.all([
          getBalance(),
          getWarningMessage()
        ]);
        
        setWarningMessage(warningMessage);

        if (balance && balance.value === 0) {
          setBalance(balance);
        }
        else {
          setBalance(balance._toFullJSON());
        }
      }
      catch (e) {
        console.log('error getting balance', e);
        checkIfSessionExpired(e);
        setBalance(ERROR_GETTING_BALANCE);
      }
    };

    const getReverted = async () => {
      //try catch not needed here due to getRevertedPurchases function structure
      const revertedCredits = await getRevertedPurchases();
      setRevertedCredits(revertedCredits);
    };

    getUserName();
    getBalanceFromApi();
    getReverted();
  };

  const beautifulBalance = (p) => {
    const price = p.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      getInitialData();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{flex: 1}}>
      <ScrollView>

        {balance ?
          <View style={styles.balanceContainer}>
            <Text style={styles.balance}>
              {balance === ERROR_GETTING_BALANCE ?
                `Ops, ocorreu um erro ao carregar seu saldo.`
                :
                `Saldo: R$ ${beautifulBalance(balance.value)}`
              }
            </Text>
          </View>
          : null}

        {revertedCredits > 0 ?
          <View style={styles.revertedContainer}>
            <Text style={styles.revertedWarningText}><Text style={{ fontFamily: 'defaultFontBold' }}>Atenção!</Text> Há <Text style={{ fontFamily: 'defaultFontBold' }}>{revertedCredits} {revertedCredits < 2 ? 'cartão não pago' : 'cartões não pagos'}</Text> devido devido a transações estornadas. Para usar o App normalmente, regularize via SAC.</Text>
            <Text style={styles.revertedWarningText}><Text style={{ fontFamily: 'defaultFontBold' }}>SAC:</Text> <Text onPress={() => { Linking.openURL(`mailto:${appParams.contactMail}`); }} style={{ color: '#EEEEEE' }}>{appParams.contactMail}</Text> ou <Text onPress={() => { Linking.openURL(`tel:${appParams.contactPhoneToCall}`); }} style={{ color: '#EEEEEE' }}>{appParams.contactPhone}</Text></Text>
          </View>
          : null}

        <View style={styles.logoContainer}>
          <Image
            source={{ uri: base64Images.appLogo }}
            style={styles.appLogo}
          />
        </View>

        <View style={styles.hello}>
          <Text style={styles.helloText}>Olá, <Text style={{ fontFamily: 'defaultFontBold' }}>{userName}</Text></Text>
          {isPOS ? <Text style={[styles.helloText, { fontFamily: 'defaultFontBold' }]}>Sua conta é um Ponto de Venda!</Text> : null}
          {warningMessage !== '-' ?
            <View style={styles.warningView}>
              <Text style={styles.helloText}>{warningMessage}</Text>
            </View>
            : null}

        </View>
        <View style={styles.whatToDo}>
          <Text style={styles.whatToDoText}>
            O que deseja fazer?</Text>
        </View>

        <View style={{ height: 10 }}></View>

        {/* <Button
          buttonStyle={styles.button}
          titleStyle={styles.buttonTitle}
          icon={
            <FontAwesome
              name='car'
              style={styles.icon}
            />
          }
          title='Estacionar agora'
          onPress={() => navigation.navigate('ParkingStack', {
            screen: 'ParkingOptions',
            params: { vehicleId: null, useVehicleAttributes: false }
          })}
        />

        <View style={{ height: 20 }}></View>

        <Button
          buttonStyle={styles.button}
          titleStyle={[styles.buttonTitle]}
          icon={
            <FontAwesome
              name='list-alt'
              style={styles.icon}
            />
          }
          title='Ver Ativações em Curso'
          onPress={() => navigation.navigate('ActiveParkingStack')}
        /> */}

        <View style={{ height: 10 }}></View>

        {!isPOS ?

          <>
            <View style={{ height: 10 }}></View>

            <Button
              titleStyle={styles.buttonTitle}
              buttonStyle={styles.button}
              icon={
                <FontAwesome
                  name='shopping-cart'
                  style={styles.icon}
                />
              }
              title='Comprar para depois'
              onPress={() => navigation.navigate('PurchaseCreditsStack', {
                screen: 'PurchaseCredits',
                params: { creditCardId: null }
              })}
            />

            <View style={{ height: 10 }}></View>

          </>
          : null}

        <View style={{ height: 10 }}></View>

        <Button
          titleStyle={styles.buttonTitle}
          buttonStyle={styles.button}
          icon={
            <FontAwesome
              name='bars'
              style={styles.icon}
            />
          }
          title='Ir ao menu'
          onPress={() => {
            navigation.openDrawer()
          }}
        />

        <View style={{ height: 10 }}></View>

      </ScrollView>
      <OfficialFooter />
    </SafeAreaView>

  );
};

const styles = StyleSheet.create({
  revertedContainer: {
    margin: 10,
    backgroundColor: '#EF6F6C',
    borderColor: '#EEEEEE',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10
  },
  revertedWarningText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  warningView: {
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 5,
    backgroundColor: '#EEEEEE',
    padding: 5
  },
  appLogo: {
    height: 75,
    width: 188,
    alignSelf: 'center'
  },
  hello: {
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginLeft: 40,
    marginRight: 40
  },
  helloText: {
    color: '#424242',
    textAlign: 'center',
    fontSize: 17,
    fontFamily: 'defaultFont'
  },
  whatToDo: {
    borderLeftColor: '#F79256',
    borderLeftWidth: 2,
    marginLeft: 40,
    paddingLeft: 10,
    marginTop: 20,
    marginBottom: 10
  },
  whatToDoText: {
    color: '#284D64',
    fontFamily: 'defaultFontBold',
    fontSize: 17
  },
  balanceContainer: {
    backgroundColor: '#EEEEEE',
    padding: 5,
    margin: 10,
    borderRadius: 5,
    alignSelf: 'flex-start'
  },
  balance: {
    color: '#284D64',
    fontFamily: 'defaultFontBold'
  },
  logoContainer: {
    margin: 20,
    marginBottom: 30
  },
  icon: {
    color: '#0992A5',
    fontSize: 20,
    marginRight: 20,
    marginLeft: 20
  },
  button: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#0992A5',
    marginRight: 40,
    marginLeft: 40,
    justifyContent: 'flex-start'
  },
  buttonTitle: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 18
  }
});

export default DefaultLoggedInScreen;
