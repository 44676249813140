import React, { useState, useContext } from 'react';
import { StyleSheet, Text, View, SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FontAwesome } from '@expo/vector-icons';
import Parse from 'parse';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { useTheme } from '@react-navigation/native';
import { AuthContext } from '../context/AuthContext';

const DrawerContent = (props) => {

  const [isLogginOut, setIsLoggingOut] = useState(false);
  const { colors } = useTheme();
  const { setCurrentUser } = useContext(AuthContext);

  const menu = [
    // { title: 'Estacionar Agora', screen: 'ParkingStack', icon: 'car', mainItem: true },
    // { title: 'Ativações em Curso', screen: 'ActiveParkingStack', icon: 'list-alt', mainItem: true },
    { title: 'Comprar Créditos', screen: 'PurchaseCreditsStack', icon: 'shopping-cart', mainItem: true },
    { title: 'Histórico de Uso', screen: 'HistoryStack', icon: 'history' },
    { title: 'Minha Conta', screen: 'AccountStack', icon: 'user' },
    { title: 'Meus Veículos', screen: 'VehiclesStack', icon: 'car' },
    { title: 'Cartões de Crédito', screen: 'CreditCardsStack', icon: 'credit-card' },
    // { title: 'Alarmes', screen: 'NotificationsStack', icon: 'bell' },
    { title: 'Fale Conosco', screen: 'AboutUsStack', icon: 'comment' },
    { title: 'Termos e Condições', screen: 'TermsAndConditionsStack', icon: 'file-text-o' },
    { title: 'Dúvidas Frequentes', screen: 'FAQStack', icon: 'question-circle-o' }
  ];

  const logOut = async () => {
    const userId = Parse.User.current().get('cpf');
    try {
      setIsLoggingOut(true);
      await AsyncStorage.setItem('lastUserLoggedInId', userId);
      await Parse.User.logOut();
      setCurrentUser(null, false, userId);
    } catch (e) {
      setCurrentUser(null, false, userId);
    }
  }

  const isActiveStack = (routes, index, stackName) => {
    if (!routes || !index) return false;
    if (routes[index].name === stackName) return true;
    return false;
  };

  return (
    <View
      style={styles.container}
    >
      <DrawerContentScrollView>
        <View style={styles.greetingContainer}>
          <Text style={styles.greeting}>Olá{Parse.User.current().get('name') ? `, ${Parse.User.current().get('name').split(" ")[0]}` : ''}!</Text>
        </View>

        {menu.map((item, index) => (
          <DrawerItem
            activeTintColor={colors.orange}
            activeBackgroundColor={colors.white}
            inactiveTintColor={colors.darkBlue}
            focused={isActiveStack(props.state.routes, props.state.index, item.screen)}
            style={[styles.menuItemContainer, item.mainItem ? styles.mainMenuItemContainer : null]}
            onPress={() => {
              item.screen === 'PurchaseCreditsStack' ?
                props.navigation.navigate('PurchaseCreditsStack', {
                  screen: 'PurchaseCredits',
                  params: { creditCardId: null }
                }) :
                (item.screen === 'ParkingStack' ?
                  props.navigation.navigate('ParkingStack', {
                    screen: 'ParkingOptions',
                    params: { vehicleId: null, useVehicleAttributes: false }
                  }) :
                  props.navigation.navigate(item.screen))
            }}
            key={item.title}
            // itemStyle={[styles.menuItem, item.mainItem ? styles.mainMenuItem : styles.regularMenuItem]}
            icon={() => (
              <FontAwesome name={item.icon}
                style={[styles.menuIcon,
                isActiveStack(props.state.routes, props.state.index, item.screen) ? styles.menuIconSelected : null
                ]} />
            )}
            label={item.title}
            labelStyle={[styles.menuLabel,
            isActiveStack(props.state.routes, props.state.index, item.screen) ? styles.menuLabelBold : null
            ]}
          />
        ))}
      </DrawerContentScrollView>

      <SafeAreaView>
        <DrawerItem
          onPress={() => {
            if (!isLogginOut) logOut();
          }}
          icon={() => (
            <FontAwesome name='sign-out' style={[styles.menuIcon, { color: colors.darkGray }]} />
          )}
          style={{ borderTopWidth: 1, borderTopColor: colors.lightGray }}
          label={isLogginOut ? 'Saindo...' : 'Sair'}
          labelStyle={[styles.menuLabel, isLogginOut ? { fontFamily: 'defaultFontItalic' } : null]}
          inactiveTintColor={colors.veryDarkGray}
        />
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  menuIcon: {
    fontSize: 16,
    paddingTop: 3,
    width: 20,
    textAlign: 'center',
    color: '#0992A5'
  },
  menuIconSelected: {
    color: '#F79256'
  },
  greetingContainer: {
    borderBottomColor: '#EFF0F1',
    borderBottomWidth: 1
  },
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 20,
    fontSize: 18,
    fontFamily: 'defaultFontBold',
    color: '#284D64'
  },
  menuItem: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10
  },
  menuItemContainer: {
    marginBottom: 0,
    marginTop: 0
  },
  mainMenuItemContainer: {
    borderLeftColor: '#F79256',
    borderLeftWidth: 5,
    borderRadius: 0,
    marginLeft: 0,
    paddingLeft: 5,
  },
  menuLabel: {
    fontSize: 16,
    fontFamily: 'defaultFont',
    marginLeft: -22 //there's a bug in DrawerItem.tsx code setting 32 marginLeft for label in drawer menu if there's an icon before the label. Here we are setting marginLeft to be really 5, therefore.
  },
  menuLabelBold: {
    fontFamily: 'defaultFontBold'
  }
});

export default DrawerContent;
