import React, { useState, useEffect, useLayoutEffect } from 'react';
import { BackHandler, PanResponder, Animated, View, Text, StyleSheet, TouchableOpacity, Platform, Alert, Image, ActivityIndicator, SafeAreaView, Dimensions, Keyboard } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button, CheckBox, Input } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import CreditCard from '../components/CreditCard';
import CreditCardElementContainer from '../components/CreditCardElementContainer';
import SaveCreditCardCheckBox from '../components/SaveCreditCardCheckBox';
import SafeEnv from '../components/SafeEnv';
import useParkingApi from '../hooks/useParkingApi';
import useFocusScroll from '../hooks/useFocusScroll.js';
import useCreditCard from '../hooks/useCreditCard';
import base64Images from '../../assets/images/base64/base64Images';
import { useTheme } from '@react-navigation/native';
import VehicleToPark from '../../assets/images/parking-car.svg';
import useBalanceAndPriceApi from '../hooks/useBalanceAndPriceApi';
import LoadingScreen from '../components/LoadingScreen';
import useTimezones from '../hooks/useTimezones';
import usePaymentApi from '../hooks/usePaymentApi';
import useAlarms from '../hooks/useAlarms';
import Parse from 'parse';
import useExpiredSession from '../hooks/useExpiredSession';
import { ConfirmDialog } from 'react-native-simple-dialogs';
import useErrorHandler from '../hooks/useErrorHandler';
import OfficialFooter from '../components/OfficialFooter';

const ParkingConfirmationScreen = ({ route, navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();
  const [showConfirmAccountDialog, setShowConfirmAccountDialog] = useState(false);
  const [smsVerificationCode, setSmsVerificationCode] = useState("");
  const [handleError] = useErrorHandler();

  const { colors } = useTheme();

  const PAYMENT_METHODS = {
    CREDIT_CARD: 3,
    BANK_SLIP: 2,
    DEBIT: 1 //deprecated
  };

  const [createTransferOrder, cancelTransferOrder, cancelCashOrder, createOrder, createBankSlipOrder, cancelBankSlipOrder, sendBankSlipMail, payOrder, findTransferOrder] = usePaymentApi();
  const [parkVehicle, getActiveTransactions, purchaseCads, sendSmsVerification, validateSmsCode, sendEmailVerification] = useParkingApi();
  const [saveCreditCard, getCreditCards, removeCreditCard, getFavoriteCard, setFavoriteCard, getCardsAndFavorite, setNewCreditCard] = useCreditCard();

  const [extensionMarker, setExtensionMarker] = useState(false);

  const {
    GEOCOORDINATES,
    VEHICLE_ID,
    VEHICLE_CATEGORY,
    VEHICLE_PLATE,
    VEHICLE_DESCRIPTION,
    PARKING_CARD_TIME,
    ADDRESS,
    EXTENSION,
    CUSTOM_START_DATE,
    SCHEDULED
  } = route.params;

  const CREDIT_CARD = 0;
  const DEBIT = 1;
  const CONECT_CAR = 2;

  const creditCardIcons = base64Images.creditCardIcons;

  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);

  const [paymentOption, setPaymentOption] = useState(CREDIT_CARD);
  const [showFurtherPaymentOptions, setShowFurtherPaymentOptions] = useState(false);
  const [creditCard, setCreditCard] = useState({});
  const [validCreditCard, setValidCreditCard] = useState(false);
  const [performCreditCardFinalCheck, setPerformCreditCardFinalCheck] = useState(0);
  const [allSet, setAllSet] = useState(0);
  const [saveCard, setSaveCard] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const [balance, setBalance] = useState({});
  const [PRICE_PER_CAD, setPRICE_PER_CAD] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(0);

  const [creditCardSelected, setCreditCardSelected] = useState({});
  const [creditCardList, setCreditCardList] = useState([]);
  const [showExchangeCardList, setShowExchangeCardList] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const [getBalance] = useBalanceAndPriceApi();

  const [, , scheduleNotifications, getNotificationsStatusForLicensePlate, changeNotificationsStatusForLicensePlate] = useAlarms();

  const [gotFocus] = useFocusScroll();
  const [getLocalTime] = useTimezones();
  const [receiptMethod, setReceiptMethod] = useState({ email: true, sms: false, printed: false });

  const beautifulPrice = (p) => {
    const price = p.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  // const getTotalPrice = (cadsToBuy) => {
  //   if (cadsToBuy < 0) return 0;
  //   else return cadsToBuy * PRICE_PER_CAD;
  // };

  const checkSmsCode = async () => {
    try {
      if (smsVerificationCode.length < 4) {
        Alert.alert(
          'Oops!',
          'O código deve ter 4 dígitos.',
          [
            { text: 'OK' },
          ],
          { cancelable: true }
        );
        return;
      }
      else {
        await validateSmsCode(smsVerificationCode);
        setShowConfirmAccountDialog(false);
        processTransaction();
      }
    }
    catch (e) {
      Alert.alert(
        'Oops!',
        'O código de confirmação informado está incorreto. Por favor, tente novamente.',
        [
          { text: 'OK' },
        ],
        { cancelable: false }
      );
      checkIfSessionExpired(e);
    }
  };

  const phoneMask = (value) => {
    if (value) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
      value = value.replace(/(\d{5})(\d)/, "$1-$2");
      value = value.substring(0, 15);
    }
    return value;
  };

  const showAlert = async (msg, opt1, opt2) => {
    return new Promise((resolve, reject) => {
      Alert.alert(
        'Atenção',
        msg,
        [
          { text: opt1 ? opt1 : 'Não', onPress: () => resolve(false) },
          { text: opt2 ? opt2 : 'Sim', onPress: () => resolve(true) }
        ],
        { cancelable: false }
      )
    });
  };

  const checkIfOnlyPartialTimeTransaction = async () => {
    try {
      //IS THERE A CASE WHEN WE ALREADY RECEIVE A DATE AND MUST EVAL THIS DIFFERENTLY?
      // if(!$scope.transactionOptions.date) expireDate = new Date($scope.$root.currDate.valueOf() + $scope.transactionOptions.parkingRule.maxMinutes * 60 * 1000);
      // else expireDate = new Date($scope.transactionOptions.date + $scope.transactionOptions.parkingRule.maxMinutes * 60 * 1000);
      // if(expireDate.getTimezoneOffset() !== 180) {
      //   expireDate = new Date(expireDate.getTime() - ((180 - expireDate.getTimezoneOffset())*60*1000));
      // }

      let isPartialTimeTransaction = false;
      let decidedToContinue = true;
      let periodEndTime = null;

      //original activation case
      if (PARKING_CARD_TIME.endHour || PARKING_CARD_TIME.endHour === 0) {

        const localExpireTime = await getLocalTime(new Date().getTime() + PARKING_CARD_TIME.maxMinutes * 60 * 1000);
        const localExpireTimeHours = localExpireTime.dateOneHours;
        const localExpireTimeMinutes = localExpireTime.dateOneMinutes;

        if ((localExpireTimeHours > PARKING_CARD_TIME.endHour)
          || (localExpireTimeHours === PARKING_CARD_TIME.endHour
            && localExpireTimeMinutes > PARKING_CARD_TIME.endMinutes)) {
          periodEndTime = `${('00' + PARKING_CARD_TIME.endHour).slice(-2)}h${('00' + PARKING_CARD_TIME.endMinutes).slice(-2)}`;
          isPartialTimeTransaction = true;
        }
      }

      //extension case
      else if (EXTENSION && PARKING_CARD_TIME.periodExpireTimeAtTransactionDay) {
        const startDate = PARKING_CARD_TIME.startDate;
        const expireDate = PARKING_CARD_TIME.expireDate;

        //extension doubles current activation time
        const extensionExpireDate = expireDate + (expireDate - startDate);

        if (PARKING_CARD_TIME.periodExpireTimeAtTransactionDay < extensionExpireDate) {
          const localExpireTime = await getLocalTime(PARKING_CARD_TIME.periodExpireTimeAtTransactionDay);
          periodEndTime = localExpireTime.dateOne;

          isPartialTimeTransaction = true;
        }
      }

      if (isPartialTimeTransaction) {
        decidedToContinue = await showAlert(`Este setor de estacionamento encerra a operação às ${periodEndTime}. Ao continuar, a ativação será limitada a este horário, com eventual restituição de saldo.\n\nDeseja continuar?`);
      }

      return decidedToContinue;
    }
    catch (e) {
      throw e;
    }

  };

  const processTransaction = async () => {

    console.log('will now process transaction');

    setIsProcessingTransaction(true);

    const showError = (msg) => {
      Alert.alert(
        'Oops!',
        msg,
        [
          { text: 'OK' },
        ],
        { cancelable: false },
      );
    }

    const formatLicensePlate = (licensePlate) => {

      licensePlate = licensePlate.replace(/[^0-9a-z]/gi, '');

      let spacer = "";

      if (/^\d+$/.test(licensePlate[3])) {
        spacer = " ";
        if (/^\d+$/.test(licensePlate[4])) {
          spacer = "-"
        }
      }

      licensePlate = licensePlate.substring(0, 3) + spacer + licensePlate.substring(3, 7);
      return licensePlate;

    };


    try {
      let alsoAcquisition = false;

      if (pendingPayment > 0) { //must pay to park

        //pay order with new or existing credit card
        let creditCardHash = null;
        let newCrediCard = null;
        let useHashAsCardId = false;

        if (!creditCardSelected || !creditCardSelected.id) { //NEW CARD
          if (creditCard.number && creditCard.expiryDate && creditCard.cvv) {
            newCrediCard = setNewCreditCard(creditCard);
            if (!newCrediCard) throw 'invalid credit card entered.';
            // else creditCardHash = await newCrediCard.hash();
            else creditCardHash = newCrediCard.encryptedCard;
          }
          else {
            throw 'invalid credit card entered.';
          }
        }

        else {
          if (!creditCardSelected.get('hashedCreditCard')) throw 'invalid credit card entered.';
          else {
            creditCardHash = creditCardSelected.get('hashedCreditCard');
            useHashAsCardId = true;
          }
        }

        console.log('\n\nthis is card hash', creditCardHash);

        alsoAcquisition = true;
        PARKING_CARD_TIME.missingCredit = pendingPayment;
        PARKING_CARD_TIME.hashedCreditCard = creditCardHash;
        PARKING_CARD_TIME.useHashAsCardId = useHashAsCardId;
        PARKING_CARD_TIME.saveCard = (saveCard && newCrediCard) ? true : false;
      }

      let activation = await parkVehicle(EXTENSION,
        GEOCOORDINATES,
        VEHICLE_PLATE,
        VEHICLE_DESCRIPTION,
        PARKING_CARD_TIME,
        ADDRESS,
        VEHICLE_CATEGORY,
        CUSTOM_START_DATE,
        SCHEDULED ? true : false,
        alsoAcquisition);

      let formattedExpireDate = await getLocalTime(activation.get('extendedExpireDate') ? activation.get('extendedExpireDate') : activation.get('expireDate'));
      formattedExpireDate = formattedExpireDate.dateOne;

      Alert.alert(
        'Pronto!',
        `Seu veículo de placa ${formatLicensePlate(activation.get('license'))} pode ficar estacionado até às ${formattedExpireDate}!
         Nota: Você pode aproveitar esse mesmo crédito vigente se mover seu veículo para um novo local que tenha as mesmas regras de estacionamento.`,
        [
          { text: 'OK' },
        ],
        { cancelable: false },
      );
      setIsProcessingTransaction(false);

      if (EXTENSION) {
        //cancel previous transaction alarms. wait for it
        await changeNotificationsStatusForLicensePlate(formatLicensePlate(activation.get('license')), 'off');
      }

      //turn on alarms and schedule then
      await changeNotificationsStatusForLicensePlate(
        formatLicensePlate(activation.get('license')),
        'on',
        activation.get('extendedExpireDate') ? activation.get('extendedExpireDate') : activation.get('expireDate')
      );

      //navigate to ActiveParking
      navigation.navigate('ActiveParkingStack');
    }
    catch (e) {
      // if there's a message from the server, display the message stripping html tags, otherwise display default message
      console.log('error processing transaction', e);
      if (e && e.message && e.message.code === 403) {
        Alert.alert(
          'Validação de conta',
          'Para prosseguir com a transação, é necessário que você valide sua conta. Você pode validá-la utilizando o seu celular ou o e-mail cadastrado.',
          [
            {
              text: 'Cancelar', onPress: () => {
                resetSliderPosition();
                setIsProcessingTransaction(false);
              }
            },
            { text: 'Validar por E-mail', onPress: () => validateAccount('e-mail') },
            { text: 'Validar por SMS', onPress: () => validateAccount('SMS') }
          ],
          { cancelable: false }
        );
      }
      else {
        let errorMessage = handleError(e);

        if (errorMessage === 'rejected') {
          errorMessage = 'Erro ao processar a transação.\n\nPor favor, cheque se o cartão de crédito inserido é válido e tente novamente. Se o problema persistir, pode haver instabilidade momentânea no processador de pagamentos com cartão.\n\nAlternativamente, você pode pagar por PIX.';
        }

        Alert.alert(
          'Oops!',
          errorMessage,
          [
            { text: 'OK' },
          ],
          { cancelable: false }
        );

        checkIfSessionExpired(e);
        resetSliderPosition();
        setIsProcessingTransaction(false);
      }
    }

  };

  const validateAccount = async (validationType) => {
    try {
      if (validationType === 'SMS') {
        await sendSmsVerification();
        setShowConfirmAccountDialog(true);
      }
      else { //email
        const message = await sendEmailVerification();
        Alert.alert(
          'Atenção!',
          message,
          [
            { text: 'OK' },
          ],
          { cancelable: false }
        );
        resetSliderPosition();
        setIsProcessingTransaction(false);
      }
    }
    catch (e) {
      console.log('error sending validation', e);
      Alert.alert(
        'Oops!',
        `Ocorreu um erro ao validar sua conta por ${validationType}. Por favor, tente novamente.`,
        [
          { text: 'OK' },
        ],
        { cancelable: false }
      );
      resetSliderPosition();
      setIsProcessingTransaction(false);
    }
  };

  const handleBackAction = (extensionTransaction, backHandler) => {
    if (extensionTransaction) {
      navigation.navigate('ActiveParkingStack');
    }
    else {
      navigation.navigate('ParkingOptions', {
        vehicleId: VEHICLE_ID
      });
    }
    if (backHandler) backHandler.remove();
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
      setExtensionMarker(extM => {
        handleBackAction(extM, backHandler);
        return extM;
      });
      return true;
    });

    return () =>
      backHandler.remove();
  }, []);


  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity
          onPress={() => setExtensionMarker(extM => {
            handleBackAction(extM);
            return extM;
          })}
          style={{ marginLeft: 10 }}
        >
          <Text style={styles.customBackText}>{"< Voltar"}</Text>
        </TouchableOpacity>
      )
    });
  }, [navigation]);

  //remove new card validation start up
  useEffect(() => {
    if (showExchangeCardList) {
      setPerformCreditCardFinalCheck(0);
      setAllSet(0);
    }
  }, [showExchangeCardList]);

  useEffect(() => {
    console.log('route.params is', route.params);

    if (route.params) {
      const extM = route.params.EXTENSION;
      setExtensionMarker(extM);
    }

  }, [route.params]);

  const processCreditCardOrder = () => {
    if (!creditCardSelected || !creditCardSelected.id) {
      setPerformCreditCardFinalCheck(performCreditCardFinalCheck + 1);
    }
    else {
      processTransaction();
    }
  };

  const doInitialPrep = async () => {
    setCreditCardSelected(null);
    setCreditCard({});
    setShowExchangeCardList(false);
    setCreditCardList(null);
    setIsLoading(true);
    setPerformCreditCardFinalCheck(0);
    setShowFurtherPaymentOptions(false);
    setAllSet(0);

    try {

      const [cardList, userBalance] =
        await Promise.all([
          getCardsAndFavorite(setCreditCardSelected),
          getBalance()
        ]);

      let myUserBalance = userBalance;

      if (!(userBalance && userBalance.value === 0)) {
        myUserBalance = userBalance._toFullJSON();
      }

      setBalance(myUserBalance);

      console.log('\n\n heeeey', PARKING_CARD_TIME.price, myUserBalance.value);

      if (myUserBalance.value < PARKING_CARD_TIME.price) {
        setPendingPayment(PARKING_CARD_TIME.price - myUserBalance.value);
        if (!cardList || cardList.length === 0) {
          setShowFurtherPaymentOptions(true);
        }
      }
      else {
        setPendingPayment(0);
      }

      setCreditCardList(cardList);
      setLoadingError(false);
      setIsLoading(false);
    }
    catch (e) {
      console.log('error getting screen data', e);
      setLoadingError(true);
      setIsLoading(false);
      checkIfSessionExpired(e);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      doInitialPrep();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
    // }, [navigation]);
  }, [route.params]);

  useEffect(() => {
    if (allSet) {
      processTransaction();
    }
  }, [allSet]);


  //MyAnimation
  const SCREEN_WIDTH = Dimensions.get('window').width;

  const SWIPE_THRESHOLD = SCREEN_WIDTH * 0.7;
  // const SWIPE_OUT_DURATION = 250;

  const position = new Animated.ValueXY();

  const resetSliderPosition = () => {
    Animated.spring(position, {
      toValue: { x: 0, y: 0 },
      useNativeDriver: false
    }).start();
  };

  const forceFinishSwipe = () => {
    Animated.spring(position, {
      toValue: { x: SCREEN_WIDTH - 50, y: 0 },
      useNativeDriver: false
    }).start();
    processTransaction();
  };

  // const onSwipeComplete = () => {
  //   position.setValue({ x: 0, y: 0 });
  // };

  // const forceSwipe = (direction) => {
  //   const xDirection = direction === 'right' ? 1 : -1;

  //   Animated.timing(position, {
  //     toValue: { x: xDirection * SCREEN_WIDTH, y: 0 },
  //     duration: SWIPE_OUT_DURATION,
  //     useNativeDriver: false
  //   }).start(() => {
  //     onSwipeComplete();
  //   });
  // };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (event, gesture) => {
      position.setValue({ x: gesture.dx, y: 0 })
    },
    onPanResponderRelease: (event, gesture) => {
      if (gesture.dx > SWIPE_THRESHOLD) {
        forceFinishSwipe();
      }
      else {
        resetSliderPosition();
      }
    }
  });

  const getArrowStyle = () => {
    return {
      ...position.getLayout()
    };
  };

  return (
    <View style={styles.container}>
      {isLoading ?
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size='large' color='#284D64' />
        </View>
        : loadingError ?
          <View style={styles.errorTextContainer}>
            <Text style={styles.errorText(colors)}>Ocorreu um erro ao carregar as informações. Por favor, tente novamente.</Text>
          </View>
          :
          <KeyboardAwareScrollView
            innerRef={ref => {
              this.scroll = ref ? ref.getScrollResponder() : null;
            }}
            enableOnAndroid={true}
            enableAutomaticScroll={(Platform.OS === 'ios')}
            keyboardOpeningTime={0}
            extraHeight={250}
            keyboardShouldPersistTaps={'handled'}
            contentContainerStyle={[styles.scrollViewContainer, showFurtherPaymentOptions ? { justifyContent: 'flex-start' } : null]}
          >

            {!showFurtherPaymentOptions ?
              <Text style={styles.confirmationText}>Confirme os dados abaixo para estacionar:</Text>
              : null}
            <View style={styles.vehiclePlateContainer}>
              <View>
                <VehicleToPark height={showFurtherPaymentOptions ? 25 : 80} width={showFurtherPaymentOptions ? 25 : 80} fill='#000000' />
              </View>
              <View style={[styles.vehicleInformationContainer, showFurtherPaymentOptions ? { flexDirection: 'row' } : null]}>
                <Text style={[styles.vehicleDescription, showFurtherPaymentOptions ? { fontSize: 18 } : null]}>{VEHICLE_DESCRIPTION}</Text>
                <Text style={[styles.vehiclePlate, showFurtherPaymentOptions ? { fontSize: 18, marginLeft: 10 } : null]}>{VEHICLE_PLATE}</Text>
              </View>
            </View>

            <View style={showFurtherPaymentOptions ? { flexDirection: 'row', justifyContent: 'space-between' } : null}>
              <View style={styles.descriptionDetail}>
                <FontAwesome
                  name='clock-o'
                  style={styles.detailsIcon}
                />
                <Text style={styles.detailsText}>
                  {!showFurtherPaymentOptions ?
                    (SCHEDULED ?
                      `Agendar para ${SCHEDULED}\n` :
                      EXTENSION ?
                        'Adicionar mais ' :
                        'Estacionar por '
                    )
                    : (SCHEDULED ?
                      `Agendar: ${SCHEDULED}\n` :
                      EXTENSION ?
                        'Mais ' :
                        null
                    )}
                  {PARKING_CARD_TIME.maxMinutes / 60 + (PARKING_CARD_TIME.maxMinutes / 60 > 1 ? ' horas' : ' hora')}
                </Text>
              </View>
            </View>

            <View style={styles.descriptionDetail}>
              <FontAwesome
                name='map-marker'
                style={styles.detailsIcon}
              />
              <View>
                <Text style={styles.detailsText}>
                  {ADDRESS.locationName}
                </Text>
                <View style={{ flexDirection: 'row' }}>
                  {ADDRESS.areaName ?
                    <Text style={styles.detailsText}>
                      {ADDRESS.areaName}
                    </Text>
                    : null}
                </View>
              </View>
            </View>

            <View style={styles.descriptionDetail}>
              <FontAwesome
                name='tags'
                style={styles.detailsIcon}
              />
              <Text style={styles.detailsText}>
                R$ {beautifulPrice(PARKING_CARD_TIME.price)} em Créditos
              </Text>
            </View>

            {!showFurtherPaymentOptions && pendingPayment > 0 ?
              <View style={styles.descriptionDetail}>
                <FontAwesome
                  name='info-circle'
                  style={styles.detailsIcon}
                />
                <Text style={styles.detailsText}>
                  Pagamento de R$ {beautifulPrice(pendingPayment)} (saldo: R$ {beautifulPrice(PARKING_CARD_TIME.price - pendingPayment)})
                </Text>
              </View>
              : null}

            {creditCardSelected && creditCardSelected.id && !showFurtherPaymentOptions && pendingPayment > 0 ?
              <View style={styles.descriptionDetail}>
                <FontAwesome
                  name='credit-card'
                  style={styles.detailsIcon}
                />
                <View style={styles.paymentSummary}>
                  <Text style={styles.detailsText}>Pagamento:</Text>
                  <Image
                    source={{ uri: creditCardIcons[creditCardSelected.get('flag')] }}
                    style={styles.cardFlag}
                  />
                  <Text style={[styles.detailsText, styles.diminishedMargin]}>final {creditCardSelected.get('finalNumbers')}</Text>
                </View>

                <TouchableOpacity
                  onPress={() => setShowFurtherPaymentOptions(true)}
                  style={styles.changeOptionTextTouchable}
                >
                  <Text style={styles.changeOptionText}>alterar</Text>
                </TouchableOpacity>
              </View>
              : null}

            {showFurtherPaymentOptions ?
              <View>
                <View style={styles.choosePaymentContainer(colors)}>
                  <Text style={styles.choosePayment}>Escolha a forma de pagamento:</Text>
                </View>
                <View style={styles.paymentDetails}>
                  {/* <Text style={styles.paymentDetailsText}>A comprar: {pendingPayment === 1 ? '1 cartão azul' : '2 cartões azuis'}</Text> */}
                  <Text style={styles.paymentDetailsText}>A pagar: R$ {beautifulPrice(pendingPayment)} (saldo: R$ {beautifulPrice(PARKING_CARD_TIME.price - pendingPayment)})</Text>
                </View>
                <View style={styles.paymentOptionsContainer}>
                  <TouchableOpacity
                    onPress={() => setPaymentOption(CREDIT_CARD)}
                    style={paymentOption === CREDIT_CARD ? styles.paymentOptionActive : styles.paymentOption}
                  >
                    <FontAwesome
                      name='credit-card'
                      style={paymentOption === CREDIT_CARD ? styles.detailsIconActive : styles.detailsIcon}
                    />
                    <Text style={paymentOption === CREDIT_CARD ? styles.paymentOptionTextActive : styles.paymentOptionText}>Cartão de Crédito</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => setPaymentOption(DEBIT)}
                    style={[paymentOption === DEBIT ? styles.paymentOptionActive : styles.paymentOption, { marginRight: 15 }]}
                  >
                    <FontAwesome
                      name='exchange'
                      style={paymentOption === DEBIT ? styles.detailsIconActive : styles.detailsIcon}
                    />
                    <Text style={paymentOption === DEBIT ? styles.paymentOptionTextActive : styles.paymentOptionText}>Pix, ConectCar ou Boleto</Text>
                  </TouchableOpacity>
                </View>
                <View>
                  <View style={paymentOption !== CREDIT_CARD ? { display: 'none' } : null}>

                    <View style={{ marginRight: 5 }}>
                      <SafeEnv />
                    </View>

                    <CreditCardElementContainer
                      creditCardSelected={creditCardSelected}
                      setCreditCardSelected={setCreditCardSelected}
                      creditCardList={creditCardList}
                      showExchangeCardList={showExchangeCardList}
                      setShowExchangeCardList={setShowExchangeCardList}
                    />

                    {!(creditCardSelected && creditCardSelected.id) && !showExchangeCardList ?
                      <View>
                        <CreditCard
                          creditCard={creditCard}
                          onCreditCardChange={setCreditCard}
                          performFinalCheck={performCreditCardFinalCheck}
                          allSet={allSet}
                          setAllSet={setAllSet}
                        />

                        <SaveCreditCardCheckBox
                          saveCard={saveCard}
                          setSaveCard={setSaveCard}
                          cardListOptionVisible={creditCardList && creditCardList.length > 0 ? true : false}
                          setShowExchangeCardList={setShowExchangeCardList}
                        />
                      </View>
                      : null}

                  </View>
                  <View style={[paymentOption !== DEBIT ? { display: 'none' } : null, styles.debitExplanation]}>
                    <TouchableOpacity onPress={() => navigation.navigate('PurchaseCreditsStack', {
                      screen: 'PurchaseCredits',
                      params: { creditCardId: null }
                    })}>
                      <Text style={styles.debitExplanationText}>
                        Para pagamento via <Text style={{ fontFamily: 'defaultFontBold' }}>Pix, ConectCar ou Boleto</Text>, compre os créditos antes de realizar a ativação por meio da opção <Text style={[styles.debitExplanationText, { color: '#F79256' }]}>Comprar Créditos</Text> do <Text style={[styles.debitExplanationText, { color: '#F79256' }]}>menu</Text>.
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              :
              null}
          </KeyboardAwareScrollView>
      }

      <ConfirmDialog
        visible={showConfirmAccountDialog}
        title='Atenção'
        titleStyle={styles.dialogTitle}
        positiveButton={{
          title: 'Confirmar',
          titleStyle: { color: '#424242' },
          onPress: () => { checkSmsCode() }
        }}
        negativeButton={{
          title: 'Cancelar',
          titleStyle: { color: '#424242' },
          onPress: () => {
            setShowConfirmAccountDialog(false);
            resetSliderPosition();
            setIsProcessingTransaction(false);
          }
        }}
      >
        <View>
          <Text style={styles.dialogText}>{`Um SMS com um código de confirmação de 4 dígitos foi enviado ao celular ${phoneMask(Parse.User.current().get('phone'))}. Insira o código abaixo:`}</Text>
          <Input
            containerStyle={styles.dialogFieldContainer}
            inputContainerStyle={styles.dialogInputContainer}
            inputStyle={styles.dialogInput}
            value={smsVerificationCode}
            onChangeText={(smsVerificationCode) => setSmsVerificationCode(smsVerificationCode)}
            onSubmitEditing={() => { checkSmsCode(); Keyboard.dismiss() }}
            autoCorrect={false}
            keyboardType='numeric'
            blurOnSubmit={false}
            maxLength={4}
          />
        </View>
      </ConfirmDialog>

      {
        isProcessingTransaction ?
          <LoadingScreen />
          : null
      }
      <SafeAreaView>
        {!isLoading ?
          (loadingError ?
            <Button
              buttonStyle={styles.errorButton}
              title={'Tentar Novamente'}
              loading={isLoading}
              disabled={(isLoading && !loadingError)}
              disabledStyle={styles.disabledParkButtonStyle}
              titleStyle={styles.parkButtonTitle}
              onPress={() => {
                doInitialPrep();
              }}
            />
            : showFurtherPaymentOptions && !showExchangeCardList && (!creditCardSelected || !creditCardSelected.id) ?
              <Button
                buttonStyle={styles.proceedButton(colors)}
                title={!isProcessingTransaction ? 'Pagar e Estacionar Agora' : 'Processando...'}
                loading={isLoading}
                disabled={(isLoading && !loadingError) || isProcessingTransaction}
                disabledStyle={styles.disabledParkButtonStyle}
                titleStyle={styles.parkButtonTitle}
                disabledTitleStyle={{ color: '#FFFFFF' }}
                onPress={() => {
                  processCreditCardOrder();
                }}
              />
              : (!showExchangeCardList ?
                <View style={styles.parkingTriggerContainer(colors)}>
                  {!isProcessingTransaction ?
                    <Animated.View
                      {...panResponder.panHandlers}
                      style={[getArrowStyle(), styles.animatedArrowView(colors)]}>
                      <FontAwesome
                        name='arrow-right'
                        style={styles.arrowIcon(colors)}
                      />
                    </Animated.View>
                    : null}
                  <View style={styles.textView}>
                    <Text style={[styles.parkButtonTitle, styles.parkingText(colors)]}>
                      {!isProcessingTransaction ? 'Deslize Para Estacionar' : 'Processando...'}
                    </Text>
                  </View>
                </View>
                : null)
          )
          : null}

      </SafeAreaView>
      <OfficialFooter />
    </View >

  );
};

const styles = StyleSheet.create({
  dialogTitle: {
    fontFamily: 'defaultFontBold',
    fontSize: 18,
    color: '#424242'
  },
  dialogText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  dialogInput: {
    fontSize: 25,
    textAlign: 'center',
    color: '#424242',
    fontFamily: 'defaultFont',
    letterSpacing: 5
  },
  dialogInputContainer: {
    borderColor: '#EEEEEE',
    borderWidth: 1,
    padding: 5
  },
  dialogFieldContainer: {
    marginTop: 10
  },
  inputIcon: {
    fontSize: 16,
    width: 20,
    paddingRight: 5,
    textAlign: 'center',
    color: 'rgb(9, 146, 165)'
  },
  input: {
    color: 'rgb(66, 66, 66)',
    fontFamily: 'defaultFont',
    fontSize: 16
  },
  inputContainer: colors => ({
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray
  }),
  checkboxContainer: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    marginLeft: 0,
    marginRight: 20
  },
  checkboxText: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontWeight: 'normal',
    fontSize: 16,
    paddingLeft: 15
  },
  receiptMethodTextBorder: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256'
  },
  receiptMethodText: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 16,
    fontFamily: 'defaultFontBold'
  },
  proceedButton: colors => ({
    backgroundColor: colors.green
  }),
  parkingText: colors => ({
    color: colors.green,
    fontSize: 16,
    alignSelf: 'center'
  }),
  textView: {
    justifyContent: 'center',
    flex: 1
  },
  arrowIcon: colors => ({
    fontSize: 30,
    color: colors.white,
    alignSelf: 'center'
  }),
  animatedArrowView: colors => ({
    backgroundColor: colors.green,
    alignSelf: 'flex-start',
    justifyContent: 'center',
    minHeight: 50,
    width: 50,
    position: 'absolute',
    zIndex: 1,
    padding: 10
  }),
  parkingTriggerContainer: colors => ({
    minHeight: 50,
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.green
  }),
  errorTextContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  errorText: colors => ({
    fontFamily: 'defaultFont',
    margin: 10,
    color: colors.blackText,
    fontSize: 16,
    textAlign: 'center'
  }),
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  detailsText: {
    color: '#284D64',
    fontFamily: 'defaultFontBold',
    marginLeft: 10,
    fontSize: 16
    // alignSelf: 'center'
  },
  paymentSummary: {
    flexDirection: 'row'
  },
  diminishedMargin: {
    marginLeft: 2
  },
  cardFlag: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
    marginLeft: 5,
    marginRight: 2
  },
  changeOptionTextTouchable: {
    marginTop: 1,
    marginLeft: 7
  },
  changeOptionText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    textDecorationLine: 'underline',
    fontSize: 16
  },
  customBackText: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 18
  },
  container: {
    justifyContent: 'space-between',
    flex: 1
  },
  debitExplanation: {
    backgroundColor: '#EEEEEE',
    padding: 10,
    borderRadius: 3
  },
  debitExplanationText: {
    fontSize: 16,
    fontFamily: 'defaultFont',
    color: '#284D64',
    textAlign: 'center'
  },
  paymentOptionsContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  paymentOption: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    marginLeft: 15,
    marginBottom: 5,
    borderBottomColor: 'transparent',
    borderBottomWidth: 4
  },
  paymentOptionActive: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottomColor: '#0992A5',
    borderBottomWidth: 4,
    marginLeft: 15,
    marginBottom: 5
  },
  paymentOptionText: {
    color: '#AAAAAA',
    fontSize: 16,
    fontFamily: 'defaultFont',
    textAlign: 'center'
  },
  paymentOptionTextActive: {
    color: '#0992A5',
    fontSize: 16,
    fontFamily: 'defaultFont',
    textAlign: 'center'
  },
  paymentDetails: {
    backgroundColor: '#EEEEEE',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 5,
    padding: 5,
    borderRadius: 3
  },
  paymentDetailsText: {
    color: '#284D64',
    fontSize: 16,
    fontFamily: 'defaultFontBold'
  },
  choosePaymentContainer: colors => ({
    marginTop: 15,
    borderLeftWidth: 2,
    borderColor: colors.orange
  }),
  choosePayment: {
    paddingLeft: 5,
    fontSize: 18,
    color: '#284D64',
    fontFamily: 'defaultFontBold'
  },
  parkButton: {
    backgroundColor: '#7FCFAB'
  },
  disabledParkButtonStyle: {
    backgroundColor: '#7FCFAB',
    marginTop: 15,
    opacity: 0.8
  },
  parkButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase',
  },
  scrollViewContainer: {
    marginLeft: 15,
    marginRight: 15,
    flexGrow: 1,
    justifyContent: 'center'
  },
  descriptionDetail: {
    flexDirection: 'row',
    marginTop: 10,
    marginRight: 5,
    alignItems: 'center'
  },
  detailsIcon: {
    fontSize: 20,
    color: '#AAAAAA',
    width: 25,
    textAlign: 'center',
    alignSelf: 'center'
  },
  detailsIconActive: {
    fontSize: 20,
    color: '#0992A5',
    width: 25,
    textAlign: 'center',
    paddingTop: 2
  },
  confirmationText: {
    color: '#F79256',
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: 10,
    fontFamily: 'defaultFont',
    lineHeight: 22
  },
  vehiclePlateContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10
  },
  vehicleInformationContainer: {
    justifyContent: 'center',
    marginLeft: 20
  },
  vehicleDescription: {
    color: '#AAAAAA',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'defaultFont',
    fontSize: 20
  },
  vehiclePlate: {
    color: '#0992A5',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 24,
    fontFamily: 'defaultFontBold'
  }
});

export default ParkingConfirmationScreen;