import React, { useEffect, useContext } from 'react';
import Parse from "parse";
import { AuthContext } from '../context/AuthContext';
import { View, ActivityIndicator } from 'react-native';
import { useTheme } from '@react-navigation/native';

const ResolveAuthScreen = (props) => {

  const { setCurrentUser } = useContext(AuthContext);
  const { colors } = useTheme();

  const checkUser = async () => {

    const userId = props.extraData ? props.extraData.userId : null;

    try {

      const user = await Parse.User.currentAsync();

      if (user) {
        setCurrentUser(user, false, userId);
      } else {
        setCurrentUser(null, false, userId);
      }
    }
    catch (e) {
      console.log('error getting logged user', e);
      setCurrentUser(null, false, userId);
    }
  }

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size='large' color={colors.darkBlue} />
    </View>
  );
};

export default ResolveAuthScreen;
