import React, { useContext, useEffect, useState } from 'react';
import { Alert, StyleSheet, View, TouchableOpacity, Text, Image, Keyboard, Linking } from 'react-native';
import alert from '../hooks/useAlert.js'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button, Input } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import cpfCnpjValidation from '../lib/validateCpfCnpj';
import Parse from "parse";
import { Dialog } from 'react-native-simple-dialogs';
import appParams from '../../constants/AppParams';
import useFocusScroll from '../hooks/useFocusScroll.js';
import { AuthContext } from '../context/AuthContext';
import * as Keychain from 'react-native-keychain';
import base64Images from '../../assets/images/base64/base64Images';
import OfficialFooter from '../components/OfficialFooter';

const SignInScreen = ({ navigation }) => {

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCpfCnpj, cpfCnpjMask] = cpfCnpjValidation();
  const [resetPasswordDialogVisibile, setResetPasswordDialogVisibile] = useState(false);
  const [showCentralOption, setShowCentralOption] = useState(false);
  const [regularLoginProcess, setRegularLoginProcess] = useState(false);

  const { state, setCurrentUser } = useContext(AuthContext);

  const [gotFocus] = useFocusScroll();

  let inputs = {};

  const focusNextField = (id) => {
    inputs[id].focus();
  };

  const setFieldWithMask = (field, value) => {
    switch (field) {
      case 'id':
        value = cpfCnpjMask(value);
        setId(value);
        return;
      default: return;
    }
  };

  const getUserEmail = async (userId) => {
    try {
      return await Parse.Cloud.run("findAssociatedEmail", { cpf: userId });
    } catch (e) {
      return e;
    }
  };

  const signInUser = async () => {

    const showError = (msg) => {
      alert(
        'Oops!',
        msg,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true },
      // );
    };

    const doServerLogin = async (userId, pwd) => {
      try {
        const userEmail = await getUserEmail(userId);

        if (!userEmail) {
          throw {
            userNotFound: true
          }
        }
        else {
          await Parse.User.logIn(userEmail, pwd);
        }
      }
      catch (e) {
        console.log('got error', e);
        let errorMsg = null;

        if (e.userNotFound) errorMsg = 'Não foi encontrado um usuário cadastrado com este CPF ou CNPJ.';
        else if (e['code'] == 101) errorMsg = 'CPF ou CNPJ ou senha incorretos.';
        else errorMsg = 'Ocorreu um erro ao realizar o login. Por favor, tente novamente.';

        throw errorMsg;
      }
    }

    setIsLoading(true);
    const plainId = id.replace(/\D/g, "");

    if (!isValidCpfCnpj(plainId)) {
      showError('O CPF ou CNPJ inserido é inválido.');
      setIsLoading(false);
      return;
    }

    if (!regularLoginProcess) {
      try {
        // const biometryType = await Keychain.getSupportedBiometryType();
        // if (biometryType && (biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' || biometryType.toUpperCase() === 'FACEID')) { //fingerprint supported
        if(false){
          const hasStoredCredentials = await Keychain.getGenericPassword({
            service: plainId
          });
          if (hasStoredCredentials && hasStoredCredentials.username) {
            const loginCredentials = await Keychain.getGenericPassword({
              authenticationPrompt: { title: 'Autenticação', cancel: 'Cancelar' },
              service: `loginCredentials${plainId}`
            });
            if (loginCredentials && loginCredentials.username) {
              try {
                await doServerLogin(loginCredentials.username, loginCredentials.password);
                //go to inner app screens
                setCurrentUser(Parse.User.current(), false, Parse.User.current().get('cpf'));
              }
              catch (e) {
                console.log('error logging in with stored credentials', e);
                showError(e);
                setRegularLoginProcess(true);
                setIsLoading(false);
              }
            }
            else {
              setRegularLoginProcess(true);
              setIsLoading(false);
            }
          }
          else {
            setRegularLoginProcess(true);
            setIsLoading(false);
          }
        }
        else {
          setRegularLoginProcess(true);
          setIsLoading(false);
        }
      }
      catch (e) {
        console.log('error getting keychain credentials', e);
        setRegularLoginProcess(true);
        setIsLoading(false);
      }
    }
    else {
      if (!password) {
        showError('É necessário preencher a senha.');
        setIsLoading(false);
        return;
      }
      else {

        try {
          await doServerLogin(plainId, password);
        }
        catch (e) {
          showError(e);
          setIsLoading(false);
          return;
        }

        //login was sucessful
        //check for fingerprint support
        try {
          // const biometryType = await Keychain.getSupportedBiometryType();
          // if (biometryType && (biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' || biometryType.toUpperCase() === 'FACEID')) { //fingerprint supported
          if(false) {
            const askToStoreLoginCredentials = async () => {
              return new Promise((resolve, reject) => {
                Alert.alert(
                  'Atenção',
                  `Você deseja utilizar ${biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' ? 'a impressão digital' : 'o reconhecimento facial'} neste aparelho da próxima vez que fizer login nesta conta?`,
                  [
                    { text: 'Não', onPress: () => resolve(false) },
                    { text: 'Sim', onPress: () => resolve(true) }
                  ],
                  { cancelable: false }
                )
              });
            };

            let storeCredentials = false;
            const hasStoredCredentials = await Keychain.getGenericPassword({
              service: plainId
            });
            if (hasStoredCredentials && hasStoredCredentials.username) {
              storeCredentials = true;
            }

            if (!storeCredentials) storeCredentials = await askToStoreLoginCredentials();

            if (storeCredentials) {
              await Keychain.setGenericPassword(plainId, 'yesStoreCredentials', {
                service: plainId
              });

              await Keychain.setGenericPassword(plainId, password, {
                accessControl: 'BiometryAny',
                service: `loginCredentials${plainId}`
              });
            }
          }
        }
        catch (e) {
          console.log('error setting fingerprint credentials', e);
        }

        //go to inner app screens
        setCurrentUser(Parse.User.current(), false, Parse.User.current().get('cpf'));
      }
    }
  };

  const resetPassword = async (sms) => {

    const goResetPassword = async () => {

      const showMessage = (title, errorMsg) => {

        alert(
          title,
          errorMsg,
          null,
          true
        );

        // Alert.alert(
        //   title,
        //   errorMsg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
      };

      const generalError = 'Ocorreu um erro no processo de gerar uma nova senha. Por favor, tente novamente.';

      if (sms) {
        setIsLoading(true);
        try {
          const result = await Parse.Cloud.run('SMSresetPassword', { cpf: id.replace(/\D/g, "") });
          showMessage('Pronto!', result);
          setIsLoading(false);
        }
        catch (e) {
          console.log('error resetting password via SMS', e);
          if (e && e.message && typeof (e.message) === 'string') {
            showMessage('Oops!', e.message);
          }
          else {
            showMessage('Oops!', generalError);
          }
          setIsLoading(false);
        }
      }
      else {
        try {
          setIsLoading(true);

          await Parse.Cloud.run("EmailResetPassword", {
            cpf: id.replace(/\D/g, "")
          });

          showMessage('Pronto!', 'Por favor, cheque seu e-mail cadastrado. Uma nova senha foi enviada.');

          setIsLoading(false);
        }
        catch (e) {
          console.log('error resetting password via email', e);
          showMessage('Oops!', generalError);
          setIsLoading(false);
        }
      }

    };

    let msg = null;
    if (sms) {
      msg = 'Você receberá uma mensagem de texto no celular cadastrado com uma nova senha. Deseja prosseguir?';
    }
    else {
      msg = 'Você receberá um e-mail com uma nova senha. Deseja prosseguir?';
    }

    alert(
      'Confirmação',
      msg,
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => { }
        },
        {
          text: 'Sim',
          onPress: () => goResetPassword()
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   msg,
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel'
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => goResetPassword()
    //     }
    //   ],
    //   { cancelable: true }
    // );

  };

  const forgotPassword = () => {

    const throwError = (errorMsg) => {
      alert(
        'Oops!',
        errorMsg,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   errorMsg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
    };
    const checkShowCentralOption = () => {
      const UTCDate = new Date();
      const weekDay = UTCDate.getUTCDay();
      const hours = UTCDate.getUTCHours();
      const minutes = UTCDate.getUTCMinutes();

      if (weekDay >= 1 && weekDay <= 5) {
        //mon-fri
        if ((hours > appParams.centralBusinessDayStartHourUTC ||
          (hours === appParams.centralBusinessDayStartHourUTC && minutes >= appParams.centralBusinessDayStartMinutesUTC)) &&
          (hours < appParams.centralBusinessDayEndHourUTC ||
            (hours === appParams.centralBusinessDayEndHourUTC && minutes <= appParams.centralBusinessDayEndMinutesUTC))) {
          setShowCentralOption(true);
        }
        else {
          setShowCentralOption(false);
        }
      }
      else if (weekDay === 6 &&
        (hours > appParams.centralSaturdayStartHourUTC ||
          (hours === appParams.centralSaturdayStartHourUTC && minutes >= appParams.centralSaturdayStartMinutesUTC)) &&
        (hours < appParams.centralSaturdayEndHourUTC &&
          (hours === appParams.centralSaturdayEndHourUTC && minutes <= appParams.centralSaturdayEndMinutesUTC))) {
        setShowCentralOption(true);
      }
      else {
        setShowCentralOption(false);
      };
    };

    const plainId = id.replace(/\D/g, "");
    if (!id) {
      throwError('Por favor, informe o CPF ou CNPJ cadastrado.');
      return;
    }
    else if (!isValidCpfCnpj(plainId)) {
      throwError('Por favor, informe um CPF ou CNPJ válido.');
      return;
    }
    else {
      checkShowCentralOption();
      setResetPasswordDialogVisibile(true);
    }

  };

  const getLastUserLoggedInId = async () => {
    try {
      let lastUserLoggedInId = state ? state.lastUserLoggedInId : null;

      if (lastUserLoggedInId) {
        setId(cpfCnpjMask(lastUserLoggedInId, true));
      }
    }
    catch (e) {
      console.log('error getting lastUserLoggedInId', e);
    }
  };

  useEffect(() => {
    getLastUserLoggedInId();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <KeyboardAwareScrollView
        // innerRef={ref => {
        //   this.scroll = ref ? ref.getScrollResponder() : null;
        // }}
        enableOnAndroid={true}
        // enableAutomaticScroll={(Platform.OS === 'ios')}
        keyboardOpeningTime={0}
        extraHeight={250}
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={styles.scrollViewContainer}
        persistentScrollbar={true}
      >
        <View style={styles.appLogoContainer}>
          <Image
            source={{ uri: base64Images.appLogo }}
            style={styles.appLogo}
          />
        </View>
        <View style={{ margin: 20 }}>
          <View>
            <Input
              ref={input => { inputs['id'] = input }}
              containerStyle={styles.container}
              inputContainerStyle={[styles.inputContainer, { marginBottom: 20 }]}
              inputStyle={styles.input}
              placeholder='CPF ou CNPJ'
              label={id.length > 0 ? 'CPF ou CNPJ' : ''}
              labelStyle={styles.label}
              leftIcon={
                <FontAwesome
                  name='user'
                  style={styles.inputIcon}
                />
              }
              value={id}
              onChangeText={(id) => { setFieldWithMask('id', id); }}
              onFocus={gotFocus}
              onSubmitEditing={() => { regularLoginProcess ? focusNextField('password') : Keyboard.dismiss() }}
              blurOnSubmit={false}
              autoCorrect={false}
              keyboardType='numeric'
              returnKeyType='next'
            />
          </View>

          {regularLoginProcess ?
            <View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['password'] = input }}
                  secureTextEntry={hidePassword}
                  containerStyle={styles.container}
                  inputContainerStyle={styles.inputContainer}
                  inputStyle={styles.input}
                  placeholder='Senha'
                  label={password.length > 0 ? 'Senha' : ' '}
                  labelStyle={styles.label}
                  leftIcon={
                    <FontAwesome
                      name='lock'
                      style={styles.inputIcon}
                    />
                  }
                  value={password}
                  onChangeText={setPassword}
                  onFocus={gotFocus}
                  onSubmitEditing={() => { Keyboard.dismiss(); }}
                  blurOnSubmit={false}
                  autoCapitalize='none'
                  autoCorrect={false}
                />
                <TouchableOpacity onPress={() => setHidePassword(!hidePassword)} style={styles.showPasswordTouchable}>
                  <FontAwesome
                    name={hidePassword ? 'eye' : 'eye-slash'}
                    style={styles.passwordIcon}
                  />
                </TouchableOpacity>
              </View>
              <TouchableOpacity onPress={() => forgotPassword()}>
                <Text style={styles.forgotPassword}>Esqueceu a senha?</Text>
              </TouchableOpacity>
            </View>
            : null}

          <Button
            title='Entrar'
            onPress={() => signInUser()}
            buttonStyle={styles.submitButtonStyle}
            loading={isLoading}
            disabled={isLoading}
            disabledStyle={styles.disabledSubmitButtonStyle}
            titleStyle={styles.signInButtonTitle}
          />

          <TouchableOpacity
            style={{ marginTop: 25 }}
            onPress={() => {
              navigation.navigate('SignUp');
            }}>
            <Text style={styles.hasNoAccount}>Ainda não possui uma conta? <Text style={{ textDecorationLine: 'underline' }}>Cadastre-se</Text></Text>
          </TouchableOpacity>
        </View>

      </KeyboardAwareScrollView>
      <OfficialFooter />

      <Dialog
        visible={resetPasswordDialogVisibile}
        title='Recuperação de Senha'
        titleStyle={styles.defaultTextBold}
      >
        <View>
          <Text style={styles.defaultText}>Escolha como deseja recuperar sua senha:</Text>
          <Button
            title='Receber SMS'
            onPress={() => {
              setResetPasswordDialogVisibile(false);
              resetPassword(true)
            }}
            buttonStyle={styles.proceedButton}
            titleStyle={styles.signInButtonTitle}
          />
          <Button
            title='Receber e-mail'
            onPress={() => {
              setResetPasswordDialogVisibile(false);
              resetPassword()
            }}
            buttonStyle={styles.proceedButton}
            titleStyle={styles.signInButtonTitle}
          />
          {showCentralOption ?
            <Button
              title='Ligar para a Central'
              onPress={() => {
                setResetPasswordDialogVisibile(false);
                Linking.openURL(`tel:${appParams.contactPhoneToCall}`);
              }}
              buttonStyle={styles.proceedButton}
              titleStyle={styles.signInButtonTitle}
            />
            : null}
          <Button
            title='Cancelar'
            onPress={() => setResetPasswordDialogVisibile(false)}
            buttonStyle={styles.cancelButton}
            titleStyle={styles.signInButtonTitle}
          />
        </View>
      </Dialog>
    </View>
  );

};

const styles = StyleSheet.create({
  defaultTextBold: {
    fontFamily: 'defaultFontBold',
    fontSize: 17,
    color: '#424242'
  },
  defaultText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  appLogoContainer: {
    marginTop: 100,
    marginBottom: 40
  },
  appLogo: {
    height: 75,
    width: 188,
    alignSelf: 'center'
  },
  logo: {
    marginTop: 50,
    marginBottom: 30
  },
  signInButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  disabledSubmitButtonStyle: {
    backgroundColor: 'rgba(127, 207, 171, 0.5)',
    marginTop: 20,
  },
  submitButtonStyle: {
    backgroundColor: '#7FCFAB',
    marginTop: 20
  },
  proceedButton: {
    backgroundColor: '#7FCFAB',
    marginTop: 10
  },
  cancelButton: {
    backgroundColor: '#EF6F6C',
    marginTop: 10
  },
  hasNoAccount: {
    marginTop: 30,
    textAlign: 'center',
    color: '#f79256',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  forgotPassword: {
    textAlign: 'right',
    paddingRight: 10,
    color: '#F79256',
    fontSize: 16,
    fontFamily: 'defaultFont',
    marginTop: 10,
    marginBottom: 20
  },
  showPasswordTouchable: {
    position: 'absolute',
    right: 20,
    top: 38
  },
  passwordIcon: {
    width: 40,
    textAlign: 'center',
    fontSize: 20,
    color: 'rgb(9, 146, 165)'
  },
  inputIcon: {
    fontSize: 16,
    width: 20,
    paddingRight: 5,
    textAlign: 'center',
    color: 'rgb(9, 146, 165)'
  },
  label: {
    color: '#424242',
    paddingLeft: 4,
    fontWeight: 'normal',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  input: {
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 16,
    outlineStyle: 'none'
  },
  inputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
  }
});

SignInScreen.navigationOptions = () => {
  return {
    header: null
  };
};

export default SignInScreen;
