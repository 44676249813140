import AsyncStorage from '@react-native-async-storage/async-storage';
import Parse from 'parse';
// import PushNotification from 'react-native-push-notification';
import useExpiredSession from '../hooks/useExpiredSession';

export default () => {

  const [checkIfSessionExpired] = useExpiredSession();

  const generateUniquishId = (input) => {
    let hash = 0;
    const len = input.length;
    for (let i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return hash.toString();
  };

  const scheduleNotification = async (notificationId, msg, soundType, dateTimeToNotify) => {
    console.log('WILL SCHEDULE', notificationId, msg, soundType, dateTimeToNotify);

    // PushNotification.deleteChannel(`alarmes-de-expiracao`);
    // for (let i = 1; i < 40; i++) {
    //   PushNotification.deleteChannel(`alarmes-de-expiracao${i}`);
    // }

    let soundFile = 'default_alarm.mp3'
    let channel = 'default-alarm-channel'
    if (soundType === 'short') {
      soundFile = 'short_alarm.mp3';
      channel = 'short-alarm-channel';
    }
    else if (soundType === 'long') {
      soundFile = 'long_alarm.mp3'
      channel = 'long-alarm-channel';
    }

    //change actions for Android according to extension possible/expiration date

    // PushNotification.localNotificationSchedule({
    //   date: dateTimeToNotify,
    //   channelId: channel,
    //   vibrate: true, // (optional) default: true
    //   vibration: 2000, // vibration length in milliseconds
    //   allowWhileIdle: true,
    //   // actions: ['Adicionar mais tempo', 'Já saí da vaga'],
    //   id: notificationId, // (optional) Valid unique 32 bit integer specified as string. default: Autogenerated Unique ID
    //   title: 'Fim do tempo de estacionamento',
    //   message: msg,
    //   playSound: true,
    //   soundName: soundFile
    // });
  };

  const scheduleNotifications = async (licensePlate, expireTimestamp) => {
    console.log('licensePlate', licensePlate, expireTimestamp);

    try {
      const alarmTimeSwitchOnPreference = await getAlarmPreferences('expireTimes');
      const alarmSoundLengthPreference = await getAlarmPreferences('expireSoundLength');

      // console.log('alarmTimeSwitchOnPreference', alarmTimeSwitchOnPreference);
      // console.log('alarmSoundLengthPreference', alarmSoundLengthPreference);

      const now = new Date();
      const nowTimestamp = now.getTime();
      const notificationMillisecondsThreshold = 5000; //five seconds

      if (alarmTimeSwitchOnPreference.onExpireTime) {
        const message = `Seus créditos para a placa ${licensePlate} expiraram!`;
        const millisecondsFromNow = expireTimestamp - nowTimestamp;
        if (millisecondsFromNow > notificationMillisecondsThreshold) {
          scheduleNotification(generateUniquishId(licensePlate), message, alarmSoundLengthPreference, new Date(nowTimestamp + millisecondsFromNow));
        }
      }
      if (alarmTimeSwitchOnPreference.fiveMinBeforeExpireTime) {
        const message = `Seus créditos para a placa ${licensePlate} expiram em 5 minutos.`;
        const millisecondsFromNow = expireTimestamp - nowTimestamp - 5 * 60 * 1000;

        if (millisecondsFromNow > notificationMillisecondsThreshold) {
          scheduleNotification(generateUniquishId(licensePlate + 5), message, alarmSoundLengthPreference, new Date(nowTimestamp + millisecondsFromNow));
        }
      }
      if (alarmTimeSwitchOnPreference.tenMinBeforeExpireTime) {
        const message = `Seus créditos para a placa ${licensePlate} expiram em 10 minutos.`;
        const millisecondsFromNow = expireTimestamp - nowTimestamp - 10 * 60 * 1000;
        if (millisecondsFromNow > notificationMillisecondsThreshold) {
          scheduleNotification(generateUniquishId(licensePlate + 10), message, alarmSoundLengthPreference, new Date(nowTimestamp + millisecondsFromNow));
        }
      }
      if (alarmTimeSwitchOnPreference.thirtyMinBeforeExpireTime) {
        const message = `Seus créditos para a placa ${licensePlate} expiram em 30 minutos.`;
        const millisecondsFromNow = expireTimestamp - nowTimestamp - 30 * 60 * 1000;
        if (millisecondsFromNow > notificationMillisecondsThreshold) {
          scheduleNotification(generateUniquishId(licensePlate + 30), message, alarmSoundLengthPreference, new Date(nowTimestamp + millisecondsFromNow));
        }
      }
    }
    catch (e) {
      console.log('error on scheduleNotifications', e);
      checkIfSessionExpired(e);
    }

  };

  const changeNotificationsStatusForLicensePlate = async (licensePlate, type, expireTimestamp) => {
    try {
      if (type === 'on') {
        console.log('changeNotificationsStatusForLicensePlate', licensePlate, type);
        scheduleNotifications(licensePlate, expireTimestamp);
        await AsyncStorage.setItem(`${licensePlate}_NotificationsStatus`, 'on');
      }
      else if (type === 'off') {
        console.log('will cancel all notifications for', licensePlate);
        // PushNotification.cancelLocalNotifications({ id: generateUniquishId(licensePlate) });
        // PushNotification.cancelLocalNotifications({ id: generateUniquishId(licensePlate + 5) });
        // PushNotification.cancelLocalNotifications({ id: generateUniquishId(licensePlate + 10) });
        // PushNotification.cancelLocalNotifications({ id: generateUniquishId(licensePlate + 30) });

        console.log('changeNotificationsStatusForLicensePlate', licensePlate, type);
        await AsyncStorage.setItem(`${licensePlate}_NotificationsStatus`, 'off');
      }
    } catch (e) {
      console.log('error changing notification status for license plate', e);
      checkIfSessionExpired(e);
    }
  };

  const getNotificationsStatusForLicensePlate = async (licensePlate) => {
    try {
      const status = await AsyncStorage.getItem(`${licensePlate}_NotificationsStatus`);
      console.log('getNotificationsStatusForLicensePlate', licensePlate, status);

      return status;
    } catch (e) {
      console.log('error getting notification status for license plate', e);
      checkIfSessionExpired(e);
    }
  };

  const getAlarmPreferences = async (type) => {
    const defaultExpireTimes = {
      onExpireTime: true,
      fiveMinBeforeExpireTime: true,
      tenMinBeforeExpireTime: true,
      thirtyMinBeforeExpireTime: false
    };

    const defaultAlarmSoundLength = 'default';

    try {
      const user = Parse.User.current();

      if (type === 'expireTimes') {
        const jsonValue = await AsyncStorage.getItem(`${user.id}_expireTimes`, jsonValue);
        if (!jsonValue) return defaultExpireTimes;
        else {
          return JSON.parse(jsonValue);;
        }
      }
      else {
        const jsonValue = await AsyncStorage.getItem(`${user.id}_expireSoundLength`, jsonValue);
        if (!jsonValue) return defaultAlarmSoundLength;
        else {
          return JSON.parse(jsonValue);;
        }
      }

    } catch (e) {
      console.log('error getting alarm preferences', e);
      checkIfSessionExpired(e);
      if (type === 'expireTimes') {
        return defaultExpireTimes;
      }
      else {
        return defaultAlarmSoundLength;
      }
    }
  };

  const setAlarmPreferences = async (type, value) => {
    try {
      const user = Parse.User.current();

      const jsonValue = JSON.stringify(value);
      if (type === 'expireTimes') await AsyncStorage.setItem(`${user.id}_expireTimes`, jsonValue);
      else await AsyncStorage.setItem(`${user.id}_expireSoundLength`, jsonValue);


    } catch (e) {
      console.log('error setting alarm preferences', e);
      checkIfSessionExpired(e);
    }
  };

  return [setAlarmPreferences, getAlarmPreferences, scheduleNotifications, getNotificationsStatusForLicensePlate, changeNotificationsStatusForLicensePlate];

};
