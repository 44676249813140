import Parse from 'parse';

export default () => {


  const getRevertedPurchases = async () => {
    try {
      const Reverted = Parse.Object.extend("Reverted");
      const revertedQuery = new Parse.Query(Reverted);
      revertedQuery.equalTo("user", Parse.User.current());
      const result = await revertedQuery.first();

      let reverted = null;
      if (result) {
        reverted = result._toFullJSON();
        if (reverted.cads) {
          return reverted.cads;
        }
      }
      return 0;
    }
    catch (e) {
      console.log('error getting reverted purchases', e);

      //fails quietly. server will not allow to proceed if there are reverted purchases
      //this is only a warning to be shown to the user
      return 0;
    }
  };

  return [getRevertedPurchases];

};