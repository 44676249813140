import React, { useContext, useEffect, useState } from 'react';
import { Alert, StyleSheet, Platform, View, TouchableOpacity, Modal, Text, SafeAreaView, Keyboard, TextInput } from 'react-native';
import alert from '../hooks/useAlert.js'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button, Input, CheckBox } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import useUserDataValidation from '../hooks/useUserDataValidation';
import TCComponent from '../components/TermsAndConditionsComponent';
import Parse from "parse";
import useFocusScroll from '../hooks/useFocusScroll.js';
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '@react-navigation/native';
import { ProgressBar } from 'react-native-paper';
import * as Keychain from 'react-native-keychain';
import OfficialFooter from '../components/OfficialFooter';

const SignUpScreen = () => {
  const vehicleCategories = [
    { name: 'Automóvel', icon: 'car', category: 1 },
    { name: 'Motocicleta', icon: 'motorcycle', category: 2 },
    { name: 'Outros', icon: 'truck', category: 3 },
  ];
  const [selectedCategory, setSelectedCategory] = useState([]);


  const { colors } = useTheme();

  const [acceptTC, setAcceptTC] = useState(false);

  const [user, setUser] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [gotBlurred, setGotBlurred] = useState({});

  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [hideReviewPassword, setHideReviewPassword] = useState(true);

  const [tcModalVisible, setTcModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [phoneMask, validField, checkAddressField, maskZipCode] = useUserDataValidation();

  const stepFieldAndNextField = {
    1: { field: 'name' },
    2: { field: 'email' },
    3: { field: 'phone' },
    4: { field: 'id' },
    5: { field: 'password' },
    6: { field: 'confirmPassword' },
    7: { field: 'licensePlate' }
  };

  const { setCurrentUser } = useContext(AuthContext);


  let inputs = {};

  const focusNextField = (id) => {
    if (id !== 'name') inputs[id].focus();
  };

  const signUpUser = async () => {

    const FORCE_BLUR = true;
    let formWithError = false;

    setLoading(true);

    const userVariables = [
      'name', user.name,
      'email', user.email,
      'phone', user.phone,
      'id', user.id,
      'password', user.password,
      'confirmPassword', user.confirmPassword,

      //new
      'licensePlate', user.licensePlate
    ];

    for (let i = 0; i < userVariables.length; i += 2) {
      if (!validField(userVariables[i], userVariables[i + 1], FORCE_BLUR, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred)) {
        formWithError = true;
        break;
      }
    };

    if (!formWithError && !acceptTC) {
      formWithError = true;
    };

    if (formWithError) {

      alert(
        'Erro no preenchimento',
        'Alguns campos do cadastro não foram preenchidos, possuem erros ou os termos e condições não foram aceitos. Avalie atentamente a tela, volte e corrija os erros.',
        null,
        true
      );


      // Alert.alert(
      //   'Erro no preenchimento',
      //   'Alguns campos do cadastro não foram preenchidos, possuem erros ou os termos e condições não foram aceitos. Avalie atentamente a tela, volte e corrija os erros.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false },
      // );
      setLoading(false);
    }
    else {
      const usr = new Parse.User();

      usr.set('name', user.name);
      usr.set('username', user.email.toLowerCase());
      usr.set('password', user.password);
      usr.set('email', user.email.toLowerCase());
      usr.set('phone', user.phone.replace(/\D/g, ""));
      usr.set('cpf', user.id.replace(/\D/g, ""));

      //new
      usr.set('licensePlate', user.licensePlate.replace(" ", ""));
      usr.set('vehicleCategory', user.vehicleCategory);
      //

      try {
        const newUser = await usr.signUp();

        //new - save User Vehicle
        const newVehicle = await Parse.Cloud.run("saveUserVehicle", {
          licensePlate:  user.licensePlate.replace(" ", ""),
          category: user.vehicleCategory,
          doNotSaveOnTransalvador: true
        });

        setLoading(false);
        setCurrentUser(newUser, false, newUser.get('cpf'));

        // const biometryType = await Keychain.getSupportedBiometryType();

        // if (biometryType && (biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' || biometryType.toUpperCase() === 'FACEID')) { //fingerprint supported
        if(false){
          const askToStoreLoginCredentials = async () => {
            return new Promise((resolve, reject) => {
              Alert.alert(
                'Pronto!',
                `Seu cadastro foi realizado com sucesso. Você deseja utilizar ${biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' ? 'a impressão digital' : 'o reconhecimento facial'} neste aparelho da próxima vez que fizer login nesta conta?`,
                [
                  { text: 'Não', onPress: () => resolve(false) },
                  { text: 'Sim', onPress: () => resolve(true) }
                ],
                { cancelable: false }
              )
            });
          };

          const storeCredentials = await askToStoreLoginCredentials();
          const plainId = user.id.replace(/\D/g, "");

          if (storeCredentials) {
            await Keychain.setGenericPassword(plainId, 'yesStoreCredentials', {
              service: plainId
            });

            await Keychain.setGenericPassword(plainId, user.password, {
              accessControl: 'BiometryAny',
              service: `loginCredentials${plainId}`
            });
          }
        }
        else {
          alert(
            'Pronto!',
            'Seu cadastro foi realizado com sucesso e você já pode utilizar o App.',
            null,
            true
          );

          // Alert.alert(
          //   'Pronto!',
          //   'Seu cadastro foi realizado com sucesso e você já pode utilizar o App.',
          //   [
          //     { text: 'OK' },
          //   ],
          //   { cancelable: true },
          // );
        }
      }
      catch (e) {
        let msg = '';

        if (e['code'] == 202) {
          msg = 'Já existe um usuário cadastrado com este e-mail. Se você já se cadastrou, volte à tela anterior e faça login.'
        }
        else {
          msg = e['message'] ? e['message'] : 'Por favor, tente novamente.'
        }

        alert(
          'Erro ao realizar cadastro',
          msg,
          null,
          true
        );

        // Alert.alert(
        //   'Erro ao realizar cadastro',
        //   msg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false },
        // );
        setLoading(false);
      }
    }
  };

  const [gotFocus] = useFocusScroll();

  const goToNextStep = () => {

    // if (step < 7) {
    if (step < 8) {
      validField(stepFieldAndNextField[step].field, user[stepFieldAndNextField[step].field], true, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);

      if (user[stepFieldAndNextField[step].field] && !errorMessages[stepFieldAndNextField[step].field]) {
        if (step !== 6 || (step === 6 && user[stepFieldAndNextField[step - 1].field] === user[stepFieldAndNextField[step].field])) {
          console.log('user is', user);
          if (step === 7 && !user.vehicleCategory) {

            alert(
              'Oops!',
              'É necessário escolher a categoria do veículo para continuar.',
              null,
              true
            );

            // Alert.alert(
            //   'Oops!',
            //   'É necessário escolher a categoria do veículo para continuar.',
            //   [
            //     { text: 'OK' },
            //   ],
            //   { cancelable: false },
            // );
          }
          else {
            setStep(step + 1);
          }
        }
      }
    }
    else {
      if (!acceptTC) {
        alert(
          'Oops!',
          'É necessário aceitar os Termos e Condições de uso do aplicativo para continuar.',
          null,
          true
        );
        
        // Alert.alert(
        //   'Oops!',
        //   'É necessário aceitar os Termos e Condições de uso do aplicativo para continuar.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false },
        // );
      }
      else {
        setStep(step + 1);
      }
    }
  };

  useEffect(() => {
    if (step < 7 && (!user[stepFieldAndNextField[step].field] || errorMessages[stepFieldAndNextField[step].field])) {
      focusNextField(stepFieldAndNextField[step].field);
    }
    else {
      Keyboard.dismiss();
    }
  }, [step]);

  return (
    <View style={styles.mainViewContainer}>
      <View style={styles.progressBarContainer}>
        <ProgressBar progress={step / 9} color={'#7FCFAB'} />
        <Text style={styles.progressText}>Passo {step} de 9</Text>
      </View>
      <KeyboardAwareScrollView
        // innerRef={ref => {
        //   this.scroll = ref ? ref.getScrollResponder() : null;
        // }}
        enableOnAndroid={true}
        enableAutomaticScroll={(Platform.OS === 'ios')}
        keyboardOpeningTime={0}
        extraHeight={250}
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={styles.scrollViewContainer}
        persistentScrollbar={true}
        // contentContainerStyle={{ justifyContent: 'flex-start' }}
      >
        <View style={styles.formContainer}>

          {step === 1 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe seu nome completo ou o nome da empresa:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['name'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.name && errorMessages.name) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  placeholder='João da Silva'
                  leftIcon={
                    <FontAwesome
                      name='user'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.name}
                  onChangeText={(name) => {
                    setUser({ ...user, name });
                    validField('name', name, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, name: true });
                    if (!user.name) validField('name', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  autoCorrect={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.name ? errorMessages.name : null}
                />
                <FontAwesome
                  name={errorMessages.name ? (gotBlurred.name ? 'times' : null) : (user.name ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.name ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 2 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe seu e-mail:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['email'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.email && errorMessages.email) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  placeholder='joao@email.com'
                  leftIcon={
                    <FontAwesome
                      name='envelope'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.email}
                  onChangeText={(email) => {
                    setUser({ ...user, email });
                    validField('email', email, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  keyboardType='email-address'
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, email: true });
                    if (!user.email) validField('email', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  autoCapitalize='none'
                  autoCorrect={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.email ? errorMessages.email : null}
                />
                <FontAwesome
                  name={errorMessages.email ? (gotBlurred.email ? 'times' : null) : (user.email ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.email ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 3 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe seu telefone celular com DDD:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['phone'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.phone && errorMessages.phone) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  placeholder='(85) 98888-7777'
                  leftIcon={
                    <FontAwesome
                      name='phone'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.phone}
                  onChangeText={(phone) => {
                    validField('phone', phone, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  autoCorrect={false}
                  keyboardType='phone-pad'
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, phone: true });
                    if (!user.phone) validField('phone', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.phone ? errorMessages.phone : null}
                />
                <FontAwesome
                  name={errorMessages.phone ? (gotBlurred.phone ? 'times' : null) : (user.phone ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.phone ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 4 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe seu CPF ou CNPJ:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['id'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.id && errorMessages.id) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  placeholder='123.456.789-00'
                  leftIcon={
                    <FontAwesome
                      name='id-card'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.id}
                  onChangeText={(id) => {
                    validField('id', id, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  autoCorrect={false}
                  keyboardType='numeric'
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, id: true });
                    if (!user.id) validField('id', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.id ? errorMessages.id : null}
                />
                <FontAwesome
                  name={errorMessages.id ? (gotBlurred.id ? 'times' : null) : (user.id ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.id ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 5 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe sua senha:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['password'] = input }}
                  secureTextEntry={hidePassword}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.password && errorMessages.password) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  leftIcon={
                    <FontAwesome
                      name='lock'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.password}
                  onChangeText={(password) => {
                    setUser({ ...user, password });
                    validField('password', password, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  autoCapitalize='none'
                  autoCorrect={false}
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, password: true });
                    if (!user.password) validField('password', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.password ? errorMessages.password : null}
                />
                <TouchableOpacity onPress={() => setHidePassword(!hidePassword)} style={styles.showPasswordTouchable}>
                  <FontAwesome
                    name={hidePassword ? 'eye' : 'eye-slash'}
                    style={styles.passwordIcon}
                  />
                </TouchableOpacity>
                <FontAwesome
                  name={errorMessages.password ? (gotBlurred.password ? 'times' : null) : (user.password ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.password ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 6 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Confirme sua senha:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  secureTextEntry={hideConfirmPassword}
                  ref={input => { inputs['confirmPassword'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.confirmPassword && errorMessages.confirmPassword) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  leftIcon={
                    <FontAwesome
                      name='lock'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.confirmPassword}
                  onChangeText={(confirmPassword) => {
                    setUser({ ...user, confirmPassword });
                    validField('confirmPassword', confirmPassword, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  autoCapitalize='none'
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  autoCorrect={false}
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, confirmPassword: true });
                    if (!user.confirmPassword) validField('confirmPassword', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.confirmPassword ? errorMessages.confirmPassword : null}
                />
                <TouchableOpacity onPress={() => setHideConfirmPassword(!hideConfirmPassword)} style={styles.showPasswordTouchable}>
                  <FontAwesome
                    name={hideConfirmPassword ? 'eye' : 'eye-slash'}
                    style={styles.passwordIcon}
                  />
                </TouchableOpacity>
                <FontAwesome
                  name={errorMessages.confirmPassword ? (gotBlurred.confirmPassword ? 'times' : null) : (user.confirmPassword ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.confirmPassword ? styles.validateIconError : null]}
                />
              </View>
            </View>
            : null}

          {step === 7 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Informe a placa de seu veículo principal:</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Input
                  ref={input => { inputs['licensePlate'] = input }}
                  containerStyle={styles.container}
                  inputContainerStyle={[styles.inputContainer, (gotBlurred.licensePlate && errorMessages.licensePlate) ? styles.inputContainerError : null]}
                  inputStyle={styles.input}
                  placeholder='ABC1234'
                  leftIcon={
                    <FontAwesome
                      name='pencil-square-o'
                      style={styles.inputIcon}
                    />
                  }
                  value={user.licensePlate}
                  onChangeText={(licensePlate) => {
                    setUser({ ...user, licensePlate: licensePlate.toUpperCase().substring(0, 10).replace(/[^0-9a-z]/gi, '') });
                    validField('licensePlate', licensePlate, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  onFocus={gotFocus}
                  onBlur={() => {
                    setGotBlurred({ ...gotBlurred, licensePlate: true });
                    if (!user.licensePlate) validField('licensePlate', null, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                  }}
                  returnKeyType='next'
                  onSubmitEditing={goToNextStep}
                  blurOnSubmit={false}
                  autoCorrect={false}
                  errorStyle={styles.errorMessage}
                  errorMessage={gotBlurred.licensePlate ? errorMessages.licensePlate : null}
                />
                <FontAwesome
                  name={errorMessages.licensePlate ? (gotBlurred.licensePlate ? 'times' : null) : (user.licensePlate ? 'check' : null)}
                  style={[styles.validateIcon, errorMessages.licensePlate ? styles.validateIconError : null]}
                />
              </View>

              <View>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>E informe a categoria do veículo:</Text>
                </View>

                <View style={styles.vehicleCategoriesContainer}>
                  {vehicleCategories.map((item, index) => (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedCategory({ name: item.name, icon: item.icon, category: item.category });
                        setUser({ ...user, vehicleCategory: item.category, vehicleIcon: item.icon });
                      }}
                      style={[styles.touchableOption, selectedCategory.name === item.name ? styles.touchableOptionActive : styles.touchableOptionUnactive]}
                    >
                      <FontAwesome
                        name={item.icon}
                        style={[styles.newVehicleIcon, selectedCategory.name === item.name ? styles.newVehicleIconActive : styles.newVehicleIconUnactive]}
                      />
                      <Text
                        style={[styles.categoryText, selectedCategory.name === item.name ? styles.categoryTextActive : styles.categoryTextUnactive]}
                      >
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>

              </View>

            </View>
            : null}

          {step === 8 ?
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Aceite os Termos e Condições de Uso do aplicativo tocando no quadrado abaixo.</Text>
              </View>
              <CheckBox
                left
                size={50}
                checkedColor='rgb(9, 146, 165)'
                containerStyle={styles.checkboxContainer}
                textStyle={styles.checkboxText}
                title={
                  <View>
                    <Text style={styles.checkboxText}>
                      Aceito os <Text style={{ fontFamily: 'defaultFontBold' }}>termos e condições</Text>
                    </Text>
                    <Text style={styles.checkboxText}>de uso do aplicativo.</Text>
                  </View>
                }
                checked={acceptTC}
                onPress={() => { setAcceptTC(!acceptTC); }}
              />

              <TouchableOpacity
                style={styles.showTCTouchable}
                onPress={() => { setTcModalVisible(true) }}
              >
                <Text style={[styles.checkboxText, { textAlign: 'center', fontFamily: 'defaultFontBold' }]}>
                  Ver os Termos e Condições
                </Text>
              </TouchableOpacity>
            </View>
            : null}

          {step === 9 ?
            <View style={{ marginRight: 10 }}>
              <View style={{ margin: 10 }}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}><Text style={{ fontFamily: 'defaultFontBold' }}>Pronto!</Text> Agora é só revisar seus dados e concluir o cadastro.</Text>
                  <Text style={[styles.label, { fontFamily: 'defaultFontItalic' }]}>Caso alguma informação esteja errada, corrija antes de finalizar tocando no ícone laranja.</Text>
                </View>
                <View style={{ margin: 10 }}>
                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='user'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>{user.name}</Text>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(1)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='envelope'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>{user.email}</Text>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(2)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='phone'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>{user.phone}</Text>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(3)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='id-card'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>{user.id}</Text>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(4)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='lock'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <TextInput
                      secureTextEntry={hideReviewPassword}
                      style={styles.reviewFieldTextPassword}
                      value={user.password}
                      editable={false}
                    />

                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setHideReviewPassword(!hideReviewPassword)}
                    >
                      <FontAwesome
                        name={hideReviewPassword ? 'eye' : 'eye-slash'}
                        style={[styles.passwordIcon, { marginTop: 2 }]}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(5)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name={user.vehicleIcon}
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>{user.licensePlate}</Text>
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(7)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={styles.reviewFieldContainer}>
                    <FontAwesome
                      name='list-alt'
                      style={[styles.inputIcon, { paddingTop: 4 }]}
                    />
                    <Text style={styles.reviewFieldText}>Termos e Condições aceitos</Text>
                    <FontAwesome
                      name='check'
                      style={[styles.inputIcon, { paddingTop: 4, color: '#7FCFAB', marginLeft: 5 }]}
                    />
                    <TouchableOpacity style={{ marginLeft: 10 }}
                      onPress={() => setStep(8)}
                    >
                      <FontAwesome
                        name='edit'
                        style={styles.editIcon}
                      />
                    </TouchableOpacity>
                  </View>

                </View>
              </View>
            </View>
            : null}
        </View>

        <View>
          {step < 9 ?
            <View style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
              <Button
                title='Continuar'
                onPress={goToNextStep}
                buttonStyle={styles.nextFieldButton}
                titleStyle={styles.nextFieldButtonTitle}
                icon={
                  <FontAwesome
                    name='arrow-right'
                    style={styles.nextArrowIcon}
                  />
                }
              />
            </View>
            : null}

          {step == 9 ?
            <View style={{ marginLeft: 10, marginRight: 10 }}>
              <Button
                title='Cadastrar-me'
                loading={isLoading}
                disabled={isLoading}
                disabledStyle={styles.disabledSubmitButtonStyle}
                onPress={() => signUpUser()}
                buttonStyle={styles.submitButton}
                titleStyle={styles.signUpButtonTitle}
              />
            </View>
            : null}

          {step > 1 ?
            <View style={{ marginTop: 10, marginLeft: 10, marginRight: 10, marginBottom: 25 }}>
              <Button
                title='Voltar'
                onPress={() => {
                  setStep(step - 1);
                }}
                buttonStyle={styles.previousFieldButton}
                titleStyle={styles.previousFieldButtonTitle}
                icon={
                  <FontAwesome
                    name='arrow-left'
                    style={styles.previousArrowIcon}
                  />
                }
              />
            </View>
            : null}

        </View>

      </KeyboardAwareScrollView>
      <OfficialFooter />

      <Modal
        animationType="slide"
        transparent={false}
        visible={tcModalVisible}
        onRequestClose={() => { setTcModalVisible(false) }}
      >
        <SafeAreaView>
          <View style={styles.modalHeader}>
            <View style={{ width: 50 }} />
            <Text style={styles.tcModalTitle}>Termos e Condições</Text>
            <TouchableOpacity
              onPress={() => setTcModalVisible(false)}
              style={styles.closeModalTouchable}>
              <FontAwesome
                name='times'
                style={styles.closeModalIcon}
              />
            </TouchableOpacity>
          </View>
        </SafeAreaView>
        <TCComponent
        />
      </Modal>

    </View>
  );
}

const styles = StyleSheet.create({
  vehicleCategoriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  touchableOption: {
    flex: 1,
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 10,
    alignItems: 'center',
    borderBottomColor: 'transparent',
    borderBottomWidth: 4
  },
  touchableOptionUnactive: {
    borderBottomColor: 'transparent'
  },
  touchableOptionActive: {
    borderBottomColor: '#0992A5'
  },
  container: {
    width: '75%'
  },
  reviewFieldContainer: {
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center'
  },
  submitButton: {
    backgroundColor: '#7FCFAB'
  },
  editIcon: {
    fontSize: 18,
    color: '#F79256',
    paddingTop: 4
  },
  reviewFieldText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242',
    paddingLeft: 10
  },
  reviewFieldTextPassword: {
    marginLeft: 10,
    color: '#424242',
    fontSize: 16,
    minWidth: 100,
    fontFamily: 'defaultFont'
  },
  showTCTouchable: {
    borderColor: '#284D64',
    margin: 10,
    borderWidth: 1,
    padding: 10,
  },
  progressText: {
    fontFamily: 'defaultFontItalic',
    textAlign: 'center',
    fontSize: 14,
    color: '#424242'
  },
  progressBarContainer: {
    marginTop: 5,
    marginBottom: 0,
    borderBottomWidth: 2,
    borderBottomColor: '#EEEEEE',
    padding: 10
  },
  nextArrowIcon: {
    color: '#7FCFAB',
    fontSize: 20,
    marginRight: 10
  },
  previousArrowIcon: {
    color: '#808080',
    fontSize: 20,
    marginRight: 10
  },
  previousFieldButtonTitle: {
    fontSize: 16,
    color: '#808080',
    textTransform: 'uppercase',
    fontFamily: 'defaultFontBold'
  },
  previousFieldButton: {
    borderColor: '#EEEEEE',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: 'rgba(238, 238, 238, 0.1)'
  },
  nextFieldButtonTitle: {
    fontSize: 16,
    color: '#7FCFAB',
    textTransform: 'uppercase',
    fontFamily: 'defaultFontBold'
  },
  nextFieldButton: {
    borderColor: '#7FCFAB',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: 'rgba(127, 207, 171, 0.1)',
  },
  mainViewContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  scrollViewContainer: {
    flexGrow: 1,
    // justifyContent: 'center'
    justifyContent: 'flex-start'
  },
  formContainer: {
    margin: 15,
    marginLeft: 5
  },
  signUpButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  disabledSubmitButtonStyle: {
    backgroundColor: 'rgba(127, 207, 171, 0.5)'
  },
  hideItem: {
    display: 'none'
  },
  modalHeader: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  tcModalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont'
  },
  closeModalTouchable: {
    justifyContent: 'flex-end'
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    width: 50,
    textAlign: 'center'
  },
  modalContent: {
    flexGrow: 1,
    borderColor: 'red',
    borderWidth: 4
  },
  inputContainerError: {
    borderBottomColor: '#EF6F6C'
  },
  validateIcon: {
    color: '#7FCFAB',
    fontSize: 18,
    marginTop: 20
  },
  validateIconError: {
    color: '#EF6F6C',
  },
  errorMessage: {
    color: '#EF6F6C',
    fontFamily: 'defaultFont',
    fontSize: 14
  },
  showPasswordTouchable: {
    marginTop: 15
  },
  passwordIcon: {
    width: 40,
    textAlign: 'center',
    fontSize: 20,
    color: 'rgb(9, 146, 165)',
    marginTop: 4
  },
  inputIcon: {
    fontSize: 16,
    width: 25,
    paddingRight: 5,
    textAlign: 'center',
    color: 'rgb(9, 146, 165)'
  },
  labelContainer: {
    borderLeftWidth: 2,
    borderLeftColor: '#f79256',
    paddingLeft: 5,
    marginLeft: 10
  },
  label: {
    color: '#284d64',
    fontWeight: 'normal',
    fontFamily: 'defaultFont',
    fontSize: 16
  },
  input: {
    color: 'rgb(66, 66, 66)',
    fontFamily: 'defaultFont',
    fontSize: 16,
    outlineStyle: 'none'
  },
  inputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE'
  },
  checkboxContainer: {
    borderWidth: 0,
    backgroundColor: 'transparent'
  },
  checkboxText: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontWeight: 'normal',
    fontSize: 16,
    paddingLeft: 15
  }
});

export default SignUpScreen;
