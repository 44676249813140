import React from 'react';
import { View, Image, Text, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { Button } from 'react-native-elements';
import base64Images from '../../assets/images/base64/base64Images';
import { ScrollView } from 'react-native-gesture-handler';

const CreditCardList = ({ creditCards, goRemoveCard, removingCard, goSetFavoriteCard, savingCard, buyCads }) => {

  const creditCardIcons = base64Images.creditCardIcons;

  return (
    <ScrollView>
      {creditCards ? creditCards.map((item, index) => (
        <View style={styles.cardItemContainer} key={item.id}>
          <View style={[styles.cardItem, (item.get('favoriteCard') ? styles.cardItemFavorite : null)]}>
            <TouchableOpacity
              onPress={() => {
                goSetFavoriteCard(item.id, true)
              }}
              disabled={savingCard ? true : false}
              style={styles.touchableCardItem}
            >
              <View style={styles.starIconContainer}>
                <FontAwesome
                  name={item.get('favoriteCard') ? 'star' : 'star-o'}
                  style={[styles.starIcon, (item.get('favoriteCard') ? styles.starIconFavorite : null)]}
                />
              </View>
              {creditCardIcons[item.get('flag')] !== undefined ?
                <Image
                  source={{ uri: creditCardIcons[item.get('flag')] }}
                  style={styles.cardFlag}
                />
                :
                <View style={styles.cardIconContainer}>
                  <FontAwesome
                    name='credit-card'
                    style={styles.cardIcon}
                  />
                </View>
              }
              <View style={styles.cardNumber}>
                <Text style={styles.cardNumberText}>Final {item.get('finalNumbers')}</Text>
              </View>
            </TouchableOpacity>

            <View style={styles.buyButtonContainer}>
              <Button
                buttonStyle={styles.buyCadsButton}
                disabledStyle={styles.disabledButtonStyle}
                onPress={() => buyCads(item)}
                icon={
                  <FontAwesome
                    name='shopping-cart'
                    style={styles.cartIcon}
                  />
                }
              />
            </View>

          </View>

          <View style={styles.trashIconView}>
            <TouchableOpacity
              onPress={() => {
                goRemoveCard(item)
              }}
              style={styles.trashIconContainer}
              disabled={removingCard ? true : false}
            >
              <FontAwesome
                name='trash'
                style={[styles.trashIcon, (removingCard === item.id ? { display: 'none' } : null)]}
              />
              <View style={removingCard !== item.id ? { display: 'none' } : null}>
                <ActivityIndicator size={18} color='#EF6F6C' />
              </View>
            </TouchableOpacity>
          </View>

        </View>
      )) : null}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  buyButtonContainer: {
    flex: 0.4
  },
  cardFlag: {
    height: '100%',
    resizeMode: 'contain',
    flex: 0.7
  },
  cardIconContainer: {
    alignItems: 'center',
    flex: 0.7
  },
  cardIcon: {
    color: '#AAAAAA',
    fontSize: 30
  },
  cartIcon: {
    color: '#FFFFFF',
    fontSize: 20
  },
  cardItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardItem: {
    flex: 6,
    flexDirection: 'row',
    borderColor: '#F3F3F3',
    borderRadius: 0,
    borderWidth: 1,
    marginBottom: 10
  },
  cardItemFavorite: {
    borderColor: '#EFC008'
  },
  touchableCardItem: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  cardNumber: {
    alignSelf: 'center',
    flex: 2
  },
  cardNumberText: {
    fontFamily: 'defaultFontBold',
    fontSize: 14,
    color: '#284D64',
    textAlign: 'left',
    paddingLeft: 10
  },
  trashIconView: {
    borderColor: 'transparent',
    borderWidth: 1,
    marginBottom: 10,
    flex: 1
  },
  trashIconContainer: {
    flex: 1,
    marginLeft: 5,
    borderWidth: 1,
    borderColor: '#EF6F6C',
    justifyContent: 'center',
    borderRadius: 0
  },
  trashIcon: {
    fontSize: 20,
    textAlign: 'center',
    color: '#EF6F6C'
  },
  starIconFavorite: {
    color: '#EFC008'
  },
  starIconContainer: {
    flex: 1.3,
    alignItems: 'center'
  },
  starIcon: {
    fontSize: 20,
    textAlign: 'center',
    color: '#AAAAAA'
  },
  buyCadsButton: {
    backgroundColor: '#7FCFAB',
    minHeight: 50,
    borderRadius: 0
  },
  buyButtonTile: {
    fontFamily: 'defaultFontBold',
    fontSize: 14
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  }
});

export default CreditCardList;
