import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, SafeAreaView, ActivityIndicator, ScrollView } from 'react-native';
import { Button } from 'react-native-elements';
import Parse from 'parse';
import useExpiredSession from '../hooks/useExpiredSession';

const TermsAndConditionsComponent = ({ navigation, loggedIn }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const [termsAndConditions, setTermsAndConditions] = useState('');
  const LOADING_STATE = {
    notLoaded: 0,
    loadedWithError: -1,
    loaded: 1
  };

  const [loadingState, setLoadingState] = useState(LOADING_STATE.notLoaded);
  // const [reloadTrigger, setReloadTrigger] = useState(false);

  const getTerms = async (componentUnmounted) => {
    try {
      setLoadingState(LOADING_STATE.notLoaded);
      const config = await Parse.Config.get();
      
      if (!componentUnmounted) {
        const TC = config.get('termsAndConditionsReact');
        setTermsAndConditions(TC);
        setLoadingState(LOADING_STATE.loaded);
      }
    }
    catch (e) {
      console.log('error getting terms and conditions', e);
      if (!componentUnmounted) {
        setLoadingState(LOADING_STATE.loadedWithError);
      }
      checkIfSessionExpired(e);
    }
  }

  useEffect(() => {
    let componentUnmounted = false;

    if (!loggedIn) {
      getTerms(componentUnmounted);
      return () => {
        componentUnmounted = true;
      };
    }
    else {
      const unsubscribe = navigation.addListener('focus', () => {
        getTerms(componentUnmounted);
      });
      return unsubscribe;
    }
  }, [navigation]);

  return (
    <>
      {loadingState === LOADING_STATE.notLoaded ?
        <View style={styles.loadingView}>
          <ActivityIndicator size={50} color='#284D64' />
          <Text style={styles.loadingText}>Carregando Termos e Condições de Uso...</Text>
        </View>
        : null}

      {loadingState === LOADING_STATE.loaded ?
        <ScrollView style={styles.scrollView}>
          <Text style={styles.TC}>{termsAndConditions}</Text>
        </ScrollView>
        : null}

      {loadingState === LOADING_STATE.loadedWithError ?
        <View style={styles.errorView}>
          <View style={styles.loadingView}>
            <Text style={styles.loadingErrorText}>Ocorreu um erro ao carregar os Termos e Condições de Uso.</Text>
          </View>

          <SafeAreaView>
            <Button
              buttonStyle={styles.errorButton}
              title='Tentar Novamente'
              titleStyle={styles.proceedButtonTitle}
              onPress={() => getTerms()}
            />
          </SafeAreaView>
        </View>
        : null}
    </>
  );
};

const styles = StyleSheet.create({
  TC: {
    fontFamily: 'defaultFont',
    color: '#424242',
    fontSize: 15
  },
  scrollView: {
    margin: 15,
    marginBottom: 20,
  },
  loadingView: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  loadingErrorText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center'
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  proceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  errorView: {
    justifyContent: 'space-between',
    flex: 1
  }
});

export default TermsAndConditionsComponent;
