import React from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import base64Images from '../../assets/images/base64/base64Images';

const CreditCardElement = ({ creditCardSelected, touchable }) => {

  const creditCardIcons = base64Images.creditCardIcons;

  return (
    <View style={styles.elementContainer}>
      {creditCardIcons[creditCardSelected.get('flag')] !== undefined ?
        <View style={styles.cardFlagContainer}>
          <Image
            source={{ uri: creditCardIcons[creditCardSelected.get('flag')] }}
            style={styles.cardFlag}
          />
        </View>
        :
        <View style={styles.cardIconContainer}>
          <FontAwesome
            name='credit-card'
            style={styles.cardIcon}
          />
        </View>
      }
      <View style={styles.cardNumber}>
        <Text style={[styles.cardNumberText, touchable? styles.cardNumberTextTouchable: null]}>
          final <Text style={{ fontFamily: 'defaultFontBold' }}>{creditCardSelected.get('finalNumbers')}</Text>
        </Text>
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  elementContainer: {
    flexDirection: 'row'
  },
  cardFlagContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  cardFlag: {
    height: 50,
    width: 50,
    resizeMode: 'contain'
  },
  cardIconContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    height: 40
  },
  cardIcon: {
    color: '#AAAAAA',
    fontSize: 30,
    paddingRight: 9
  },
  cardNumber: {
    alignSelf: 'center',
    flex: 2
  },
  cardNumberText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#0992A5',
    textAlign: 'left',
    paddingLeft: 10
  },
  cardNumberTextTouchable: {
    color: '#424242'
  }
});

export default CreditCardElement;
