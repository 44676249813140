import React, { useState, useEffect } from 'react';
import { Alert, View, Text, StyleSheet, ActivityIndicator, Modal, TouchableOpacity, Platform, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import NoCreditCard from '../../assets/images/no-credit-card.svg';
import alert from '../hooks/useAlert.js'

import base64Icons from '../../assets/images/base64/base64Icons';
// import { Image } from 'expo-image';


import { Button } from 'react-native-elements';
import CreditCard from '../components/CreditCard';
import { FontAwesome } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import CreditCardList from '../components/CreditCardList';
import SafeEnv from '../components/SafeEnv';
import useCreditCard from '../hooks/useCreditCard';
import useExpiredSession from '../hooks/useExpiredSession';
import OfficialFooter from '../components/OfficialFooter';

const CreditCardsScreen = ({ navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const NOT_LOADED = 0;
  const LOADED_WITH_ERROR = -1;
  const LOADED = 1;

  const [loadingState, setLoadingState] = useState(NOT_LOADED);
  const [creditCards, setCreditCards] = useState(null);
  const [addCardModalVisible, setAddCardModalVisible] = useState(false);
  const [removingCard, setRemovingCard] = useState(false);

  const [creditCard, setCreditCard] = useState({});
  const [performCreditCardFinalCheck, setPerformCreditCardFinalCheck] = useState(0);
  const [allSet, setAllSet] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [savingCard, setSavingCard] = useState(false);

  const [saveCreditCard, getCreditCards, removeCreditCard, getFavoriteCard, setFavoriteCard] = useCreditCard();

  const addNewCard = async () => {
    setPerformCreditCardFinalCheck(performCreditCardFinalCheck + 1);
  };

  const goRemoveCard = (creditCard) => {
    setRemovingCard(creditCard.id);

    async function yesRemove() {
      try {
        await removeCreditCard(creditCard);
        setRemovingCard(null);
        goGetCreditCards();

        alert(
          'Pronto!',
          'Cartão excluído com sucesso!',
          null,
          true
        );

        // Alert.alert(
        //   'Pronto!',
        //   'Cartão excluído com sucesso!',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );

      }
      catch (e) {
        console.log('error removing card', e);
        setRemovingCard(null);

        alert(
          'Oops!',
          'Ocorreu um erro ao remover o cartão de crédito. Por favor, tente novamente.',
          null,
          true
        );

        // Alert.alert(
        //   'Oops!',
        //   'Ocorreu um erro ao remover o cartão de crédito. Por favor, tente novamente.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false }
        // );
        checkIfSessionExpired(e);

      }
    }

    alert(
      'Confirmação',
      `Tem certeza de que deseja remover o cartão de crédito ${creditCard.get('flag') ? creditCard.get('flag') : ''} de final ${creditCard.get('finalNumbers')}?`,
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => setRemovingCard(null)
        },
        {
          text: 'Sim',
          onPress: () => yesRemove()
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   `Tem certeza de que deseja remover o cartão de crédito ${creditCard.get('flag') ? creditCard.get('flag') : ''} de final ${creditCard.get('finalNumbers')}?`,
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => setRemovingCard(null)
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => yesRemove()
    //     }
    //   ],
    //   { cancelable: false }
    // );


  }

  useEffect(() => {
    const saveNewCard = async () => {
      if (allSet) {
        setIsLoading(true);
        try {

          await saveCreditCard(creditCard);
          setAddCardModalVisible(false);
          Alert.alert(
            'Pronto',
            'Cartão de crédito adicionado com sucesso.',
            [
              { text: 'OK' },
            ],
            { cancelable: false },
          );
          setIsLoading(false);
          setAllSet(0);
          setCreditCard({});
          setPerformCreditCardFinalCheck(0);
          goGetCreditCards();
        }
        catch (e) {
          Alert.alert(
            'Oops',
            'Ocorreu um erro ao salvar o cartão de crédito. Por gentileza, tente novamente.',
            [
              { text: 'OK' },
            ],
            { cancelable: false },
          );
          setIsLoading(false);
          setAllSet(0);
          setPerformCreditCardFinalCheck(0);
          checkIfSessionExpired(e);
        }
      }
    };

    saveNewCard();

  }, [allSet]);

  const goSetFavoriteCard = async (cardId, updateCards) => {
    try {
      setSavingCard(true);
      await setFavoriteCard(cardId);
      if (updateCards) {
        for (let i = 0; i < creditCards.length; i++) {
          if (creditCards[i].id === cardId) creditCards[i].set('favoriteCard', true);
          else creditCards[i].set('favoriteCard', false);
        }
      }
      setSavingCard(false);
    } catch (e) {
      setSavingCard(false);
      console.log('error setting favorite card', e);

      alert(
        'Oops!',
        'Ocorreu um erro ao definir o cartão de crédito como favorito. Tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops',
      //   'Ocorreu um erro ao definir o cartão de crédito como favorito. Tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      checkIfSessionExpired(e);
    }
  };

  const goGetCreditCards = async () => {

    setLoadingState(NOT_LOADED);

    try {
      const creditCardList = await getCreditCards();
      const favoriteCardId = await getFavoriteCard();

      let foundFavoriteCard = false;

      for (let i = 0; i < creditCardList.length; i++) {
        if (creditCardList[i].id === favoriteCardId) {
          creditCardList[i].set('favoriteCard', true);
          foundFavoriteCard = true;
          break;
        }
      }

      if (!foundFavoriteCard && creditCardList && creditCardList[0]) {
        await goSetFavoriteCard(creditCardList[0].id, false);
        creditCardList[0].set('favoriteCard', true);
      }

      setLoadingState(LOADED);
      setCreditCards(creditCardList);
    } catch (e) {
      setLoadingState(LOADED_WITH_ERROR);
      checkIfSessionExpired(e);
      console.log('got error', e);
    }
  };

  const buyCads = (creditCard) => {
    // nested navigators navigation with params
    navigation.navigate('PurchaseCreditsStack', {
      screen: 'PurchaseCredits',
      params: { creditCardId: creditCard.id }
    });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      goGetCreditCards();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.cardsContainer}>
        <View style={styles.mainViewContainer}>

          {loadingState === NOT_LOADED ?
            <View style={styles.noCreditCard}>
              <ActivityIndicator size='large' color='#284D64' />
              <Text style={styles.loadingText}>Carregando seus cartões...</Text>
            </View>
            : (loadingState === LOADED_WITH_ERROR ?
              <View style={styles.noCreditCard}>
                {/* <NoCreditCard style={styles.noCardIcon} height={80} width={80} /> */}

                <Image
                  // source={{ uri: base64Icons.noCreditCard }}
                  source={require('../../assets/images/no-credit-card.png')}

                  style={styles.noCardIcon}
                />

                <Text style={styles.noCardText}>Ocorreu um erro ao carregar seus cartões.</Text>
              </View>
              : (creditCards && creditCards.length > 0 ?
                <>
                  <Text style={styles.explanationText}>O cartão de crédito com a estrela dourada será automaticamente carregado quando você for comprar créditos.</Text>
                  <CreditCardList
                    creditCards={creditCards}
                    goRemoveCard={goRemoveCard}
                    removingCard={removingCard}
                    goSetFavoriteCard={goSetFavoriteCard}
                    savingCard={savingCard}
                    buyCads={buyCads}
                  />
                </>
                :
                <View style={styles.noCreditCard}>
                  {/* <NoCreditCard style={styles.noCardIcon} height={80} width={80} /> */}
                  <Image
                    // source={{ uri: base64Icons.noCreditCard }}
                    source={require('../../assets/images/no-credit-card.png')}
                    style={styles.noCardIcon}
                  />

                  <Text style={[styles.noCardText, { textAlign: 'center' }]}>Você não possui cartões de crédito salvos.</Text>
                  <Text style={[styles.noCardText, { textAlign: 'center' }]}>Para salvar um cartão para uso recorrente, primeiro utilize-o para fazer uma compra no aplicativo, deixando selecionada a opção "Salvar Cartão" no momento da compra.</Text>
                </View>
              ))
          }

        </View>
        {loadingState === LOADED ?
          <View></View>
          // <Button
          //   buttonStyle={styles.addCardButton}
          //   title='Cadastrar Novo Cartão'
          //   titleStyle={styles.buttonTitle}
          //   onPress={() => setAddCardModalVisible(true)}
          // />
          : (loadingState === LOADED_WITH_ERROR ?
            <Button
              buttonStyle={styles.errorButton}
              title='Tentar Novamente'
              titleStyle={styles.buttonTitle}
              onPress={() => goGetCreditCards()}
            />
            : null)
        }

        {/* <Modal
          animationType="slide"
          transparent={false}
          visible={addCardModalVisible}
          onRequestClose={() => {
            setAddCardModalVisible(false);
            setCreditCard({});
            setPerformCreditCardFinalCheck(0);
            setAllSet(0);
          }}
        >
          <SafeAreaView>
            <View style={styles.modalHeader}>
              <View style={{ width: 50 }} />
              <Text style={styles.cardModalTitle}>Adicionar Cartão</Text>
              <TouchableOpacity
                onPress={() => {
                  setAddCardModalVisible(false);
                  setCreditCard({});
                  setPerformCreditCardFinalCheck(0);
                  setAllSet(0);
                }}
                style={styles.closeModalTouchable}>
                <FontAwesome
                  name='times'
                  style={styles.closeModalIcon}
                />
              </TouchableOpacity>
            </View>
          </SafeAreaView>

          <KeyboardAwareScrollView
            // innerRef={ref => {
            //   this.scroll = ref ? ref.getScrollResponder() : null;
            // }}
            enableOnAndroid={true}
            enableAutomaticScroll={(Platform.OS === 'ios')}
            keyboardOpeningTime={0}
            extraHeight={250}
            keyboardShouldPersistTaps={'handled'}
            contentContainerStyle={styles.scrollViewContainer}
          >
            <View style={styles.modalContent}>
              <Text style={styles.insertDataText}>Insira os dados do cartão:</Text>

              <SafeEnv />

              <CreditCard
                creditCard={creditCard}
                onCreditCardChange={setCreditCard}
                performFinalCheck={performCreditCardFinalCheck}
                allSet={allSet}
                setAllSet={setAllSet}
              />
            </View>

            <Button
              buttonStyle={styles.proceedButton}
              title='Adicionar'
              loading={isLoading}
              disabled={isLoading}
              disabledStyle={styles.disabledProceedButtonStyle}
              titleStyle={styles.proceedButtonTitle}
              onPress={addNewCard}
            />

          </KeyboardAwareScrollView>
        </Modal> */}
      </View>
      <OfficialFooter />
    </View>
  );
};

const styles = StyleSheet.create({
  cardsContainer: {
    flex: 1
  },
  mainViewContainer: {
    marginLeft: 15,
    marginRight: 15,
    flex: 1
  },
  explanationText: {
    marginTop: 15,
    marginBottom: 20,
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 15
  },
  proceedButton: {
    marginTop: 10,
    backgroundColor: '#7FCFAB'
  },
  disabledProceedButtonStyle: {
    backgroundColor: '#7FCFAB',
    marginTop: 15,
    opacity: 0.5
  },
  proceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  noCardText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16
  },
  addCardButton: {
    backgroundColor: '#7FCFAB'
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  insertDataText: {
    fontFamily: 'defaultFontBold',
    fontSize: 18,
    borderLeftColor: '#F79256',
    borderLeftWidth: 2,
    paddingLeft: 10,
    color: '#284D64',
    marginBottom: 20
  },
  noCreditCardContainer: {
    justifyContent: 'space-between',
    flex: 1
  },
  noCardIcon: {
    alignSelf: 'center',
    height: 80,
    width: 80
  },
  noCreditCard: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  loadingContainer: {
    justifyContent: 'center',
    marginTop: 20
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  cardModalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont'
  },
  closeModalTouchable: {
    justifyContent: 'flex-end'
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    width: 50,
    textAlign: 'center'
  },
  modalContent: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10
  }
});

export default CreditCardsScreen;
