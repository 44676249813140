import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

const SafeEnv = () => {
  return (
    <View style={styles.secureEnv}>
      <FontAwesome
        name='lock'
        style={styles.lockerIcon}
      />
      <Text style={styles.secureEnvText}>Ambiente Seguro</Text>
    </View>
  )
};

const styles = StyleSheet.create({
  secureEnv: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10
  },
  secureEnvText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    fontSize: 12,
    marginLeft: 3,
    marginBottom: 10
  },
  lockerIcon: {
    color: '#F79256',
    fontSize: 14,
    paddingTop: 2
  }
});

export default SafeEnv;
