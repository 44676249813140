import React from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import CreditCardElement from '../components/CreditCardElement';

const CreditCardElementContainer = ({ creditCardSelected, setCreditCardSelected, creditCardList, showExchangeCardList, setShowExchangeCardList }) => {
  return (
    <View>
      {creditCardSelected && creditCardSelected.id && !showExchangeCardList ?
        <View>
          {!showExchangeCardList ?
            <View style={styles.selectedCreditCardContainer}>
              <CreditCardElement
                creditCardSelected={creditCardSelected}
              />
            </View>
            : null}

          <View style={styles.exchangeCard}>
            <TouchableOpacity
              onPress={() => {
                setShowExchangeCardList(true);
              }}
              style={styles.touchableExchangeCard}
            >
              <FontAwesome
                name='exchange'
                style={styles.exchangeCardIcon}
              />
              <Text style={styles.exchangeCardText}>TROCAR CARTÃO</Text>
            </TouchableOpacity>
          </View>
        </View>
        : null}

      {showExchangeCardList ?
        <View>
          {creditCardList ? creditCardList.map((item, index) => (
            <TouchableOpacity
              onPress={() => {
                setCreditCardSelected(item);
                setShowExchangeCardList(false);
              }}
              key={item.id}
              style={styles.touchableCreditCardElementContainer}
            >
              <CreditCardElement
                creditCardSelected={item}
                touchable={true}
              />
            </TouchableOpacity>
          )) : null}
          <TouchableOpacity
            onPress={() => {
              setCreditCardSelected(null);
              setShowExchangeCardList(false);
            }}
            key={0}
            style={styles.touchableCreditCardElementContainer}
          >
            <View style={styles.newCardTouchableContainer}>
              <View style={styles.newCardIconContainer}>
                <FontAwesome
                  name='plus'
                  style={styles.newCardIcon}
                />
              </View>
              <Text style={styles.newCardText}>Novo Cartão</Text>
            </View>
          </TouchableOpacity>
        </View>
        : null}
    </View>
  )
};

const styles = StyleSheet.create({
  touchableCreditCardElementContainer: {
    marginLeft: 10,
    marginRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
  },
  selectedCreditCardContainer: {
    borderWidth: 1,
    borderColor: '#0992A5',
    marginLeft: 10,
    marginRight: 10
  },
  exchangeCard: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 15,
    marginTop: 10,
    marginBottom: 30
  },
  touchableExchangeCard: {
    flexDirection: 'row'
  },
  exchangeCardIcon: {
    color: '#F79256',
    fontSize: 16,
    paddingTop: 3
  },
  exchangeCardText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    fontSize: 16,
    marginLeft: 3,
    textDecorationLine: 'underline'
  },
  newCardTouchableContainer: {
    flexDirection: 'row',
    height: 50
  },
  newCardIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  newCardText: {
    flex: 2,
    textAlign: 'left',
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 16,
    paddingLeft: 10,
    alignSelf: 'center'
  },
  newCardIcon: {
    fontSize: 20,
    color: '#7FCFAB',
    paddingRight: 14
  }
});

export default CreditCardElementContainer;
