import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Font from 'expo-font';
import { NavigationContainer, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AuthReducer, ContextMethods, AuthContext } from './src/context/AuthContext';
import appParams from './constants/AppParams';
// import Parse from 'parse/react-native';
import Parse from 'parse';
Parse.setAsyncStorage(AsyncStorage);
Parse.initialize(appParams.applicationId, '');
Parse.serverURL = appParams.serverURL;

import ResolveAuthScreen from './src/screens/ResolveAuthScreen';
import DefaultLoggedOutScreen from './src/screens/DefaultLoggedOutScreen';
import SignUpScreen from './src/screens/SignUpScreen';
import SignInScreen from './src/screens/SignInScreen';
import DefaultLoggedInScreen from './src/screens/DefaultLoggedInScreen';
import ParkingOptionsScreen from './src/screens/ParkingOptionsScreen';
import ParkingConfirmationScreen from './src/screens/ParkingConfirmationScreen';
import ActiveParkingScreen from './src/screens/ActiveParkingScreen';
import PurchaseCreditsScreen from './src/screens/PurchaseCreditsScreen';
import HistoryScreen from './src/screens/HistoryScreen';
import AccountScreen from './src/screens/AccountScreen';
import VehiclesScreen from './src/screens/VehiclesScreen';
import CreditCardsScreen from './src/screens/CreditCardsScreen';
import NotificationsScreen from './src/screens/NotificationsScreen';
import AboutUsScreen from './src/screens/AboutUsScreen';
import TermsAndConditionsScreen from './src/screens/TermsAndConditionsScreen';
import FAQScreen from './src/screens/FAQ';
import DrawerContent from './src/screens/DrawerContent';

import MenuTrigger from './src/components/MenuTrigger';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
// import PushNotification, { Importance } from 'react-native-push-notification';

// Must be outside of any component LifeCycle (such as `componentDidMount`).
// PushNotification.configure({
//   // (required) Called when a remote is received or opened, or local notification is opened
//   onNotification: function (notification) {
//     // console.log('NOTIFICATION 01:', notification);

//     // process the notification

//     // (required) Called when a remote is received or opened, or local notification is opened
//     notification.finish(PushNotificationIOS.FetchResult.NoData);
//   },

//   // (optional) Called when Registered Action is pressed and invokeApp is false, if true onNotification will be called (Android)
//   // onAction: function (notification) {
//   //   console.log('ACTION:', notification.action);
//   //   // process the action
//   // },

//   // IOS ONLY (optional): default: all - Permissions to register.
//   permissions: {
//     alert: true,
//     badge: true,
//     sound: true,
//   },

//   // Should the initial notification be popped automatically
//   // default: true
//   popInitialNotification: true,

//   /**
//    * (optional) default: true
//    * - Specified if permissions (ios) and token (android and ios) will requested or not,
//    * - if not, you must call PushNotificationsHandler.requestPermissions() later
//    * - if you are not using remote notification or do not have Firebase installed, use this:
//    *     requestPermissions: Platform.OS === 'ios'
//    */

//   // requestPermissions: true,
//   requestPermissions: Platform.OS === 'ios'
// });

// PushNotification.createChannel({
//   channelId: 'default-alarm-channel',
//   channelName: 'Alarmes de Expiração',
//   channelDescription: 'Canal para alarmes de expiração do tempo de estacionamento',
//   playSound: true,
//   soundName: 'default_alarm.mp3',
//   importance: Importance.HIGH,
//   vibrate: true,
//   vibration: 2000
// });

// PushNotification.createChannel({
//   channelId: 'short-alarm-channel',
//   channelName: 'Alarmes de Expiração com Som Curto',
//   channelDescription: 'Canal para alarmes de expiração do tempo de estacionamento, com som curto',
//   playSound: true,
//   soundName: 'short_alarm.mp3',
//   importance: Importance.HIGH,
//   vibrate: true,
//   vibration: 2000
// });

// PushNotification.createChannel({
//   channelId: 'long-alarm-channel',
//   channelName: 'Alarmes de Expiração com Som Longo',
//   channelDescription: 'Canal para alarmes de expiração do tempo de estacionamento, com som longo',
//   playSound: true,
//   soundName: 'long_alarm.mp3',
//   importance: Importance.HIGH,
//   vibrate: true,
//   vibration: 2000
// });

const DefaultTheme = {
  colors: {
    white: '#FFFFFF',
    orange: '#F79256',
    lightBlue: '#0992A5',
    darkBlue: '#284D64',
    blackText: '#424242',
    green: '#7FCFAB',
    red: '#EF6F6C',
    veryLightGray: '#F3F3F3',
    lightGray: '#EEEEEE',
    darkGray: '#AAAAAA',
    veryDarkGray: '#808080'
  }
};

const defaultHeaderOptions = {
  headerStyle: {
    backgroundColor: DefaultTheme.colors.white,
    borderBottomColor: DefaultTheme.colors.lightGray,
    borderBottomWidth: 1,
    shadowOpacity: 0,
    shadowOffset: {
      height: 0,
    },
    shadowRadius: 0,
    elevation: 0
  },
  headerTitleStyle: {
    fontWeight: 'normal',
    fontSize: 20,
    fontFamily: 'defaultFont'
  },
  headerTintColor: DefaultTheme.colors.darkBlue,
  headerTitleAlign: 'center',
  headerLeft: () => (
    <MenuTrigger
    />
  )
};

//giving drawer navigator views a title requires the views to be wrapped in a stack navigator
const ItemStack = ({ screenName, screenComponent, screenTitle }) => {

  const ItemStack = createStackNavigator();

  return (
    <ItemStack.Navigator>
      <ItemStack.Screen
        name={screenName}
        component={screenComponent}
        options={{ ...defaultHeaderOptions, title: screenTitle }}
      />
    </ItemStack.Navigator>

  )
};

const ParkingStack = () => {

  const ParkingStack = createStackNavigator();

  let parkingConfirmationtHeaderOptions = { ...defaultHeaderOptions };
  delete parkingConfirmationtHeaderOptions.headerLeft;

  return (
    <ParkingStack.Navigator>
      <ParkingStack.Screen
        name='ParkingOptions'
        component={ParkingOptionsScreen}
        options={{ ...defaultHeaderOptions, title: 'Estacionar Agora' }}
      />
      <ParkingStack.Screen
        name='ParkingConfirmation'
        component={ParkingConfirmationScreen}
        options={{ ...parkingConfirmationtHeaderOptions, title: 'Confirmação', headerBackTitle: '< Voltar', gestureEnabled: false }}
      />
    </ParkingStack.Navigator>
  )
};

const DrawerNavigator = () => {

  const Drawer = createDrawerNavigator();

  return (
    <Drawer.Navigator
      drawerContent={props => <DrawerContent {...props} initialRouteName='DefaultLoggedIn' />}
    >
      <Drawer.Screen name='DefaultLoggedInStack'
        children={() => <ItemStack screenName='DefaultLoggedIn' screenComponent={DefaultLoggedInScreen} screenTitle='Início' />}
      />
      <Drawer.Screen name='ParkingStack'
        component={ParkingStack}
        options={({ route }) => {
          if (route && getFocusedRouteNameFromRoute(route) === 'ParkingConfirmation') {
            return { gestureEnabled: false };
          }
        }}
      />
      <Drawer.Screen
        name='ActiveParkingStack'
        children={() => <ItemStack screenName='ActiveParking' screenComponent={ActiveParkingScreen} screenTitle='Ativações em Curso' />}
      />
      <Drawer.Screen
        name='PurchaseCreditsStack'
        children={() => <ItemStack screenName='PurchaseCredits' screenComponent={PurchaseCreditsScreen} screenTitle='Comprar Créditos' />}
      />
      <Drawer.Screen name='HistoryStack'
        children={() => <ItemStack screenName='History' screenComponent={HistoryScreen} screenTitle='Histórico de Uso' />}
      />
      <Drawer.Screen name='AccountStack'
        children={() => <ItemStack screenName='Account' screenComponent={AccountScreen} screenTitle='Minha Conta' />}
      />
      <Drawer.Screen name='VehiclesStack'
        children={() => <ItemStack screenName='Vehicles' screenComponent={VehiclesScreen} screenTitle='Meus Veículos' />}
      />
      <Drawer.Screen name='CreditCardsStack'
        children={() => <ItemStack screenName='CreditCards' screenComponent={CreditCardsScreen} screenTitle='Cartões de Crédito' />}
      />
      <Drawer.Screen name='NotificationsStack'
        children={() => <ItemStack screenName='Notifications' screenComponent={NotificationsScreen} screenTitle='Alarmes' />}
      />
      <Drawer.Screen name='AboutUsStack'
        children={() => <ItemStack screenName='AboutUs' screenComponent={AboutUsScreen} screenTitle='Fale Conosco' />}
      />
      <Drawer.Screen name='TermsAndConditionsStack'
        children={() => <ItemStack screenName='TermsAndConditions' screenComponent={TermsAndConditionsScreen} screenTitle='Termos e Condições' />}
      />
      <Drawer.Screen name='FAQStack'
        children={() => <ItemStack screenName='FAQ' screenComponent={FAQScreen} screenTitle='Dúvidas Frequentes' />}
      />
    </Drawer.Navigator>
  );
};

const LoggedOutStack = ({ state }) => {

  const Stack = createStackNavigator();

  let loggedOutDefaultHeaderOptions = { ...defaultHeaderOptions };
  delete loggedOutDefaultHeaderOptions.headerLeft;

  return (
    <Stack.Navigator
      initialRouteName={state.lastUserLoggedInId ? 'SignIn' : 'DefaultLoggedOut'}
      screenOptions={{ gestureEnabled: false }}
    >
      <Stack.Screen
        name='DefaultLoggedOut'
        component={DefaultLoggedOutScreen}
        options={{ ...loggedOutDefaultHeaderOptions, title: 'FAZ Digital Salvador', headerShown: false }}
      />
      <Stack.Screen
        name='SignUp'
        component={SignUpScreen}
        options={{ ...loggedOutDefaultHeaderOptions, title: 'Cadastro', headerBackTitle: '< Voltar' }}
      />
      <Stack.Screen
        name='SignIn'
        component={SignInScreen}
        options={{ ...loggedOutDefaultHeaderOptions, title: 'Entrar', headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const ResolveAuthStack = ({ userId }) => {

  const Stack = createStackNavigator();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='ResolveAuth'
        options={{ ...defaultHeaderOptions, headerShown: false }}
      >
        {props => <ResolveAuthScreen {...props} extraData={{ userId }} />}
      </Stack.Screen>

    </Stack.Navigator >
  );
};

const App = ({ userId }) => {

  const [state, dispatch] = AuthReducer();

  const authContext = ContextMethods(state, dispatch);


  return (
    <NavigationContainer theme={DefaultTheme}>
      <AuthContext.Provider value={authContext}>
     
        {state.checkingUser ? (
          <ResolveAuthStack
            userId={userId}
          />
        ) : (state.user === null ? (
          <LoggedOutStack
            state={state}
          />
        ) : (
          <DrawerNavigator />
        ))}

      </AuthContext.Provider>
    </NavigationContainer>
  );
};

export default () => {

  const [isReady, setIsReady] = useState(false);
  const [userId, setUserId] = useState(null);

  const _cacheSplashResourcesAsync = async () => {
    try {
      await Font.loadAsync({
        'defaultFont': require('./assets/fonts/TitilliumWeb-Regular.ttf'),
        'defaultFontBold': require('./assets/fonts/TitilliumWeb-SemiBold.ttf'),
        'defaultFontItalic': require('./assets/fonts/TitilliumWeb-Italic.ttf'),
        'licensePlateFont': require('./assets/fonts/IBMPlexMono-Regular.ttf'),
        'licensePlateFontBold': require('./assets/fonts/IBMPlexMono-SemiBold.ttf')
      });
      return true;
    }
    catch (e) {
      console.log('error loading fonts', e);
      throw e;
    }
  };

  const _getLastUserLoggedInIdAsync = async () => {
    try {
      const uID = await AsyncStorage.getItem('lastUserLoggedInId');
      setUserId(uID);
      return uID;
    }
    catch (e) {
      console.log('error getting userId', e);
      throw e;
    }
  }

  const _callAllPromises = async () => {
    try {
      await Promise.all([
        _cacheSplashResourcesAsync(),
        _getLastUserLoggedInIdAsync()
      ]);
      setIsReady(true);
    }
    catch (e) {
      console.log('error on callAllPromises', e);
      setIsReady(true);
    }
  };

  useEffect(() => {
    _callAllPromises();
  }, []);


  //DISABLE WARNINGS WHEN TESTING
  // console.disableYellowBox = true;

  if (!isReady) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size='large' color={DefaultTheme.colors.darkBlue} />
      </View>
    )
  }
  else {
    return (
      <App
        userId={userId}
      />
    );
  }

};
