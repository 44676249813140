import { useReducer, createContext } from 'react';

export const AuthReducer = () => {
  return useReducer((state, action) => {
    switch (action.type) {
      case 'SET_CURRENT_USER':
        return {
          ...state,
          user: action.user,
          checkingUser: action.checkingUser,
          lastUserLoggedInId: action.lastUserLoggedInId
        }
    }
  },
    {
      user: null,
      checkingUser: true,
      lastUserLoggedInId: null
    }
  )
};

export const ContextMethods = (state, dispatch) => {
  return {
    state,
    setCurrentUser: (user, checkingUser, lastUserLoggedInId) => {
      dispatch({ type: 'SET_CURRENT_USER', user, checkingUser, lastUserLoggedInId });
    }
  }
};

export const AuthContext = createContext();



