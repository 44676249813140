import AsyncStorage from '@react-native-async-storage/async-storage';
import Parse from 'parse';

export default () => {
  const getVehicles = async () => {
    try {
      const vehicles = await Parse.Cloud.run("getUserVehicles");
      return vehicles;
    } catch (e) {
      throw e;
    }
  };

  const getFavoriteVehicle = async () => {
    try {
      const vehicleId = await AsyncStorage.getItem('favoriteVehicleId');
      return vehicleId;
    } catch (e) {
      throw e;
    }
  };

  const setFavoriteVehicle = async (vehicleId) => {
    try {
      const currentFavoriteVehicleId = await getFavoriteVehicle();
      if (currentFavoriteVehicleId === vehicleId) return;
      await AsyncStorage.setItem('favoriteVehicleId', vehicleId);
    } catch (e) {
      throw e;
    }
  };

  return [getVehicles, getFavoriteVehicle, setFavoriteVehicle];

};
