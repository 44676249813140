import cpfCnpjValidation from '../lib/validateCpfCnpj';

export default () => {

  const [isValidCpfCnpj, cpfCnpjMask] = cpfCnpjValidation();

  const phoneMask = (value) => {
    if (value) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
      value = value.replace(/(\d{5})(\d)/, "$1-$2");
      value = value.substring(0, 15);
    }
    return value;
  }

  const setFieldWithMask = (field, value, setUser, user) => {
    switch (field) {
      case 'phone':
        value = phoneMask(value);
        setUser({ ...user, phone: value });
        break;
      case 'id':
        if (value) {
          value = cpfCnpjMask(value);
        }
        setUser({ ...user, id: value });
        break;
    }
  };

  const checkAddressField = (value, field, errorMessages, finalCheck, userAddress) => {

    const errorMessagesSufix = {
      zipCode: 'um CEP válido',
      street: 'o logradouro',
      streetNumber: 'o número do logradouro',
      neighborhood: 'o bairro',
      city: 'a cidade',
      state: 'a sigla do estado com 2 letras'
    };

    const check = (v, f) => {
      console.log('checking form', v,f);

      if (!v || v.trim().length === 0 ||
        (f === 'zipCode' && v.replace(/\D/g, "").length !== 8) ||
        (f === 'state' && v.replace(/[^a-zA-Z]/g, "").length !== 2)) {
          console.log('error on check');
        errorMessages[f] = `Informe ${errorMessagesSufix[f]}.`;
      }
      else {
        console.log('NO error on check');

        errorMessages[f] = null;
      }
    };

    if (finalCheck) {
      for (const fd in errorMessagesSufix) {
        const vl = userAddress[fd];
        check(vl, fd);
      }
    }

    else {
      check(value, field);
    }

  }

  const validField = (field, value, forceBlur, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred) => {
    let pattern = null;

    switch (field) {
      case 'licensePlate':
        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, licensePlate: true });
        }
        if (value && value.length >= 4) {
          setErrorMessages({ ...errorMessages, licensePlate: null });
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, licensePlate: 'Insira ao menos 4 caracteres para placa internacional, ou 7 para placa nacional.' });
          return false;
        }
      case 'name':
        pattern = new RegExp(/^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,.'-]+\s+[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,.'-\s]+$/);

        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, name: true });
        }
        if (pattern.test(value)) {
          setErrorMessages({ ...errorMessages, name: null });
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, name: 'Insira ao menos dois nomes.' });
          return false;
        }
      case 'email':
        pattern = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, email: true });
        }
        if (pattern.test(value)) {
          setErrorMessages({ ...errorMessages, email: null });
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, email: 'Insira um e-mail válido.' });
          return false;
        }
      case 'phone':
        setFieldWithMask(field, value, setUser, user);

        if (value) {
          value = value.replace(/\D/g, "");
          value = value.substring(0, 11);
        }
        pattern = new RegExp(/^[0-9]{2}[9]{1}[0-9]{8}$/);

        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, phone: true });
        }
        if (pattern.test(value)) {
          setErrorMessages({ ...errorMessages, phone: null });
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, phone: 'Insira um celular com DDD válido.' });
          return false;
        }
      case 'id':
        setFieldWithMask(field, value, setUser, user);

        if (value) {
          value = value.replace(/\D/g, "");
          value = value.substring(0, 14);
        }

        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, id: true });
        }
        if (isValidCpfCnpj(value)) {
          setErrorMessages({ ...errorMessages, id: null });
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, id: 'Insira um CPF ou CNPJ válido.' });
          return false;
        }
      case 'password':
        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, password: true });
        }
        if (value && value.length >= 6) {
          if (value === user.confirmPassword && errorMessages.confirmPassword) {
            setErrorMessages({ ...errorMessages, password: null, confirmPassword: null });
          }
          else {
            setErrorMessages({ ...errorMessages, password: null });
          }
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, password: 'Insira uma senha de ao menos 6 caracteres.' });
          return false;
        }
      case 'confirmPassword':
        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, confirmPassword: true });
        }
        if (value && value.length >= 6 && value === user.password) {
          setErrorMessages({ ...errorMessages, confirmPassword: null });
          return true;
        }
        else {
          let errorMsg = '';
          if (!value || value.length < 6) errorMsg = 'Insira uma senha de ao menos 6 caracteres.';
          else errorMsg = 'A senha e a confirmação devem ser iguais. Se for o caso, volte e confira a senha escolhida.';
          setErrorMessages({ ...errorMessages, confirmPassword: errorMsg });
          return false;
        }
      case 'newPassword':
        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, newPassword: true });
        }
        if (value && value.length >= 6) {
          if (value === user.newPasswordConfirmation) {
            setErrorMessages({ ...errorMessages, newPassword: null, newPasswordConfirmation: null });
          }
          else if (user.newPasswordConfirmation && user.newPasswordConfirmation.length >= 6 && value !== user.newPasswordConfirmation) {
            setErrorMessages({ ...errorMessages, newPassword: null, newPasswordConfirmation: 'A nova senha e a confirmação dela devem ser iguais.' });
          }
          else {
            setErrorMessages({ ...errorMessages, newPassword: null });
          }
          return true;
        }
        else {
          setErrorMessages({ ...errorMessages, newPassword: 'Insira uma nova senha de ao menos 6 caracteres.' });
          return false;
        }
      case 'newPasswordConfirmation':
        if (forceBlur) {
          setGotBlurred({ ...gotBlurred, newPasswordConfirmation: true });
        }
        if (value && value.length >= 6 && value === user.newPassword) {
          setErrorMessages({ ...errorMessages, newPasswordConfirmation: null });
          return true;
        }
        else {
          let errorMsg = '';
          if (!value || value.length < 6) errorMsg = 'Insira uma confirmação de nova senha de ao menos 6 caracteres.';
          else errorMsg = 'A nova senha e a confirmação dela devem ser iguais.';
          setErrorMessages({ ...errorMessages, newPasswordConfirmation: errorMsg });
          return false;
        }
    }
  };

  const maskZipCode = (zipCode) => {
    zipCode = zipCode.replace(/\D/g, "");
    zipCode = zipCode.substring(0, 8);
    zipCode = zipCode.replace(/^(\d{5})(\d)/, "$1-$2");

    return zipCode;
  };

  return [phoneMask, validField, checkAddressField, maskZipCode];

};