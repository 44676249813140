import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Animated, TouchableOpacity, Alert, ActivityIndicator, Platform } from 'react-native';
import { FontAwesome, FontAwesome5, Entypo } from '@expo/vector-icons';
import { Button } from 'react-native-elements';
import useAlarms from '../hooks/useAlarms';
import Parse from 'parse';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import useBalanceAndPriceApi from '../hooks/useBalanceAndPriceApi';
import useExpiredSession from '../hooks/useExpiredSession';
import useErrorHandler from '../hooks/useErrorHandler';
import { Dialog } from 'react-native-simple-dialogs';

const ParkedVehicle = ({ navigation, activationData, activations, setActivations }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const [beautifilExpireTime, setBeautifilExpireTime] = useState(null);
  const [beautifilElapsedTime, setbeautifilElapsedTime] = useState(null);
  const [greenBarFlex, setGreenBarFlex] = useState(0);
  const [previousGreenBarFlex, setPreviousGreenBarFlex] = useState(0);
  const animVal = new Animated.Value(previousGreenBarFlex);
  const interpolateGreenBar = animVal.interpolate({ inputRange: [0, 1], outputRange: [1, 0] });
  const [getBalance] = useBalanceAndPriceApi();
  const [isGoingToExtend, setIsGoingToExtend] = useState(false);
  const [handleError] = useErrorHandler();
  const [stoppingActivation, setStoppingActivation] = useState(false);
  const [notYetActive, setNotYetActive] = useState(false);
  const [showExtensionButton, setShowExtensionButton] = useState(false);
  const [showCancelationButton, setShowCancelationButton] = useState(true);
  const [showExtensionOptionsDialog, setShowExtensionOptionsDialog] = useState(false);
  const [possibleParkingRules, setPossibleParkingRules] = useState([]);

  const CATEGORY_NAME = ['Automóvel', 'Motocicleta', 'Carga e Descarga', 'Misto (Automóvel e Carga)'];

  const NOTIFICATIONS_STATE_ON = 1;
  const NOTIFICATIONS_STATE_OFF = 0;

  const CAR = 1;
  const MOTORCYCLE = 2;
  const TRUCK = 3;

  const [notificationsState, setNotificatiosnState] = useState(NOTIFICATIONS_STATE_ON);

  const [, , , getNotificationsStatusForLicensePlate, changeNotificationsStatusForLicensePlate] = useAlarms();

  const animatedTransition = Animated.spring(animVal, {
    toValue: greenBarFlex,
    useNativeDriver: false,
    tension: 1
  });

  const formatLicensePlate = (licensePlate) => {

    licensePlate = licensePlate.replace(/[^0-9a-z]/gi, '');

    let spacer = "";

    if (/^\d+$/.test(licensePlate[3])) {
      spacer = " ";
      if (/^\d+$/.test(licensePlate[4])) {
        spacer = "-"
      }
    }

    licensePlate = licensePlate.substring(0, 3) + spacer + licensePlate.substring(3, 7);
    return licensePlate;

  };

  const beautifulPrice = (price) => {
    if (!price) return 0;

    price = price.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  const evalCadMinutes = (idZone) => {
    switch (idZone) {
      case 'A':
        return '30';
      case 'B':
        return '60';
      case 'C':
        return '120';
      case 'D':
        return '300'
    }
  };

  const printReceipt = () => {

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Pdf Content</title>
          <style>
              body {
                  font-size: ${Platform.OS === 'ios' ? '6px' : '10px'};
                  color: #000000;
                  border: 1px solid #000000;
                  padding: 10px;
                  margin: 20px;
              }
              h1 {
                  text-align: center;
                  font-size: ${Platform.OS === 'ios' ? '8px' : '14px'};
              }
              h2 {
                text-align: center;
                font-size: ${Platform.OS === 'ios' ? '7px' : '12px'};
              }
          </style>
      </head>
      <body>
          <h1>ESTACIONAMENTO ROTATIVO FORTALEZA</h1>
          <h1>CARTÃO AZUL DIGITAL</h1>
          <img src='https://fazdigitalfortaleza.com/static/media/logoZonaAzul.png' width="${Platform.OS === 'ios' ? '100' : '150'}" style="display: block; margin-left: auto; margin-right: auto;" />
          <h1>COMPROVANTE DE PAGAMENTO</h1>
          <p>PDV nº ${Parse.User.current().get('posCode')}</p>
          <p>AUTENTICAÇÃO nº ${activationData.idCET}</p>
          <p>DATA: ${activationData.dateDay} - HORÁRIO: ${activationData.startLocal}</p>
          <p>PLACA: ${formatLicensePlate(activationData.license)}</p>
          <p>VALOR: R$ ${beautifulPrice(activationData.totalPrice)}</p>
          <p>FORMA DE PAGAMENTO: Espécie, direto ao PDV</p>
          <p>CAD(s): ${activationData.cads} de ${evalCadMinutes(activationData.idZone)} minutos ${activationData.cads > 1 ? 'cada' : ''}</p>
          
          ${activationData.extendedExpireDate && activationData.cads === 1 ?
        `
              <p>--------------------------------------------------------</p>
              <p>TRANSAÇÃO DE EXTENSÃO:</p>
              <p>AUTENTICAÇÃO nº ${activationData.firstTransactionIdCet}</p>
              <p>DATA: ${activationData.firstExpireDay} - HORÁRIO: ${activationData.firstExpireLocal}</p>
              <p>VALOR: R$ ${beautifulPrice(activationData.totalPrice)}</p>
              <p>FORMA DE PAGAMENTO: Espécie, direto ao PDV</p>
              <p>CAD(s): ${activationData.cads} de ${evalCadMinutes(activationData.idZone)} minutos ${activationData.cads > 1 ? 'cada' : ''}</p>
            `
        : ''}

          <h2>DISTRIBUIDOR</h2>
          <p>Inova Soluções em Tecnologia e Gestão Ltda., CNPJ 22.368.937/0001-57</p>
          <p>Rua Coronel Melo de Oliveira, 1.121 - Perdizes, São Paulo - SP, 05011-040</p>
          
          <h2>LEGISLAÇÃO</h2>
          <p>Estacionamento Regulamentado pelos Decretos nº XYZ de DIA/MÊS/ANO</p>
          
          <h2>INSTRUÇOES PARA UTILIZAÇÃO DO CARTÃO
          AZUL DIGITAL</h2>
          <p>O usuário deve sempre observar a placa de
          sinalização para verificar o horário de
          funcionamento e o tempo de validade do
          Cartão. Como regra geral 1 Cartão é válido para
          estacionar o veículo por 1 hora, no entanto, em
          alguns locais o tempo de validade do Cartão é
          diferenciado. É permitido usar no máximo 2
          Cartões na mesma vaga, sendo obrigatória a
          retirada do veículo ao término do período.
          Para os casos em que o horário do
          comprovante for anterior ao horário que
          consta da placa de sinalização, prevalecerá a
          informação constante da sinalização. Este
          comprovante de pagamento não precisa ser
          deixado no painel do veículo.</p>
          
          <h2>DÚVIDAS, RECLAMAÇÕES E SUGESTÕES:</h2>
          <p>Telefone: 156</p>
          <p>Website: http://www.fortaleza.ce.gov.br</p>
      </body>
      </html>
    `;

    const createPDF = async (html) => {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } catch (err) {
        console.error(err);
        checkIfSessionExpired(e);
      }
    };

    createPDF(htmlContent);

  };

  const changeNotificationsState = async () => {
    const doChangeNotificationsState = async () => {
      if (notificationsState === NOTIFICATIONS_STATE_OFF) {
        await changeNotificationsStatusForLicensePlate(
          formatLicensePlate(activationData.license),
          'on',
          activationData.extendedExpireDate ? activationData.extendedExpireDate : activationData.expireDate
        );
        setNotificatiosnState(NOTIFICATIONS_STATE_ON);
      }
      else {
        await changeNotificationsStatusForLicensePlate(formatLicensePlate(activationData.license), 'off');
        setNotificatiosnState(NOTIFICATIONS_STATE_OFF);
      }
    };

    Alert.alert(
      'Confirmação',
      `Os alarmes de aviso de fim do tempo para a placa ${formatLicensePlate(activationData.license)} serão ${notificationsState === NOTIFICATIONS_STATE_ON ? 'desativados' : 'ativados'} para esta ativação. Tem certeza que deseja continuar?`,
      [
        {
          text: 'Não',
          style: 'cancel'
        },
        {
          text: 'Sim',
          onPress: () => doChangeNotificationsState()
        }
      ],
      { cancelable: true }
    );
  };

  useEffect(() => {
    animatedTransition.start(() => {
      //animation ended
      setPreviousGreenBarFlex(greenBarFlex);
      updateParkedVehicleState();
    });
  }, [greenBarFlex]);


  const updateParkedVehicleState = () => {
    const timeOnStart = evalTransactionTime('start');
    const timeOnExpire = evalTransactionTime('expire');
    const totalTransactionTime = evalTransactionTime('total');

    const checkIfTimeHasChanged = () => {
      const dateRightNow = new Date().getTime();

      //check if cancelation button shout be available
      //only avalaible in the first 15 minutes of activation and activation can not be extension
      const TOLERANCE_TIME_TO_CANCEL = 15 * 60000; //15 minutes
      const maxTimestampToCancel = activationData.startDate + TOLERANCE_TIME_TO_CANCEL;

      if (maxTimestampToCancel > dateRightNow && !activationData.extendedExpireDate) {
        if (!showCancelationButton) {
          setShowCancelationButton(true);
        }
      }
      else {
        if (showCancelationButton) {
          setShowCancelationButton(false);
        }
      }

      //do not show extend button if
      // a) transaction hasn't started or
      // b) if transaction was extended (has extensionDate) and extension transaction hasn't started (extensionDate is the expireDate from extended tr)
      // c) there is less than a minute left to transaction expire

      //check if extension button should NOT be available
      const doNotShowExtButton = ((activationData.startDate > dateRightNow) ||
        (activationData.extensionDate && activationData.extensionDate >= dateRightNow) ||
        (activationData.expireDate - 60000 <= dateRightNow));

      if (doNotShowExtButton) {
        if (showExtensionButton) {
          setShowExtensionButton(false);
        }
      }
      else {
        if (!showExtensionButton) {
          setShowExtensionButton(true);
        }
      }

      //check if scheduled to the future
      if (activationData.startDate > dateRightNow) {
        if (!notYetActive) {
          setNotYetActive(true);
        }
      }
      else {
        if (notYetActive) {
          setNotYetActive(false);
        }
      }

      const timeNow = Math.floor(new Date().getTime() / 1000);
      const secondsToExpire = timeOnExpire - timeNow;
      const secondsElapsed = timeOnExpire - timeOnStart - secondsToExpire;

      if (secondsToExpire < 0) {
        const newActivationsArray = activations.filter(function (activation) { return activation.objectId !== activationData.objectId });
        setActivations(newActivationsArray);
        return;
      }

      setBeautifilExpireTime(lastExpireTime => {
        const newExpireTime = setBeautifulTimeRemaining(secondsToExpire);
        if (lastExpireTime === newExpireTime) {
          setTimeout(() => {
            checkIfTimeHasChanged();
          }, 500);
          setbeautifilElapsedTime(setBeautifulTimeRemaining(secondsElapsed));
          return newExpireTime;
        }
        else {
          setGreenBarFlex(lastGreenBarFlex => {
            return (timeNow - timeOnStart) / totalTransactionTime;
          });
          setbeautifilElapsedTime(setBeautifulTimeRemaining(secondsElapsed));
          return newExpireTime;
        }
      });
    };
    checkIfTimeHasChanged();
  }

  const evalTransactionTime = (type) => {
    const timeOnStart = Math.floor(activationData.startDate / 1000);
    const timeOnExpire = activationData.extendedExpireDate ?
      Math.floor(activationData.extendedExpireDate / 1000) :
      Math.floor(activationData.expireDate / 1000);

    const totalTransactionTime = timeOnExpire - timeOnStart;

    switch (type) {
      case 'start':
        return timeOnStart;
      case 'expire':
        return timeOnExpire
      case 'total':
        return totalTransactionTime;
    }
  };

  const checkNotificationsState = async () => {
    try {
      const state = await getNotificationsStatusForLicensePlate(formatLicensePlate(activationData.license));
      if (state === 'off') {
        setNotificatiosnState(NOTIFICATIONS_STATE_OFF);
      }
    }
    catch (e) {
      console.log('error getting state', e);
      checkIfSessionExpired(e);
    }
  };

  useEffect(() => {
    console.log('\n\nactivationData', activationData);

    if (activationData && activationData.startDate && (activationData.expireDate || activationData.extendedExpireDate)) {
      updateParkedVehicleState();
      checkNotificationsState();
    }
  }, []);

  const stopActivation = (activationId, licensePlate) => {

    const doStopActivation = async () => {
      setStoppingActivation(true);
      console.log('user decided to stop', activationId, licensePlate);
      try {
        const result = await Parse.Cloud.run('cancelActivation', { transactionId: activationId });
        console.log('got this result', result);

        await changeNotificationsStatusForLicensePlate(formatLicensePlate(licensePlate), 'off');

        showWarning('Sua ativação foi cancelada e seu crédito foi devolvido.', 'Pronto!');
        setStoppingActivation(false);

        //remove activation from current active transactions screen
        const newActivationsArray = activations.filter(function (activation) { return activation.objectId !== activationId });
        setActivations(newActivationsArray);
      }
      catch (e) {
        console.log('error stoping activation', e);
        const errorMessage = handleError(e);
        showWarning(errorMessage);
        setStoppingActivation(false);
      }
    };

    Alert.alert(
      'Atenção',
      'Ao prosseguir, as informações da ativação não serão mais apresentadas nesta tela, e seu crédito será devolvido.\n\nTem certeza de que deseja encerrar a ativação?',
      [
        {
          text: 'Não',
          style: 'cancel'
        },
        {
          text: 'Sim',
          onPress: () => doStopActivation()
        }
      ],
      { cancelable: true }
    );
  };

  //this function will transform seconds in H M S
  const setBeautifulTimeRemaining = (secondsToExpire) => {
    let hours = Math.floor(secondsToExpire / 3600);
    let minutes = Math.floor((secondsToExpire % 3600) / 60);
    let seconds = secondsToExpire - (hours * 3600) - (minutes * 60);

    hours = hours.toString();
    minutes = minutes.toString();
    seconds = seconds.toString();

    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    if (hours > 0) {
      // return hours + 'h ' + minutes + 'min ' + seconds + 's';
      return hours + 'h ' + minutes + 'min ';
    }
    else {
      if (minutes > 0) {
        // return minutes + 'min ' + seconds + 's';
        return minutes + ' min';
      }
      else {
        return seconds + ' s';
      }
    }
  };

  const showWarning = (msg, title) => {
    Alert.alert(
      title ? title : 'Oops!',
      msg,
      [
        { text: 'OK' },
      ],
      { cancelable: false }
    );
  };

  const parkThisVehicle = () => {
    const vehicleToPark = {
      license: activationData.license,
      description: activationData.vehicleDescription,
      category: activationData.category
    }

    navigation.navigate('ParkingStack', {
      screen: 'ParkingOptions',
      params: { vehicleId: vehicleToPark, useVehicleAttributes: true }
    });
  }

  const doExtendActivation = (extensionRule) => {
    console.log('\n\nentered doExtendActivation', extensionRule, activationData);
    setIsGoingToExtend(false);

    if (activationData.address) {
      activationData.address.text = activationData.address.displayName
    }

    setShowExtensionOptionsDialog(false);
    
    navigation.navigate('ParkingStack', {
      screen: 'ParkingConfirmation',
      params: {
        GEOCOORDINATES: { latitude: activationData.geolocation.latitude, longitude: activationData.geolocation.longitude },
        VEHICLE_ID: activationData.vehicle.id,
        VEHICLE_CATEGORY: activationData.category,
        VEHICLE_CATEGORY_NAME: CATEGORY_NAME[activationData.category - 1],
         PARKING_CARD_TIME: { price: extensionRule.price, maxMinutes: extensionRule.maxMinutes, ruleId: extensionRule.ruleId },
        VEHICLE_PLATE: formatLicensePlate(activationData.license),
        VEHICLE_DESCRIPTION: activationData.vehicleDescription,
        ADDRESS: { locationName: activationData.locationName, locationIdTransalvador: activationData.locationIdTransalvador },
        EXTENSION: true,
        CUSTOM_START_DATE: activationData.expireDate,
        SCHEDULED: false
      }
    });

  };

  const extendActivation = async () => {
    try {
      setIsGoingToExtend(true);

      const params = { locationIdTransalvador: activationData.locationIdTransalvador }
      console.log('\n\nparams', params);
      const result = await Parse.Cloud.run('getNearestLocationsParkingRulesValidToday', params);
      if (result && result[0] && result[0].parkingRules && result[0].parkingRules.length > 0) {
        for (let i = 0; i < result[0].parkingRules.length; i++) {
          console.log('\n\nparkingRules', i, result[0].parkingRules[i]);
        }
        setPossibleParkingRules(result[0].parkingRules);
        setShowExtensionOptionsDialog(true);
      }
      else {
        showWarning('Não há opções para renovação por mais um período nessa área de Zona Azul no momento.');
        setIsGoingToExtend(false);
      }
      console.log('got LOCATION results', result);
    }
    catch (e) {
      console.log('error on getNearestLocationsParkingRulesValidToday', e);
      showWarning('Ocorreu um erro ao verificar as opções para renovação.\n\nPor favor, tente novamente.');
      setIsGoingToExtend(false);
    }
  };

  return (

    <View>
      {beautifilExpireTime !== '00s' ? //if there's no time remaining, don't show anything
        <View style={styles.container}>
          <View style={styles.firstPart}>
            <View style={{ justifyContent: 'center' }}>
              {activationData.vehicleDescription ?
                <Text style={[styles.carInfo, styles.regularFont]}>{activationData.vehicleDescription}</Text>
                : null}
              <Text style={[styles.carLicense, styles.boldFont]}>{formatLicensePlate(activationData.license)}</Text>
            </View>
            {notYetActive ?
              <View>
                <Text style={[styles.expireInfo, styles.regularFont]}>Créditos iniciam às</Text>
                <Text style={[styles.expireTime, styles.boldFont]}>{activationData.startLocal}</Text>
              </View>
              :
              <View>
                <Text style={[styles.expireInfo, styles.regularFont]}>Créditos expiram em</Text>
                <Text style={[styles.expireTime, styles.boldFont]}>{beautifilExpireTime}</Text>
              </View>
            }


            <TouchableOpacity
              onPress={() => {
                changeNotificationsState();
              }}
              style={[styles.alarmContainer, notificationsState === NOTIFICATIONS_STATE_OFF ? { borderColor: '#AAAAAA' } : null]}
            >
              <FontAwesome
                name={notificationsState === NOTIFICATIONS_STATE_OFF ? 'bell-slash' : 'bell'}
                style={[styles.alarmIcon, notificationsState === NOTIFICATIONS_STATE_OFF ? { color: '#AAAAAA' } : null]}
              />
            </TouchableOpacity>

          </View>

          <View style={{ flexDirection: 'row', marginLeft: 20, marginRight: 20, justifyContent: 'flex-end' }}>

            <View style={{
              backgroundColor: 'black', position: 'absolute', width: '100%', backgroundColor: '#EEEEEE',
              top: 15, height: 5
            }}>
            </View>

            <View style={{ width: 25 }}>
              <FontAwesome5
                name={activationData.category === CAR ? 'car-side' : activationData.category === MOTORCYCLE ? 'motorcycle' : 'truck'}
                style={styles.carIcon}
              />
            </View>
            <Animated.View style={{
              flex: interpolateGreenBar,
              backgroundColor: '#7FCFAB',
              height: 5,
              marginTop: 15,
              alignItems: 'flex-end'
            }}>
            </Animated.View>
          </View>

          <View style={styles.thirdPart}>
            <Text style={[styles.parkingHours, styles.regularFont]}>{activationData.startLocal}</Text>

            {notYetActive ?
              <Text style={[styles.parkingInfo, styles.regularFont]}>Agendada para {activationData.dateDay}</Text>
              :
              <Text style={[styles.parkingInfo, styles.regularFont]}>Tempo transcorrido: {beautifilElapsedTime}</Text>
            }

            <Text style={[styles.parkingHours, styles.regularFont]}>{activationData.expireLocal}</Text>
          </View>

          <View style={styles.fourthPart}>
            <Entypo name="location-pin" style={styles.locationPin} />
            <View>
              <Text style={[styles.parkingInfo, styles.regularFont, { textTransform: 'capitalize' }]}>{activationData.locationName}</Text>
            </View>
          </View>

          <View style={styles.fifthPart}>
            {showExtensionButton ?
              <Button
                buttonStyle={styles.addTimeButton}
                titleStyle={styles.addTimeButtonTitle}
                title={isGoingToExtend ?
                  <ActivityIndicator size={25} color='#F79256' />
                  : 'Renovar por\n + 1 período'
                }
                icon={
                  <FontAwesome5
                    name='clock'
                    style={styles.addTimeButtonIcon}
                  />
                }
                disabled={isGoingToExtend}
                disabledTitleStyle={{ color: '#F79256' }}
                disabledStyle={styles.disabledProceedButtonStyle}

                onPress={() => extendActivation()}
              />
              :
              <Button
                buttonStyle={[styles.addTimeButton, { borderColor: '#7FCFAB' }]}
                titleStyle={[styles.addTimeButtonTitle, { color: '#7FCFAB' }]}
                title={`Ativar \nnovo crédito`}
                // title={isGoingToExtend ?
                //   <ActivityIndicator size={25} color='#F79256' />
                //   : 'Ativar \nnovo crédito'
                // }
                icon={
                  <FontAwesome5
                    name='plus'
                    style={[styles.addTimeButtonIcon, { color: '#7FCFAB' }]}
                  />
                }
                // disabled={isGoingToExtend}
                // disabledTitleStyle={{ color: '#F79256' }}
                // disabledStyle={styles.disabledProceedButtonStyle}
                onPress={() => parkThisVehicle()}
              />
            }

            {showCancelationButton ?
              <Button
                buttonStyle={styles.endTimeButton}
                titleStyle={styles.endTimeButtonTitle}
                title={stoppingActivation ?
                  <ActivityIndicator size={25} color='#EF6F6C' />
                  : 'Cancelar'
                }
                icon={
                  <FontAwesome
                    name='close'
                    style={styles.endTimeButtonIcon}
                  />
                }
                disabled={stoppingActivation}
                disabledTitleStyle={{ color: '#EF6F6C' }}
                disabledStyle={styles.disabledProceedButtonStyle}
                onPress={() => stopActivation(activationData.objectId, activationData.license)}
              />
              : null}

          </View>
        </View >
        : null}
      <Dialog
        visible={showExtensionOptionsDialog}
        title='Renovar por mais um período'
        titleStyle={styles.defaultTextBold}
      >
        <View>
          <Text style={styles.defaultText}>Escolha por quanto tempo <Text style={styles.defaultTextBold}>a mais</Text> deseja renovar:</Text>

          {possibleParkingRules.map((item, index) => (
            <Button
              key={item.ruleId}
              title={`${item.ruleName}: +${item.maxMinutes / 60} horas`}
              onPress={() => {
                doExtendActivation(item);
              }}
              buttonStyle={styles.proceedButton}
              titleStyle={styles.extensionOptionButtonTile}
            />
          ))}

          <Button
            title='Cancelar'
            onPress={() => {
              setIsGoingToExtend(false);
              setShowExtensionOptionsDialog(false);
            }}
            buttonStyle={styles.cancelButton}
            titleStyle={styles.extensionOptionButtonTile}
          />
        </View>
      </Dialog>
    </View>

  );
};

const styles = StyleSheet.create({
  cancelButton: {
    backgroundColor: '#EF6F6C',
    marginTop: 10
  },
  proceedButton: {
    backgroundColor: '#7FCFAB',
    marginTop: 10
  },
  extensionOptionButtonTile: {
    fontFamily: 'defaultFontBold',
    textTransform: 'capitalize'
  },
  defaultTextBold: {
    fontFamily: 'defaultFontBold',
    fontSize: 17,
    color: '#424242'
  },
  defaultText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  disabledProceedButtonStyle: {
    backgroundColor: 'transparent'
  },
  movingCar: {
    alignItems: 'flex-start'
  },
  carLines: {
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 30
  },
  carLineGray: {
    marginTop: 2,
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    height: 1
  },
  carLineGreen: {
    backgroundColor: '#7FCFAB',
    borderRadius: 5,
    height: 5
  },
  fourthPart: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 7,
    marginLeft: 25,
    marginRight: 25
  },
  locationPin: {
    color: '#0992A5',
    fontSize: 16,
    textAlign: 'center',
    paddingTop: 2,
    alignSelf: 'center'
  },
  fifthPart: {
    minHeight: 50,
    alignItems: 'center',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  addTimeButton: {
    margin: 0,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderColor: '#F79256',
    borderWidth: 1,
    flex: 1,
    minHeight: 50
  },
  addTimeButtonTitle: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    fontSize: 14,
    marginLeft: 10
  },
  addTimeButtonIcon: {
    color: '#F79256',
    fontSize: 18
  },
  endTimeButton: {
    margin: 0,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderColor: '#EF6F6C',
    borderWidth: 1,
    flex: 1,
    minHeight: 50
  },
  endTimeButtonTitle: {
    color: '#EF6F6C',
    fontFamily: 'defaultFont',
    fontSize: 14,
    marginLeft: 10
  },
  endTimeButtonIcon: {
    color: '#EF6F6C',
    fontSize: 18
  },
  addMoreTime: {
    color: '#7FCFAB',
    fontSize: 20,
    textAlign: 'center'
  },
  parkingHours: {
    color: '#284D64',
    fontSize: 14,
    textAlign: 'center'
  },
  parkingInfo: {
    color: '#0992A5',
    fontSize: 14,
    textAlign: 'center'
  },
  carIcon: {
    color: '#0992A5',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: -3
  },
  alarmIcon: {
    color: '#EF6F6C',
    fontSize: 20,
    textAlign: 'center'
  },
  alarmContainer: {
    borderWidth: 1,
    borderColor: '#EF6F6C',
    padding: 5,
    marginTop: 5,
    marginBottom: 5
  },
  carInfo: {
    color: '#0992A5',
    textTransform: 'uppercase',
    fontSize: 13,
    textAlign: 'center'
  },
  carLicense: {
    color: '#0992A5',
    textTransform: 'uppercase',
    fontSize: 18,
    textAlign: 'center'
  },
  expireInfo: {
    color: '#284D64',
    fontSize: 14,
    textAlign: 'center'
  },
  expireTime: {
    color: '#F79256',
    fontSize: 17,
    textAlign: 'center'
  },
  regularFont: {
    fontFamily: 'defaultFont',
  },
  boldFont: {
    fontFamily: 'defaultFontBold'
  },
  container: {
    borderColor: '#EEEEEE',
    backgroundColor: '#FDFDFD',
    borderWidth: 1,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    paddingTop: 10,
    paddingBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  },
  firstPart: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 10
  },
  thirdPart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10
  }
});

export default ParkedVehicle;
