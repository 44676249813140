import Parse from 'parse';
import { Alert } from 'react-native';
import alert from './useAlert.js'

export default () => {

  const REGULAR_ACTIVATION = 1;

  const getLocalTime = async (dateOne, dateTwo, firstExpireLocal) => {

    let tzOffset = 0;

    try {
      let config = Parse.Config.current();

      if (!config || !config.get) {
        config = await Parse.Config.get();
      }

      tzOffset = config.get('currentLocalTimeOffset');
    }
    catch (e) {
      throw e;
    }

    const getLocalHours = (timestamp, dateDay, type) => {
      const date = new Date(timestamp);
      const hoursBehindGMT = date.getTimezoneOffset() / 60;

      //set hours of date to be in desired time zone
      //date.getHours() + 2*hoursBehindGMT equals hours in GMT
      //+tzOffset give us the desired time 
      date.setUTCHours(date.getHours() + 2 * hoursBehindGMT + tzOffset);

      if (dateDay) {
        let day = date.getDate();
        if (day < 10) day = '0' + day;

        let month = date.getMonth();
        month += 1;

        const fullYear = date.getFullYear();

        return day + '/' + month + '/' + fullYear;
      }
      else {
        let hours = date.getHours();
        if (type === 'h') return hours;

        if (hours < 10) hours = '0' + hours;

        let minutes = date.getMinutes();
        if (type === 'm') return minutes;

        if (minutes < 10) minutes = '0' + minutes;

        return hours + 'h' + minutes;
      }

    };

    const result = {
      dateOne: dateOne ? getLocalHours(dateOne) : null,
      dateTwo: dateTwo ? getLocalHours(dateTwo) : null,
      dateDay: dateOne ? getLocalHours(dateOne, true) : null,
      firstExpireLocal: firstExpireLocal ? getLocalHours(firstExpireLocal, false) : null,
      firstExpireDay: firstExpireLocal ? getLocalHours(firstExpireLocal, true) : null,
      dateOneHours: getLocalHours(dateOne, null, 'h'),
      dateOneMinutes: getLocalHours(dateOne, null, 'm')
    };

    return result;

  };

  const isHoliday = async (date) => {
    try {
      const Holiday = Parse.Object.extend('Holiday');

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const query = new Parse.Query(Holiday);
      query.equalTo('day', day);
      query.equalTo('month', month);
      query.equalTo('year', year);

      const result = await query.find();

      if (result && result.length > 0) return true;
      else return false;
    }
    catch (e) {
      throw e;
    }
  };

  const getSpecialTimeActivationPermission = async (parkingLocation) => {

    let tzOffset = 0;

    try {
      let config = Parse.Config.current();

      if (!config) {
        config = await Parse.Config.get();
      }

      tzOffset = config.get('currentLocalTimeOffset');

      const PARKING_MESSAGES = {
        lateNightParking: 'Entre 00h e 07h a Zona Azul não opera.\nCaso você prossiga e conclua a transação, a ativação será considerada somente a partir do próximo horário de início regulamentado para o local.',
        sundaysAndHolidaysParking: 'Apenas alguns locais na cidade têm funcionamento de Zona Azul aos domingos e feriados.\nObserve a sinalização do local para verificar se a ativação está sendo realizada dentro do horário de funcionamento.\n\nDeseja continuar?',
        postBusinessHoursParking_MonToFri: 'Apenas alguns locais na cidade têm funcionamento de Zona Azul após às 19h.\nObserve a sinalização do local para verificar se a ativação está sendo realizada dentro do horário de funcionamento.\n\nDeseja continuar?',
        postBusinessHoursParking_Sat: 'Apenas alguns locais na cidade têm funcionamento de Zona Azul aos sábados após às 14h.\nObserve a sinalização do local para verificar se a ativação está sendo realizada dentro do horário de funcionamento.\n\nDeseja continuar?'
      };

      const SPECIFIC_PARKING_MESSAGES = {
        sundaysAndHolidaysParking: 'De acordo com nossos registros, esta área de Zona Azul não funciona nesse horário aos domingos e feriados.',
        postBusinessHoursParking_MonToFri: 'De acordo com nossos registros, esta área de Zona Azul não funciona nesse horário neste dia útil.',
        postBusinessHoursParking_Sat: 'De acordo com nossos registros, esta área de Zona Azul não funciona nesse horário aos sábados.',

        NoSundaysAndHolidaysParking: 'De acordo com nossos registros, esta área de Zona Azul não funciona aos domingos e feriados.\n\nTem certeza que deseja continuar?',
        NoPostBusinessHoursParking_MonToFri: 'De acordo com nossos registros, esta área de Zona Azul não funciona neste dia útil.\n\nTem certeza que deseja continuar?',
        NoPostBusinessHoursParking_Sat: 'De acordo com nossos registros, esta área de Zona Azul não funciona aos sábados.\n\nTem certeza que deseja continuar?'
      };

      const showMessage = async (msg, msg2) => {
        return new Promise((resolve, reject) => {

          alert(
            'Atenção',
            `${msg}${msg2 ? msg2 : ''}`,
            [
              {
                text: 'Não',
                style: 'cancel',
                onPress: () => resolve(0) 
              },
              {
                text: 'Sim',
                onPress: () => resolve(1)
              }
            ],
          );

          // Alert.alert(
          //   'Atenção',
          //   `${msg}${msg2 ? msg2 : ''}`,
          //   [
          //     { text: 'Não', onPress: () => resolve(0) },
          //     { text: 'Sim', onPress: () => resolve(1) }
          //   ],
          //   { cancelable: false }
          // )
        });
      };

      const evaluateActiveNow = (beginHour, endHour, nowHour, nowMinutes) => {
        const beginHourArray = beginHour.split(':');
        const intBeginHour = parseInt(beginHourArray[0]);
        const intBeginMinutes = parseInt(beginHourArray[1]);

        const endHourArray = endHour.split(':');
        const intEndHour = parseInt(endHourArray[0]);
        const intEndMinutes = parseInt(endHourArray[1]);

        if (nowHour === 0) nowHour = 24;

        if (nowHour < intBeginHour) return false;
        else {
          if (nowHour === intBeginHour && nowMinutes < intBeginMinutes) return false;
          else {
            if (nowHour > intEndHour) return false;
            else if (nowHour === intEndHour && nowMinutes > intEndMinutes) return false;
            else return true;
          }
        }

      };

      const date = new Date();
      const hoursBehindGMT = date.getTimezoneOffset() / 60;

      //set hours of date to be in desired time zone
      //date.getHours() + 2*hoursBehindGMT equals hours in GMT
      //+tzOffset give us the desired time 
      date.setUTCHours(date.getHours() + 2 * hoursBehindGMT + tzOffset);

      //work if correct datetime after timezone correction:
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const day = date.getDay();

      //check if time between 12 pm and 7 am - late night activation
      if (hours <= 6) {

        //parkingLocation was selected using GPS
        if (parkingLocation && parkingLocation.name) {
          let notSkippedDay = day;

          const checkForSkipDays = () => {
            let totalSkippedDays = 0;

            //check for skip days
            if (parkingLocation.skipDays && parkingLocation.skipDays.length > 0) {
              while (parkingLocation.skipDays.includes(notSkippedDay)) {
                notSkippedDay++;
                totalSkippedDays++;
                if (notSkippedDay === 7) notSkippedDay = 0;
              }
            }

            if (totalSkippedDays > 0) {
              date.setHours(date.getHours() + 24 * totalSkippedDays);
            }
          };

          const setOperationalBeginTime = (beginHour) => {
            const beginHourArray = beginHour.split(':');
            const intBeginHour = parseInt(beginHourArray[0]);
            const intBeginMinutes = parseInt(beginHourArray[1]);
            return date.setHours(intBeginHour, intBeginMinutes, 0, 0);
          };

          const evalNextOperationalDay = async () => {
            checkForSkipDays();

            let holiday = await isHoliday(date);

            //check if sunday or holiday
            if (notSkippedDay === 0 || holiday) {
              if (parkingLocation.sundaysBegin && parkingLocation.sundaysEnd) {
                return setOperationalBeginTime(parkingLocation.sundaysBegin);
              }
              else {
                notSkippedDay++;
                if (notSkippedDay === 7) notSkippedDay = 0;
                date.setHours(date.getHours() + 24);
                return evalNextOperationalDay();
              }
            }

            else if (notSkippedDay === 6) {
              if (parkingLocation.saturdaysBegin && parkingLocation.saturdaysEnd) {
                return setOperationalBeginTime(parkingLocation.saturdaysBegin);
              }
              else {
                notSkippedDay = 0;
                date.setHours(date.getHours() + 24);
                return evalNextOperationalDay();
              }
            }

            //business day
            else {
              if (parkingLocation.workDaysBegin && parkingLocation.workDaysEnd) {
                return setOperationalBeginTime(parkingLocation.workDaysBegin);
              }
              else {
                notSkippedDay++;
                date.setHours(date.getHours() + 24);
                return evalNextOperationalDay();
              }
            }
          }

          const iniDate = await evalNextOperationalDay();

          const localIniDate = await getLocalTime(iniDate);

          const result = await showMessage(PARKING_MESSAGES.lateNightParking, `\n\nInício em ${localIniDate.dateDay} às ${localIniDate.dateOne}\n\nDeseja continuar?`);
          if (result) return iniDate; //return timestamp of X am/pm when scheduling, X being the next operational time for the selected place
          else return result;

        }

        //manually selected parking location
        else {
          const result = await showMessage(PARKING_MESSAGES.lateNightParking, '\n\nDeseja continuar?');
          if (result) return date.setHours(7, 0, 0, 0); //return timestamp of 7 am when scheduling
          else return result;
        }
      }

      else if (parkingLocation && parkingLocation.name) { //parkingLocation was selected using GPS
        const holiday = await isHoliday(date);

        //check skip days
        if (parkingLocation.skipDays && parkingLocation.skipDays.length > 0) {
          if (parkingLocation.skipDays.includes(day)) {
            if (day === 0 || holiday) return await showMessage(SPECIFIC_PARKING_MESSAGES.NoSundaysAndHolidaysParking);
            else if (day === 6) return await showMessage(SPECIFIC_PARKING_MESSAGES.NoPostBusinessHoursParking_Sat);
            else return await showMessage(SPECIFIC_PARKING_MESSAGES.NoPostBusinessHoursParking_MonToFri);
          }
        }

        //check if sunday or holiday
        if (day === 0 || holiday) {
          if (parkingLocation.sundaysBegin && parkingLocation.sundaysEnd) {
            const activeNow = evaluateActiveNow(parkingLocation.sundaysBegin, parkingLocation.sundaysEnd, hours, minutes);
            if (activeNow) return REGULAR_ACTIVATION;
            else return await showMessage(SPECIFIC_PARKING_MESSAGES.sundaysAndHolidaysParking, `\n\nOperação de ${parkingLocation.sundaysBegin} até ${parkingLocation.sundaysEnd}.\n\nDeseja continuar?`);
          }
          else {
            return await showMessage(SPECIFIC_PARKING_MESSAGES.NoSundaysAndHolidaysParking);
          }
        }
        //else, check if saturday
        else if (day === 6) {
          if (parkingLocation.saturdaysBegin && parkingLocation.saturdaysEnd) {
            const activeNow = evaluateActiveNow(parkingLocation.saturdaysBegin, parkingLocation.saturdaysEnd, hours, minutes);
            if (activeNow) return REGULAR_ACTIVATION;
            else return await showMessage(SPECIFIC_PARKING_MESSAGES.postBusinessHoursParking_Sat, `\n\nOperação de ${parkingLocation.saturdaysBegin} até ${parkingLocation.saturdaysEnd}.\n\nDeseja continuar?`);
          }
          else {
            return await showMessage(SPECIFIC_PARKING_MESSAGES.NoPostBusinessHoursParking_Sat);
          }
        }
        //business day
        else {
          if (parkingLocation.workDaysBegin && parkingLocation.workDaysEnd) {
            const activeNow = evaluateActiveNow(parkingLocation.workDaysBegin, parkingLocation.workDaysEnd, hours, minutes);
            if (activeNow) return REGULAR_ACTIVATION;
            else return await showMessage(SPECIFIC_PARKING_MESSAGES.postBusinessHoursParking_MonToFri, `\n\nOperação de ${parkingLocation.workDaysBegin} até ${parkingLocation.workDaysEnd}.\n\nDeseja continuar?`);
          }
          else {
            return await showMessage(SPECIFIC_PARKING_MESSAGES.NoPostBusinessHoursParking_MonToFri);
          }
        }
      }

      else { //manually selected parking location
        const holiday = await isHoliday(date);

        //check if sunday or holiday
        if (day === 0 || holiday) {
          return await showMessage(PARKING_MESSAGES.sundaysAndHolidaysParking);
        }
        //else, check if saturday after 2 pm
        else if (day === 6 && hours >= 14) {
          return await showMessage(PARKING_MESSAGES.postBusinessHoursParking_Sat);
        }
        //else, check if business day after 7 pm
        else if (hours >= 19) {
          return await showMessage(PARKING_MESSAGES.postBusinessHoursParking_MonToFri);
        }

        return REGULAR_ACTIVATION;
      }

    }
    catch (e) {
      throw e;
    }
  };

  return [getLocalTime, getSpecialTimeActivationPermission];

};