import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView, ScrollView, Platform } from 'react-native';
import { Switch, RadioButton } from 'react-native-paper';
import { useTheme } from '@react-navigation/native';
import useAlarms from '../hooks/useAlarms';
import useExpiredSession from '../hooks/useExpiredSession';
import appParams from '../../constants/AppParams';
import OfficialFooter from '../components/OfficialFooter';

const NotificationsScreen = ({ navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const { colors } = useTheme();
  const [setAlarmPreferences, getAlarmPreferences] = useAlarms();

  const [alarmTimeSwitchOn, setAlarmTimeSwitchOn] = useState(null);
  const [alarmSoundLengthChecked, setAlarmSoundLengthChecked] = useState(null);

  const setAlarmTimeSwitchOnPreference = async (preference) => {
    setAlarmTimeSwitchOn(preference);
    setAlarmPreferences('expireTimes', preference);
  };

  const setAlarmSoundLengthCheckedPreference = async (preference) => {
    setAlarmSoundLengthChecked(preference);
    setAlarmPreferences('expireSoundLength', preference);
  };

  const getInitialData = async () => {
    let alarmTimeSwitchOnPreference = null;
    let alarmSoundLengthPreference = null;
    try {
      alarmTimeSwitchOnPreference = await getAlarmPreferences('expireTimes');
      setAlarmTimeSwitchOn({ ...alarmTimeSwitchOnPreference });

      alarmSoundLengthPreference = await getAlarmPreferences('expireSoundLength');
      setAlarmSoundLengthChecked(alarmSoundLengthPreference);
    }
    catch (e) {
      checkIfSessionExpired(e);
      console.log('error getting alarm preferences. this should NEVER happen.', e);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      getInitialData();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{flex: 1}}>

      {alarmTimeSwitchOn && alarmSoundLengthChecked ?
        <ScrollView style={{ margin: 15 }}>

          <View style={styles.explanationTextContainer(colors)}>
            <Text style={styles.explanationText(colors)}>As alterações realizadas aqui valerão apenas para novas ativações.</Text>

            {Platform.OS !== 'ios' ?
              <View>
                <Text></Text>
                <Text style={styles.explanationText(colors)}><Text style={{ fontFamily: 'defaultFontBold' }}>Atenção:</Text> Os alarmes podem não funcionar como esperado:</Text>
                <Text style={styles.explanationText(colors)}>1) Se nos ajustes do seu celular para este App (Configurações - Aplicativos - {appParams.appName}), na seção <Text style={{ fontFamily: 'defaultFontBold' }}>"Bateria"</Text>, a opção selecionada for <Text style={{ fontFamily: 'defaultFontBold' }}>diferente de "Não restrito"</Text></Text>
                <Text style={styles.explanationText(colors)}>2) Se seu celular estiver no <Text style={{ fontFamily: 'defaultFontBold' }}>modo "Economia de energia"</Text></Text>
              </View>
              : null}
          </View>

          <View style={styles.fieldContainer}>
            <View style={styles.fieldTitleContainer(colors)}>
              <Text style={styles.fieldTitle(colors)}>Quando ativar o alarme?</Text>
            </View>
            <View style={styles.optionContainer}>
              <Switch value={alarmTimeSwitchOn.onExpireTime} onValueChange={() => setAlarmTimeSwitchOnPreference({ ...alarmTimeSwitchOn, onExpireTime: !alarmTimeSwitchOn.onExpireTime })} color={colors.lightBlue} />
              <Text style={styles.simpleText(colors)}><Text style={{ fontFamily: 'defaultFontBold' }}>Na hora</Text> em que o crédito expirar</Text>
            </View>
            <View style={styles.optionContainer}>
              <Switch value={alarmTimeSwitchOn.fiveMinBeforeExpireTime} onValueChange={() => setAlarmTimeSwitchOnPreference({ ...alarmTimeSwitchOn, fiveMinBeforeExpireTime: !alarmTimeSwitchOn.fiveMinBeforeExpireTime })} color={colors.lightBlue} />
              <Text style={styles.simpleText(colors)}><Text style={{ fontFamily: 'defaultFontBold' }}>5 min</Text> antes de expirar</Text>
            </View>
            <View style={styles.optionContainer}>
              <Switch value={alarmTimeSwitchOn.tenMinBeforeExpireTime} onValueChange={() => setAlarmTimeSwitchOnPreference({ ...alarmTimeSwitchOn, tenMinBeforeExpireTime: !alarmTimeSwitchOn.tenMinBeforeExpireTime })} color={colors.lightBlue} />
              <Text style={styles.simpleText(colors)}><Text style={{ fontFamily: 'defaultFontBold' }}>10 min</Text> antes de expirar</Text>
            </View>
            <View style={styles.optionContainer}>
              <Switch value={alarmTimeSwitchOn.thirtyMinBeforeExpireTime} onValueChange={() => setAlarmTimeSwitchOnPreference({ ...alarmTimeSwitchOn, thirtyMinBeforeExpireTime: !alarmTimeSwitchOn.thirtyMinBeforeExpireTime })} color={colors.lightBlue} />
              <Text style={styles.simpleText(colors)}><Text style={{ fontFamily: 'defaultFontBold' }}>30 min</Text> antes de expirar</Text>
            </View>
          </View>

          <View style={[styles.fieldContainer, { marginTop: 30 }]}>
            <View style={styles.fieldTitleContainer(colors)}>
              <Text style={styles.fieldTitle(colors)}>Qual a duração do som do alarme?</Text>
            </View>
            <View style={styles.optionContainer}>
              <RadioButton.Android
                value='short'
                status={alarmSoundLengthChecked === 'short' ? 'checked' : 'unchecked'}
                onPress={() => setAlarmSoundLengthCheckedPreference('short')}
                color={colors.lightBlue}
              />
              <Text style={styles.simpleText(colors)}>Curta</Text>
            </View>
            <View style={styles.optionContainer}>
              <RadioButton.Android
                value='default'
                status={alarmSoundLengthChecked === 'default' ? 'checked' : 'unchecked'}
                onPress={() => setAlarmSoundLengthCheckedPreference('default')}
                color={colors.lightBlue}
              />
              <Text style={styles.simpleText(colors)}>Média (padrão)</Text>
            </View>
            <View style={styles.optionContainer}>
              <RadioButton.Android
                value='long'
                status={alarmSoundLengthChecked === 'long' ? 'checked' : 'unchecked'}
                onPress={() => setAlarmSoundLengthCheckedPreference('long')}
                color={colors.lightBlue}
              />
              <Text style={styles.simpleText(colors)}>Longa</Text>
            </View>
          </View>

        </ScrollView>
        : null}
      <OfficialFooter />
    </SafeAreaView>

  )
};

const styles = StyleSheet.create({
  optionContainer: {
    flexDirection: 'row',
    marginTop: 15,
    alignItems: 'center'
  },
  explanationTextContainer: colors => ({
    backgroundColor: colors.lightGray,
    padding: 10,
    borderRadius: 5
  }),
  explanationText: colors => ({
    color: colors.darkBlue,
    fontFamily: 'defaultFont',
    fontSize: 15
  }),
  simpleText: colors => ({
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: colors.blackText,
    marginLeft: 10
  }),
  fieldContainer: {
    marginTop: 20,
    marginRight: 15
  },
  fieldTitleContainer: colors => ({
    borderLeftWidth: 2,
    borderLeftColor: colors.orange,
    marginBottom: 5
  }),
  fieldTitle: colors => ({
    color: colors.darkBlue,
    paddingLeft: 8,
    fontSize: 17,
    fontFamily: 'defaultFontBold'
  })
});

export default NotificationsScreen;
