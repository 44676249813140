import React from 'react';
import { ScrollView, View, Text, StyleSheet, Image, Linking, SafeAreaView } from 'react-native';
import appParams from '../../constants/AppParams';
import base64Images from '../../assets/images/base64/base64Images';
import OfficialFooter from '../components/OfficialFooter';

const AboutUsScreen = () => {
  return (
    <View style={{flex: 1}}>
      <ScrollView style={{ margin: 15 }}>
        <View>
          <Image
            source={{ uri: base64Images.appLogo }}
            style={styles.appLogo}
          />
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Central de Atendimento ao Usuário:</Text>
          </View>
          <Text style={styles.simpleText}>Fale com a gente pelo e-mail <Text onPress={() => { Linking.openURL(`mailto:${appParams.contactMail}`); }} style={{ color: '#0992A5' }}>{appParams.contactMail}</Text> ou pelo telefone <Text onPress={() => { Linking.openURL(`tel:${appParams.contactPhoneToCall}`); }} style={{ color: '#0992A5' }}>{appParams.contactPhone}</Text></Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Sítio Eletrônico</Text>
          </View>
          <Text style={styles.simpleText}>Você encontra mais informações sobre o aplicativo, bem como orientações para a utilização e sobre o funcionamento do estacionamento rotativo, no endereço:</Text>
          <Text style={styles.simpleText}><Text onPress={() => { Linking.openURL(appParams.webSite); }} style={{ color: '#0992A5' }}>{appParams.webSiteSimplified}</Text></Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Portal Web</Text>
          </View>
          <Text style={styles.simpleText}>Você também pode acessar a sua conta pelo Portal Web no endereço:</Text>
          <Text style={styles.simpleText}><Text onPress={() => { Linking.openURL(appParams.webPortal); }} style={{ color: '#0992A5' }}>{appParams.webPortalSimplified}</Text></Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>FAZ Digital Salvador</Text>
          </View>
          <Text style={styles.simpleText}>Versão {appParams.appVersion}, lançada em {appParams.appReleaseDate}</Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Desenvolvido por {appParams.developerName}</Text>
          </View>
          <Text style={styles.simpleText}><Text onPress={() => { Linking.openURL(appParams.developerWebsite); }} style={{ color: '#0992A5' }}>{appParams.developerWebsiteSimplified}</Text></Text>
        </View>

      </ScrollView>
      <OfficialFooter />
    </View>
  );
};

const styles = StyleSheet.create({
  appLogo: {
    height: 75,
    width: 188,
    alignSelf: 'center'
  },
  simpleText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  fieldContainer: {
    marginTop:10,
    marginRight: 15
  },
  fieldTitleContainer: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginBottom: 5
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 17,
    fontFamily: 'defaultFontBold'
  }
});

export default AboutUsScreen;
