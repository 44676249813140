export default () => {

  function calc_digitos_posicoes(digitos, posicoes, soma_digitos) {

    if (!posicoes) posicoes = 10;
    if (!soma_digitos) soma_digitos = 0;

    // Garante que o valor é uma string
    digitos = digitos.toString();

    // Faz a soma dos dígitos com a posição
    // Ex. para 10 posições:
    //   0    2    5    4    6    2    8    8   4
    // x10   x9   x8   x7   x6   x5   x4   x3  x2
    //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
    for (var i = 0; i < digitos.length; i++) {
      // Preenche a soma com o dígito vezes a posição
      soma_digitos = soma_digitos + (digitos[i] * posicoes);

      // Subtrai 1 da posição
      posicoes--;

      // Parte específica para CNPJ
      // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
      if (posicoes < 2) {
        // Retorno a posição para 9
        posicoes = 9;
      }
    }

    // Captura o resto da divisão entre soma_digitos dividido por 11
    // Ex.: 196 % 11 = 9
    soma_digitos = soma_digitos % 11;

    // Verifica se soma_digitos é menor que 2
    if (soma_digitos < 2) {
      // soma_digitos agora será zero
      soma_digitos = 0;
    } else {
      // Se for maior que 2, o resultado é 11 menos soma_digitos
      // Ex.: 11 - 9 = 2
      // Nosso dígito procurado é 2
      soma_digitos = 11 - soma_digitos;
    }

    // Concatena mais um dígito aos primeiro nove dígitos
    // Ex.: 025462884 + 2 = 0254628842
    var cpfcnpj = digitos + soma_digitos;

    // Retorna
    return cpfcnpj;

  } // calc_digitos_posicoes

  /*
   Valida CPF
   
   Valida se for CPF
   
   @param  string cpf O CPF com ou sem pontos e traço
   @return bool True para CPF correto - False para CPF incorreto
  */
  function isValidCpf(valor) {

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');

    if (valor.length != 11) return false;

    // Captura os 9 primeiros dígitos do CPF
    // Ex.: 02546288423 = 025462884
    var digitos = valor.substr(0, 9);

    // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
    var novo_cpf = calc_digitos_posicoes(digitos);

    // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
    var novo_cpf = calc_digitos_posicoes(novo_cpf, 11);

    // Verifica se o novo CPF gerado é idêntico ao CPF enviado
    if (novo_cpf === valor) {
      // CPF válido
      return true;
    } else {
      // CPF inválido
      return false;
    }

  } // valida_cpf

  /*
   valida_cnpj
   
   Valida se for um CNPJ
   
   @param string cnpj
   @return bool true para CNPJ correto
  */
  function isValidCnpj(valor) {

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');

    if (valor.length != 14) return false;

    // O valor original
    var cnpj_original = valor;

    // Captura os primeiros 12 números do CNPJ
    var primeiros_numeros_cnpj = valor.substr(0, 12);

    // Faz o primeiro cálculo
    var primeiro_calculo = calc_digitos_posicoes(primeiros_numeros_cnpj, 5);

    // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
    var segundo_calculo = calc_digitos_posicoes(primeiro_calculo, 6);

    // Concatena o segundo dígito ao CNPJ
    var cnpj = segundo_calculo;

    // Verifica se o CNPJ gerado é idêntico ao enviado
    if (cnpj === cnpj_original) {
      return true;
    }

    // Retorna falso por padrão
    return false;

  } // valida_cnpj

  const isValidCpfCnpj = (id) => {

    if (!id) return false;
    
    const digitis = id.split('');
    const unique = new Set(digitis);

    if (unique.size === 1) return false;
    else if (id.length == 14) return isValidCnpj(id);
    else return isValidCpf(id);

  };

  const cpfCnpjMask = (value, fullNumberAlreadyTyped) => {
    if ((!fullNumberAlreadyTyped && value.length <= 14) || (fullNumberAlreadyTyped && value.length === 11)) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{3})(\d)/, "$1.$2");
      value = value.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    }
    else {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/, "$1.$2");
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
      value = value.substring(0, 18);
    }
    return value;
  };

  return [isValidCpfCnpj, cpfCnpjMask];
};
