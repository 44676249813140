import { findNodeHandle } from 'react-native';

export default () => {

  const _scrollToInput = (reactNode) => {
    if(this && this.scroll && this.scroll.props && this.scroll.props.scrollToFocusedInput) {
      this.scroll.props.scrollToFocusedInput(reactNode);
    }
  };

  const gotFocus = (event) => {
    if(event) _scrollToInput(findNodeHandle(event.target))
  };

  return [gotFocus];

};