import React, { useState } from 'react';
import { View, ScrollView, Text, StyleSheet, Modal, TouchableOpacity, FlatList, Alert, SafeAreaView } from 'react-native';
import alert from '../hooks/useAlert.js'
import { Button, Input } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import Parse from "parse";
import constants from '../../constants/AppParams';
import useGetVehicles from '../hooks/useGetVehicles';
import useExpiredSession from '../hooks/useExpiredSession';
import LoadingScreen from '../components/LoadingScreen';

const NewVehicle = ({ addVehicleModalVisible, setAddVehicleModalVisible, getVehicles, setVehicleToPark, fromState, setUserVehicles, vehicleToPark, navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPlateModel, setSelectedPlateModel] = useState(null);
  const [licensePlate, setLicensePlate] = useState('');
  const [vehicleDescription, setVehicleDescription] = useState('');
  const [keyboardType, setKeyboardType] = useState('default');
  const [step, setStep] = useState(0);
  const [concludedStep, setConcludedStep] = useState([]);
  const [lastPlateModel, setLastPlateModel] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [savingVehicle, setSavingVehicle] = useState(false);
  const [showConfirmLicensePlateBox, setShowConfirmLicensePlateBox] = useState(false);

  const vehicleCategories = [
    { name: 'Automóvel', icon: 'car', category: 1 },
    { name: 'Motocicleta', icon: 'motorcycle', category: 2 },
    { name: 'Outros', icon: 'truck', category: 3 },
    // { name: 'Misto - Automóvel e Carga', icon: 'road', category: 4 }
  ];

  const BRAZIL = 'Brasileiro';
  const MERCOSUL = 'Mercosul';
  const INTERNATIONAL = 'Internacional';


  const plateModels = [
    { name: BRAZIL, examplePattern: 'ABC-1234' },
    { name: MERCOSUL, examplePattern: 'ABC 1A34' },
    { name: INTERNATIONAL, examplePattern: 'A1B2C3D4' },
  ];

  const MAX_VEHICLE_DESCRIPTION_LENGTH = constants.maxVehicleDescriptionLength;

  const [getUserVehicles] = useGetVehicles();

  const setLicensePlateWithMask = (licensePlate) => {
    if (!licensePlate || licensePlate.length === 0) {
      setLicensePlate('');
    }
    else {
      let formattedLicensePlate = null;
      licensePlate = licensePlate.replace(/[^0-9a-z]/gi, '');

      if (selectedPlateModel === INTERNATIONAL) {
        licensePlate = licensePlate.substring(0, 10);
        setLicensePlate(licensePlate.toUpperCase());
        if (licensePlate.length >= 4) setButtonDisabled(false);
        else setButtonDisabled(true);
      }
      else {
        licensePlate = licensePlate.substring(0, 7);

        const licensePlatePartOne = licensePlate.substring(0, 3).replace(/[^a-z]/gi, '');
        let licensePlatePartTwo = "";

        if (licensePlatePartOne.length < 3) {
          formattedLicensePlate = licensePlatePartOne;
        }
        else {
          if (selectedPlateModel === BRAZIL) {
            licensePlatePartTwo = licensePlate.substring(3, 7).replace(/[^0-9]/gi, '');
          }
          else {
            licensePlatePartTwo = licensePlate.substring(3, 4).replace(/[^0-9]/gi, '');
            licensePlatePartTwo += licensePlate.substring(4, 5).replace(/[^a-z]/gi, '');
            licensePlatePartTwo += licensePlate.substring(5, 7).replace(/[^0-9]/gi, '');
          }
          let spacer = "";
          if (licensePlatePartTwo.length > 0) {
            spacer = "-";
            if (selectedPlateModel === MERCOSUL) spacer = " ";
          }
          formattedLicensePlate = licensePlatePartOne + spacer + licensePlatePartTwo;
        }

        if (formattedLicensePlate.length === 8) setButtonDisabled(false);
        else setButtonDisabled(true);

        setLicensePlate(formattedLicensePlate.toUpperCase());
        if (licensePlatePartOne.length === 3 && (selectedPlateModel === BRAZIL || (selectedPlateModel === MERCOSUL && licensePlatePartTwo.length !== 1))) setKeyboardType('numeric');
        else setKeyboardType('default');
      }
    }
  };

  const goToStep = (stepNumber) => {

    setButtonDisabled(false);
    if (step === 2 && licensePlate.length !== 8 && stepNumber === 3) {
      alert(
        'Oops',
        'Por favor, preencha uma placa válida antes de alterar a descrição.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops',
      //   'Por favor, preencha uma placa válida antes de alterar a descrição.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
    }
    else {
      if (step === 2 && licensePlate.length !== 8) {
        let updatedConcludeStep = [...concludedStep];
        updatedConcludeStep[2] = false;
        updatedConcludeStep[3] = false;
        setConcludedStep(updatedConcludeStep);
      }
      setStep(stepNumber);
    }
  };

  const saveVehicle = async () => {

    setSavingVehicle(true);
    setShowConfirmLicensePlateBox(false);

    try {
      const newVehicle = await Parse.Cloud.run("saveUserVehicle", {
        licensePlate: licensePlate.replace("-", "").replace(" ", ""),
        userDescription: vehicleDescription.trim(),
        isMercosulPlate: selectedPlateModel === MERCOSUL ? true : false,
        isInternationalPlate: selectedPlateModel === INTERNATIONAL ? true : false,
        category: selectedCategory.category
      });

      if (fromState === 'parkingOptions') {
        setVehicleToPark(newVehicle);
        setUserVehicles(await getUserVehicles());
      }
      else {
        alert(
          'Pronto!',
          'Veículo adicionado com sucesso!',
          null,
          true
        );

        // Alert.alert(
        //   'Pronto!',
        //   'Veículo adicionado com sucesso!',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
        getVehicles();
      }

      setAddVehicleModalVisible(false);
      setSavingVehicle(false);

      //return modal value to default
      setSelectedCategory([]);
      setSelectedPlateModel(null);
      setLastPlateModel(null);
      setLicensePlate('');
      setVehicleDescription('');
      setKeyboardType('default');
      setStep(0);
      setConcludedStep([]);
      setButtonDisabled(true);
    }
    catch (e) {
      let errorMessage = 'Ocorreu um erro ao salvar o veículo. Por gentileza, tente novamente.';

      if (e && e.message === 'vehicle_already_exists') {
        errorMessage = 'A placa informada já está cadastrada. Se desejar alterar informações do veículo, exclua a placa na tela anterior e tente novamente.'
      }

      alert(
        'Oops!',
        errorMessage,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   errorMessage,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
      setSavingVehicle(false);
      checkIfSessionExpired(e);
    }
  };

  const addNewVehicle = () => {

    if (step === 2 && (!licensePlate || licensePlate.length === 0)) {
      alert(
        'Oops',
        'Por favor, preencha a placa antes de avançar.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops',
      //   'Por favor, preencha a placa antes de avançar.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      return;
    }

    if (step === 4) {
      setShowConfirmLicensePlateBox(true);
    }
    else {
      let updatedConcludeStep = [...concludedStep];
      updatedConcludeStep[step] = true;

      setConcludedStep(updatedConcludeStep);

      if (lastPlateModel != selectedPlateModel) {
        if (concludedStep[1]) {
          setButtonDisabled(true);
        }
        setLastPlateModel(selectedPlateModel);
        setKeyboardType('default');
        setStep(step + 1);
        setLicensePlate('');
      }
      else {
        let i = 0;
        for (i = step + 1; i < 4; i++) {
          if (!concludedStep[i]) break;
        }

        if (i < 3) {
          if (i !== 1 || !selectedPlateModel) {
            if (i !== 2 || licensePlate.length !== 8) {
              setButtonDisabled(true);
            }
          }
        }
        setStep(i);
      }
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={addVehicleModalVisible}
      onRequestClose={() => {
        setAddVehicleModalVisible(false);
        if (fromState === 'parkingOptions' && !vehicleToPark) {
          navigation.goBack();
        }
      }}
    >
      <SafeAreaView>
        <View style={styles.modalHeader}>
          <View style={{ flex: 1 }} />
          <Text style={styles.cardModalTitle}>Novo Veículo</Text>
          <TouchableOpacity
            onPress={() => {
              setAddVehicleModalVisible(false);
              if (fromState === 'parkingOptions' && !vehicleToPark) {
                navigation.goBack();
              }
            }}
            style={styles.closeModalTouchable}>
            <FontAwesome
              name='times'
              style={styles.closeModalIcon}
            />
          </TouchableOpacity>
        </View>
      </SafeAreaView>

      {showConfirmLicensePlateBox ?
        <>
          <View style={styles.confirmLicensePlateContainer}>
            <Text style={styles.confirmLicensePlateTitle}>Confirma a placa?</Text>
            <Text style={styles.confirmLicensePlateWarning}><Text style={{ fontFamily: 'defaultFontBold' }}>Atenção:</Text> Sempre atente para o <Text style={{ fontFamily: 'defaultFontBold' }}>correto preenchimento da placa do veículo</Text> - a exatidão desta informação é indispensável para a efetividade das ativações.</Text>

            <View
              style={[styles.licensePlateContainer, styles.licensePlateMargins, selectedPlateModel === BRAZIL ? styles.plateTemplateContainer : styles.plateTemplateContainerMercosul]}
            >
              <View style={[styles.plateTemplateHeaderContainer, selectedPlateModel !== BRAZIL ? styles.plateTemplateHeaderContainerMercosul : null]}>
                <FontAwesome
                  name={selectedCategory.icon}
                  style={[styles.licensePlateTemplateIcon, { color: selectedPlateModel === BRAZIL ? '#424242' : '#FFFFFF' }]}
                />
                <Text style={[styles.vehicleDescriptionInput, selectedPlateModel !== BRAZIL ? { color: '#FFFFFF' } : null]}>{vehicleDescription.toUpperCase()}</Text>
              </View>

              <Text style={[styles.licensePlateInput, { color: '#424242' }]}>{licensePlate}</Text>
            </View>

            <View style={styles.confirmLicensePlateButtonContainer}>
              <Button
                buttonStyle={styles.dontConfirmButton}
                title='Não'
                titleStyle={styles.buttonTitle}
                onPress={() => setShowConfirmLicensePlateBox(false)}
              />

              <Button
                buttonStyle={styles.doConfirmButton}
                title='Sim'
                titleStyle={styles.buttonTitle}
                onPress={saveVehicle}
              />
            </View>

          </View>
          <LoadingScreen />
        </>
        : null}

      <ScrollView>
        <View style={styles.modalContent}>

          <View style={(step !== 0 && !concludedStep[0]) ? { display: 'none' } : null}>
            <View style={styles.fieldTitleBorder}>
              <Text style={styles.fieldTitle}>
                1. {step === 0 ? 'Qual a categoria do veículo?' : 'Categoria do veículo:'}
              </Text>
            </View>

            <View style={[styles.selectedOptionContainer, (step === 0 ? { display: 'none' } : null)]}>
              <Text style={styles.selectedOptionContainerText}>
                {selectedCategory.name}
              </Text>
              <FontAwesome
                name={selectedCategory.icon}
                style={[styles.newVehicleIcon, styles.newVehicleIconActive, { marginLeft: 10 }]}
              />
              <TouchableOpacity
                onPress={() => goToStep(0)}
              >
                <Text style={styles.changeOptionText}>alterar</Text>
              </TouchableOpacity>
            </View>

            {step === 0 ?
              <View style={styles.vehicleCategoriesContainer}>
                {vehicleCategories.map((item, index) => (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      setSelectedCategory({ name: item.name, icon: item.icon, category: item.category });
                      setButtonDisabled(false);
                    }}
                    style={[styles.touchableOption, selectedCategory.name === item.name ? styles.touchableOptionActive : styles.touchableOptionUnactive]}
                  >
                    <FontAwesome
                      name={item.icon}
                      style={[styles.newVehicleIcon, selectedCategory.name === item.name ? styles.newVehicleIconActive : styles.newVehicleIconUnactive]}
                    />
                    <Text
                      style={[styles.categoryText, selectedCategory.name === item.name ? styles.categoryTextActive : styles.categoryTextUnactive]}
                    >
                      {item.name}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
              : null}

          </View>

          <View style={(step !== 1 && !concludedStep[1]) ? { display: 'none' } : null}>

            <View style={styles.fieldTitleBorder}>
              <Text style={styles.fieldTitle}>
                2. {step === 1 ? 'Qual o padrão da placa do veículo?' : 'Padrão da placa:'}
              </Text>
            </View>
            <View style={[styles.selectedOptionContainer, (step === 1 ? { display: 'none' } : null)]}>
              <Text style={styles.selectedOptionContainerText}>
                {selectedPlateModel}
              </Text>
              <TouchableOpacity
                onPress={() => goToStep(1)}
              >
                <Text style={styles.changeOptionText}>alterar</Text>
              </TouchableOpacity>
            </View>


            <FlatList
              horizontal
              contentContainerStyle={[styles.modalFlatlist, (step !== 1 ? { display: 'none' } : null)]}
              data={plateModels}
              keyExtractor={plateModel => plateModel.name}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      if (selectedPlateModel !== item.name) {
                        setSelectedPlateModel(item.name);
                      }
                      setButtonDisabled(false);
                    }}
                    style={[styles.touchableOption, selectedPlateModel === item.name ? styles.touchableOptionActive : styles.touchableOptionUnactive]}
                  >
                    <View
                      style={[styles.licensePlateContainer, selectedPlateModel === item.name ? styles.licensePlateContainerActive : styles.licensePlateContainerUnactive]}
                    >
                      <Text
                        style={[styles.categoryText, selectedPlateModel === item.name ? styles.categoryTextActive : styles.categoryTextUnactive]}
                      >
                        {item.examplePattern}</Text>
                    </View>
                    <Text
                      style={[styles.categoryText, selectedPlateModel === item.name ? styles.categoryTextActive : styles.categoryTextUnactive]}
                    >
                      {item.name}
                    </Text>
                  </TouchableOpacity>
                )
              }}
            />
          </View>

          <View style={(step !== 2 && !concludedStep[2]) ? { display: 'none' } : null}>

            <View style={styles.fieldTitleBorder}>
              <Text style={styles.fieldTitle}>
                3. {step === 2 ? 'Qual a placa do veículo?' : 'Placa:'}
              </Text>
            </View>
            <View style={[styles.selectedOptionContainer, (step === 2 ? { display: 'none' } : null)]}>
              <Text style={styles.selectedOptionContainerText}>
                {licensePlate}
              </Text>
              <TouchableOpacity
                onPress={() => goToStep(2)}
              >
                <Text style={styles.changeOptionText}>alterar</Text>
              </TouchableOpacity>
            </View>

            <View
              style={step !== 2 ? { display: 'none' } : ([styles.licensePlateContainer, styles.licensePlateContainerUnactive, styles.licensePlateMargins, selectedPlateModel !== BRAZIL ? { borderColor: '#284D64' } : null])}
            >
              <Input
                value={licensePlate}
                onChangeText={setLicensePlateWithMask}
                autoCorrect={false}
                inputContainerStyle={styles.licensePlateInputContainer}
                inputStyle={styles.licensePlateInput}
                placeholder={selectedPlateModel === BRAZIL ? 'ABC-1234' : selectedPlateModel === MERCOSUL ? 'ABC 1A34' : 'A1B2C3D4'}
                keyboardType={keyboardType}
                returnKeyType='next'
                renderErrorMessage={false}
              />
            </View>
          </View>

          <View style={(step !== 3 && !concludedStep[3]) ? { display: 'none' } : null}>
            <View style={styles.fieldTitleBorder}>
              <Text style={styles.fieldTitle}>
                4. {step === 3 ? 'Qual a descrição do veículo?' : 'Descrição:'}
              </Text>
            </View>
            <View style={[styles.selectedOptionContainer, (step === 3 ? { display: 'none' } : null)]}>
              <Text style={styles.selectedOptionContainerText}>
                {vehicleDescription.trim().length > 0 ? vehicleDescription : '-'}
              </Text>
              <TouchableOpacity
                onPress={() => goToStep(3)}
              >
                <Text style={styles.changeOptionText}>alterar</Text>
              </TouchableOpacity>
            </View>


            <View style={step !== 3 ? { display: 'none' } : null}>
              <Text style={styles.explanationField}>Caso queira, informe uma descrição para identificar o veículo, por exemplo 'FIAT ARGO' ou 'CARRO AZUL'. A descrição será exibida junto à placa quando você for estacionar.</Text>
              <Input
                value={vehicleDescription}
                onChangeText={vehicleDescription => setVehicleDescription(vehicleDescription.substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH))}
                autoCorrect={false}
                inputContainerStyle={[styles.vehicleDescriptionInputContainer]}
                inputStyle={styles.vehicleDescriptionInput}
                placeholder='DESCRIÇÃO (EX.: FIAT ARGO)'
                keyboardType='default'
                returnKeyType='next'
              />
            </View>

          </View>
        </View>

        <Button
          buttonStyle={styles.addVehicleButton}
          title={step === 4 ? 'Salvar Veículo' : 'Avançar'}
          loading={savingVehicle}
          disabled={buttonDisabled || savingVehicle}
          disabledStyle={styles.disabledButtonStyle}
          titleStyle={styles.buttonTitle}
          onPress={addNewVehicle}
        />

      </ScrollView>

    </Modal>
  );

};

const styles = StyleSheet.create({
  vehicleCategoriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  confirmLicensePlateContainer: {
    position: 'absolute',
    left: 25,
    right: 25,
    top: 80,
    padding: 20,
    borderWidth: 1,
    borderColor: '#AAAAAA',
    backgroundColor: '#FFFFFF',
    zIndex: 1
  },
  confirmLicensePlateTitle: {
    fontFamily: 'defaultFontBold',
    fontSize: 18,
    textAlign: 'center',
    color: '#424242'
  },
  confirmLicensePlateWarning: {
    marginTop: 5,
    marginBottom: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  addVehicleButton: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0
  },
  dontConfirmButton: {
    backgroundColor: '#EF6F6C',
    paddingLeft: 30,
    paddingRight: 30
  },
  doConfirmButton: {
    backgroundColor: '#7FCFAB',
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  disabledButtonStyle: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0,
    opacity: 0.5
  },
  newVehicleIcon: {
    fontSize: 20,
    minWidth: 25,
    textAlign: 'center'
  },
  newVehicleIconActive: {
    color: '#0992A5'
  },
  licensePlateTemplateIcon: {
    minWidth: 25,
    alignSelf: 'center',
    marginRight: 10,
    fontSize: 18
  },
  newVehicleIconUnactive: {
    color: '#AAAAAA'
  },
  modalHeader: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  cardModalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont',
    textAlign: 'center',
    flex: 3
  },
  closeModalTouchable: {
    justifyContent: 'flex-end',
    flex: 1
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    textAlign: 'center'
  },
  modalContent: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  selectedOptionContainer: {
    flexDirection: 'row',
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 5
  },
  selectedOptionContainerText: {
    fontFamily: 'defaultFontBold',
    fontSize: 16,
    color: '#0992A5',
    marginLeft: 10
  },
  changeOptionText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    textDecorationLine: 'underline',
    fontSize: 16,
    marginLeft: 10
  },
  explanationField: {
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 14,
    marginLeft: 20
  },
  vehicleDescriptionInput: {
    color: '#424242',
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    outlineStyle: 'none'
  },
  vehicleDescriptionInputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#AAAAAA',
  },
  licensePlateInput: {
    color: '#424242',
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase',
    fontSize: 30,
    textAlign: 'center',
    outlineStyle: 'none'
  },
  licensePlateInputContainer: {
    borderBottomWidth: 0
  },
  licensePlateMargins: {
    marginTop: 10,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 10
  },
  licensePlateContainer: {
    borderWidth: 2,
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#FFFFFF'
  },
  plateTemplateContainer: {
    backgroundColor: '#F3F3F3'
  },
  plateTemplateContainerMercosul: {
    borderColor: '#284D64',
    backgroundColor: '#FFFFFF',
    padding: 0,
    paddingBottom: 10
  },
  confirmLicensePlateButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 10,
    marginTop: 10
  },
  plateTemplateHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  plateTemplateHeaderContainerMercosul: {
    paddingBottom: 5,
    paddingTop: 5,
    backgroundColor: '#284D64',
    flex: 1
  },
  licensePlateContainerActive: {
    borderColor: '#0992A5'
  },
  licensePlateContainerUnactive: {
    borderColor: '#AAAAAA'
  },
  modalFlatlist: {
    flexGrow: 1,
    justifyContent: 'space-around'
  },
  touchableOption: {
    flex: 1,
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 10,
    alignItems: 'center',
    borderBottomColor: 'transparent',
    borderBottomWidth: 4
  },
  touchableOptionUnactive: {
    borderBottomColor: 'transparent'
  },
  touchableOptionActive: {
    borderBottomColor: '#0992A5'
  },
  fieldTitleBorder: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginLeft: 15
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 18,
    fontFamily: 'defaultFontBold'
  },
  categoryText: {
    fontSize: 14,
    fontFamily: 'defaultFont',
    textAlign: 'center'
  },
  categoryTextActive: {
    color: '#0992A5'
  },
  categoryTextUnactive: {
    color: '#AAAAAA'
  },
});

export default NewVehicle;