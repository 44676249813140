import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Image, Platform } from 'react-native';
import base64Images from '../../assets/images/base64/base64Images';
import { Keyboard } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Parse from "parse";
import appParams from '../../constants/AppParams';

const OfficialFooter = () => {
  const [isKeyboardHidden, setKeyboardHidden] = useState(true);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {

    //ios footer
    const checkFooterConfig = async () => {
      if (Platform.OS === 'ios') {
        try {
          let config = Parse.Config.current();

          if (!config || !config.get) {
            config = await Parse.Config.get();
          }

          const iosFooterVersionLimit = config.get('maxIOSVersionToShowTransalvadorFooter');

          const intIosFooterVersionLimit = parseInt(iosFooterVersionLimit.replace(/\D/g, ""));
          const intAppVersion = parseInt(appParams.appVersion.replace(/\D/g, ""));

          if (intAppVersion <= intIosFooterVersionLimit) setShowFooter(true);
          else setShowFooter(false);

        }
        catch (e) {
          setShowFooter(false);
          console.log('error getting footer config', e);
        }
      }
      else {
        setShowFooter(true);
      }
    }
    checkFooterConfig();

    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardHidden(false);
      },
    );

    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardHidden(true);
      },
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={[styles.imageContainer, { borderTopColor: '#FF7F07' }]}>
        <Image
          source={{ uri: base64Images.transalvadorLogo }}
          style={styles.logoImage}
        />
      </View>
      <View style={[styles.imageContainer, { borderTopColor: '#00B1F6' }]}>
        <Image
          source={{ uri: base64Images.streetParkingLogo }}
          style={styles.logoImage}
        />
      </View>

      <View style={[styles.imageContainer, { borderTopColor: '#006DBB' }]}>
        <Image
          source={{ uri: base64Images.cityHallLogo }}
          style={styles.logoImage}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 44,
    backgroundColor: '#DDDDDD',
    position: 'sticky',
    bottom: 0
  },
  imageContainer: {
    borderTopWidth: 4,
    flex: 1,
    padding: 2
  },
  logoImage: {
    flex: 1,
    resizeMode: 'contain'
  }
});

export default OfficialFooter;

