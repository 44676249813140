import React, { useState, useEffect, useContext } from 'react';
import { useWindowDimensions, View, StyleSheet, Text, Keyboard, Alert, TouchableOpacity, Platform } from 'react-native';
import alert from '../hooks/useAlert.js'
import { SafeAreaView } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TabView, TabBar } from 'react-native-tab-view';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import { Button, Input } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import cpfCnpjValidation from '../lib/validateCpfCnpj';
import Parse from "parse";
import useUserDataValidation from '../hooks/useUserDataValidation';
import useUserAddress from '../hooks/useUserAddress';
import { AuthContext } from '../context/AuthContext';
import * as Keychain from 'react-native-keychain';
import useExpiredSession from '../hooks/useExpiredSession';
import OfficialFooter from '../components/OfficialFooter';
import useErrorHandler from '../hooks/useErrorHandler';

const AccountScreen = ({ navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();
  const [handleError] = useErrorHandler();

  const { colors } = useTheme();
  const { setCurrentUser } = useContext(AuthContext);

  const [phoneMask, validField, checkAddressField, maskZipCode] = useUserDataValidation();

  const [errorMessages, setErrorMessages] = useState({});
  const [gotBlurred, setGotBlurred] = useState({});
  const [, cpfCnpjMask] = cpfCnpjValidation();
  const [modifiedFields, setModifiedFields] = useState({});
  const [searchingZipCode, setSearchingZipCode] = useState(false);
  const [user, setUser] = useState(null);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [hidePassword, setHidePassword] = useState({ current: true, new: true, newConfirmation: true });
  const [nameFocused, setNameFocused] = useState(false);
  const [getAddress] = useUserAddress();
  const [isRequestingAccountDeletion, setIsRequestingAccountDeletion] = useState(false);
  const [accountDeletionRequested, setAccountDeletionRequested] = useState(false);

  let inputs = {};

  const focusNextField = (id) => {
    inputs[id].focus();
  };

  const validateUserAddress = (field, value) => {

    if (field === 'zipCode') value = value.substring(0, 9);

    let addressErrorMessages = {};
    checkAddressField(value, field, addressErrorMessages);

    let allErrorMessages = { ...errorMessages };
    if (!allErrorMessages.address) allErrorMessages.address = {};
    allErrorMessages.address[field] = addressErrorMessages[field];

    setErrorMessages(allErrorMessages);

  };

  const searchZipCode = async (zipCode) => {
    if (zipCode) zipCode = zipCode.replace(/\D/g, "");

    if (!zipCode || zipCode.length !== 8) {

      alert(
        'Oops!',
        'O CEP deve ter 8 dígitos. Por favor, corrija e tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   'O CEP deve ter 8 dígitos. Por favor, corrija e tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );

      return;
    }
    else {
      Keyboard.dismiss();
      try {
        setSearchingZipCode(true);
        const address = await getAddress(zipCode);
        setErrorMessages({ ...errorMessages, address: null });
        setModifiedFields({
          ...modifiedFields,
          zipCode: true,
          street: null,
          streetNumber: null,
          complement: null,
          neighborhood: null,
          city: null,
          state: null
        });
        setUser({ ...user, address: { ...address, zipCode: maskZipCode(zipCode) } })
        setSearchingZipCode(false);
      }
      catch (e) {
        console.log('error getting address from zip code', e);

        alert(
          'Oops!',
          'Erro ao buscar endereço correspondente ao CEP fornecido. Por favor, preencha manualmente os campos ou tente novamente.',
          null,
          true
        );
        
        // Alert.alert(
        //   'Oops!',
        //   'Erro ao buscar endereço correspondente ao CEP fornecido. Por favor, preencha manualmente os campos ou tente novamente.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );

        setModifiedFields({
          ...modifiedFields,
          zipCode: true,
          street: null,
          streetNumber: null,
          complement: null,
          neighborhood: null,
          city: null,
          state: null
        });
        setUser({ ...user, address: { zipCode: maskZipCode(zipCode) } })
        setErrorMessages({ ...errorMessages, address: null });
        setSearchingZipCode(false);
        checkIfSessionExpired(e);
      }
    }
  };

  const saveUserData = () => {

    const showError = () => {
      alert(
        'Oops!',
        'Por favor, corrija os erros para os campos indicados na tela e tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   'Por favor, corrija os erros para os campos indicados na tela e tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
    };

    const goSave = () => {
      const showResultAlert = (title, msg) => {

        alert(
          title,
          msg,
          null,
          true
        );

        // Alert.alert(
        //   title,
        //   msg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
      };

      const saveUser = async () => {
        let unmodifiedUser = null;
        let updatedUser = null;
        try {
          setIsSavingUser(true);
          updatedUser = Parse.User.current();
          unmodifiedUser = Object.assign({}, updatedUser._toFullJSON());

          updatedUser.set('name', user.name);
          updatedUser.set('username', user.email.toLowerCase());
          updatedUser.set('email', user.email.toLowerCase());
          updatedUser.set('phone', user.phone.replace(/\D/g, ""));

          if (user.address) {
            updatedUser.set('address', user.address);
          }

          await updatedUser.save();
          setErrorMessages({});
          setGotBlurred({});

          if(modifiedFields.email) {
            showResultAlert('Pronto!', 'As alterações foram salvas com sucesso! Por razões de segurança, você está sendo deslogado e precisará entrar no app novamente.');
            
            setModifiedFields({});
            setIsSavingUser(false);
            //logout user
            try {
              await AsyncStorage.setItem('lastUserLoggedInId', user.cpf);
              await Parse.User.logOut();
              setCurrentUser(null, false, user.cpf);
            }
            catch (e) {
              setCurrentUser(null, false, user.cpf);
            }
          }
          else {
            setModifiedFields({});
            setIsSavingUser(false);
            showResultAlert('Pronto!', 'As alterações foram salvas com sucesso!');
          }
        }
        catch (e) {
          console.log('error saving user', e, unmodifiedUser);

          if (updatedUser && unmodifiedUser) {
            setUser({ ...unmodifiedUser, phone: phoneMask(unmodifiedUser.phone) });

            updatedUser.set('name', unmodifiedUser.name);
            updatedUser.set('username', unmodifiedUser.email.toLowerCase());
            updatedUser.set('email', unmodifiedUser.email.toLowerCase());
            updatedUser.set('phone', unmodifiedUser.phone.replace(/\D/g, ""));

            if (user.address) {
              updatedUser.set('address', unmodifiedUser.address);
            }
          }

          const errorMessage = handleError(e);

          setIsSavingUser(false);
          // showResultAlert('Oops!', 'Ocorreu um erro salvando as alterações. Por favor, tente novamente. Se o problema persistir, contate a nossa Central de Atendimento.');
          showResultAlert('Oops!', errorMessage);
          checkIfSessionExpired(e);
        }
      };

      alert(
        'Confirmação',
        'Tem certeza de que deseja salvar as alterações?',
        [
          {
            text: 'Não',
            style: 'cancel',
            onPress: () => { }
          },
          {
            text: 'Sim',
            onPress: () => saveUser()
          }
        ],
      );

      // Alert.alert(
      //   'Confirmação',
      //   'Tem certeza de que deseja salvar as alterações?',
      //   [
      //     {
      //       text: 'Não',
      //       style: 'cancel',
      //       onPress: () => { }
      //     },
      //     {
      //       text: 'Sim',
      //       onPress: () => saveUser()
      //     }
      //   ],
      //   { cancelable: false }
      // );
    };


    let addressError = false;
    let addressErrorMessages = {};

    if (user.address) {
      //check address, if exists
      checkAddressField(null, null, addressErrorMessages, true, user.address);

      for (const fd in addressErrorMessages) {
        if (addressErrorMessages[fd]) {
          addressError = true;
          break;
        }
      }
    }

    //check name, phone and email
    if (!errorMessages.name && !errorMessages.email && !errorMessages.phone && !addressError) {
      goSave();
    }
    else {
      if (addressError) {
        setErrorMessages({ ...errorMessages, address: addressErrorMessages });
      }
      showError();
    }

  };

  const deleteAccount = () => {
    setIsRequestingAccountDeletion(true);

    const showAlert = (title, message) => {
      alert(
        title,
        message,
        null,
        true
      );

      // Alert.alert(
      //   title,
      //   message,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
    };

    const doRequestAccountDeletion = async () => {
      try {
        const params = {};
        await Parse.Cloud.run('sendRequestAccountDeletionMail', params);

        if (!Parse.User.current()) {
          throw 'No user logged in. This should not happen.';
        }

        const userId = Parse.User.current().get('cpf');
        const storageKey = `acDeletionReq${userId}`;

        await AsyncStorage.setItem(storageKey, 'requested');
        setAccountDeletionRequested(true);

        showAlert(
          'Pronto!',
          'O processo deleção de sua conta foi iniciado. O processo pode levar até 5 dias úteis para ser finalizado. Você será comunicado via e-mail.\nSe houver saldo em sua conta, o mesmo será estornado.'
        );
        setIsRequestingAccountDeletion(false);
      }
      catch (e) {
        showAlert(
          'Oops',
          'Ocorreu um erro ao iniciar o processo de deleção da sua conta. Por favor, tente novamente.'
        );
        console.log('error on sendRequestAccountDeletionMail', e);
        setIsRequestingAccountDeletion(false);
      }
    }

    alert(
      'Atenção',
      'Ao prosseguir iniciaremos o processo para apagar em definitivo sua conta  e todos os dados pessoais e de pagamento a ela associados.\n\nDeseja continuar?',
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => { setIsRequestingAccountDeletion(false); }
        },
        {
          text: 'Sim',
          onPress: () => doRequestAccountDeletion()
        }
      ],
    );

    // Alert.alert(
    //   'Atenção',
    //   'Ao prosseguir iniciaremos o processo para apagar em definitivo sua conta  e todos os dados pessoais e de pagamento a ela associados.\n\nDeseja continuar?',
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => { setIsRequestingAccountDeletion(false); }
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => { doRequestAccountDeletion(); }
    //     }
    //   ],
    //   { cancelable: false }
    // );
  };

  const changeUserPassword = () => {

    const showMsg = (msg, title) => {

      alert(
        title ? title : 'Oops!',
        msg,
        null,
        true
      );

      // Alert.alert(
      //   title ? title : 'Oops!',
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
    };

    const goChangeUserPassword = async () => {
      setIsChangingPassword(true);

      let currentPasswordVerified = false;

      try {
        //check if provided current password is correct
        await Parse.User.logIn(user.username, user.password);

        //save new password
        currentPasswordVerified = true;
        const userToSave = Parse.User.current();
        userToSave.set('password', user.newPassword);
        await userToSave.save();

        //login again to remove saved password from user object stored locally
        await Parse.User.logIn(user.username, user.newPassword);

        // const biometryType = await Keychain.getSupportedBiometryType();

        //check if user has login credentials stored
        // if (biometryType && (biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' || biometryType.toUpperCase() === 'FACEID')) { //fingerprint supported
        
        if(false){
          const hasStoredCredentials = await Keychain.getGenericPassword({
            service: user.cpf
          });

          if (hasStoredCredentials && hasStoredCredentials.username) {
            await Keychain.setGenericPassword(user.cpf, user.newPassword, {
              accessControl: 'BiometryAny',
              service: `loginCredentials${user.cpf}`
            });
          }
          else {
            const askToStoreLoginCredentials = async () => {
              return new Promise((resolve, reject) => {
                Alert.alert(
                  'Atenção',
                  `Você deseja utilizar ${biometryType.toUpperCase() === 'FINGERPRINT' || biometryType.toUpperCase() === 'TOUCHID' ? 'a impressão digital' : 'o reconhecimento facial'} neste aparelho da próxima vez que fizer login nesta conta?`,
                  [
                    { text: 'Não', onPress: () => resolve(false) },
                    { text: 'Sim', onPress: () => resolve(true) }
                  ],
                  { cancelable: false }
                )
              });
            };

            const storeCredentials = await askToStoreLoginCredentials();

            if (storeCredentials) {
              await Keychain.setGenericPassword(user.cpf, 'yesStoreCredentials', {
                service: user.cpf
              });

              await Keychain.setGenericPassword(user.cpf, user.newPassword, {
                accessControl: 'BiometryAny',
                service: `loginCredentials${user.cpf}`
              });
            }
          }
        }

        setHidePassword({ current: true, new: true, newConfirmation: true });
        setGotBlurred({ ...gotBlurred, password: null, newPassword: null, newPasswordConfirmation: null });
        setModifiedFields({ ...modifiedFields, password: null, newPassword: null, newPasswordConfirmation: null });
        setUser({ ...user, password: null, newPassword: null, newPasswordConfirmation: null });
        showMsg('Sua senha foi alterada.', 'Pronto!');
        setIsChangingPassword(false);
      }
      catch (e) {
        setIsChangingPassword(false);
        console.log('error changing password', e);
        checkIfSessionExpired(e);

        if (!currentPasswordVerified) {
          showMsg('Ocorreu um erro e sua senha não foi alterada. Confirme se sua senha antiga está correta e tente novamente.');
        }
        else {
          showMsg('Ocorreu um ao salvar sua nova senha. Por razões de segurança, você está sendo deslogado e precisará entrar no app novamente. Por favor, tente entrar com sua senha nova e, caso não consiga, com a sua senha antiga.');

          //logout user
          try {
            await AsyncStorage.setItem('lastUserLoggedInId', user.cpf);
            await Parse.User.logOut();
            setCurrentUser(null, false, user.cpf);
          }
          catch (e) {
            setCurrentUser(null, false, user.cpf);
          }
        }
      }
    };

    if (!user.password || !user.newPassword || !user.newPasswordConfirmation) {
      showMsg('Por favor, preencha a senha atual, a nova senha desejada e a confirmação da nova senha e tente novamente.')
    }
    else if (errorMessages.password || errorMessages.newPassword || errorMessages.newPasswordConfirmation) {
      showMsg('Por favor, corrija os erros mostrados na tela e tente novamente.')

    }
    else {

      alert(
        'Confirmação',
        'Tem certeza de que deseja alterar sua senha?',
        [
          {
            text: 'Não',
            style: 'cancel',
            onPress: () => { }
          },
          {
            text: 'Sim',
            onPress: () => goChangeUserPassword()
          }
        ],
      );

      // Alert.alert(
      //   'Confirmação',
      //   'Tem certeza de que deseja alterar sua senha?',
      //   [
      //     {
      //       text: 'Não',
      //       style: 'cancel',
      //       onPress: () => { }
      //     },
      //     {
      //       text: 'Sim',
      //       onPress: () => goChangeUserPassword()
      //     }
      //   ],
      //   { cancelable: false }
      // );
    }

  };

  const PersonalData = () => {
    return (
      <View style={{ flex: 1 }}>
        {user ?
          <KeyboardAwareScrollView
            style={{ margin: 10, marginTop: 0, marginBottom: 0 }}
            // innerRef={ref => {
            //   this.scroll = ref ? ref.getScrollResponder() : null;
            // }}
            enableOnAndroid={true}
            enableAutomaticScroll={(Platform.OS === 'ios')}
            keyboardOpeningTime={0}
            extraHeight={250}
            keyboardShouldPersistTaps={'handled'}
            contentContainerStyle={styles.scrollViewContainer}
            persistentScrollbar={true}
            // contentContainerStyle={{ justifyContent: 'flex-start' }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Input
                containerStyle={styles.fieldContainer}
                inputContainerStyle={[styles.inputContainer, (gotBlurred.name && errorMessages.name) ? styles.inputContainerError : null]}
                inputStyle={styles.input}
                renderErrorMessage={errorMessages.name ? true : false}
                placeholder='João da Silva'
                leftIcon={
                  <FontAwesome
                    name='user'
                    style={styles.inputIcon}
                  />
                }
                value={user.name}
                onChangeText={(name) => {
                  setModifiedFields({ ...modifiedFields, name: true });
                  setUser({ ...user, name });
                  validField('name', name, true, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                }}
                selection={nameFocused ? null : { start: 0, end: 0 }}
                onFocus={() => setNameFocused(true)}
                onSubmitEditing={() => Keyboard.dismiss()}
                blurOnSubmit={false}
                autoCorrect={false}
                errorStyle={styles.errorMessage}
                errorMessage={gotBlurred.name ? errorMessages.name : null}
              />
              <FontAwesome
                name={errorMessages.name ? (gotBlurred.name ? 'times' : null) : (modifiedFields.name ? 'check' : null)}
                style={[styles.validateIcon, errorMessages.name ? styles.validateIconError : null]}
              />
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Input
                containerStyle={styles.fieldContainer}
                inputContainerStyle={[styles.inputContainer, (gotBlurred.email && errorMessages.email) ? styles.inputContainerError : null]}
                inputStyle={styles.input}
                renderErrorMessage={false}
                placeholder='joao@email.com'
                leftIcon={
                  <FontAwesome
                    name='envelope'
                    style={styles.inputIcon}
                  />
                }
                value={user.email}
                onChangeText={(email) => {
                  setModifiedFields({ ...modifiedFields, email: true });
                  setUser({ ...user, email });
                  validField('email', email, true, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                }}
                blurOnSubmit={true}
                autoCapitalize='none'
                autoCorrect={false}
                errorStyle={styles.errorMessage}
                errorMessage={gotBlurred.email ? errorMessages.email : null}
              />
              <FontAwesome
                name={errorMessages.email ? (gotBlurred.email ? 'times' : null) : (modifiedFields.email ? 'check' : null)}
                style={[styles.validateIcon, errorMessages.email ? styles.validateIconError : null]}
              />
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Input
                containerStyle={styles.fieldContainer}
                inputContainerStyle={[styles.inputContainer, (gotBlurred.phone && errorMessages.phone) ? styles.inputContainerError : null]}
                inputStyle={styles.input}
                renderErrorMessage={false}
                placeholder='(85) 98888-7777'
                leftIcon={
                  <FontAwesome
                    name='phone'
                    style={styles.inputIcon}
                  />
                }
                value={user.phone}
                onChangeText={(phone) => {
                  setModifiedFields({ ...modifiedFields, phone: true });
                  setUser({ ...user, phone })
                  validField('phone', phone, true, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
                }}
                keyboardType='phone-pad'
                blurOnSubmit={true}
                autoCorrect={false}
                errorStyle={styles.errorMessage}
                errorMessage={gotBlurred.phone ? errorMessages.phone : null}
              />
              <FontAwesome
                name={errorMessages.phone ? (gotBlurred.phone ? 'times' : null) : (modifiedFields.phone ? 'check' : null)}
                style={[styles.validateIcon, errorMessages.phone ? styles.validateIconError : null]}
              />
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Input
                containerStyle={styles.fieldContainer}
                inputContainerStyle={styles.inputContainer}
                inputStyle={[styles.input, { color: '#AAAAAA' }]}
                renderErrorMessage={false}
                placeholder='123.456.789-00'
                leftIcon={
                  <FontAwesome
                    name='id-card'
                    style={styles.inputIcon}
                  />
                }
                value={cpfCnpjMask(user.cpf, true)}
                editable={false}
              />
            </View>

            {user.address ?
              <View>
                <View style={styles.addressTitle}>
                  <FontAwesome
                    name='home'
                    style={styles.inputHomeIcon}
                  />
                  <Text style={styles.addressTitleText}>Endereço:</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Input
                    containerStyle={styles.addressFieldContainer}
                    inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.zipCode) ? styles.inputContainerError : null]}
                    inputStyle={styles.input}
                    renderErrorMessage={false}
                    label='CEP:'
                    labelStyle={styles.addressLabel}
                    value={user.address.zipCode}
                    onChangeText={(zipCode) => {
                      setModifiedFields({ ...modifiedFields, zipCode: true });
                      setUser({ ...user, address: { ...user.address, zipCode: maskZipCode(zipCode) } });
                      validateUserAddress('zipCode', zipCode);
                    }}
                    onSubmitEditing={() => { Keyboard.dismiss(); }}
                    autoCorrect={false}
                    keyboardType='numeric'
                    blurOnSubmit={false}
                    errorStyle={styles.errorMessage}
                    errorMessage={errorMessages.address ? errorMessages.address.zipCode : null}
                  />
                  <FontAwesome
                    name={(errorMessages.address && errorMessages.address.zipCode) ? 'times' : (modifiedFields.zipCode ? 'check' : null)}
                    style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.zipCode) ? styles.validateIconError : null]}
                  />

                  <View style={[styles.addressFieldContainer]}>
                    <Button
                      buttonStyle={[styles.searchAddressButton, searchingZipCode ? { marginTop: 20 } : null]}
                      disabled={searchingZipCode}
                      disabledStyle={{ backgroundColor: 'transparent' }}
                      disabledTitleStyle={{ color: '#7FCFAB' }}
                      loading={searchingZipCode}
                      loadingProps={{ color: '#7FCFAB' }}
                      title={'Buscar\nEndereço'}
                      titleStyle={styles.searchAddressButtonTitle}
                      onPress={() => searchZipCode(user.address.zipCode)}
                    />
                  </View>
                </View>

                {!searchingZipCode ?
                  <View>
                    <View style={{ flexDirection: 'row' }}>
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.street) ? styles.inputContainerError : null]}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Logradouro:'
                        labelStyle={styles.addressLabel}
                        value={user.address.street}
                        onChangeText={(street) => {
                          setModifiedFields({ ...modifiedFields, street: true });
                          setUser({ ...user, address: { ...user.address, street } });
                          validateUserAddress('street', street);
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        blurOnSubmit={false}
                        errorStyle={styles.errorMessage}
                        errorMessage={errorMessages.address ? errorMessages.address.street : null}
                      />
                      <FontAwesome
                        name={(errorMessages.address && errorMessages.address.street) ? 'times' : (modifiedFields.street ? 'check' : null)}
                        style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.street) ? styles.validateIconError : null]}
                      />
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.streetNumber) ? styles.inputContainerError : null]}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Número:'
                        labelStyle={styles.addressLabel}
                        value={user.address.streetNumber}
                        onChangeText={(streetNumber) => {
                          setModifiedFields({ ...modifiedFields, streetNumber: true });
                          setUser({ ...user, address: { ...user.address, streetNumber } });
                          validateUserAddress('streetNumber', streetNumber);
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        keyboardType='numeric'
                        blurOnSubmit={false}
                        errorStyle={styles.errorMessage}
                        errorMessage={errorMessages.address ? errorMessages.address.streetNumber : null}
                      />
                      <FontAwesome
                        name={(errorMessages.address && errorMessages.address.streetNumber) ? 'times' : (modifiedFields.streetNumber ? 'check' : null)}
                        style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.streetNumber) ? styles.validateIconError : null]}
                      />
                    </View>

                    <View style={{ flexDirection: 'row' }}>
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={styles.inputContainer}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Complemento:'
                        labelStyle={styles.addressLabel}
                        value={user.address.complement}
                        onChangeText={(complement) => {
                          setModifiedFields({ ...modifiedFields, complement: true });
                          setUser({ ...user, address: { ...user.address, complement } });
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        blurOnSubmit={false}
                      />
                      <FontAwesome
                        name={modifiedFields.complement ? 'check' : null}
                        style={[styles.addressCheckIcon, styles.validateIcon]}
                      />
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.neighborhood) ? styles.inputContainerError : null]}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Bairro:'
                        labelStyle={styles.addressLabel}
                        value={user.address.neighborhood}
                        onChangeText={(neighborhood) => {
                          setModifiedFields({ ...modifiedFields, neighborhood: true });
                          setUser({ ...user, address: { ...user.address, neighborhood } });
                          validateUserAddress('neighborhood', neighborhood);
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        blurOnSubmit={false}
                        errorStyle={styles.errorMessage}
                        errorMessage={errorMessages.address ? errorMessages.address.neighborhood : null}
                      />
                      <FontAwesome
                        name={(errorMessages.address && errorMessages.address.neighborhood) ? 'times' : (modifiedFields.neighborhood ? 'check' : null)}
                        style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.neighborhood) ? styles.validateIconError : null]}
                      />
                    </View>

                    <View style={{ flexDirection: 'row' }}>
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.city) ? styles.inputContainerError : null]}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Cidade:'
                        labelStyle={styles.addressLabel}
                        value={user.address.city}
                        onChangeText={(city) => {
                          setModifiedFields({ ...modifiedFields, city: true });
                          setUser({ ...user, address: { ...user.address, city } });
                          validateUserAddress('city', city);
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        blurOnSubmit={false}
                        errorStyle={styles.errorMessage}
                        errorMessage={errorMessages.address ? errorMessages.address.city : null}
                      />
                      <FontAwesome
                        name={(errorMessages.address && errorMessages.address.city) ? 'times' : (modifiedFields.city ? 'check' : null)}
                        style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.city) ? styles.validateIconError : null]}
                      />
                      <Input
                        containerStyle={styles.addressFieldContainer}
                        inputContainerStyle={[styles.inputContainer, (errorMessages.address && errorMessages.address.state) ? styles.inputContainerError : null]}
                        inputStyle={styles.input}
                        renderErrorMessage={false}
                        label='Estado (2 letras):'
                        labelStyle={styles.addressLabel}
                        value={user.address.state}
                        onChangeText={(state) => {
                          setModifiedFields({ ...modifiedFields, state: true });
                          setUser({ ...user, address: { ...user.address, state: state.replace(/[^a-zA-Z]/g, "") } });
                          validateUserAddress('state', state);
                        }}
                        onSubmitEditing={() => { Keyboard.dismiss(); }}
                        autoCorrect={false}
                        blurOnSubmit={false}
                        errorStyle={styles.errorMessage}
                        errorMessage={errorMessages.address ? errorMessages.address.state : null}
                      />
                      <FontAwesome
                        name={(errorMessages.address && errorMessages.address.state) ? 'times' : (modifiedFields.state ? 'check' : null)}
                        style={[styles.addressCheckIcon, styles.validateIcon, (errorMessages.address && errorMessages.address.state) ? styles.validateIconError : null]}
                      />
                    </View>
                  </View>
                  : null}
              </View>
              : null}

          </KeyboardAwareScrollView>
          : null}

        {user ?
          <>
            <Button
              buttonStyle={styles.saveChangesButton}
              title='Salvar Alterações'
              loading={isSavingUser}
              disabled={
                !(modifiedFields.name || modifiedFields.email || modifiedFields.phone ||
                  modifiedFields.zipCode || modifiedFields.street || modifiedFields.streetNumber ||
                  modifiedFields.complement || modifiedFields.neighborhood || modifiedFields.city || modifiedFields.state)
                || isSavingUser
              }
              disabledStyle={styles.disabledSaveChangesButtonStyle}
              titleStyle={styles.saveChangesButtonTitle}
              onPress={() => {
                Keyboard.dismiss();
                saveUserData();
              }}
            />

            {Platform.OS === 'ios' ?
              <Button
                buttonStyle={styles.deleteAccountButton}
                title={accountDeletionRequested ? 'Deleção de conta em processamento' : 'Apagar minha conta'}
                loading={isRequestingAccountDeletion}
                disabled={accountDeletionRequested || isRequestingAccountDeletion}
                disabledStyle={styles.disabledDeleteAccountButtonStyle}
                titleStyle={styles.saveChangesButtonTitle}
                disabledTitleStyle={{ color: '#424242' }}
                onPress={() => {
                  deleteAccount();
                }}
              />
              : null}

          </>
          : null}
        <OfficialFooter />

      </View>
    );
  };

  const ChangePassword = () => {
    return (
      <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          style={{ margin: 10 }}
          // innerRef={ref => {
          //   this.scroll = ref ? ref.getScrollResponder() : null;
          // }}
          enableOnAndroid={true}
          enableAutomaticScroll={(Platform.OS === 'ios')}
          keyboardOpeningTime={0}
          extraHeight={250}
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={styles.scrollViewContainer}
          persistentScrollbar={true}
          // contentContainerStyle={{ justifyContent: 'flex-start' }}
        >

          <View style={{ flexDirection: 'row' }}>
            <Input
              ref={input => { inputs['password'] = input }}
              containerStyle={styles.fieldContainer}
              secureTextEntry={hidePassword.current}
              inputContainerStyle={[styles.inputContainer, (gotBlurred.password && errorMessages.password) ? styles.inputContainerError : null]}
              inputStyle={styles.input}
              renderErrorMessage={errorMessages.name ? true : false}
              placeholder={modifiedFields.password ? '' : 'Senha atual'}
              label={modifiedFields.password ? 'Senha atual:' : ' '}
              labelStyle={styles.label}
              leftIcon={
                <FontAwesome
                  name='lock'
                  style={styles.inputIcon}
                />
              }
              value={user.password || ""}
              onChangeText={(password) => {
                setModifiedFields({ ...modifiedFields, password: true });
                setUser({ ...user, password });
                validField('password', password, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
              }}
              onBlur={() => {
                if (modifiedFields.password && !gotBlurred.password)
                  setGotBlurred({ ...gotBlurred, password: true });
              }}
              onSubmitEditing={() => {
                focusNextField('newPassword');
              }}
              blurOnSubmit={false}
              autoCapitalize='none'
              autoCorrect={false}
              errorStyle={styles.errorMessage}
              errorMessage={gotBlurred.password ? errorMessages.password : null}
            />
            <TouchableOpacity onPress={() => setHidePassword({ ...hidePassword, current: !hidePassword.current })} style={styles.showPasswordTouchable}>
              <FontAwesome
                name={hidePassword.current ? 'eye' : 'eye-slash'}
                style={styles.passwordIcon}
              />
            </TouchableOpacity>
            <FontAwesome
              name={errorMessages.password ? (gotBlurred.password ? 'times' : null) : (modifiedFields.password ? 'check' : null)}
              style={[styles.validateIcon, errorMessages.password ? styles.validateIconError : null, { marginTop: 40 }]}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <Input
              ref={input => { inputs['newPassword'] = input }}
              containerStyle={[styles.fieldContainer, { marginTop: 10 }]}
              secureTextEntry={hidePassword.new}
              inputContainerStyle={[styles.inputContainer, (gotBlurred.newPassword && errorMessages.newPassword) ? styles.inputContainerError : null]}
              inputStyle={styles.input}
              renderErrorMessage={errorMessages.name ? true : false}
              placeholder={modifiedFields.newPassword ? '' : 'Nova senha'}
              label={modifiedFields.newPassword ? 'Nova senha:' : ' '}
              labelStyle={styles.label}
              leftIcon={
                <FontAwesome
                  name='lock'
                  style={styles.inputIcon}
                />
              }
              value={user.newPassword || ""}
              onChangeText={(newPassword) => {
                setModifiedFields({ ...modifiedFields, newPassword: true });
                setUser({ ...user, newPassword });
                validField('newPassword', newPassword, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
              }}
              onBlur={() => {
                if (modifiedFields.newPassword && !gotBlurred.newPassword)
                  setGotBlurred({ ...gotBlurred, newPassword: true });
              }}
              onSubmitEditing={() => {
                focusNextField('newPasswordConfirmation');
              }}
              blurOnSubmit={false}
              autoCapitalize='none'
              autoCorrect={false}
              errorStyle={styles.errorMessage}
              errorMessage={gotBlurred.newPassword ? errorMessages.newPassword : null}
            />
            <TouchableOpacity onPress={() => setHidePassword({ ...hidePassword, new: !hidePassword.new })} style={styles.showPasswordTouchable}>
              <FontAwesome
                name={hidePassword.new ? 'eye' : 'eye-slash'}
                style={styles.passwordIcon}
              />
            </TouchableOpacity>
            <FontAwesome
              name={errorMessages.newPassword ? (gotBlurred.newPassword ? 'times' : null) : (modifiedFields.newPassword ? 'check' : null)}
              style={[styles.validateIcon, errorMessages.newPassword ? styles.validateIconError : null, { marginTop: 40 }]}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <Input
              ref={input => { inputs['newPasswordConfirmation'] = input }}
              containerStyle={[styles.fieldContainer, { marginTop: 10 }]}
              secureTextEntry={hidePassword.newConfirmation}
              inputContainerStyle={[styles.inputContainer, (gotBlurred.newPasswordConfirmation && errorMessages.newPasswordConfirmation) ? styles.inputContainerError : null]}
              inputStyle={styles.input}
              renderErrorMessage={errorMessages.name ? true : false}
              placeholder={modifiedFields.newPasswordConfirmation ? '' : 'Confirmação da nova senha'}
              label={modifiedFields.newPasswordConfirmation ? 'Confirmação da nova senha:' : ' '}
              labelStyle={styles.label}
              leftIcon={
                <FontAwesome
                  name='lock'
                  style={styles.inputIcon}
                />
              }
              value={user.newPasswordConfirmation || ""}
              onChangeText={(newPasswordConfirmation) => {
                setModifiedFields({ ...modifiedFields, newPasswordConfirmation: true });
                setUser({ ...user, newPasswordConfirmation });
                validField('newPasswordConfirmation', newPasswordConfirmation, false, setUser, user, setErrorMessages, errorMessages, setGotBlurred, gotBlurred);
              }}
              onBlur={() => {
                if (modifiedFields.newPasswordConfirmation && !gotBlurred.newPasswordConfirmation)
                  setGotBlurred({ ...gotBlurred, newPasswordConfirmation: true });
              }}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              blurOnSubmit={false}
              autoCapitalize='none'
              autoCorrect={false}
              errorStyle={styles.errorMessage}
              errorMessage={gotBlurred.newPasswordConfirmation ? errorMessages.newPasswordConfirmation : null}
            />
            <TouchableOpacity onPress={() => setHidePassword({ ...hidePassword, newConfirmation: !hidePassword.newConfirmation })} style={styles.showPasswordTouchable}>
              <FontAwesome
                name={hidePassword.newConfirmation ? 'eye' : 'eye-slash'}
                style={styles.passwordIcon}
              />
            </TouchableOpacity>
            <FontAwesome
              name={errorMessages.newPasswordConfirmation ? (gotBlurred.newPasswordConfirmation ? 'times' : null) : (modifiedFields.newPasswordConfirmation ? 'check' : null)}
              style={[styles.validateIcon, errorMessages.newPasswordConfirmation ? styles.validateIconError : null, { marginTop: 40 }]}
            />
          </View>

        </KeyboardAwareScrollView>

        <Button
          buttonStyle={styles.saveChangesButton}
          title='Alterar Senha'
          loading={isChangingPassword}
          disabled={(!modifiedFields.password && !modifiedFields.newPassword && !modifiedFields.newPasswordConfirmation) || isChangingPassword}
          disabledStyle={styles.disabledSaveChangesButtonStyle}
          titleStyle={styles.saveChangesButtonTitle}
          onPress={() => {
            Keyboard.dismiss();
            changeUserPassword();
          }}
        />
        <OfficialFooter />
      </View>
    );
  };

  const [index, setIndex] = useState(0);
  const routes = [
    { key: 'personalData', title: 'Dados Gerais', icon: 'user' },
    { key: 'changePassword', title: 'Alterar Senha', icon: 'lock' }
  ];

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'personalData':
        return PersonalData();
      default:
        return ChangePassword();
    }
  };

  useEffect(() => {
    const getAccountDeletionRequestStatus = async () => {
      try {
        if (!Parse.User.current()) {
          throw 'No user logged in. This should not happen.';
        }
        const userId = Parse.User.current().get('cpf');
        const deletionRequested = await AsyncStorage.getItem(`acDeletionReq${userId}`);
        if (deletionRequested === 'requested') setAccountDeletionRequested(true);
      }
      catch (e) {
        console.log('error on getAccountDeletionRequestStatus', e);
      }
    };

    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action

      setHidePassword({ current: true, new: true, newConfirmation: true });
      setErrorMessages({});
      setGotBlurred({});
      setModifiedFields({});

      const currentUser = Parse.User.current()._toFullJSON();
      setUser({ ...currentUser, phone: phoneMask(currentUser.phone) });
      getAccountDeletionRequestStatus();

    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={styles.container}>
      <TabView
        navigationState={{
          index,
          routes
        }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: useWindowDimensions().width }}
        renderTabBar={props => (
          <TabBar
            {...props}
            pressColor={'#0992A5'}
            tabStyle={styles.tabBar}
            renderIcon={({ route, focused }) => (
              <FontAwesome
                name={route.icon}
                style={[styles.icon, focused ? { color: '#0992A5' } : null]}
              />
            )}
            renderLabel={({ route, focused }) => (
              <Text style={[styles.text, focused ? { color: '#0992A5' } : null]}>
                {route.title}
              </Text>
            )}
            indicatorStyle={styles.indicator}
            style={{ backgroundColor: colors.white }}
          />
        )}
      />
    </View>
  );

};

const styles = StyleSheet.create({
  passwordIcon: {
    width: 40,
    textAlign: 'center',
    fontSize: 20,
    color: '#0992A5',
    marginTop: 40
  },
  addressCheckIcon: {
    flex: 1,
    paddingTop: 25
  },
  label: {
    color: '#424242',
    paddingLeft: 4,
    fontWeight: 'normal',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  deleteAccountButton: {
    marginTop: 10,
    backgroundColor: '#EF6F6C'
  },
  saveChangesButton: {
    marginTop: 10,
    backgroundColor: '#7FCFAB'
  },
  disabledSaveChangesButtonStyle: {
    backgroundColor: '#7FCFAB',
    marginTop: 10
  },
  disabledDeleteAccountButtonStyle: {
    backgroundColor: '#EF6F6C',
    marginTop: 10
  },
  saveChangesButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase',
    fontSize: 16
  },
  searchAddressButton: {
    borderColor: '#7FCFAB',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: 'transparent',
    marginRight: 20
  },
  searchAddressButtonTitle: {
    color: '#7FCFAB',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  addressFieldContainer: {
    flex: 5,
    marginTop: 10
  },
  addressLabel: {
    fontSize: 16,
    color: '#0992A5',
    fontFamily: 'defaultFont',
    fontWeight: 'normal'
  },
  addressTitle: {
    flexDirection: 'row',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 5,
    alignItems: 'center'
  },
  addressTitleText: {
    fontSize: 16,
    color: '#0992A5',
    fontFamily: 'defaultFont'
  },
  fieldContainer: {
    width: '75%'
  },
  validateIcon: {
    color: '#7FCFAB',
    fontSize: 18,
    marginTop: 20
  },
  validateIconError: {
    color: '#EF6F6C',
  },
  inputContainerError: {
    borderBottomColor: '#EF6F6C'
  },
  errorMessage: {
    color: '#EF6F6C',
    fontFamily: 'defaultFont',
    fontSize: 14
  },
  scrollViewContainer: {
    flexGrow: 1,
    // justifyContent: 'center'
    justifyContent: 'flex-start'
  },
  inputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE'
  },
  input: {
    color: 'rgb(66, 66, 66)',
    fontFamily: 'defaultFont',
    fontSize: 16,
    outlineStyle: 'none'
  },
  inputIcon: {
    fontSize: 16,
    width: 20,
    paddingRight: 5,
    textAlign: 'center',
    color: '#0992A5'
  },
  inputHomeIcon: {
    fontSize: 18,
    width: 20,
    paddingRight: 5,
    textAlign: 'center',
    color: '#0992A5'
  },
  container: {
    flex: 1
  },
  tabBar: {
    flexDirection: 'row',
    margin: 2
  },
  indicator: {
    backgroundColor: '#0992A5',
    height: 4
  },
  text: {
    color: '#AAAAAA',
    margin: 2,
    fontFamily: 'defaultFontBold',
    fontSize: 17
  },
  icon: {
    fontSize: 18,
    color: '#AAAAAA',
    marginRight: 5
  }
});

export default AccountScreen;
