import React from 'react';
import { Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';

const MenuTrigger = () => {

  const navigation = useNavigation();
  const { colors } = useTheme();

  return (
    <TouchableOpacity onPress={() => navigation.openDrawer()} style={{flexDirection: 'row'}}>
      <FontAwesome
        name='bars'
        style={styles.icon}
      />
      <Text style={styles.text}>menu</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    color: '#F79256',
    fontSize: 16,
    marginRight: 3,
    marginLeft: 8,
    marginTop: 4,
    alignSelf: 'center'
  },
  text: {
    color: '#F79256',
    fontSize: 16,
    alignSelf: 'center',
    fontFamily: 'defaultFont',
  }
});

export default MenuTrigger;
