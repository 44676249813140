import React, { useState, useEffect } from 'react';
import { Image, Alert, ScrollView, View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Modal, Keyboard, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';
import constants from '../../constants/AppParams';
import useGetVehicles from '../hooks/useGetVehicles';
import NewVehicle from '../components/NewVehicle';
import VehicleList from '../components/VehicleList';
import { Searchbar } from 'react-native-paper';
import useMapBoxGeocoder from '../hooks/useMapBoxGeocoder';
import { useTheme } from '@react-navigation/native';
import { check, PERMISSIONS, RESULTS, request } from 'react-native-permissions';
import RNAndroidLocationEnabler from 'react-native-android-location-enabler';
import useBalanceAndPriceApi from '../hooks/useBalanceAndPriceApi';
import useTimezones from '../hooks/useTimezones';
import useParkingApi from '../hooks/useParkingApi';
import Parse from 'parse';
import useExpiredSession from '../hooks/useExpiredSession';
import Geolocation from 'react-native-geolocation-service';
import base64Images from '../../assets/images/base64/base64Images';
import OfficialFooter from '../components/OfficialFooter';

const ParkingOptionsScreen = ({ route, navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const { colors } = useTheme();

  const NOT_LOADED = 0;
  const LOADED_WITH_ERROR = -1;
  const LOADED = 1;

  const REGULAR_ACTIVATION = 1;

  const [getAddressDetails] = useMapBoxGeocoder();

  const [loadingState, setLoadingState] = useState(NOT_LOADED);
  const [activeOption, setActiveOption] = useState(null);
  // const [autoActiveOption, setAutoActiveOption] = useState(null);
  const [oneCard, setOneCard] = useState(true);
  const [vehicleToPark, setVehicleToPark] = useState(null);
  const [vehicleListModalVisible, setVehicleListModalVisible] = useState(false);
  const [addVehicleModalVisible, setAddVehicleModalVisible] = useState(false);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [possibleAddresses, setpossibleAddresses] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [queryFocused, setQueryFocused] = useState(false);
  const [searchQueryChanged, setSearchQueryChanged] = useState(false);
  const [lastUserLocation, setLastUserLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [searchAddressStatusCode, setSearchAddressStatusCode] = useState(6);
  const [getBalance] = useBalanceAndPriceApi();
  const [balance, setBalance] = useState({});
  const [isGoingForward, setIsGoingForward] = useState(false);
  const [findindParkingLocation, setFindindParkingLocation] = useState(false);
  const [parkingLocation, setParkingLocation] = useState({});
  const [showParkingOptions, setShowParkingOptions] = useState(false);
  const [loadingAddressRules, setLoadingAddressRules] = useState(false);
  const [possibleParkingLocations, setPossibleParkingLocations] = useState(null);
  const [chosenVehicleCategory, setChosenVehicleCategory] = useState(null);
  const [geoCoordinates, setGeoCoordinates] = useState({});
  const [getLocalTime] = useTimezones();

  const LICENSE_PLATE_SELECTION = 0;
  const ADDRESS_SELECTION = 1;
  const NO_ADDRESS = 2;
  const [step, setStep] = useState(LICENSE_PLATE_SELECTION);

  const CAR = 1;
  const MOTORCYCLE = 2;
  const TRUCK = 3;
  const MIXEDVEHICLE = 4;

  const CATEGORY_NAME = ['Automóvel', 'Motocicleta', 'Carga e Descarga', 'Misto (Automóvel e Carga)'];

  const [, getActiveTransactions] = useParkingApi();

  const MAX_VEHICLE_DESCRIPTION_LENGTH = constants.maxVehicleDescriptionLength;

  const [getVehicles, getFavoriteVehicle, setFavoriteVehicle] = useGetVehicles();

  const beautifulBalance = (p) => {
    const price = p.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  const [doNotChangeSelectedAddress, setDoNotChangeSelectedAddress] = useState(false);

  const getVehicleToPark = async (vehicleToParkId, useVehicleAttributes) => {

    // console.log('\n\n EEEEEEE getVehicleToPark', useVehicleAttributes);

    setLoadingState(NOT_LOADED);
    setParkingLocation({});
    setActiveOption(null);
    setShowParkingOptions(false);
    setLoadingAddressRules(false);
    setMapCenter(null);
    setSearchQueryChanged(false);
    setSelectedAddress(null);
    setDoNotChangeSelectedAddress(false);
    setGeoCoordinates({});

    try {

      const [balance, vehicles] = await Promise.all([getBalance(), getVehicles()]);

      if (balance && balance.value === 0) {
        setBalance(balance);
      }
      else {
        setBalance(balance._toFullJSON());
      }

      setVehicles(vehicles);

      if (vehicles && vehicles.length > 0) {

        let favoriteVehicle = null;
        let foundVehicleToPark = false;

        const favoriteVehicleId = await getFavoriteVehicle();

        const vehicleDescriptionsAreTheSame = (localDescription, serverDescription) => {
          if (!localDescription && !serverDescription) return true;
          else if (localDescription && localDescription.trim().length === 0 && !serverDescription) return true;
          else if (serverDescription && serverDescription.trim().length === 0 && !localDescription) return true;
          else if (localDescription === serverDescription) return true;
          else return false;
        };

        for (let i = 0; i < vehicles.length; i++) {

          if (useVehicleAttributes) {
            const vehicleOfInterest = vehicleToParkId;
            // console.log('\n\n got vehicle', vehicles[i].get('vehicle').get('licensePlate'), vehicles[i].get('userDescription'), vehicles[i].get('category'));

            if (vehicleOfInterest.license === vehicles[i].get('vehicle').get('licensePlate') &&
              vehicleDescriptionsAreTheSame(vehicleOfInterest.description, vehicles[i].get('userDescription')) &&
              vehicleOfInterest.category === vehicles[i].get('category')) {
              foundVehicleToPark = true;
              setVehicleToPark(vehicles[i]);
            }
          }
          else {
            if (vehicleToParkId && vehicleToParkId === vehicles[i].id) {
              foundVehicleToPark = true;
              setVehicleToPark(vehicles[i]);
            }
          }

          if (favoriteVehicleId === vehicles[i].id) {
            favoriteVehicle = vehicles[i];
          }
        }
        if (favoriteVehicle) {
          if (!foundVehicleToPark) setVehicleToPark(favoriteVehicle);
        }
        else {
          setFavoriteVehicle(vehicles[0].id);
          if (!foundVehicleToPark) setVehicleToPark(vehicles[0]);
        }

        setLoadingState(LOADED);
      }
      else {
        setAddVehicleModalVisible(true);
        setLoadingState(LOADED);
      }

    } catch (e) {
      console.log('error loading Parking Options Screen', e);
      checkIfSessionExpired(e);
      setLoadingState(LOADED_WITH_ERROR);
    }
  };

  const beautifulLicensePlate = (licensePlate) => {
    licensePlate = licensePlate.replace(/[^0-9a-z]/gi, '');

    let spacer = "";

    if (/^\d+$/.test(licensePlate[3])) {
      spacer = " ";
      if (/^\d+$/.test(licensePlate[4])) {
        spacer = "-"
      }
    }

    licensePlate = licensePlate.substring(0, 3) + spacer + licensePlate.substring(3, 7);
    return licensePlate;
  };

  const getParamFromVehicle = (vehicle, param) => {
    if (!vehicle) return;

    switch (param) {
      case 'licensePlate':
        let licensePlate = vehicle.get('vehicle').get('licensePlate').trim();
        return beautifulLicensePlate(licensePlate);

      case 'description':
        return (vehicle.get('userDescription') && vehicle.get('userDescription').trim().length) ?
          vehicle.get('userDescription').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)
          : ((vehicle.get('vehicle') && vehicle.get('vehicle').get('description').trim().length) ?
            vehicle.get('vehicle').get('description').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)
            : "");
      case 'category':
        return vehicle.get('category')
    }
    return;
  };

  const goToNextScreen = (customStart) => {
    navigation.navigate('ParkingConfirmation', {
      GEOCOORDINATES: geoCoordinates,
      VEHICLE_ID: vehicleToPark.id,
      VEHICLE_CATEGORY: chosenVehicleCategory,
      VEHICLE_CATEGORY_NAME: CATEGORY_NAME[chosenVehicleCategory - 1],
      PARKING_CARD_TIME: activeOption,
      // ONE_CARD: oneCard,
      VEHICLE_PLATE: getParamFromVehicle(vehicleToPark, 'licensePlate'),
      VEHICLE_DESCRIPTION: getParamFromVehicle(vehicleToPark, 'description'),
      ADDRESS: parkingLocation,
      // CAD_PRICE: cadPrice,
      EXTENSION: false,
      CUSTOM_START_DATE: customStart ? customStart.startsAt : null,
      SCHEDULED: customStart ? customStart.readableStartsAt : null
    });
  };

  const showError = (errorMsg) => {
    Alert.alert(
      'Oops!',
      errorMsg,
      [
        { text: 'OK' },
      ],
      { cancelable: true }
    );
  };

  const enableGPS = async () => {
    try {
      const data = await RNAndroidLocationEnabler.promptForEnableLocationIfNeeded({
        interval: 10000,
        fastInterval: 5000,
      });
      // setLocationModalVisible(true);
      // setDoNotChangeSelectedAddress(false);
      findParkingLocation();
    }
    catch (e) {
      console.log('error enabling gps', e);
      const errorMessage = 'É necessário ativar o serviço de localização do celular para iniciar o processo de ativação de créditos.\n\nCaso necessário, acesse as configurações do seu aparelho e ative o serviço de localização.';
      showError(errorMessage);
      // proceedToNextStage(NO_ADDRESS);
      checkIfSessionExpired(e);
    }
  };

  // const findServerParkingSector = async (latitude, longitude, vehicleCategory, returnServerLocationName, locationName, addressChosenFromList) => {
  const findServerParkingSector = async (latitude, longitude, addressChosenFromList, locationName) => {

    // console.log('entering findServerParkingSector', latitude, longitude, vehicleCategory, returnServerLocationName, locationName, addressChosenFromList);
    setLastUserLocation([longitude, latitude]);

    const params = {
      coords: { lat: latitude, lng: longitude },

      //debug
      // coords: { lat: -13.012280, lng:  -38.491707 },

      // locationName,
      // vehicleCategory,
      // returnServerLocationName,
      // doNotReturnDefaultParkingOptions: defaultParkingSectors ? true : false,
    }
    console.log('search params', params);

    const startManualAddressSelection = async () => {
      setLocationModalVisible(true);
      setSearchQuery('');
      setSearchAddressStatusCode(6);

      // const queryResult = await getAddressDetails(`${longitude},${latitude}`, true);
      // if (queryResult && queryResult[0] && queryResult[0].place_name) {
      //   setDoNotChangeSelectedAddress((currentState) => {
      //     if (!currentState) setSelectedAddress(queryResult[0]);
      //     return currentState;
      //   });
      //   setSearchQuery(queryResult[0].place_name);
      // }

    };

    try {
      console.log('WILL CALL getNearestLocationsParkingRulesValidToday', params);

      // if(params.lat && params.lng){
      //   params = {coords: params};
      // }
      // else{
      //   params = {locationIdTransalvador: params.locationIdTransalvador};
      // }


      const result = await Parse.Cloud.run('getNearestLocationsParkingRulesValidToday', params);
      console.log('got LOCATION results', result);

      const showMsg = (msg, shouldStartManualAddressSelection) => {
        Alert.alert(
          'Oops!',
          msg,
          [
            {
              text: 'OK',
              onPress: () => shouldStartManualAddressSelection ? startManualAddressSelection() : null
            },
          ],
          { cancelable: false }
        );
      };

      //load default parking sectors once
      // if (!defaultParkingSectors && result) {
      //   setDefaultParkingSectors(result.defaultLocations);
      //   // params.doNotReturnDefaultParkingOptions = false;
      //   // // params.returnServerLocationName = false;
      //   // // params.locationName = 'mockLocation';

      //   // console.log('222 WILL CALL getNearestLocationsParkingRulesValidToday', params);

      //   // const defaultParkingSectors = await Parse.Cloud.run('getNearestLocationsParkingRulesValidToday', params);
      //   // console.log('got defaultParkingSectors results', defaultParkingSectors);
      //   // setDefaultParkingSectors(defaultParkingSectors.defaultLocations);
      // }

      if (result && result.locationParkingRules && result.locationParkingRules.length > 0) {
        // setLocationModalVisible(false);

        console.log('ENTERED IF', result.locationParkingRules);
        setSearchAddressStatusCode(0);

        setPossibleParkingLocations(result.locationParkingRules);

        // setParkingLocation(result.locationParkingRules[0]);
        // autoSelectParkingPeriod(result.locationParkingRules[0]);

      }
      else if (result && !result.locationParkingRules && result[0]) {
        // setLocationModalVisible(false);

        console.log('ENTERED ELSE IF', result);

        setSearchAddressStatusCode(0);
        setPossibleParkingLocations(result);

        setParkingLocation(result[0]);
        autoSelectParkingPeriod(result[0]);
      }
      else {
        if (addressChosenFromList) {
          const msg = `Não foi encontrada correspondência de proximidade entre o logradouro escolhido (${locationName}) e uma área da Zona Azul.\n\nPor favor, tente digitar novamente.`;
          showMsg(msg);
          // setSearchAddressStatusCode(0);
          setSearchQuery('');
        }
        else {
          const msg = 'Não foram encontradas áreas da Zona Azul próximas à localização atual.\n\nPor favor, indique a seguir a localização do veículo.';
          showMsg(msg, true);
        }
      }
      setLoadingAddressRules(false);
      setFindindParkingLocation(false);
    }
    catch (e) {
      showError('Ocorreu um erro buscando áreas da Zona Azul nessa região. Por favor, tente novamente.');
      console.log('error on getNearestLocationsParkingRulesValidToday', e);
      setFindindParkingLocation(false);
      setLoadingAddressRules(false);
      checkIfSessionExpired(e);
    }
  };

  const findParkingLocation = async () => {
    setFindindParkingLocation(true);

    let vehicleCategory = getParamFromVehicle(vehicleToPark, 'category');

    let availableParkingOptions = null;

    try {
      availableParkingOptions = Parse.Config.current() ? Parse.Config.current().get('availableParkingOptions') : null;
    }
    catch (e) {
      console.log('error getting availableParkingOptions', e);
    }

    console.log('got availableParkingOptions', availableParkingOptions);

    setChosenVehicleCategory(vehicleCategory);

    Geolocation.getCurrentPosition(
      (position) => {
        if (position && position.coords && position.coords.latitude && position.coords.longitude) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setGeoCoordinates({ latitude, longitude });

          findServerParkingSector(latitude, longitude);
          // findServerParkingSector(-13.012708, -38.491401); //rua guedes cabral
        }
        else {
          showError('Ocorreu um erro ao tentar obter a localização atual. Por favor, tente novamente.');
          setFindindParkingLocation(false);
        }
      },
      (error) => {
        // See error code charts below.
        console.log(error.code, error.message);
        showError('Ocorreu um erro ao tentar obter a localização atual. Por favor, tente novamente.');
        setFindindParkingLocation(false);
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    );

  };

  const checkPermissions = async () => {
    const defaultErrorMessage = 'É necessário permitir que o App acesse a localização do celular para iniciar o processo de ativação de créditos.\n\nCaso necessário, acesse as configurações do seu aparelho para este App para fornecer essa permissão.';

    try {
      let locationPermission;

      if (Platform.OS === 'ios') locationPermission = await check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE);
      else locationPermission = await check(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION);

      switch (locationPermission) {
        case RESULTS.GRANTED:
          if (Platform.OS !== 'ios') enableGPS(); //enable Android location services - only necessary in Android
          else {
            findParkingLocation();
            // setLocationModalVisible(true);
            // setDoNotChangeSelectedAddress(false);
          }
          break;

        case RESULTS.UNAVAILABLE:
        case RESULTS.DENIED:
        case RESULTS.BLOCKED:
          let permissionRequestResult;

          if (Platform.OS === 'ios') {
            permissionRequestResult = await request(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE);
            if (permissionRequestResult === 'granted') {
              findParkingLocation();
              // setLocationModalVisible(true);
              // setDoNotChangeSelectedAddress(false);
            }
            else {
              showError(defaultErrorMessage);
              // proceedToNextStage(NO_ADDRESS);
            }
          }
          else {
            permissionRequestResult = await request(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION);

            if (permissionRequestResult === 'granted') enableGPS(); //enable Android location services - only necessary in Android
            else {
              showError(defaultErrorMessage);
              // proceedToNextStage(NO_ADDRESS);
            }
          }
          break;
        default:
          showError(defaultErrorMessage);
      }
    }
    catch (e) {
      console.log('Error checking permissions', e);
      showError('Ocorreu um erro ao permitir que o APP acesse a localização do celular via GPS. Por favor, tente novamente.')
      checkIfSessionExpired(e);
    }
  };

  const showAlert = async (msg, opt1, opt2) => {
    return new Promise((resolve, reject) => {
      Alert.alert(
        'Atenção',
        msg,
        [
          { text: opt1 ? opt1 : 'Não', onPress: () => resolve(false) },
          { text: opt2 ? opt2 : 'Sim', onPress: () => resolve(true) }
        ],
        { cancelable: false }
      )
    });
  };

  const getLocationDistance = (distance) => {
    if (!distance || distance === 0) {
      return '0m';
    } else if (distance < 1000) {
      return distance.toFixed(1).replace('.', ',') + 'm';
    } else {
      var distanceInKm = distance / 1000;
      return distanceInKm.toFixed(1).replace('.', ',') + 'km';
    }
  };

  const activationConditionsAllowProceed = async () => {
    console.log('entering activationConditionsAllowProceed');

    //3. "Neste local é permitida somente uma renovação, por isso não é possível ativar um novo Crédito. Tente novamente após transcorrida uma hora do término da sua ativação.";

    const MAX_CURRENT_ACTIVATIONS = 2;

    const showError = (msg) => {
      Alert.alert(
        'Oops!',
        msg,
        [
          { text: 'OK' },
        ],
        { cancelable: false },
      );
    };


    try {
      setIsGoingForward(true);
      let result = REGULAR_ACTIVATION;

      //scheduling
      console.log('\nthis is activeoption', activeOption);
      console.log('\nthis is parkingLocation', parkingLocation);

      if (activeOption.startsAt) {
        const timeNow = new Date().getTime();
        if (activeOption.startsAt > timeNow) { //activation will be scheduled
          const localStartTime = await getLocalTime(activeOption.startsAt);
          console.log('localStartTime', localStartTime);
          const scheduleMessage = `Este setor de estacionamento ficará disponível a partir das ${localStartTime.dateOne} de ${localStartTime.dateDay}. Ao avançar e confirmar, você estará agendando a utilização dos créditos para o horário de início do setor.\n\nDeseja continuar?`;
          const decidedToContinue = await showAlert(scheduleMessage);
          if (!decidedToContinue) {
            result = false;
            setIsGoingForward(false);
            return result;
          }
          else {
            result = {
              startsAt: activeOption.startsAt,
              readableStartsAt: `${localStartTime.dateOne} de ${localStartTime.dateDay}`
            }
          }
        }
      }

      const currentLicensePlate = getParamFromVehicle(vehicleToPark, 'licensePlate').replace(/[^0-9a-z]/gi, '');
      const activeTransactions = await getActiveTransactions();

      let currentLicensePlateInActiveTransactions = false;

      //license plate of desired activation is current in active transaction
      if (activeTransactions && activeTransactions.length > 0) {
        for (let i = 0; i < activeTransactions.length; i++) {
          if (currentLicensePlate === activeTransactions[i].license && parkingLocation.locationIdTransalvador === activeTransactions[i].locationIdTransalvador) {
            currentLicensePlateInActiveTransactions = true;

            if (activeTransactions[i].scheduled) {
              showError(`Existe um crédito agendado para esta placa neste local, conforme exibido na tela de "Ativações em Curso".\n\nÉ necessário aguardar o início da ativação para poder adicionar mais tempo.`);
            }
            else {
              showError(`Já existe um crédito ativo para esta placa neste local.\n\nPara adicionar mais tempo, utilize a opção "Renovar por + 1 período" na tela de "Ativações em Curso".\n\nNota: A opção "Renovar por + 1 período" aparecerá apenas no momento em que a renovação for possível.`);
            }

            result = false;
            setIsGoingForward(false);
            return result;
          }
          else if (currentLicensePlate === activeTransactions[i].license && parkingLocation.locationIdTransalvador !== activeTransactions[i].locationIdTransalvador) {
            currentLicensePlateInActiveTransactions = true;

            let creditUseMessage = null;
            if (activeOption.price === activeTransactions[i].totalPrice
              && activeOption.maxMinutes === activeTransactions[i].maxParkingMinutes
              && activeTransactions[i].startDate < Date.now() //previous transaction is currently active (not to start in the future)
              && (!activeOption.startsAt || activeOption.startsAt <= Date.now())) { //new transaction is not going to be scheduled
              creditUseMessage = `Já existe um crédito ativo para esse veículo com essa regra de estacionamento em ${activeTransactions[i].locationName}.\nVocê pode aproveitar esse crédito vigente para a vaga em ${parkingLocation.locationName}, sem realizar uma nova ativação.\n\nAo prosseguir, você vai usar um novo crédito e inutilizará o crédito já ativado.\nTem certeza de que deseja continuar?`;
            }
            else {
              creditUseMessage = `Ao ativar um novo crédito para a mesma placa (${beautifulLicensePlate(currentLicensePlate)}) em um novo local quando há uma ativação anterior ainda não concluída, a ativação anterior é inutilizada e somente a nova é considerada.\n\nTem certeza de que deseja prosseguir?`;
            }
            const decidedToContinue = await showAlert(creditUseMessage);
            if (!decidedToContinue) {
              result = false;
              setIsGoingForward(false);
              return result;
            }
          }
        }
      }

      //max activations at the same time
      if (activeTransactions.length >= MAX_CURRENT_ACTIVATIONS && !currentLicensePlateInActiveTransactions) {
        showError(`É permitida a ativação para apenas ${MAX_CURRENT_ACTIVATIONS} placas diferentes simultaneamente por usuário.`);
        result = false;
      }

      setIsGoingForward(false);
      return result;
    }
    catch (e) {
      console.log('error proceeding with activation', e);
      showError('Ocorreu um erro ao verificar as informações para continuar. Por favor, tente novamente.');
      setIsGoingForward(false);
      checkIfSessionExpired(e);
      return false;
    }
  };

  const autoSelectParkingPeriod = (item) => {
    console.log('autoSelectParkingPeriod called for', item, item.parkingRules, item.parkingRules.length, item.parkingRules[0]);
    if (item && item.parkingRules && item.parkingRules.length === 1) {
      setActiveOption(item.parkingRules[0]);
    }
    else {
      setActiveOption(null);
    }
  };

  const proceedToNextStage = async (previousStage, addressChosenFromList) => {

    if (previousStage == NO_ADDRESS) {
      setSelectedAddress(null);
      // setAutoActiveOption(null);
      setDoNotChangeSelectedAddress(true);
    }

    if (previousStage === LICENSE_PLATE_SELECTION && !parkingLocation.areaName && !showParkingOptions) {
      checkPermissions();
    }
    else if (previousStage === ADDRESS_SELECTION && addressChosenFromList) {
      // setLocationModalVisible(false);
      setLoadingAddressRules(true);
      setParkingLocation({});
      setActiveOption(null);

      // if (!selectedAddress || !selectedAddress.center) {
      //   throw 'error getting user location';
      // }
      // if (!addressChosenFromList) {
      //   throw 'error getting user location';
      // }

      // const requestOptions = {
      //   longitude: selectedAddress.center[0],
      //   latitude: selectedAddress.center[1]
      // };
      const requestOptions = {
        longitude: addressChosenFromList.center[0],
        latitude: addressChosenFromList.center[1]
      };


      // findServerParkingSector(requestOptions.latitude, requestOptions.longitude, chosenVehicleCategory, false, addressChosenFromList.text_pt, true);
      findServerParkingSector(requestOptions.latitude, requestOptions.longitude, true, addressChosenFromList.text_pt);

    }
    else if (previousStage === NO_ADDRESS) {
      setLocationModalVisible(false);
      setShowParkingOptions(true);
      // setAutoActiveOption(null);
    }
    else {
      if (!activeOption) {
        if (parkingLocation.parkingRules && parkingLocation.parkingRules.length > 0) showError('É necessário escolher o tempo de estacionamento para prosseguir.');
        else showError('Não há operação para esta categoria de veículo neste setor.\n\nPor favor, escolha outro veículo, outro setor ou altere a categoria do veículo.');
        return;
      }

      const proceed = await activationConditionsAllowProceed();

      if (!proceed) return;

      if (proceed !== REGULAR_ACTIVATION) {
        goToNextScreen(proceed);
      }
      else {
        goToNextScreen();
      }

    }
  };

  const onChangeSearch = async (query) => {
    if (!searchQueryChanged) setSearchQueryChanged(true);
    setSearchQuery(query);

    try {
      if (query && query.trim().length > 3) {
        setSearchAddressStatusCode(1);
        const queryResult = await getAddressDetails(query, false, lastUserLocation);

        if (queryResult && queryResult.length === 0) setSearchAddressStatusCode(2);
        else setSearchAddressStatusCode(3);
        setpossibleAddresses(queryResult);
      }
      else {
        setSearchAddressStatusCode(4);
        setpossibleAddresses([]);
      }
    }
    catch (e) {
      console.log('error searching address', e);
      setpossibleAddresses([]);
      setSearchAddressStatusCode(5);
      checkIfSessionExpired(e);
    }

  }

  // const updatedUserLocation = async (location) => {

  //   if (!searchQueryChanged) {
  //     try {
  //       if (location && location.coords && location.coords.latitude && location.coords.longitude) {

  //         setLastUserLocation([location.coords.longitude, location.coords.latitude]);
  //         const queryResult = await getAddressDetails(`${location.coords.longitude},${location.coords.latitude}`, true);

  //         if (queryResult && queryResult[0] && queryResult[0].place_name) {
  //           setDoNotChangeSelectedAddress((currentState) => {
  //             if (!currentState) setSelectedAddress(queryResult[0]);
  //             return currentState;
  //           });
  //           setSearchQuery(queryResult[0].place_name);
  //         }
  //         else {
  //           throw 'error getting address from user location';
  //         }
  //       }
  //       else {
  //         throw 'missing params to search for users location address';
  //       }
  //     }
  //     catch (e) {
  //       console.log('error getting address from user location', e);
  //       checkIfSessionExpired(e);
  //     }
  //   }
  // };

  const transformMinutesToParkingHours = (minutes) => {
    return minutes / 60 + 'h'
  }

  useEffect(() => {
    if (route && route.params) {
      const vehicleId = route.params.vehicleId;
      const useVehicleAttributes = route.params.useVehicleAttributes;
      getVehicleToPark(vehicleId, useVehicleAttributes);
    }
  }, [route.params]);

  useEffect(() => {
    setParkingLocation({});
    setActiveOption(null);
    // setDefaultParkingSectors(null);
  }, [vehicleToPark]);

  return (
    <View style={{ flex: 1 }}>

      {/* Loading View */}
      {loadingState === NOT_LOADED ?
        <View style={styles.centerView}>
          <ActivityIndicator size={50} color='#284D64' />
          <Text style={styles.loadingText}>Carregando informações...</Text>
        </View>
        : null}

      {/* Loaded With Error View */}
      {loadingState === LOADED_WITH_ERROR ?
        <View style={styles.centerView}>
          <View style={styles.centerView}>
            <Text style={styles.loadingErrorText}>Ocorreu um erro ao carregar as informações.</Text>
          </View>
          <Button
            buttonStyle={[styles.errorButton, (loadingState !== LOADED_WITH_ERROR ? { display: 'none' } : null)]}
            title='Tentar Novamente'
            titleStyle={styles.buttonTitle}
            onPress={() => getVehicleToPark()}
          />
        </View>
        : null}

      {/* Regular View */}
      {loadingState === LOADED && vehicleToPark ?
        <ScrollView>
          <View style={styles.balanceAndCartContainer}>
            <View style={styles.balanceContainer}>
              <Text style={styles.balance}>Saldo: R$ {beautifulBalance(balance.value)}</Text>
            </View>

            <TouchableOpacity
              onPress={() => navigation.navigate('PurchaseCreditsStack', {
                screen: 'PurchaseCredits',
                params: { creditCardId: null }
              })}
              style={styles.cartIconContainer(colors)}>
              <FontAwesome
                name='shopping-cart'
                style={styles.cartIcon(colors)}
              />
            </TouchableOpacity>
          </View>

          <View style={styles.fieldContainer}>
            <View style={styles.fieldTitleBorder}>
              <Text style={styles.fieldTitle}>Selecione a placa do veículo:</Text>
            </View>
            <View>
              <View style={styles.vehicleDescriptionAndButtonContainer}>
                <View style={styles.vehicleDescriptionContainer}>
                  <FontAwesome
                    name={getParamFromVehicle(vehicleToPark, 'category') === 1 ? 'car' : getParamFromVehicle(vehicleToPark, 'category') === 2 ? 'motorcycle' : getParamFromVehicle(vehicleToPark, 'category') === 3 ? 'truck' : 'road'}
                    style={styles.vehicleIcon}
                  />
                  <View style={styles.vehicleDescriptionTextContainer}>
                    {getParamFromVehicle(vehicleToPark, 'description').trim().length ?
                      <Text style={styles.vehicleDescription}>
                        {getParamFromVehicle(vehicleToPark, 'description')}
                      </Text>
                      : null}
                    <Text style={styles.vehiclePlate}>
                      {getParamFromVehicle(vehicleToPark, 'licensePlate')}
                    </Text>
                  </View>
                </View>
                <View style={styles.swapVehicleButtonContainer}>
                  <Button
                    buttonStyle={styles.swapVehicleButton}
                    titleStyle={styles.swapVehicleButtonTitle}
                    onPress={() => {
                      if (vehicles.length < 2) setAddVehicleModalVisible(true);
                      else setVehicleListModalVisible(true)
                    }}
                    icon={
                      <FontAwesome
                        name='exchange'
                        style={styles.swapVehicleButtonIcon}
                      />
                    }
                    title='Trocar' />
                </View>
              </View>
            </View>
          </View>

          {parkingLocation.areaName ?
            <View style={styles.fieldContainer}>
              <View style={styles.fieldTitleBorder}>
                <Text style={styles.fieldTitle}>Selecione o local de estacionamento:</Text>
              </View>
              <View>
                <View style={styles.vehicleDescriptionAndButtonContainer}>
                  <View style={styles.vehicleDescriptionContainer}>
                    <View>
                      <FontAwesome
                        name={'map-marker'}
                        style={[styles.vehicleIcon, { fontSize: 20 }]}
                      />
                    </View>
                    <View style={styles.vehicleDescriptionTextContainer}>
                      {parkingLocation.locationName ?
                        <View style={{ alignItems: 'center' }}>
                          <Text style={styles.vehicleDescription}>
                            {parkingLocation.areaName}
                          </Text>
                          <Text style={[styles.vehiclePlate, { textAlign: 'center' }]}>
                            {parkingLocation.locationName}
                          </Text>
                        </View>
                        :
                        <Text style={styles.vehiclePlate}>
                          {parkingLocation.areaName}
                        </Text>
                      }
                    </View>
                  </View>
                  <View style={styles.swapVehicleButtonContainer}>
                    <Button
                      buttonStyle={styles.swapVehicleButton}
                      titleStyle={styles.swapVehicleButtonTitle}
                      onPress={() => {
                        // checkPermissions();
                        // setParkingLocation({});
                        // setShowParkingOptions(false);
                        // setLoadingAddressRules(false);
                        setSearchQuery('');
                        setSearchAddressStatusCode(0);
                        setLocationModalVisible(true);
                      }}
                      icon={
                        <FontAwesome
                          name='exchange'
                          style={styles.swapVehicleButtonIcon}
                        />
                      }
                      title='Outro' />
                  </View>
                </View>
              </View>
            </View>
            : null}



          {parkingLocation.areaName ?
            <View>
              <View style={styles.fieldContainer}>
                <View style={styles.fieldTitleBorder}>
                  <Text style={styles.fieldTitle}>Selecione o tempo de estacionamento:</Text>
                </View>
                {parkingLocation.parkingRules && parkingLocation.parkingRules.length > 0 ?
                  <View style={styles.optionsBlock}>
                    {parkingLocation.parkingRules.map((item, index) => (
                      <TouchableOpacity
                        key={index}
                        onPress={() => setActiveOption(item)}
                        style={activeOption && activeOption.ruleId === item.ruleId ? styles.touchableOptionActive : styles.touchableOption}
                      >
                        <Text
                          style={activeOption && activeOption.ruleId === item.ruleId ? [styles.touchableOptionText, styles.touchableOptionTextActive] : styles.touchableOptionText}
                        >
                          {item.ruleName}
                        </Text>
                        <Text
                          style={activeOption && activeOption.ruleId === item.ruleId ? [styles.touchableOptionText, styles.touchableOptionParkingMinutesActive] : [styles.touchableOptionText, styles.touchableOptionParkingMinutes]}
                        >
                          {transformMinutesToParkingHours(item.maxMinutes)}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                  :
                  <View style={{ margin: 10 }}>
                    <Text style={styles.noParkingRuleText(colors)}>Não há operação para esta categoria de veículo neste setor.</Text>
                  </View>
                }
              </View>
            </View>
            : null}

          {!loadingAddressRules ?
            <Button
              buttonStyle={[styles.proceedButton(colors), { marginTop: 20 }]}
              title={isGoingForward ?
                <ActivityIndicator size={25} color='#FFFFFF' />
                : findindParkingLocation ?
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={[styles.defaultProceedButtonTitle]}>Checando Local...</Text>
                    <ActivityIndicator size={25} color='#FFFFFF' />
                  </View> :
                  <View>
                    <Text style={[styles.defaultProceedButtonTitle]}>Avançar</Text>
                  </View>
              }
              disabled={isGoingForward || findindParkingLocation}
              disabledTitleStyle={{ color: '#FFFFFF' }}
              disabledStyle={[styles.disabledProceedButtonStyle, , { marginTop: 20 }]}
              titleStyle={styles.proceedButtonTitle}
              onPress={() => proceedToNextStage(LICENSE_PLATE_SELECTION)} />
            : null}

        </ScrollView>
        : null}

      {/* Vehicle List Modal */}
      <Modal
        animationType="slide"
        transparent={false}
        visible={vehicleListModalVisible}
        onRequestClose={() => setVehicleListModalVisible(false)}
      >
        <SafeAreaView>
          <View style={styles.modalHeader}>
            <View style={{ flex: 1 }} />
            <Text style={styles.cardModalTitle}>Meus Veículos</Text>
            <TouchableOpacity
              onPress={() => setVehicleListModalVisible(false)}
              style={styles.closeModalTouchable}>
              <FontAwesome
                name='times'
                style={styles.closeModalIcon}
              />
            </TouchableOpacity>
          </View>
        </SafeAreaView>

        <VehicleList
          fromState='parkingOptions'
          setVehicleToPark={setVehicleToPark}
          setVehicleListModalVisible={setVehicleListModalVisible}
          modalView={true}
        />
      </Modal>

      <NewVehicle
        addVehicleModalVisible={addVehicleModalVisible}
        setAddVehicleModalVisible={setAddVehicleModalVisible}
        setVehicleToPark={setVehicleToPark}
        fromState='parkingOptions'
        setUserVehicles={setVehicles}
        vehicleToPark={vehicleToPark}
        navigation={navigation}
      />

      {/* Map Modal */}
      <Modal
        animationType="slide"
        transparent={false}
        visible={locationModalVisible}
        onRequestClose={() => {
          setLocationModalVisible(false);
          setSearchQuery('');
          setSearchAddressStatusCode(0);
        }}
      >
        <SafeAreaView>
          <View style={styles.modalHeader}>
            <View style={{ flex: 1 }} />
            <Text style={styles.cardModalTitle}>Confirmar Localização</Text>
            <TouchableOpacity
              onPress={() => {
                setLocationModalVisible(false);
                setSearchQuery('');
                setSearchAddressStatusCode(0);
              }}
              style={styles.closeModalTouchable}>
              <FontAwesome
                name='times'
                style={styles.closeModalIcon}
              />
            </TouchableOpacity>
          </View>
        </SafeAreaView>

        {searchAddressStatusCode ?
          <View style={[styles.fieldTitleBorder, { marginTop: 10 }]}>
            <Text style={styles.explanationText(colors)}>Digite abaixo o endereço e escolha uma opção de local da lista que será exibida.</Text>
          </View>
          : null}

        <Searchbar
          placeholder='Digite um endereço...'
          onChangeText={onChangeSearch}
          value={searchQuery}
          inputStyle={styles.searchBarText(colors)}
          style={styles.searchBarContainer(colors)}
          selection={queryFocused ? null : { start: 0, end: 0 }}
          onFocus={() => setQueryFocused(true)}
          blurOnSubmit={false}
          onSubmitEditing={() => Keyboard.dismiss()}
        />

        <SafeAreaView style={{ flex: 1 }}>

          {searchAddressStatusCode && searchAddressStatusCode !== 3 ?
            <View style={styles.informationView(colors)}>
              {searchAddressStatusCode === 1 ?
                <ActivityIndicator size={30} color={colors.lightBlue} />
                : null}

              {searchAddressStatusCode === 2 ?
                <Text style={styles.informationViewText(colors)}>Não há endereços correspondentes ao texto digitado</Text>
                : null}

              {searchAddressStatusCode === 4 ?
                <Text style={styles.informationViewText(colors)}>Digite ao menos 4 letras / números para buscar endereços</Text>
                : null}

              {searchAddressStatusCode === 5 ?
                <Text style={styles.informationViewText(colors)}>Ocorreu um erro ao buscar pelo endereço. Continue digitando para tentar novamente.</Text>
                : null}
            </View>
            : null}

          {searchAddressStatusCode !== 0 && possibleAddresses.length > 0 ?
            <ScrollView style={styles.searchResultContainer(colors)} keyboardShouldPersistTaps={'handled'}
            >
              {possibleAddresses.map((item, index) => (
                <View style={styles.addressOptionMainContainer(colors)} key={item.center}>
                  <FontAwesome
                    name='map-marker'
                    style={styles.mapMarkerForAddressList(colors)}
                  />
                  <View style={styles.addressOptionContainer}>
                    <TouchableOpacity onPress={() => {
                      setSelectedAddress(item);
                      setSearchQuery(item.place_name);
                      setQueryFocused(false);
                      Keyboard.dismiss();
                      setpossibleAddresses([]);
                      setMapCenter(item.center);
                      proceedToNextStage(ADDRESS_SELECTION, item);
                    }}>
                      <Text style={styles.addressOption(colors)}>{item.place_name}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </ScrollView>
            : null}

          {loadingAddressRules ?
            <View style={{ marginTop: 20 }}>
              <ActivityIndicator size={50} color='#284D64' />
            </View>
            : null}

          {!searchAddressStatusCode ?
            <>
              <View style={[styles.fieldTitleBorder, { marginTop: 10 }]}>
                <Text style={styles.explanationText(colors)}>
                  {searchQuery.length === 0 ? 'Ou selecione' : 'Selecione'} uma das áreas próximas com Zona Azul:
                </Text>
              </View>

              <ScrollView>
                {possibleParkingLocations && possibleParkingLocations.map((item, index) => (
                  <View key={item.locationIdTransalvador}>
                    <TouchableOpacity
                      onPress={() => {
                        autoSelectParkingPeriod(item);
                        setParkingLocation(item);
                        setLocationModalVisible(false);
                      }}
                      style={styles.defaultParkingSectorContainer(colors)}
                    >
                      <FontAwesome
                        name='map-marker'
                        style={styles.mapMarkerForAddressList(colors)}
                      />

                      <View style={styles.parkingTextOptionContainer}>
                        <View>
                          <Text style={styles.boldParkingSectorText(colors)}>{item.locationDescription}</Text>
                          <View>
                            <View style={styles.parkingOptionDistanceContainer}>
                              <Text style={styles.defaultParkingSectorText(colors)}>Distância: {getLocationDistance(item.distanceTo)} - <Text style={{ textTransform: 'uppercase' }}>{item.areaName}</Text></Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                ))}
              </ScrollView>
            </>
            : null}

        </SafeAreaView>
      </Modal>
      <OfficialFooter />

    </View>
  );
};

const styles = StyleSheet.create({
  parkingOptionDistanceContainer: {
    alignSelf: 'flex-end',
    marginTop: 5
  },
  parkingTextOptionContainer: {
    flex: 1,
    marginLeft: 10
  },
  noParkingRuleText: colors => ({
    marginLeft: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: colors.blackText,
  }),
  boldParkingSectorText: colors => ({
    fontFamily: 'defaultFontBold',
    color: colors.darkBlue,
    fontSize: 16,
    textTransform: 'capitalize'
  }),
  defaultParkingSectorText: colors => ({
    fontFamily: 'defaultFont',
    color: colors.blackText,
    fontSize: 16,
    textTransform: 'capitalize'
  }),
  defaultParkingSectorContainer: colors => ({
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.darkGray,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    padding: 5
  }),
  explanationText: colors => ({
    padding: 10,
    color: colors.darkBlue,
    fontFamily: 'defaultFont',
    fontSize: 16,
  }),
  explanationTextContainer: {
    margin: 10,
    backgroundColor: '#EEEEEE',
  },
  balanceAndCartContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cartIconContainer: colors => ({
    backgroundColor: colors.red,
    padding: 5,
    marginTop: 10,
    marginRight: 15,
    borderRadius: 5
  }),
  cartIcon: colors => ({
    color: colors.white,
    fontSize: 20,
    textAlign: 'center'
  }),
  searchBarContainer: colors => ({
    marginTop: 10,
    paddingRight: 12,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray
  }),
  informationView: colors => ({
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: colors.white,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15
  }),
  informationViewText: colors => ({
    fontSize: 16,
    color: colors.blackText,
    fontFamily: 'defaultFont'
  }),
  mapMapMarker: colors => ({
    fontSize: 50,
    color: colors.lightBlue
  }),
  searchBarText: colors => ({
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: colors.blackText
  }),
  mapMarkerForAddressList: colors => ({
    fontSize: 20,
    color: colors.lightBlue,
    alignSelf: 'center',
    marginLeft: 5
  }),
  addressOption: colors => ({
    fontSize: 14,
    fontFamily: 'defaultFont',
    color: colors.blackText,
  }),
  addressOptionMainContainer: colors => ({
    marginTop: 5,
    marginBottom: 5,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray,
    flexDirection: 'row'
  }),
  addressOptionContainer: {
    marginLeft: 10,
    marginRight: 50
  },
  searchResultContainer: colors => ({
    position: 'absolute',
    zIndex: 1,
    backgroundColor: colors.white,
    width: '100%',
    height: 200
  }),
  container: {
    height: '100%',
    width: '100%',
  },
  map: {
    flex: 1
  },
  modalHeader: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  cardModalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont',
    textAlign: 'center',
    flex: 3
  },
  closeModalTouchable: {
    justifyContent: 'flex-end',
    flex: 1
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    textAlign: 'center'
  },
  modalContent: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  loadingErrorText: {
    fontFamily: 'defaultFont',
    marginTop: 10,
    color: '#424242',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center'
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  centerView: {
    justifyContent: 'center',
    flex: 1
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  optionsWithMap: {
    flex: 1
  },
  proceedButton: colors => ({
    backgroundColor: colors.green
  }),
  cancelButton: colors => ({
    backgroundColor: colors.red
  }),
  disabledProceedButtonStyle: {
    backgroundColor: '#7FCFAB',
    marginTop: 15
  },
  defaultProceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    fontSize: 16
  },
  proceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  noMarginBottom: {
    marginBottom: 0
  },
  optionsBlock: {
    flexDirection: 'row',
    marginRight: 15,
    marginLeft: 15,
    flexWrap: 'wrap'
  },
  touchableOption: {
    flex: 1,
    marginBottom: 10,
    marginTop: 10,
    minWidth: 100
  },
  touchableOptionActive: {
    flex: 1,
    borderBottomColor: '#0992A5',
    borderBottomWidth: 4,
    marginBottom: 6,
    marginTop: 10,
    minWidth: 100
  },
  touchableOptionText: {
    textTransform: 'uppercase',
    color: '#AAAAAA',
    textAlign: 'center',
    fontFamily: 'defaultFont',
    fontSize: 14,
  },
  touchableOptionParkingMinutes: {
    fontSize: 20,
    fontFamily: 'defaultFontBold',
    marginBottom: 10
  },
  touchableOptionParkingMinutesActive: {
    fontSize: 20,
    fontFamily: 'defaultFontBold',
    color: '#0992A5',
    marginBottom: 10
  },
  touchableOptionTextActive: {
    color: '#0992A5'
  },
  fieldContainer: {
    marginTop: 10
  },
  vehicleDescriptionAndButtonContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  vehicleDescriptionContainer: {
    marginLeft: 15,
    paddingBottom: 10,
    flexDirection: 'row',
    flex: 3,
    borderBottomColor: '#0992A5',
    borderBottomWidth: 4,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  vehicleDescriptionTextContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  swapVehicleButtonContainer: {
    flex: 1,
    marginLeft: 5,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  swapVehicleButton: {
    borderRadius: 0,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: 'transparent',
    borderColor: '#F79256',
    borderWidth: 1
  },
  swapVehicleButtonTitle: {
    color: '#F79256',
    fontFamily: 'defaultFont',
  },
  swapVehicleButtonIcon: {
    color: '#F79256',
    marginRight: 5
  },
  vehicleIcon: {
    color: '#0992A5',
    fontSize: 16,
    marginRight: 15
  },
  vehicleDescription: {
    color: '#0992A5',
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'defaultFont'
  },
  vehiclePlate: {
    color: '#0992A5',
    fontSize: 20,
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase',
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 18,
    fontFamily: 'defaultFontBold'
  },
  fieldTitleBorder: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginLeft: 15
  },
  balanceContainer: {
    backgroundColor: '#EEEEEE',
    padding: 5,
    marginLeft: 15,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'flex-start'
  },
  balance: {
    color: '#284D64',
    fontFamily: 'defaultFontBold'
  }
});

export default ParkingOptionsScreen;
