import React from 'react';
import { ScrollView, Text, StyleSheet, View, Linking } from 'react-native';
import OfficialFooter from '../components/OfficialFooter';
import appParams from '../../constants/AppParams';

const FAQScreen = () => {

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={{ margin: 15, marginTop: 0 }}>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Ativação de Créditos:</Text>
          </View>
          <Text style={styles.questionText}>- Como ativar o Crédito Digital?</Text>
          <Text style={styles.simpleText}>A ativação é feita na opção "Estacionar Agora" do aplicativo. Insira a placa, confirme/defina a localização do automóvel a ser estacionado na Zona Azul, confira/defina a regra de estacionamento e toque no botão “Avançar”.</Text>
          <Text style={styles.simpleText}>Se houver saldo suficiente para a transação, basta confirmar os dados na tela de confirmação e finalizar o processo.</Text>
          <Text style={styles.simpleText}>Se não houver saldo, abaixo dos dados da transação serão disponibilizados os campos para inserção dos dados para pagamento.</Text>
          <Text style={styles.simpleText}>Após a conclusão da ativação, um e-mail com um comprovante da transação será enviado.</Text>

          <Text style={styles.questionText}>- Posso estender o tempo de estacionamento?</Text>
          <Text style={styles.simpleText}>Sim. É possível estender o tempo até um minuto antes de o tempo expirar. Porém, para regras que possuem apenas a regra de curta duração, é possível estender apenas uma vez para o mesmo logradouro. Para estender o tempo utilize o botão "Renovar por + 1 período" no cronômetro.</Text>

          <Text style={styles.questionText}>- Não utilizei o tempo total. Como faço para cancelar o tempo restante?</Text>
          <Text style={styles.simpleText}>Não é permitida a cobrança fracionada ou pausar o tempo da ativação. Entretanto, é possível cancelar a utilizaçao do crédito caso não ultrapasse os 15 primeiros minutos. O crédito é devolvido ao usuário. Mas atenção: Caso o usuário volte a ativar a utilização de uma vaga no mesmo logradouro antes de completar 2 (duas) horas do cancelamento, ele não poderá utilizar os 15 minutos de tolerância, devendo nesse caso ser cobrado o valor do uso normalmente.</Text>

          <Text style={styles.questionText}>- Se ficar sem internet, como ativar um Crédito Digital?</Text>
          <Text style={styles.simpleText}>Na impossibilidade de fazer ativações a partir de seu aparelho, entre em contato conosco pelo telefone <Text onPress={() => { Linking.openURL(`tel:${appParams.contactPhoneToCall}`); }} style={{ color: '#0992A5' }}>{appParams.contactPhone}</Text> para realizarmos a transação, caso tenha saldo.</Text>
          <Text style={styles.simpleText}>Ou também se pode ativar um Crédito Digital mandando SMS para o número 29092 com os seguintes termos:</Text>
          <Text style={styles.simpleText}>ATIVECARTAO TESTE (placa do veículo) (CPF/CNPJ) (horas de permanência) (preço)</Text>
          <Text style={styles.simpleText}>Importante: preste atenção nos espaços em branco.</Text>
          <Text style={styles.simpleText}>Exemplo:</Text>
          <Text style={styles.simpleText}>ATIVECARTAO TESTE AAA1111 12345678912 2h 3</Text>

          <Text style={styles.questionText}>- Posso utilizar o aplicativo em qualquer vaga do Estacionamento Rotativo?</Text>
          <Text style={styles.simpleText}>Com o aplicativo é possível utilizar quaisquer vagas do Estacionamento Rotativo dentro do município de Salvador, sempre respeitando as regras especificadas na placa de sinalização da via.</Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Compra de Créditos:</Text>
          </View>
          <Text style={styles.questionText}>- Como comprar Crédito Digital?</Text>
          <Text style={styles.simpleText}>A aquisição de Créditos Digitais pode ser feita por meio do menu "Comprar Créditos".</Text>
          <Text style={styles.simpleText}>É possível pagar utilizando Cartão de crédito, Pix e Boleto bancário.</Text>
          <Text style={styles.simpleText}>Atenção: Para estacionar no Estacionamento Rotativo é preciso ATIVAR os Créditos Digitais comprados.</Text>

          <Text style={styles.questionText}>- Meu saldo expira?</Text>
          <Text style={styles.simpleText}>Não.</Text>

          <Text style={styles.questionText}>- Como cadastrar/excluir os dados do cartão de crédito?</Text>
          <Text style={styles.simpleText}>Para cadastrar um novo cartão de crédito no App, faça uma compra utilizando os dados do cartão a cadastrar e deixe marcada a opção "Salvar Cartão".</Text>
          <Text style={styles.simpleText}>A visualização e exclusão de cartões de crédito está disponível na opção "Cartões de Crédito" do menu.</Text>
          <Text style={styles.simpleText}>Caso queira excluir um cartão, toque no ícone da lixeira localizado à direita do cartão desejado e confirme a exclusão.</Text>

          <Text style={styles.questionText}>- Excluí meu comprovante. Como solicito a segunda via?</Text>
          <Text style={styles.simpleText}>A segunda via dos recibos pode ser solicitada diretamente no aplicativo. Basta acessar a opção "Histórico de Uso" e filtrar o período desejado. Selecione os recibos desejados e depois toque no botão para reenviar. A segunda via será enviada ao e-mail cadastrado.</Text>
          <Text style={styles.simpleText}>Se não estiver recebendo os comprovantes, confirme se o e-mail cadastrado está correto na opção "Minha Conta" do menu e verifique o Lixo Eletrônico/Spam.</Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Fiscalização:</Text>
          </View>
          <Text style={styles.questionText}>- Como é feita a fiscalização do Estacionamento Rotativo?</Text>
          <Text style={styles.simpleText}>Os agentes de fiscalização possuem um dispositivo móvel para consultar quais veículos estão regulares, ou seja, têm Crédito Digital ativo. Não é necessário deixar nenhum comprovante no veículo.</Text>

          <Text style={styles.questionText}>- Em quais cidades posso utilizar este aplicativo para estacionar?</Text>
          <Text style={styles.simpleText}>O aplicativo permite o estacionamento no Rotativo apenas no munícipio de Salvador.</Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Senha:</Text>
          </View>
          <Text style={styles.questionText}>- Esqueci minha senha. Como faço para recuperar?</Text>
          <Text style={styles.simpleText}>A recuperação da senha pode ser feita diretamente pelo aplicativo através da opção “Esqueceu a senha?” na tela de acesso.</Text>

          <Text style={styles.questionText}>- Como alterar minha senha?</Text>
          <Text style={styles.simpleText}>A alteração de senha é feita via aplicativo na opção "Minha Conta" do menu, tocando no aba "Alterar Senha”.</Text>
        </View>

        <View style={styles.fieldContainer}>
          <View style={styles.fieldTitleContainer}>
            <Text style={styles.fieldTitle}>Placas e Alarmes:</Text>
          </View>
          <Text style={styles.questionText}>- Como cadastrar/excluir uma placa?</Text>
          <Text style={styles.simpleText}>O cadastro e exclusão de placas está disponível na opção “Meus Veículos” do menu. Para cadastrar, basta tocar no botão "Cadastrar Novo Veículo” e inserir as informações necessárias.</Text>
          <Text style={styles.simpleText}>Caso queira excluir uma placa, toque no ícone de lixeira localizado à direita da placa desejada e confirme a exclusão.</Text>

          <Text style={styles.questionText}>- Como altero os alarmes de expiração?</Text>
          <Text style={styles.simpleText}>As notificações podem ser programadas através da opção "Alarmes" no menu do aplicativo. Para ativar ou desativar, toque no marcador da configuração desejada. O alarme funciona mesmo sem acesso à internet ou deslogado da conta.</Text>
        </View>

      </ScrollView>
      <OfficialFooter />
    </View>
  );
};

const styles = StyleSheet.create({
  fieldContainer: {
    marginTop: 10,
    marginRight: 5
  },
  fieldTitleContainer: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginTop: 10
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 17,
    fontFamily: 'defaultFontBold',
  },
  questionText: {
    marginTop: 10,
    fontFamily: 'defaultFontBold',
    fontSize: 17,
    color: '#0992A5',
  },
  simpleText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242',
    textAlign: 'justify'
  }
});

export default FAQScreen;
