import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Image, Modal, Keyboard, Alert } from 'react-native';
import alert from '../hooks/useAlert.js'
import { SafeAreaView } from 'react-native-safe-area-context';
import Clipboard from '@react-native-community/clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ConfirmDialog } from 'react-native-simple-dialogs';
import { Button, Input } from 'react-native-elements';
import { FontAwesome, Entypo, FontAwesome5 } from '@expo/vector-icons';
import CreditCard from '../components/CreditCard';
import SaveCreditCardCheckBox from '../components/SaveCreditCardCheckBox';
import CreditCardElementContainer from '../components/CreditCardElementContainer';
import LoadingScreen from '../components/LoadingScreen';
import SafeEnv from '../components/SafeEnv';
import useCreditCard from '../hooks/useCreditCard';
import usePaymentApi from '../hooks/usePaymentApi';
import useParkingApi from '../hooks/useParkingApi';
import useUserAddress from '../hooks/useUserAddress';
import useGetVehicles from '../hooks/useGetVehicles';
import useBalanceAndPriceApi from '../hooks/useBalanceAndPriceApi';
import cpfCnpjValidation from '../lib/validateCpfCnpj';
import Parse from "parse";
import useUserDataValidation from '../hooks/useUserDataValidation';
import useExpiredSession from '../hooks/useExpiredSession';
import base64Images from '../../assets/images/base64/base64Images';
import useErrorHandler from '../hooks/useErrorHandler';
import OfficialFooter from '../components/OfficialFooter';
import constants from '../../constants/AppParams';

const PurchaseCreditsScreen = ({ route, navigation }) => {

  const [checkIfSessionExpired] = useExpiredSession();

  const [handleError] = useErrorHandler();

  const [, , checkAddressField, maskZipCode] = useUserDataValidation();

  const [getVehicles] = useGetVehicles();

  const [conectCarVehicles, setConectCarVehicles] = useState([]);

  const user = Parse.User.current();

  const getTotalPrice = (creditsToBuy) => {
    if (creditsToBuy < 0) return 0;
    else return creditsToBuy * PRICE_PER_CAD;
  };

  const NOT_LOADED = 0;
  const LOADED_WITH_ERROR = -1;
  const LOADED = 1;

  const BANKS = [
    { id: 1, code: "001", name: 'Do Brasil', icon: base64Images.bbBank },
    { id: 2, code: "237", name: 'Bradesco', icon: base64Images.bradescoBank },
    { id: 3, code: "341", name: 'Itaú', icon: base64Images.itauBank },
    { id: 4, code: "033", name: 'Santander', icon: base64Images.santanderBank }
  ];

  const PAYMENT_METHODS = {
    CREDIT_CARD: 3,
    BANK_SLIP: 2,
    DEBIT: 1 //deprecated
  };
  const [allowedToPurchaseViaCreditCard, setAllowedToPurchaseViaCreditCard] = useState(false);

  const [selectedBank, setSelectedBank] = useState(null);
  const [balance, setBalance] = useState({});
  const [PRICE_PER_CAD, setPRICE_PER_CAD] = useState(null);

  const [loadingState, setLoadingState] = useState(NOT_LOADED);

  const [creditsToBuy, setCreditsToBuy] = useState(0);
  const [step, setStep] = useState(1);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(0);
  const [messageCadAvaibility, setMessageCadAvaibility] = useState('');
  const [messageCadPayment, setMessageCadPayment] = useState('');

  const [creditCardList, setCreditCardList] = useState([]);
  const [creditCard, setCreditCard] = useState({});
  const [creditCardSelected, setCreditCardSelected] = useState({});

  const [transferDetails, setTransferDetails] = useState({});
  const [cashTransferDetails, setCashTransferDetails] = useState({});
  const [bankSlipTransferDetails, setBankSlipTransferDetails] = useState({});

  const [showExchangeCardList, setShowExchangeCardList] = useState(false);
  const [cancelTransferLoading, setCancelTransferLoading] = useState(false);
  const [showCopiedTextMessage, setShowCopiedTextMessage] = useState(false);
  const [sendingBankSlipMail, setSendingBankSlipMail] = useState(false);

  const [isProcessingCreditCardPayment, setIsProcessingCreditCardPayment] = useState(false);
  const [performCreditCardFinalCheck, setPerformCreditCardFinalCheck] = useState(0);
  const [allSet, setAllSet] = useState(0);

  const [saveCard, setSaveCard] = useState(true);
  const [bankTransferModalVisible, setBankTransferModalVisible] = useState(false);
  const [bankSlipModalVisible, setBankSlipModalVisible] = useState(false);
  const [showAllAddressFields, setShowAllAddressFields] = useState(false);

  const [isValidCpfCnpj, cpfCnpjMask] = cpfCnpjValidation();
  const [getAddress, updateUserAddress] = useUserAddress();

  const [savingUserAddress, setSavingUserAddress] = useState(false);
  const [isCheckingTransferPayment, setIsCheckingTransferPayment] = useState(false);

  const [userBankAccountData, setUserBankAccountData] = useState({ userDocId: cpfCnpjMask(user.get('cpf')) });
  const [accountDataError, setAccountDataError] = useState({ agency: "", account: "", userDocId: "" });
  const [accountDataFieldBlurred, setAccountDataFieldBlurred] = useState({});

  const [isProcessingBankTransfer, setIsProcessingBankTransfer] = useState(false);
  const [showTransferData, setShowTransferData] = useState(false);

  const [showConfirmAccountDialog, setShowConfirmAccountDialog] = useState(false);

  const [userAddress, setUserAddress] = useState({});
  const [searchingZipCode, setSearchingZipCode] = useState(false);

  const [userAddressError, setUserAddressError] = useState({});

  const [saveCreditCard, getCreditCards, removeCreditCard, getFavoriteCard, setFavoriteCard, getCardsAndFavorite, setNewCreditCard] = useCreditCard();

  const [createTransferOrder, cancelTransferOrder, cancelCashOrder, createOrder, createBankSlipOrder, cancelBankSlipOrder, sendBankSlipMail, payOrder, findTransferOrder] = usePaymentApi();

  const [parkVehicle, getActiveTransactions, purchaseCredits, sendSmsVerification, validateSmsCode, sendEmailVerification] = useParkingApi();
  const [smsVerificationCode, setSmsVerificationCode] = useState("");

  const [getBalance] = useBalanceAndPriceApi();
  const [cadPriceProps, setCadPriceProps] = useState({});

  const [selectedConectCarVehicle, setSelectedConectCarVehicle] = useState(null);
  const [conectCarVehiclesLoadingState, setConectCarVehiclesLoadingState] = useState(0);
  const [pixPaymentDataState, setPixPaymentDataState] = useState(NOT_LOADED);

  const MAX_CREDITS_PER_PURCHASE = 210;
  const MAX_VEHICLE_DESCRIPTION_LENGTH = constants.maxVehicleDescriptionLength;

  const [pixPaymentDetails, setPixPaymentDetails] = useState({});

  const cadText = (qty) => {
    if (qty < 2) return 'Cartão';
    else return 'Cartões'
  };

  const beautifulBalance = (p) => {
    const price = p.toFixed(2).toString().replace(".", ",");
    const priceWithThousandSeparator = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return priceWithThousandSeparator;
  };

  //pre PIX
  // const paymentOptions = [
  //   { name: 'Cartão de Crédito', id: 1, icon: 'credit-card', messageCadAvaibility: 'Créditos disponíveis imediatamente', messageCadPayment: 'Pague agora sem sair do App' },
  //   { name: 'Transferência', id: 2, icon: 'swap', library: 'Entypo', messageCadAvaibility: 'Créditos disponíveis em alguns minutos', messageCadPayment: 'Pague pelo computador ou celular' },
  //   { name: 'ConectCar', id: 4, icon: 'dots-three-horizontal', library: 'Entypo', messageCadAvaibility: 'Créditos disponíveis imediatamente', messageCadPayment: 'Pague agora sem sair do App' },
  //   { name: 'Boleto', id: 3, icon: 'barcode', messageCadAvaibility: 'Créditos disponíveis em até 3 dias', messageCadPayment: 'Pague pelo computador, celular ou no banco' }
  // ];

  //pos PIX
  const paymentOptions = [
    { name: 'PIX (Transferência)', id: 7, icon: 'money', messageCadAvaibility: 'Créditos disponíveis em alguns segundos', messageCadPayment: 'Pague utilizando o App do seu banco' },
    { name: 'ConectCar', id: 4, icon: 'dots-three-horizontal', library: 'Entypo', messageCadAvaibility: 'Créditos disponíveis imediatamente', messageCadPayment: 'Pague agora sem sair do App' },
    { name: 'Cartão de Crédito', id: 1, icon: 'credit-card', messageCadAvaibility: 'Créditos disponíveis imediatamente', messageCadPayment: 'Pague agora sem sair do App' },
    { name: 'Boleto', id: 3, icon: 'barcode', messageCadAvaibility: 'Créditos disponíveis em até 3 dias', messageCadPayment: 'Pague pelo computador, celular ou na agência bancária' }
  ];

  const doInitialPrep = async (creditCardSelectedId) => {

    setLoadingState(NOT_LOADED);

    //reset variables
    setCreditCardSelected(null);
    setShowExchangeCardList(false);
    setCreditCardList(null);
    setPerformCreditCardFinalCheck(0);
    setCreditCard({});
    setAllSet(0);
    setStep(1);
    setTransferDetails({});
    setBankSlipTransferDetails({});
    setPixPaymentDetails({});
    setCashTransferDetails({});
    setShowTransferData(false);
    setCreditsToBuy(0);
    setSelectedPaymentOption(0);

    try {
      const [balance, cardList] =
        await Promise.all([
          getBalance(),
          getCardsAndFavorite(setCreditCardSelected, null)
        ]);


      if (balance && balance.value === 0) {
        setBalance(balance);
      }
      else {
        setBalance(balance._toFullJSON());
      }

      setCreditCardList(cardList);

      if (creditCardSelectedId) {
        for (let i = 0; i < cardList.length; i++) {
          if (cardList[i].id === creditCardSelectedId) {
            setCreditCardSelected(cardList[i]);
            break;
          }
        }
      }

      setLoadingState(LOADED);
    }
    catch (e) {
      console.log('error getting initial data', e);
      setLoadingState(LOADED_WITH_ERROR);
      checkIfSessionExpired(e);
    }
  };

  const maskAccountField = (field, value) => {

    const AGENCY_ERROR = 'Por favor, insira um número de agência válido (sem o dígito)'
    const ACCOUNT_ERROR = 'Por favor, insira um número de conta corrente válido (com o dígito)'
    const DOC_ERROR = 'Por favor, insira um CPF ou CNPJ válido'

    let pattern = null;

    switch (field) {
      case 'agency':
        //field mask
        value = value.replace(/\D/g, "");
        value = value.substring(0, 4);

        //field validation
        pattern = new RegExp(/^[0-9]{1,4}$/);

        if (!value || !pattern.test(value)) {
          setAccountDataError({ ...accountDataError, agency: AGENCY_ERROR });
        }
        else {
          setAccountDataError({ ...accountDataError, agency: "" });
        }
        return value;
      case 'account':
        //field mask
        value = value.replace(/\D/g, "");
        value = value.substring(0, 13);

        if (value.length >= 2) {
          value = value.replace(/(.+)(.)$/, "$1-$2");
        }

        //field validation
        pattern = new RegExp(/^[0-9]{1,12}-[0-9]{1}$/);

        if (!value || !pattern.test(value)) {
          setAccountDataError({ ...accountDataError, account: ACCOUNT_ERROR });
        }
        else {
          setAccountDataError({ ...accountDataError, account: "" });
        }
        return value;
      case 'userDocId':
        //field mask
        value = cpfCnpjMask(value);

        const noMaskValue = value.replace(/\D/g, "");
        if (!isValidCpfCnpj(noMaskValue)) {
          setAccountDataError({ ...accountDataError, userDocId: DOC_ERROR });
        }
        else {
          setAccountDataError({ ...accountDataError, userDocId: "" });
        }

        return value;
    }
  };

  const searchZipCode = async (zipCode) => {
    if (zipCode) zipCode = zipCode.replace(/\D/g, "");

    if (!zipCode || zipCode.length !== 8) {

      alert(
        'Oops!',
        'O CEP deve ter 8 dígitos. Por favor, corrija e tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   'O CEP deve ter 8 dígitos. Por favor, corrija e tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      return;
    }
    else {
      Keyboard.dismiss();
      try {
        setSearchingZipCode(true);
        const address = await getAddress(zipCode);
        setUserAddressError({});
        setUserAddress({ ...address, zipCode: maskZipCode(zipCode) });
        setShowAllAddressFields(true);
        setSearchingZipCode(false);
      }
      catch (e) {
        console.log('error getting address from zip code', e);

        
        // Alert.alert(
        //   'Oops!',
        //   'Erro ao buscar endereço correspondente ao CEP fornecido. Por favor, preencha manualmente os campos ou tente novamente.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );

        alert(
          'Oops!',
          'Erro ao buscar endereço correspondente ao CEP fornecido. Por favor, preencha manualmente os campos ou tente novamente.',
          null,
          true
        );

        setUserAddress({ zipCode: maskZipCode(zipCode) });
        setShowAllAddressFields(true);
        setSearchingZipCode(false);
        setUserAddressError({});
        checkIfSessionExpired(e);
      }
    }
  };

  const getUserAccountData = async (bankId) => {
    try {
      const accountData = await AsyncStorage.getItem(`accountData${bankId}`);
      if (Object.keys(accountData).length !== 0) {
        setUserBankAccountData(JSON.parse(accountData));
      }
    }
    catch (e) {
      //go ahead even it didn't save
      checkIfSessionExpired(e);
    }
  };

  const checkPixOrderProcessed = async (orderId, price) => {
    try {
      console.log('entering checkPixOrderProcessed', orderId, price);

      const showMessage = (title, msg) => {

        alert(
          title,
          msg,
          null,
          true
        );

        // Alert.alert(
        //   title,
        //   msg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
      };

      setIsCheckingTransferPayment(true);

      const PixOrder = Parse.Object.extend('PIXOrder');
      const query = new Parse.Query(PixOrder);
      query.equalTo('orderId', orderId);
      const pixOrder = await query.find();
      console.log('got this result', pixOrder)
      const defaultErrorMessage = 'Ainda não identificamos o seu pagamento por PIX. Tente novamente em alguns instantes. Se o problema persistir, entre em contato com o nosso suporte.';

      if (pixOrder && pixOrder.length) {
        console.log('pagamento NAO processado')
        showMessage('Oops!', defaultErrorMessage);
      }
      else {
        console.log('current balance', balance);
        const currentBalance = balance.value;
        const newBalance = await getBalance();

        console.log('newBalance', newBalance);
        if (newBalance.get('value') === currentBalance + price) { //this is a good guess
          console.log('pagamento processado')
          showMessage('Pronto!', `Identificamos seu pagamento por PIX de R$ ${beautifulBalance(price)} e seus créditos já estão disponíveis para uso.`);
          navigation.navigate('DefaultLoggedIn');
        }
        else {
          showMessage('Oops!', defaultErrorMessage);
        }
      }
      setIsCheckingTransferPayment(false);
    }
    catch (e) {
      setIsCheckingTransferPayment(false);
      console.log('error on checkPixOrderProcessed', e);
    }
  };

  const cancelPixOrder = () => {
    setCancelTransferLoading(true);

    alert(
      'Confirmação',
      'Tem certeza que deseja cancelar este pagamento por PIX?',
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => { setCancelTransferLoading(false); }
        },
        {
          text: 'Sim',
          onPress: () => { setStep(1); setCancelTransferLoading(false); }
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   'Tem certeza que deseja cancelar este pagamento por PIX?',
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => { setCancelTransferLoading(false); }
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => { setStep(1); setCancelTransferLoading(false); }
    //     }
    //   ],
    //   { cancelable: false }
    // );
  };

  const cancelCurrentTransferOrder = (cash, bankSlip) => {

    setCancelTransferLoading(true);
    let transactionType = null;
    let fullTransactionType = null;

    if (cash) {
      transactionType = 'o depósito';
      fullTransactionType = 'depósito bancário';
    }
    else if (bankSlip) {
      transactionType = 'o pagamento do boleto';
      fullTransactionType = 'boleto';
    }
    else {
      transactionType = 'a transferência';
      fullTransactionType = 'transferência bancária';
    }

    const showAlert = (title, msg) => {

      alert(
        title,
        msg,
        null,
        true
      );

      // Alert.alert(
      //   title,
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );

      setCancelTransferLoading(false);
    };

    const proceed = async () => {

      let title = null;
      let msg = null;

      try {
        let sucessOnRemoval = null;

        if (cash) sucessOnRemoval = await cancelCashOrder();
        else if (bankSlip) sucessOnRemoval = await cancelBankSlipOrder();
        else sucessOnRemoval = await cancelTransferOrder();

        if (sucessOnRemoval) {
          title = 'Pronto!';
          msg = `Seu pedido de pagamento por ${fullTransactionType} foi cancelado.`;
        }
        else {
          title = 'Oops!';
          msg = `Não há pedido de pagamento por ${fullTransactionType} a ser deletado para essa conta.`;
        }
        showAlert(title, msg);

        if (cash) {
          setStep(2);
          setCashTransferDetails({});
        }
        else if (bankSlip) {
          setStep(2);
          setBankSlipTransferDetails({});
        }
        else setShowTransferData(false);
      }
      catch (e) {
        console.log('error canceling transfer order', e);
        title = 'Oops!';
        msg = 'Ocorreu um erro ao processar o cancelamento, por favor, tente novamente.';
        showAlert(title, msg);
        checkIfSessionExpired(e);
      }

    };

    alert(
      'Confirmação',
      `Não cancele este pedido caso você já tenha feito ${transactionType} ou tenha a intenção de fazer. Em caso de dúvidas, contate o nosso suporte. Tem certeza que deseja continuar?`,
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => { setCancelTransferLoading(false); }
        },
        {
          text: 'Sim',
          onPress: () => { proceed(); }
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   `Não cancele este pedido caso você já tenha feito ${transactionType} ou tenha a intenção de fazer. Em caso de dúvidas, contate o nosso suporte. Tem certeza que deseja continuar?`,
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => { setCancelTransferLoading(false); }
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => { proceed(); }
    //     }
    //   ],
    //   { cancelable: false }
    // );

  };

  const validateUserAddress = (field, value, finalCheck) => {

    let errorMessages = { ...userAddressError };

    const saveAddress = async () => {
      try {
        setSavingUserAddress(true);

        await updateUserAddress(userAddress);

        setBankSlipModalVisible(false);
        setSavingUserAddress(false);
        processBankSlipPaymentOrder();
      }
      catch (e) {

        alert(
          'Oops!',
          'Ocorreu um erro ao salvar o endereço. Por favor, tente novamente.',
          null,
          true
        );

        // Alert.alert(
        //   'Oops!',
        //   'Ocorreu um erro ao salvar o endereço. Por favor, tente novamente.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
        setSavingUserAddress(false);
        checkIfSessionExpired(e);
      }
    }

    if (finalCheck) {
      checkAddressField(null, null, errorMessages, true, userAddress);

      let formWithError = false;
      for (const fd in errorMessages) {
        if (errorMessages[fd]) {

          alert(
            'Oops!',
            'Por favor, corrija os erros do formulário e tente novamente.',
            null,
            true
          );

          // Alert.alert(
          //   'Oops!',
          //   'Por favor, corrija os erros do formulário e tente novamente.',
          //   [
          //     { text: 'OK' },
          //   ],
          //   { cancelable: true }
          // );
          formWithError = true;
          break;
        }
      }

      if (!formWithError) saveAddress();

    }
    else {
      checkAddressField(value, field, errorMessages);
    }
    setUserAddressError(errorMessages);
  }

  const processBankSlipPaymentOrder = async (saveAddress) => {

    // if (creditsToBuy < cadPriceProps.minBankSlipPurchaseQty) {
    //   Alert.alert(
    //     'Oops!',
    //     `A compra mínima por boleto é de ${cadPriceProps.minBankSlipPurchaseQty} cartões. Por favor, ajuste a quantidade a comprar e tente novamente.`,
    //     [
    //       { text: 'OK' },
    //     ],
    //     { cancelable: true }
    //   );
    //   return;
    // };

    if (saveAddress) {
      validateUserAddress(null, null, true);
      return;
    }
    else if (!user.get('address') || Object.keys(user.get('address')).length === 0) {
      setUserAddress({});
      setUserAddressError({});
      setBankSlipModalVisible(true);
      return;
    };

    setIsProcessingBankTransfer(true);

    try {

      // const formattedPrice = getTotalPrice(creditsToBuy) * 100;
      // const formattedPrice = creditsToBuy * 100;

      // const orderId = await createOrder(creditsToBuy, formattedPrice, PAYMENT_METHODS.BANK_SLIP);

      // const bankSlipOrder = await createBankSlipOrder(orderId);

      const params = {
        creditsToBuy
        // formattedPrice
      };

      const bankSlipOrder = await Parse.Cloud.run('createBankSlipOrder', { ...params });

      if (Object.keys(bankSlipOrder).length === 0) {
        throw 'bankSlipOrder is empty';
      }

      alert(
        'Quase lá...',
        `Seu boleto foi gerado e enviado ao e-mail ${user.get('email')}\n\nSe preferir utilize a linha digitável exibida na tela de baixo e pague utilizando o aplicativo do seu banco.`,
        null,
        true
      );

      // Alert.alert(
      //   'Quase lá...',
      //   `Seu boleto foi gerado e enviado ao e-mail ${user.get('email')}\n\nSe preferir utilize a linha digitável exibida na tela de baixo e pague utilizando o aplicativo do seu banco.`,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );

      setBankSlipTransferDetails(bankSlipOrder);
      setIsProcessingBankTransfer(false);
      setStep(3);
    }
    catch (e) {
      console.log('error generating bank slip', e);
      let msg = null;

      if (e && e.message && typeof (e.message) === "string" && e.message !== 'rejected') { //if blacklisted, message with appear here
        msg = e.message;
      }
      else {
        msg = 'Ocorreu um erro ao processar seu pedido de pagamento por boleto. Tente novamente.';
      }

      alert(
        'Oops!',
        msg,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      setIsProcessingBankTransfer(false);
      checkIfSessionExpired(e);
    }
  };

  const resendBankSlipMail = async (url, amount, price, lineCode) => {

    setSendingBankSlipMail(true);

    try {

      const params = {};
      params.url = url;
      params.amount = amount;
      params.price = price;
      params.lineCode = lineCode;

      await sendBankSlipMail(params);

      alert(
        'Pronto!',
        `O boleto foi enviado com sucesso ao seu e-mail ${user.get('email')}\n\nPor favor, cheque seu e-mail e efetue o pagamento. Em alguns casos é possível que o e-mail demore alguns minutos para chegar.`,
        null,
        true
      );

      // Alert.alert(
      //   'Pronto!',
      //   `O boleto foi enviado com sucesso ao seu e-mail ${user.get('email')}\n\nPor favor, cheque seu e-mail e efetue o pagamento. Em alguns casos é possível que o e-mail demore alguns minutos para chegar.`,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );

      setSendingBankSlipMail(false);
    }
    catch (e) {
      console.log('error sending bank slip mail', e);

      alert(
        'Oops!',
        'Ocorreu um erro e o boleto não foi enviado ao seu e-mail. Por favor, tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   'Ocorreu um erro e o boleto não foi enviado ao seu e-mail. Por favor, tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      setSendingBankSlipMail(false);
      checkIfSessionExpired(e);
    }
  };

  //remove new card validation start up
  useEffect(() => {
    if (showExchangeCardList) {
      setPerformCreditCardFinalCheck(0);
      setAllSet(0);
    }
  }, [showExchangeCardList]);

  useEffect(() => {
    if (!allSet || !allowedToPurchaseViaCreditCard) return;
    else {
      setAllowedToPurchaseViaCreditCard(false);
      goProcessCreditCardOrder();
    }
  }, [allSet, allowedToPurchaseViaCreditCard]);

  const processCreditCardOrder = () => {
    if (!creditCardSelected || !creditCardSelected.id) {
      setAllSet(0);
      setPerformCreditCardFinalCheck(performCreditCardFinalCheck + 1);
      setAllowedToPurchaseViaCreditCard(true);
    }
    else {
      setAllowedToPurchaseViaCreditCard(false);
      goProcessCreditCardOrder();
    }
  };

  const getVehiclesForConectCarPayment = async () => {
    try {
      setConectCarVehiclesLoadingState(NOT_LOADED);
      setSelectedConectCarVehicle(null);
      const vehicles = await getVehicles();

      if (vehicles && vehicles.length > 0) { //sort by license plate asc.
        vehicles.sort(function (vehicleA, vehicleB) {
          const licensePlateA = vehicleA.get('vehicle').get('licensePlate');
          const licensePlateB = vehicleB.get('vehicle').get('licensePlate');
          if (licensePlateA < licensePlateB) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      setConectCarVehicles(vehicles);
      setConectCarVehiclesLoadingState(LOADED);
    }
    catch (e) {
      console.log('error on getVehiclesForConectCarPayment', e);
      setConectCarVehiclesLoadingState(LOADED_WITH_ERROR);
    }
  }

  const processConectCarOrder = async () => {
    console.log('entered pay with ConectCar');

    const showWarning = (title, message) => {

      alert(
        title,
        message,
        null,
        true
      );

      // Alert.alert(
      //   title,
      //   message,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
    }

    if (!selectedConectCarVehicle) {
      showWarning('Oops!', 'É necessário escolher um veículo da lista para prosseguir.');
      return;
    }

    const doProcessConectCarOrder = async () => {
      try {
        setIsProcessingCreditCardPayment(true); //same function of creditcard to disable payment button

        const conectCarPlate = selectedConectCarVehicle.get('vehicle').get('licensePlate');
        console.log('will pay with conectcar for vehicle', conectCarPlate, creditsToBuy);

        const purchaseResult = await purchaseCredits({
          price: creditsToBuy,
          conectCarPlate
        });

        showWarning(
          'Pronto!',
          `Sua compra de R$ ${beautifulBalance(creditsToBuy)} em créditos foi realizada com sucesso.`,
        );

        setIsProcessingCreditCardPayment(false);
        navigation.navigate('DefaultLoggedInStack');
      }

      catch (e) {
        const errorMsg = handleError(e);
        showWarning('Oops!', errorMsg);
        setIsProcessingCreditCardPayment(false);
        console.log('error on processConectCarOrder', errorMsg);
      }
    };

    alert(
      'Confirmação',
      `Confirma a compra de R$ ${beautifulBalance(creditsToBuy)} em créditos?`,
      [
        {
          text: 'Não',
          style: 'cancel',
          onPress: () => { }
        },
        {
          text: 'Sim',
          onPress: () => { doProcessConectCarOrder(); }
        }
      ],
    );

    // Alert.alert(
    //   'Confirmação',
    //   `Confirma a compra de R$ ${beautifulBalance(creditsToBuy)} em créditos?`,
    //   [
    //     {
    //       text: 'Não',
    //       style: 'cancel',
    //       onPress: () => { }
    //     },
    //     {
    //       text: 'Sim',
    //       onPress: () => { doProcessConectCarOrder(); }
    //     }
    //   ],
    //   { cancelable: false }
    // );

  };

  const checkSmsCode = async () => {
    try {
      if (smsVerificationCode.length < 4) {

        alert(
          'Oops!',
          'O código deve ter 4 dígitos.',
          null,
          true
        );

        // Alert.alert(
        //   'Oops!',
        //   'O código deve ter 4 dígitos.',
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
        return;
      }
      else {
        await validateSmsCode(smsVerificationCode);
        setIsProcessingCreditCardPayment(false);
        setShowConfirmAccountDialog(false);
        goProcessCreditCardOrder(true);
      }
    }
    catch (e) {

      alert(
        'Oops!',
        'O código de confirmação informado está incorreto. Por favor, tente novamente.',
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   'O código de confirmação informado está incorreto. Por favor, tente novamente.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
      checkIfSessionExpired(e);
    }
  };

  const maskPhone = (phone) => {
    phone = phone.replace(/\D/g, "");
    phone = phone.replace(/^(\d\d)(\d)/g, "($1) $2");
    phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
    return phone;
  };

  const validateAccount = async (validationType) => {
    try {
      if (validationType === 'SMS') {
        await sendSmsVerification();
        setShowConfirmAccountDialog(true);
      }
      else { //email
        const message = await sendEmailVerification();

        alert(
          'Atenção!',
          message,
          null,
          true
        );

        // Alert.alert(
        //   'Atenção!',
        //   message,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: false }
        // );
        setIsProcessingCreditCardPayment(false);
      }
    }
    catch (e) {
      console.log('error sending validation', e);

      alert(
        'Oops!',
        `Ocorreu um erro ao validar sua conta por ${validationType}. Por favor, tente novamente.`,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   `Ocorreu um erro ao validar sua conta por ${validationType}. Por favor, tente novamente.`,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
      setIsProcessingCreditCardPayment(false);
      checkIfSessionExpired(e);
    }
  };


  const goProcessCreditCardOrder = (proceedWithoutAsking) => {

    const proceed = async () => {
      setIsProcessingCreditCardPayment(true);

      try {
        let config = Parse.Config.current();

        if (!config || !config.get) {
          config = await Parse.Config.get();
        }

        const minPurchaseValue = config.get('minValuePurchase');

        if (minPurchaseValue > creditsToBuy) {
          throw `A compra mínima é de R$ ${beautifulBalance(minPurchaseValue)} em créditos. Por gentileza, aumente o valor de créditos a comprar e tente novamente.`;
        }

        let creditCardHash = null;
        let newCrediCard = null;
        let useHashAsCardId = false;

        if (!creditCardSelected || !creditCardSelected.id) {
          if (creditCard.number && creditCard.expiryDate && creditCard.cvv) {
            newCrediCard = setNewCreditCard(creditCard);
            if (!newCrediCard) throw 'invalid credit card entered.';
            // else creditCardHash = await newCrediCard.hash();
            else creditCardHash = newCrediCard.encryptedCard;
          }
          else {
            throw 'invalid credit card entered.';
          }
        }
        else {
          if (!creditCardSelected.get('hashedCreditCard')) throw 'invalid credit card entered.';
          else {
            creditCardHash = creditCardSelected.get('hashedCreditCard');
            useHashAsCardId = true;
          }
        }

        // const paidOrder = await payOrder(orderId, creditCardHash);

        // //save credit card if the case
        // if (saveCard && newCrediCard) {
        //   await saveCreditCard(creditCard);
        // }

        const purchaseResult = await purchaseCredits({
          price: creditsToBuy,
          hashedCreditCard: creditCardHash,
          saveCreditCard: (saveCard && newCrediCard) ? true : false,
          useHashAsCardId
        });

        alert(
          'Pronto!',
          `Sua compra de R$ ${beautifulBalance(creditsToBuy)} em créditos foi realizada com sucesso.`,
          null,
          true
        );

        // Alert.alert(
        //   'Pronto!',
        //   `Sua compra de R$ ${beautifulBalance(creditsToBuy)} em créditos foi realizada com sucesso.`,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
        setIsProcessingCreditCardPayment(false);
        navigation.navigate('DefaultLoggedInStack');

      }
      catch (e) {
        console.log('error processing credit card payment', e);
        let msg = null;
        if (e && e.message && e.message.code === 403) {

          alert(
            'Validação de conta',
            'Para prosseguir com a compra, é necessário que você valide sua conta por SMS.',
            [
              {
                text: 'Não',
                style: 'cancel',
                onPress: () => { setIsProcessingCreditCardPayment(false) }
              },
              {
                text: 'Sim',
                onPress: () => validateAccount('SMS')
              }
            ],
          );


          // Alert.alert(
          //   'Validação de conta',
          //   'Para prosseguir com a compra, é necessário que você valide sua conta. Você pode validá-la utilizando o seu celular ou o e-mail cadastrado.',
          //   [
          //     { text: 'Cancelar', onPress: () => setIsProcessingCreditCardPayment(false) },
          //     { text: 'Validar por E-mail', onPress: () => validateAccount('e-mail') },
          //     { text: 'Validar por SMS', onPress: () => validateAccount('SMS') }
          //   ],
          //   { cancelable: false }
          // );
          return;
        }
        // else if (e && e.message && typeof (e.message) === "string" && e.message !== 'rejected') { //if blacklisted, message will appear here
        //   msg = e.message;
        // }
        // else {
        //   msg = 'Erro ao pagar com o cartão de crédito.\n\nPor favor, cheque se o cartão que você inseriu é de crédito, válido e está funcionando e tente novamente.';
        // }
        else {
          msg = handleError(e);

          if (msg === 'rejected') {
            msg = 'Erro ao processar a transação.\n\nPor favor, cheque se o cartão de crédito inserido é válido e tente novamente. Se o problema persistir, pode haver instabilidade momentânea no processador de pagamentos com cartão.\n\nAlternativamente, você pode pagar por PIX.';
          }
        }

        alert(
          'Oops!',
          msg,
          null,
          true
        );

        // Alert.alert(
        //   'Oops!',
        //   msg,
        //   [
        //     { text: 'OK' },
        //   ],
        //   { cancelable: true }
        // );
        checkIfSessionExpired(e);
        setIsProcessingCreditCardPayment(false);
      }
    };

    if (proceedWithoutAsking) {
      proceed();
    }
    else {

      alert(
        'Confirmação',
        `Confirma a compra de R$ ${beautifulBalance(creditsToBuy)} em créditos?`,
        [
          {
            text: 'Não',
            style: 'cancel',
            onPress: () => { }
          },
          {
            text: 'Sim',
            onPress: () => proceed()
          }
        ],
      );

      // Alert.alert(
      //   'Confirmação',
      //   `Confirma a compra de R$ ${beautifulBalance(creditsToBuy)} em créditos?`,
      //   [
      //     {
      //       text: 'Não',
      //       style: 'cancel',
      //       onPress: () => { }
      //     },
      //     {
      //       text: 'Sim',
      //       onPress: () => { proceed(); }
      //     }
      //   ],
      //   { cancelable: false }
      // );
    }
  }

  const processCashPaymentOrder = async () => {

    // if (creditsToBuy < cadPriceProps.minCashPurchaseQty) {
    //   Alert.alert(
    //     'Oops!',
    //     `A compra mínima com depósito em dinheiro é de ${cadPriceProps.minCashPurchaseQty} cartões. Por favor, ajuste a quantidade a comprar e tente novamente.`,
    //     [
    //       { text: 'OK' },
    //     ],
    //     { cancelable: true }
    //   );
    //   return;
    // };

    setIsProcessingBankTransfer(true);

    const centsPrice = getTotalPrice(creditsToBuy) * 100;

    try {
      const bankData = {
        bankCode: "341" //cash order is always paid via Itau
      }
      const cashPaymentOrder = await createTransferOrder(centsPrice, bankData, true);

      if (Object.keys(cashPaymentOrder).length === 0) {
        throw 'cashPaymentOrder is empty';
      }

      setCashTransferDetails(cashPaymentOrder);
      setIsProcessingBankTransfer(false);
      setStep(3);
    }
    catch (e) {
      console.log('error on processCashPaymentOrder', e);

      let msg = null;
      if (e && e.message && typeof (e.message) === "string" && e.message !== 'rejected') { //if blacklisted, message with appear here
        msg = e.message;
      }
      else {
        msg = 'Ocorreu um erro ao processar seu pedido de pagamento por depósito bancário. Tente novamente.';
      }

      alert(
        'Oops!',
        msg,
        null,
        true
      );


      // Alert.alert(
      //   'Oops!',
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      checkIfSessionExpired(e);
      setIsProcessingBankTransfer(false);
    }

  };

  const checkTransferPaymentProcessed = async (transferOrderId, amount) => {
    //this function is a *good guess* that the payment was processed

    console.log('checkingTransferPaymentProcessed', transferOrderId, amount);

    const showMessage = (title, msg) => {
      alert(
        title,
        msg,
        null,
        true
      );


      // Alert.alert(
      //   title,
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
    };

    try {

      setIsCheckingTransferPayment(true);

      const transferOrder = await findTransferOrder(transferOrderId);

      const defaultErrorMessage = 'Ainda não identificamos o seu pagamento. Tente novamente em alguns instantes. Se o problema persistir, entre em contato com o nosso suporte.';

      if (transferOrder) {
        showMessage('Oops!', defaultErrorMessage);
      }
      else {
        console.log('current balance', balance);
        const currentBalance = balance.value;
        const newBalance = await getBalance();

        console.log('newBalance', newBalance);


        if (newBalance.get('value') === currentBalance + amount) { //this is a good guess
          showMessage('Pronto!', `Identificamos seu pagamento e seu saldo agora é de R$ ${beautifulBalance(newBalance.get('value'))}.`);
          navigation.navigate('DefaultLoggedIn');
        }
        else {
          showMessage('Oops!', defaultErrorMessage);
        }
      }
      setIsCheckingTransferPayment(false);

    }
    catch (e) {
      console.log('error checking transfer order payment', e);
      showMessage('Oops!', 'Algo deu errado checando o seu pagamento. Por favor, tente novamente.');
      setIsCheckingTransferPayment(false);
      checkIfSessionExpired(e);
    }
  };

  const processBankTransfer = async () => {

    setIsProcessingBankTransfer(true);

    try {
      await AsyncStorage.setItem(`accountData${selectedBank.id}`, JSON.stringify(userBankAccountData));
    }
    catch (e) {
      //go ahead even it didn't save
      checkIfSessionExpired(e);
    }

    const centsPrice = creditsToBuy * 100;

    try {
      const bankData = {
        bankCode: selectedBank.code,
        agency: userBankAccountData.agency,
        account: userBankAccountData.account,
        docIdOwner: userBankAccountData.userDocId.replace(/\D/g, "")
      };
      const transferOrder = await createTransferOrder(centsPrice, bankData, false);

      if (Object.keys(transferOrder).length === 0) {
        throw 'transferOrder is empty';
      }
      console.log('transferOrder is', transferOrder);
      setTransferDetails(transferOrder);

      //final block must be this:
      setBankTransferModalVisible(false);
      setShowTransferData(true);

      alert(
        'Quase lá...',
        'Agora é só ler atentamente as instruções da tela abaixo e realizar a transferência no site ou app do seu banco.',
        null,
        true
      );
 
      // Alert.alert(
      //   'Quase lá...',
      //   'Agora é só ler atentamente as instruções da tela abaixo e realizar a transferência no site ou app do seu banco.',
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: false }
      // );
      setAccountDataError({ agency: "", account: "", userDocId: "" });
      setAccountDataFieldBlurred({});
      setUserBankAccountData({ userDocId: cpfCnpjMask(user.get('cpf')) });
      setIsProcessingBankTransfer(false);
    }
    catch (e) {
      console.log('error generating bank transfer payment order', e);
      setIsProcessingBankTransfer(false);

      let msg = null;
      if (e && e.message && typeof (e.message) === "string" && e.message !== 'rejected') { //if blacklisted, message with appear here
        msg = e.message;
      }
      else {
        msg = 'Ocorreu um erro ao processar seu pedido de pagamento por por transferência bancária. Tente novamente.';
      }

      alert(
        'Oops!',
        msg,
        null,
        true
      );

      // Alert.alert(
      //   'Oops!',
      //   msg,
      //   [
      //     { text: 'OK' },
      //   ],
      //   { cancelable: true }
      // );
      checkIfSessionExpired(e);
    }
  };

  let inputs = {};
  let addressInputs = {};


  const focusNextField = (id, address) => {
    if (address) addressInputs[id].focus();
    else inputs[id].focus();
  };

  useEffect(() => {
    if (route && route.params) {
      const creditCardSelectedId = route.params.creditCardId;
      doInitialPrep(creditCardSelectedId);
    }
  }, [route.params]);

  const formatLicensePlate = (licensePlate) => {

    licensePlate = licensePlate.replace(/[^0-9a-z]/gi, '');

    let spacer = "";

    if (/^\d+$/.test(licensePlate[3])) {
      spacer = " ";
      if (/^\d+$/.test(licensePlate[4])) {
        spacer = "-"
      }
    }

    licensePlate = licensePlate.substring(0, 3) + spacer + licensePlate.substring(3, 7);
    return licensePlate;

  };

  const processPIXPaymentOrder = async () => {
    console.log('\n\nTESTING API');
    try {
      setPixPaymentDataState(NOT_LOADED);
      setPixPaymentDetails({});

      const params = {
        price: creditsToBuy
      };

      const result = await Parse.Cloud.run('createPixOrder', { ...params });

      console.log('\n\nSUCCESS TESTING API', result);

      if (Parse.User.current().id !== result.user ||
        creditsToBuy !== result.price ||
        !result.id || !result.orderId || !result.paymentCode || !result.qrCodeImageLink) {
        throw 'Error on processPIXPaymentOrder. Result received differs from expected.';
      }
      else {
        setPixPaymentDetails({ ...result });
        setPixPaymentDataState(LOADED);
      }
    }
    catch (e) {
      setPixPaymentDataState(LOADED_WITH_ERROR);
      console.log('Error on processPIXPaymentOrder', e);
    }
  };

  const PaymentOptionElement = ({ item }) => {
    return (
      <TouchableOpacity
        key={item.id}
        onPress={() => {
          setSelectedPaymentOption(item);
          setMessageCadAvaibility(item.messageCadAvaibility);
          setMessageCadPayment(item.messageCadPayment);
        }}
        style={[styles.touchablePaymentOption, selectedPaymentOption.id === item.id ? styles.touchablePaymentOptionActive : null]}
      >

        {item.library ?
          <Entypo
            name={item.library ? item.icon : null}
            style={[styles.touchablePaymentOptionIcon, selectedPaymentOption.id === item.id ? styles.touchablePaymentOptionIconActive : null]}
          />
          :
          <FontAwesome
            name={item.library ? null : item.icon}
            style={[styles.touchablePaymentOptionIcon, selectedPaymentOption.id === item.id ? styles.touchablePaymentOptionIconActive : null]}
          />
        }

        <Text
          style={[styles.touchablePaymentOptionText, selectedPaymentOption.id === item.id ? styles.touchablePaymentOptionTextActive : null]}
        >
          {item.name}
        </Text>

      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.mainContainer}>
      {loadingState === NOT_LOADED ?
        <View style={styles.loadingState}>
          <ActivityIndicator size='large' color='#284D64' />
          <Text style={styles.loadingText}>Carregando informações...</Text>
        </View>
        : (loadingState === LOADED_WITH_ERROR ?
          <View style={styles.loadingState}>
            <Text style={styles.loadingText}>Ocorreu um erro ao carregar.</Text>
          </View>
          :
          <KeyboardAwareScrollView
            // innerRef={ref => {
            //   this.scroll = ref ? ref.getScrollResponder() : null;
            // }}
            enableOnAndroid={true}
            // enableAutomaticScroll={(Platform.OS === 'ios')}
            keyboardOpeningTime={0}
            extraHeight={250}
            keyboardShouldPersistTaps={'handled'}
            contentContainerStyle={styles.scrollViewContainer}
          >
            <View>
              <View style={styles.balanceContainer}>
                {Object.keys(balance).length !== 0 ?
                  <Text style={styles.balance}>Saldo: R$ {beautifulBalance(balance.value)}</Text>
                  : null}
              </View>

              {/* First Step - CADS to Buy */}
              <View style={styles.fieldContainer}>
                <View style={styles.fieldTitleContainer}>
                  <Text style={styles.fieldTitle}>
                    1. {step > 1 ? 'Créditos a comprar:' : 'Quantos créditos comprar?'}
                  </Text>
                </View>

                {step === 1 ?
                  <View>
                    <View style={styles.buttonsContainer}>
                      <View style={styles.amountButtonsContainer}>
                        <View style={styles.twoButtonsContainer}>
                          <Button
                            buttonStyle={styles.amountToPurchaseButton}
                            titleStyle={styles.amountToPurchaseButtonTitle}
                            onPress={() => setCreditsToBuy(100)}
                            title='R$ 100' />
                          <Button
                            buttonStyle={styles.amountToPurchaseButton}
                            titleStyle={styles.amountToPurchaseButtonTitle}
                            onPress={() => setCreditsToBuy(72)}
                            title='R$ 72' />
                        </View>
                        <View style={styles.twoButtonsContainer}>
                          <Button
                            buttonStyle={styles.amountToPurchaseButton}
                            titleStyle={styles.amountToPurchaseButtonTitle}
                            onPress={() => setCreditsToBuy(36)}
                            title='R$ 36' />
                          <Button
                            buttonStyle={styles.amountToPurchaseButton}
                            titleStyle={styles.amountToPurchaseButtonTitle}
                            onPress={() => setCreditsToBuy(12)}
                            title='R$ 12' />
                        </View>
                      </View>
                      <View style={styles.changeAmountButtonsContainer}>
                        <Text style={styles.changeAmountText}>Alterar:</Text>
                        <View style={styles.twoButtonsContainer}>
                          <Button
                            buttonStyle={styles.changeAmountToPurchaseButton}
                            titleStyle={styles.changeAmountToPurchaseButtonTitle}
                            onPress={() => {
                              if (creditsToBuy !== MAX_CREDITS_PER_PURCHASE) {
                                setCreditsToBuy(creditsToBuy + 1)
                              }
                            }}
                            title='+ R$ 1' />
                          <Button
                            buttonStyle={styles.changeAmountToPurchaseButton}
                            titleStyle={styles.changeAmountToPurchaseButtonTitle}
                            onPress={() => {
                              if (creditsToBuy + 10 < MAX_CREDITS_PER_PURCHASE) {
                                setCreditsToBuy(creditsToBuy + 10)
                              }
                              else {
                                setCreditsToBuy(MAX_CREDITS_PER_PURCHASE)
                              }
                            }}
                            title='+ R$ 10' />
                        </View>
                        <View style={styles.twoButtonsContainer}>
                          <Button
                            buttonStyle={styles.changeAmountToPurchaseButton}
                            titleStyle={styles.changeAmountToPurchaseButtonTitle}
                            onPress={() => {
                              if (creditsToBuy > 0) {
                                setCreditsToBuy(creditsToBuy - 1)
                              }
                              else {
                                setCreditsToBuy(0)
                              }
                            }}
                            title='- R$ 1' />
                          <Button
                            buttonStyle={styles.changeAmountToPurchaseButton}
                            titleStyle={styles.changeAmountToPurchaseButtonTitle}
                            onPress={() => {
                              if (creditsToBuy - 10 > 0) {
                                setCreditsToBuy(creditsToBuy - 10)
                              }
                              else {
                                setCreditsToBuy(0)
                              }
                            }}
                            title='- R$ 10' />
                        </View>
                      </View>
                    </View>

                    <View style={styles.priceAndAmount}>
                      <Text style={[styles.priceAndAmountText, { fontSize: 17 }]}>Total: R$ {creditsToBuy}</Text>
                    </View>

                  </View>
                  : null}

                {step > 1 ?
                  <View style={styles.priceAndAmountSummarized}>
                    <Text style={styles.priceAndAmountText}>R$ {beautifulBalance(creditsToBuy)}</Text>
                    <TouchableOpacity onPress={() => setStep(1)} style={styles.changeOptionTextTouchable}>
                      <Text style={styles.changeOptionText}>alterar</Text>
                    </TouchableOpacity>
                  </View>
                  : null}

              </View>

              {/* Second Step - Payment Method */}
              {step > 1 ?
                <View style={styles.fieldContainer}>
                  <View style={styles.fieldTitleContainer}>
                    <Text style={styles.fieldTitle}>
                      2. {step === 2 ? 'Qual a forma de pagamento?' : 'Forma de pagamento:'}
                    </Text>
                  </View>

                  {step === 2 ?
                    <View>
                      <View style={styles.paymentOptionsContainer}>
                        <View style={styles.paymentOptionsGroupContainer}>
                          <PaymentOptionElement
                            item={paymentOptions[0]}
                          />
                          {/* <PaymentOptionElement
                            item={paymentOptions[1]}
                          /> */}
                          <PaymentOptionElement
                            item={paymentOptions[3]}
                          />
                        </View>
                        <View style={styles.paymentOptionsGroupContainer}>
                          <PaymentOptionElement
                            item={paymentOptions[2]}
                          />
                          {/* <PaymentOptionElement
                            item={paymentOptions[3]}
                          /> */}
                        </View>
                      </View>

                      {
                        selectedPaymentOption ?
                          <View style={styles.infoContainer
                          } >
                            <View>
                              <FontAwesome
                                name='info-circle'
                                style={styles.infoIcon}
                              />
                            </View>
                            <View>
                              <Text style={styles.infoText}>{messageCadAvaibility}</Text>
                              <Text style={styles.infoText}>{messageCadPayment}</Text>
                            </View>
                          </View>
                          : null}

                    </View>
                    : null}

                  {step > 2 ?
                    <View style={styles.paymentOptionSummarized}>

                      {selectedPaymentOption.library ?
                        <Entypo
                          name={selectedPaymentOption.library ? selectedPaymentOption.icon : null}
                          style={styles.selectedPaymentOptionIcon}
                        />
                        :
                        <FontAwesome
                          name={selectedPaymentOption.library ? null : selectedPaymentOption.icon}
                          style={styles.selectedPaymentOptionIcon}
                        />
                      }

                      <Text style={styles.selectedPaymentOptionText}>{selectedPaymentOption.id === 1 ? 'Cartão de Crédito' : selectedPaymentOption.name}</Text>
                      <TouchableOpacity onPress={() => setStep(2)} style={styles.changeOptionTextTouchable}>
                        <Text style={styles.changeOptionText}>alterar</Text>
                      </TouchableOpacity>
                    </View>
                    : null}
                </View>
                : null}

              {/* Third Step - Payment Method Details - Credit Card */}
              {step > 2 && selectedPaymentOption.id === 1 ?
                <View style={styles.fieldContainer}>
                  <View style={styles.fieldTitleContainer}>
                    <Text style={styles.fieldTitle}>
                      3. {step === 3 && (!creditCardSelected || !creditCardSelected.id) && !showExchangeCardList ? 'Quais os dados do cartão de crédito?' : 'Confirme o cartão de crédito:'}
                    </Text>
                  </View>

                  <View style={styles.creditCardContainer}>
                    <View style={{ marginRight: 5 }}>
                      <SafeEnv />
                    </View>

                    <CreditCardElementContainer
                      creditCardSelected={creditCardSelected}
                      setCreditCardSelected={setCreditCardSelected}
                      creditCardList={creditCardList}
                      showExchangeCardList={showExchangeCardList}
                      setShowExchangeCardList={setShowExchangeCardList}
                    />
                  </View>

                  {!(creditCardSelected && creditCardSelected.id) && !showExchangeCardList ?
                    <View style={styles.newCreditCardContainer}>
                      <CreditCard
                        creditCard={creditCard}
                        onCreditCardChange={setCreditCard}
                        performFinalCheck={performCreditCardFinalCheck}
                        allSet={allSet}
                        setAllSet={setAllSet}
                      />

                      <SaveCreditCardCheckBox
                        saveCard={saveCard}
                        setSaveCard={setSaveCard}
                        cardListOptionVisible={creditCardList && creditCardList.length > 0 ? true : false}
                        setShowExchangeCardList={setShowExchangeCardList}
                      />
                    </View>
                    : null}

                </View>
                : null}

              {/* Third Step - Payment Method Details - Bank Transfer */}
              {step > 2 && selectedPaymentOption.id === 2 ?
                <View style={styles.fieldContainer}>
                  {!showTransferData ?
                    <View>
                      <View style={styles.fieldTitleContainer}>
                        <Text style={styles.fieldTitle}>
                          3. Selecione o seu banco:
                        </Text>
                      </View>

                      <View style={styles.bankIconContainer}>
                        {BANKS.map((item, index) => (
                          <TouchableOpacity
                            key={item.name}
                            onPress={() => {
                              setSelectedBank(item);
                              getUserAccountData(item.id);
                              setBankTransferModalVisible(true);
                            }}
                          >
                            <Image
                              source={{ uri: item.icon }}
                              style={styles.bankIcon}
                            />
                            <Text style={styles.bankText}>{item.name}</Text>
                          </TouchableOpacity>
                        ))}
                      </View>

                      <Modal
                        animationType="slide"
                        transparent={false}
                        visible={bankTransferModalVisible}
                        onRequestClose={() => {
                          setBankTransferModalVisible(false);
                          setAccountDataError({ agency: "", account: "", userDocId: "" });
                          setAccountDataFieldBlurred({});
                          setUserBankAccountData({ userDocId: cpfCnpjMask(user.get('cpf')) });
                        }}
                      >
                        <SafeAreaView>
                          <View style={styles.modalHeader}>
                            <View style={{ width: 50 }} />
                            <Text style={styles.modalTitle}>Transferência Bancária</Text>
                            <TouchableOpacity
                              onPress={() => {
                                setBankTransferModalVisible(false);
                                setAccountDataError({ agency: "", account: "", userDocId: "" });
                                setAccountDataFieldBlurred({});
                                setUserBankAccountData({ userDocId: cpfCnpjMask(user.get('cpf')) });
                              }}
                              style={styles.closeModalTouchable}>
                              <FontAwesome
                                name='times'
                                style={styles.closeModalIcon}
                              />
                            </TouchableOpacity>
                          </View>
                        </SafeAreaView>

                        <KeyboardAwareScrollView
                          // innerRef={ref => {
                          //   this.scroll = ref ? ref.getScrollResponder() : null;
                          // }}
                          enableOnAndroid={true}
                          // enableAutomaticScroll={(Platform.OS === 'ios')}
                          keyboardOpeningTime={0}
                          extraHeight={250}
                          keyboardShouldPersistTaps={'handled'}
                          contentContainerStyle={styles.scrollViewContainer}
                        >
                          <View style={styles.modalContent}>
                            <View style={styles.fieldTitleContainer}>
                              <Text style={styles.fieldTitle}>Insira os dados da conta corrente do Banco {selectedBank ? selectedBank.name : null} da qual realizará a transferência:</Text>
                            </View>

                            <View style={styles.bankTransferAccountInstructionContainer}>
                              <Text style={styles.bankTransferAccountInstruction}>Se o dígito da conta for 'X', substitua-o por '0' (zero).</Text>
                            </View>

                            <View style={styles.accountDataContainer}>
                              <View style={styles.accountDataAndImageContainer}>
                                <Input
                                  ref={input => { inputs['agency'] = input }}
                                  containerStyle={styles.accountFieldContainer}
                                  inputContainerStyle={(accountDataFieldBlurred.agency && accountDataError.agency.length > 0) ? styles.accountInputContainerError : styles.accountInputContainer}
                                  inputStyle={styles.accountInput}
                                  label='Agência (sem dígito):'
                                  labelStyle={styles.accountLabel}
                                  value={userBankAccountData.agency}
                                  onChangeText={(agency) => setUserBankAccountData({ ...userBankAccountData, agency: maskAccountField('agency', agency) })}
                                  onBlur={() => { if (!accountDataFieldBlurred.agency) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, agency: true }) }}
                                  onSubmitEditing={() => { if (!accountDataFieldBlurred.agency) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, agency: true }); focusNextField('account'); }}
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  returnKeyType='next'
                                  blurOnSubmit={false}
                                  errorStyle={styles.errorMessage}
                                  errorMessage={accountDataFieldBlurred.agency ? accountDataError.agency : null}

                                />
                                <Image
                                  source={selectedBank ? { uri: selectedBank.icon } : null}
                                  style={styles.bankSmallIcon}
                                />
                              </View>

                              <Input
                                ref={input => { inputs['account'] = input }}
                                containerStyle={styles.accountFieldContainer}
                                inputContainerStyle={(accountDataFieldBlurred.account && accountDataError.account.length > 0) ? styles.accountInputContainerError : styles.accountInputContainer}
                                inputStyle={styles.accountInput}
                                label='Conta (com dígito):'
                                labelStyle={styles.accountLabel}
                                value={userBankAccountData.account}
                                onChangeText={(account) => setUserBankAccountData({ ...userBankAccountData, account: maskAccountField('account', account) })}
                                onBlur={() => { if (!accountDataFieldBlurred.account) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, account: true }) }}
                                onSubmitEditing={() => { if (!accountDataFieldBlurred.account) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, account: true }); focusNextField('userDocId'); }}
                                autoCorrect={false}
                                keyboardType='numeric'
                                returnKeyType='next'
                                blurOnSubmit={false}
                                errorStyle={styles.errorMessage}
                                errorMessage={accountDataFieldBlurred.account ? accountDataError.account : null}
                              />

                              <Input
                                ref={input => { inputs['userDocId'] = input }}
                                containerStyle={styles.accountFieldContainer}
                                inputContainerStyle={(accountDataFieldBlurred.userDocId && accountDataError.userDocId.length > 0) ? styles.accountInputContainerError : styles.accountInputContainer}
                                inputStyle={styles.accountInput}
                                label='CPF ou CNPJ do titular desta conta:'
                                labelStyle={styles.accountLabel}
                                value={userBankAccountData.userDocId}
                                onChangeText={(userDocId) => setUserBankAccountData({ ...userBankAccountData, userDocId: maskAccountField('userDocId', userDocId) })}
                                onBlur={() => { if (!accountDataFieldBlurred.userDocId) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, userDocId: true }) }}
                                onSubmitEditing={() => { if (!accountDataFieldBlurred.userDocId) setAccountDataFieldBlurred({ ...accountDataFieldBlurred, userDocId: true }); Keyboard.dismiss(); }}
                                autoCorrect={false}
                                keyboardType='numeric'
                                blurOnSubmit={false}
                                errorStyle={styles.errorMessage}
                                errorMessage={accountDataFieldBlurred.userDocId ? accountDataError.userDocId : null}
                              />
                            </View>
                          </View>
                        </KeyboardAwareScrollView>
                        {isProcessingBankTransfer ?
                          <LoadingScreen />
                          : null}
                        <SafeAreaView>
                          <Button
                            buttonStyle={styles.doneButton}
                            title='Pronto!'
                            loading={isProcessingBankTransfer}
                            disabled={
                              (isProcessingBankTransfer || accountDataError.agency.length > 0 || !userBankAccountData.agency || userBankAccountData.agency.length === 0
                                || accountDataError.account.length > 0 || !userBankAccountData.account || userBankAccountData.account.length === 0
                                || accountDataError.userDocId.length > 0 || !userBankAccountData.userDocId || userBankAccountData.userDocId.length === 0)}
                            disabledStyle={styles.disabledProceedButtonStyle}
                            titleStyle={styles.buttonTitle}
                            onPress={() => processBankTransfer()}
                          />
                        </SafeAreaView>
                      </Modal>
                    </View>
                    : (Object.keys(transferDetails).length !== 0 ?
                      <View style={styles.transferDetailsContainer}>
                        <View style={styles.transferDetailsDescription}>
                          <View style={[styles.fieldTitleContainer, styles.alteredMargin]}>
                            <Text style={styles.fieldTitle}>Dados para transferência:</Text>
                          </View>
                          <Text style={styles.transferDetailsText}>Faça uma transferência da sua conta corrente para a nossa.</Text>
                          <Text style={styles.transferDetailsText}>Seus créditos serão creditados em alguns minutos.</Text>
                        </View>
                        <View>
                          <View>
                            <View style={styles.accountTitleContainer}>
                              <FontAwesome5
                                name='wallet'
                                style={styles.transferDetailsIcon}
                              />
                              <Text style={styles.accountTitle}>Sua conta</Text>
                            </View>
                            <View style={styles.accountDetailsContainer}>
                              <View style={styles.flexRow}>
                                <FontAwesome
                                  name='id-card'
                                  style={styles.idDocIcon}
                                />
                                <Text style={[styles.blueTransferText, styles.boldText]}>{cpfCnpjMask(transferDetails.get('docIdOwner'))}</Text>
                              </View>
                              <Text style={styles.blueTransferText}>{transferDetails.get('creditBankName')}</Text>
                              <Text style={styles.blackTransferText}>Agência: <Text style={styles.blueTransferText}>{transferDetails.get('debitAgency')}</Text></Text>
                              <Text style={styles.blackTransferText}>Conta Corrente: <Text style={styles.blueTransferText}>{transferDetails.get('debitAccount')}</Text></Text>
                            </View>
                          </View>

                          <View style={styles.transferValue}>
                            <FontAwesome
                              name='arrow-down'
                              style={styles.transferDetailsIcon}
                            />
                            <Text style={[styles.blueTransferText, styles.boldText]}>R$ {beautifulBalance(transferDetails.get('price'))}</Text>
                          </View>

                          <View>
                            <View style={styles.accountTitleContainer}>
                              <FontAwesome5
                                name='wallet'
                                style={styles.transferDetailsIcon}
                              />
                              <Text style={styles.accountTitle}>Nossa conta</Text>
                            </View>
                            <View style={styles.accountDetailsContainer}>
                              <Text style={[styles.blueTransferText, styles.boldText]}>PayMee Brasil</Text>
                              <Text style={[styles.blueTransferText, styles.boldText]}>Serviços de Pagamentos S.A.</Text>
                              <Text style={styles.blueTransferText}>{transferDetails.get('creditBankName')}</Text>
                              <View style={styles.flexRow}>
                                <Text style={styles.blackTransferText}>Agência: <Text style={styles.blueTransferText}>{transferDetails.get('creditAgency')}</Text></Text>
                                <TouchableOpacity
                                  onPress={() => {
                                    Clipboard.setString(transferDetails.get('creditAgency'));
                                    setShowCopiedTextMessage({ agency: true });
                                    setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                                  }}
                                  style={styles.copyAndPasteTouchable}
                                >
                                  <FontAwesome5
                                    name='copy'
                                    style={styles.transferDetailsIcon}
                                  />
                                </TouchableOpacity>
                                {showCopiedTextMessage.agency ? <Text style={styles.copiedText}>(copiado)</Text> : null}
                              </View>
                              <View style={styles.flexRow}>
                                <Text style={styles.blackTransferText}>Conta Corrente: <Text style={styles.blueTransferText}>{transferDetails.get('creditAccount')}</Text></Text>
                                <TouchableOpacity
                                  onPress={() => {
                                    Clipboard.setString(transferDetails.get('creditAccount'));
                                    setShowCopiedTextMessage({ account: true });
                                    setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                                  }}
                                  style={styles.copyAndPasteTouchable}
                                >
                                  <FontAwesome5
                                    name='copy'
                                    style={styles.transferDetailsIcon}
                                  />
                                </TouchableOpacity>
                                {showCopiedTextMessage.account ? <Text style={styles.copiedText}>(copiado)</Text> : null}
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={styles.transferBottomButtons}>
                          <Button
                            loadingProps={{ color: '#7FCFAB' }}
                            disabled={isCheckingTransferPayment}
                            disabledStyle={{ backgroundColor: 'transparent' }}
                            loading={isCheckingTransferPayment}
                            buttonStyle={styles.alreadyPaidButton}
                            title='JÁ PAGUEI'
                            titleStyle={styles.alreadyPaidButtonTitle}
                            onPress={() => { checkTransferPaymentProcessed(transferDetails.id, transferDetails.get('price')) }}
                          />
                          <Button
                            buttonStyle={styles.cancelTransferButton}
                            title='CANCELAR'
                            disabled={cancelTransferLoading}
                            disabledStyle={{ backgroundColor: 'transparent' }}
                            loading={cancelTransferLoading}
                            loadingProps={{ color: '#EF6F6C' }}
                            titleStyle={styles.cancelTransferButtonTitle}
                            onPress={() => cancelCurrentTransferOrder()}
                          />
                        </View>
                      </View>
                      : null)}
                </View>
                :
                null}

              {/* Third Step - Payment Method Details - Bank Slip */}
              {step > 2 && selectedPaymentOption.id === 3 && (Object.keys(bankSlipTransferDetails).length !== 0) ?
                <View style={styles.transferDetailsContainer}>
                  <View style={styles.transferDetailsDescription}>
                    <View style={[styles.fieldTitleContainer, styles.alteredMargin]}>
                      <Text style={styles.fieldTitle}>Boleto gerado:</Text>
                    </View>

                    <Text style={styles.transferDetailsText}>
                      Acesse o boleto que enviamos por e-mail ou pague utilizando o número do código de barras abaixo:
                    </Text>
                    <View style={[styles.transferValue, { flexDirection: 'column' }]}>
                      <FontAwesome
                        name='barcode'
                        style={styles.transferDetailsIconBarcode}
                      />
                      <TouchableOpacity
                        onPress={() => {
                          Clipboard.setString(bankSlipTransferDetails.get('lineCode'));
                          setShowCopiedTextMessage({ lineCode: true });
                          setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                        }}
                        style={styles.copyAndPasteTouchable}
                      >
                        <Text style={[styles.blueTransferText, styles.boldText, { textAlign: 'center' }]}>{bankSlipTransferDetails.get('lineCode')}</Text>
                        <FontAwesome5
                          name='copy'
                          style={styles.transferDetailsIcon}
                        />
                      </TouchableOpacity>
                      {showCopiedTextMessage.lineCode ? <Text style={styles.copiedTextCenter}>(copiado)</Text> : null}
                    </View>
                    <Text style={[styles.blueTransferText, styles.centerAlign, { marginTop: 10 }]}>R$ {beautifulBalance(bankSlipTransferDetails.get('price'))} em Créditos</Text>
                  </View>
                  <View style={styles.transferBottomButtons}>
                    <Button
                      buttonStyle={[styles.alreadyPaidButton, { minHeight: 65 }]}
                      disabled={sendingBankSlipMail || cancelTransferLoading}
                      disabledStyle={{ backgroundColor: 'transparent' }}
                      disabledTitleStyle={{ color: '#7FCFAB' }}
                      loading={sendingBankSlipMail}
                      loadingProps={{ color: '#7FCFAB' }}
                      title={"REENVIAR\nBOLETO"}
                      titleStyle={styles.alreadyPaidButtonTitle}
                      onPress={() => resendBankSlipMail(bankSlipTransferDetails.get('paymentLink'), bankSlipTransferDetails.get('price'), bankSlipTransferDetails.get('price'), bankSlipTransferDetails.get('lineCode'))}
                    />
                    <Button
                      buttonStyle={[styles.cancelTransferButton, { minHeight: 65 }]}
                      title={"CANCELAR\nBOLETO"}
                      disabled={sendingBankSlipMail || cancelTransferLoading}
                      disabledStyle={{ backgroundColor: 'transparent' }}
                      disabledTitleStyle={{ color: '#EF6F6C' }}
                      loading={cancelTransferLoading}
                      loadingProps={{ color: '#EF6F6C' }}
                      titleStyle={styles.cancelTransferButtonTitle}
                      onPress={() => cancelCurrentTransferOrder(false, true)}
                    />
                  </View>
                </View>
                :
                null}
              {/* Bank Slip Modal */}
              <Modal
                animationType="slide"
                transparent={false}
                visible={bankSlipModalVisible}
                onRequestClose={() => {
                  setBankSlipModalVisible(false);
                }}
              >
                <SafeAreaView>
                  <View style={styles.modalHeader}>
                    <View style={{ width: 50 }} />
                    <Text style={styles.modalTitle}>Gerar Boleto</Text>
                    <TouchableOpacity
                      onPress={() => {
                        setBankSlipModalVisible(false);
                      }}
                      style={styles.closeModalTouchable}>
                      <FontAwesome
                        name='times'
                        style={styles.closeModalIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </SafeAreaView>

                <KeyboardAwareScrollView
                  // innerRef={ref => {
                  //   this.scroll = ref ? ref.getScrollResponder() : null;
                  // }}
                  enableOnAndroid={true}
                  // enableAutomaticScroll={(Platform.OS === 'ios')}
                  keyboardOpeningTime={0}
                  extraHeight={250}
                  keyboardShouldPersistTaps={'handled'}
                  contentContainerStyle={styles.scrollViewContainer}
                >
                  <View style={styles.modalContent}>
                    <View style={styles.fieldTitleContainer}>
                      <Text style={styles.fieldTitle}>Para emissão de boletos é necessário informar seu endereço.</Text>
                    </View>

                    <View style={styles.userAddressFormContainer}>
                      <View style={[styles.flexRow]}>
                        <Input
                          containerStyle={[styles.accountFieldContainer, styles.flex2]}
                          inputContainerStyle={(showAllAddressFields && userAddressError.zipCode) ? styles.accountInputContainerError : styles.accountInputContainer}
                          inputStyle={styles.accountInput}
                          label='CEP:'
                          labelStyle={styles.accountLabel}
                          value={userAddress.zipCode}
                          onChangeText={(zipCode) => { setUserAddress({ ...userAddress, zipCode: maskZipCode(zipCode) }); validateUserAddress('zipCode', zipCode); }}
                          onSubmitEditing={() => { searchZipCode(userAddress.zipCode); Keyboard.dismiss(); }}
                          autoCorrect={false}
                          keyboardType='numeric'
                          blurOnSubmit={false}
                          errorStyle={styles.errorMessage}
                          errorMessage={showAllAddressFields ? userAddressError.zipCode : null}
                        />
                        <View style={styles.searchAddressButtonContainer}>
                          <Button
                            buttonStyle={styles.alreadyPaidButton}
                            disabled={searchingZipCode}
                            disabledStyle={{ backgroundColor: 'transparent' }}
                            disabledTitleStyle={{ color: '#7FCFAB' }}
                            loading={searchingZipCode}
                            loadingProps={{ color: '#7FCFAB' }}
                            title="Buscar Endereço"
                            titleStyle={styles.alreadyPaidButtonTitle}
                            onPress={() => searchZipCode(userAddress.zipCode)}
                          />
                        </View>
                      </View>
                      {showAllAddressFields ?
                        <View>
                          <View style={styles.flexRow}>
                            <Input
                              ref={input => { addressInputs['street'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex2]}
                              inputContainerStyle={userAddressError.street ? styles.accountInputContainerError : styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Logradouro:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.street}
                              onChangeText={(street) => { setUserAddress({ ...userAddress, street }); validateUserAddress('street', street); }}
                              onSubmitEditing={() => { focusNextField('streetNumber', true); }}
                              autoCorrect={false}
                              returnKeyType='next'
                              blurOnSubmit={false}
                              errorStyle={styles.errorMessage}
                              errorMessage={userAddressError.street}
                            />
                            <Input
                              ref={input => { addressInputs['streetNumber'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex1_5]}
                              inputContainerStyle={userAddressError.streetNumber ? styles.accountInputContainerError : styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Número:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.streetNumber}
                              onChangeText={(streetNumber) => { setUserAddress({ ...userAddress, streetNumber: streetNumber.replace(/\D/g, "") }); validateUserAddress('streetNumber', streetNumber.replace(/\D/g, "")); }}
                              onSubmitEditing={() => { focusNextField('complement', true); }}
                              autoCorrect={false}
                              keyboardType='numeric'
                              returnKeyType='next'
                              blurOnSubmit={false}
                              maxLength={7}
                              errorStyle={styles.errorMessage}
                              errorMessage={userAddressError.streetNumber}
                            />
                          </View>
                          <View style={styles.flexRow}>
                            <Input
                              ref={input => { addressInputs['complement'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex2]}
                              inputContainerStyle={styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Complemento:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.complement}
                              onChangeText={(complement) => setUserAddress({ ...userAddress, complement })}
                              onSubmitEditing={() => { focusNextField('neighborhood', true); }}
                              autoCorrect={false}
                              returnKeyType='next'
                              blurOnSubmit={false}
                            />
                            <Input
                              ref={input => { addressInputs['neighborhood'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex1_5]}
                              inputContainerStyle={userAddressError.neighborhood ? styles.accountInputContainerError : styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Bairro:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.neighborhood}
                              onChangeText={(neighborhood) => { setUserAddress({ ...userAddress, neighborhood }); validateUserAddress('neighborhood', neighborhood); }}
                              onSubmitEditing={() => { focusNextField('city', true); }}
                              autoCorrect={false}
                              returnKeyType='next'
                              blurOnSubmit={false}
                              errorStyle={styles.errorMessage}
                              errorMessage={userAddressError.neighborhood}
                            />
                          </View>

                          <View style={styles.flexRow}>
                            <Input
                              ref={input => { addressInputs['city'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex2]}
                              inputContainerStyle={userAddressError.city ? styles.accountInputContainerError : styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Cidade:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.city}
                              onChangeText={(city) => { setUserAddress({ ...userAddress, city }); validateUserAddress('city', city); }}
                              onSubmitEditing={() => { focusNextField('state', true); }}
                              autoCorrect={false}
                              returnKeyType='next'
                              blurOnSubmit={false}
                              errorStyle={styles.errorMessage}
                              errorMessage={userAddressError.city}
                            />
                            <Input
                              ref={input => { addressInputs['state'] = input }}
                              containerStyle={[styles.accountFieldContainer, styles.flex1_5]}
                              inputContainerStyle={userAddressError.state ? styles.accountInputContainerError : styles.accountInputContainer}
                              inputStyle={styles.accountInput}
                              label='Estado:'
                              labelStyle={styles.accountLabel}
                              value={userAddress.state}
                              onChangeText={(state) => { setUserAddress({ ...userAddress, state }); validateUserAddress('state', state); }}
                              onSubmitEditing={() => { Keyboard.dismiss() }}
                              autoCorrect={false}
                              blurOnSubmit={false}
                              errorStyle={styles.errorMessage}
                              errorMessage={userAddressError.state}
                            />
                          </View>
                        </View>
                        : null}
                    </View>
                  </View>
                </KeyboardAwareScrollView>
                {savingUserAddress ? <LoadingScreen /> : null}
                {showAllAddressFields ?
                  <SafeAreaView>
                    <Button
                      disabled={savingUserAddress}
                      loading={savingUserAddress}
                      disabledStyle={styles.disabledProceedButtonStyle}
                      buttonStyle={styles.proceedButton}
                      title='GERAR BOLETO'
                      titleStyle={styles.buttonTitle}
                      onPress={() => { Keyboard.dismiss(); processBankSlipPaymentOrder(true) }}
                    />
                  </SafeAreaView>
                  : null}
              </Modal>

              {/* Third Step - Payment Method Details - PIX */}
              {step > 2 && selectedPaymentOption.id === 7 ?
                <View style={styles.fieldContainer}>

                  {pixPaymentDataState === NOT_LOADED ?
                    <View style={[styles.loadingState, { marginTop: 10 }]}>
                      <ActivityIndicator size='large' color='#284D64' />
                      <Text style={styles.loadingText}>Carregando informações...</Text>
                    </View>
                    : null}

                  {pixPaymentDataState === LOADED_WITH_ERROR ?
                    <View style={[styles.loadingState, { margin: 20, marginTop: 10 }]}>
                      <Text style={styles.blackTransferText}>Ocorreu um erro carregando os dados para pagamento com PIX.</Text>
                      <Button
                        buttonStyle={[styles.errorButton, { marginTop: 10 }]}
                        title='Tentar Novamente'
                        titleStyle={styles.buttonTitle}
                        onPress={() => processPIXPaymentOrder()}
                      />
                    </View>
                    : null}

                  {pixPaymentDataState === LOADED ?
                    <View style={styles.transferDetailsContainer}>
                      <View style={styles.transferDetailsDescription}>
                        <View style={[styles.fieldTitleContainer, styles.alteredMargin]}>
                          <Text style={[styles.transferDetailsText, { marginLeft: 5 }]}><Text style={styles.fieldTitle}>Faça um PIX</Text> de <Text style={[styles.blueTransferText, styles.boldText]}>R$ {beautifulBalance(creditsToBuy)}</Text>. Seus créditos serão disponibilizados em instantes.</Text>
                        </View>
                      </View>
                      <View>
                        <View>
                          <Text style={styles.blueTransferText}>1 - Pague o Código QR inserindo o código abaixo no App do seu banco (PIX Copia e Cola):</Text>
                          <Text style={[styles.transferDetailsText, styles.boldText, { marginTop: 5 }]}>{pixPaymentDetails.paymentCode}</Text>
                          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <TouchableOpacity
                              onPress={() => {
                                Clipboard.setString(pixPaymentDetails.paymentCode);
                                setShowCopiedTextMessage({ pixCode: true });
                                setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                              }}
                              style={{ flexDirection: 'row', borderWidth: 1, borderColor: '#F79256', alignSelf: 'center', padding: 5, paddingTop: 10, paddingBottom: 10, margin: 5 }}
                            >
                              <FontAwesome5
                                name='copy'
                                style={styles.transferDetailsIcon}
                              />
                              <Text style={styles.copyText}>Copiar Código</Text>
                            </TouchableOpacity>
                            {showCopiedTextMessage.pixCode ? <Text style={styles.copiedText}>(copiado)</Text> : null}
                          </View>
                        </View>

                        <View>
                          <Text style={styles.blueTransferText}>2 - Ou, se preferir, pague escaneando este Código QR com o App do seu banco:</Text>
                          <Image
                            source={{ uri: pixPaymentDetails.qrCodeImageLink }}
                            style={{ width: 200, height: 200, alignSelf: 'center', marginTop: 5 }}
                          />
                        </View>
                      </View>
                      <View style={styles.transferBottomButtons}>
                        <Button
                          loadingProps={{ color: '#7FCFAB' }}
                          disabled={isCheckingTransferPayment}
                          disabledStyle={{ backgroundColor: 'transparent' }}
                          loading={isCheckingTransferPayment}
                          buttonStyle={styles.alreadyPaidButton}
                          title='JÁ PAGUEI'
                          titleStyle={styles.alreadyPaidButtonTitle}
                          onPress={() => { checkPixOrderProcessed(pixPaymentDetails.orderId, pixPaymentDetails.price) }}
                        />
                        <Button
                          buttonStyle={styles.cancelTransferButton}
                          title='CANCELAR'
                          disabled={cancelTransferLoading}
                          disabledStyle={{ backgroundColor: 'transparent' }}
                          loading={cancelTransferLoading}
                          loadingProps={{ color: '#EF6F6C' }}
                          titleStyle={styles.cancelTransferButtonTitle}
                          onPress={() => cancelPixOrder()}
                        />
                      </View>
                    </View>
                    : null}

                </View>
                : null}

              {/* Third Step - Payment Method Details - ConectCar */}
              {step > 2 && selectedPaymentOption.id === 4 ?
                <View style={styles.fieldContainer}>
                  <View style={styles.fieldTitleContainer}>
                    <Text style={styles.fieldTitle}>3. Escolha a placa do veículo associado à sua conta ConectCar:</Text>
                  </View>

                  <View>
                    {conectCarVehiclesLoadingState === NOT_LOADED ?
                      <View style={[styles.loadingState, { marginTop: 10 }]}>
                        <ActivityIndicator size='large' color='#284D64' />
                        <Text style={styles.loadingText}>Carregando veículos...</Text>
                      </View>
                      : null}

                    {conectCarVehiclesLoadingState === LOADED_WITH_ERROR ?
                      <View style={[styles.loadingState, { marginTop: 10 }]}>
                        <Text style={styles.blackTransferText}>Ocorreu um erro carregando os veículos para pagamento com ConectCar.</Text>
                        <Button
                          buttonStyle={[styles.errorButton, { marginTop: 10 }]}
                          title='Tentar Novamente'
                          titleStyle={styles.buttonTitle}
                          onPress={() => getVehiclesForConectCarPayment()}
                        />
                      </View>
                      : null}

                    {conectCarVehiclesLoadingState === LOADED ?
                      conectCarVehicles && conectCarVehicles.length > 0 ?
                        <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, alignItems: 'center' }}>
                          {conectCarVehicles.map((item, index) => (
                            <TouchableOpacity
                              style={[{ borderBottomColor: '#AAAAAA', borderBottomWidth: 1, flexDirection: 'row', margin: 10, minHeight: 50, alignItems: 'center', width: '70%' },
                              selectedConectCarVehicle && selectedConectCarVehicle.id === item.id ? { borderBottomColor: '#0992A5', borderBottomWidth: 3, backgroundColor: '#EEEEEE' } : null
                              ]}
                              key={item.id}
                              onPress={() => { setSelectedConectCarVehicle(item) }}
                            >
                              <FontAwesome
                                name={item.get('category') === 1 ? 'car' : item.get('category') === 2 ? 'motorcycle' : item.get('category') === 3 ? 'truck' : 'road'}
                                style={[styles.carIcon, selectedConectCarVehicle && selectedConectCarVehicle.id === item.id ? { color: '#0992A5' } : null]}
                              />
                              <View style={styles.vehiclePlateContainer}>
                                <View style={styles.vehicleDescription}>
                                  {(item.get('userDescription') && item.get('userDescription').trim().length) ?
                                    <Text style={styles.vehicleDescriptionText}>{item.get('userDescription').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)}</Text>
                                    : ((item.get('vehicle') && item.get('vehicle').get('description').trim().length) ?
                                      <Text style={styles.vehicleDescriptionText}>{item.get('vehicle').get('description').substring(0, MAX_VEHICLE_DESCRIPTION_LENGTH)}</Text> : null)}
                                </View>
                                <View style={styles.vehiclePlate}>
                                  {item.get('vehicle') ? <Text style={styles.vehiclePlateText}>{formatLicensePlate(item.get('vehicle').get('licensePlate'))}</Text> : null}
                                </View>
                              </View>
                            </TouchableOpacity>
                          ))}
                          <Text style={[styles.blackTransferText, { marginTop: 10 }]}>Nota: Para escolher um veículo não listado, cadastre antes o veículo na opção "Meus Veículos" do menu.</Text>
                        </View>
                        :
                        <View style={[styles.loadingState, { marginTop: 10, marginLeft: 20, marginRight: 20 }]}>
                          <Text style={styles.blackTransferText}>Não há veículos cadastrados em sua conta no App. Antes de pagar com ConectCar, primeiro cadastre o veículo na opção "Meus Veículos" disponível no menu.</Text>
                        </View>
                      : null}
                  </View>
                </View>
                :
                null}

              {/* Third Step - Payment Method Details - Cash */}
              {step > 2 && selectedPaymentOption.id === 5 && (Object.keys(cashTransferDetails).length !== 0) ?
                <View style={styles.transferDetailsContainer}>
                  <View style={styles.transferDetailsDescription}>
                    <View style={[styles.fieldTitleContainer, styles.alteredMargin]}>
                      <Text style={styles.fieldTitle}>Dados para depósito bancário:</Text>
                    </View>
                    <Text style={styles.transferDetailsText}>
                      Dirija-se a uma agência Itaú e faça na boca do caixa um <Text style={{ fontFamily: 'defaultFontBold' }}>depósito identificado com o código {cashTransferDetails.get('identification')}</Text> para a nossa conta conforme os dados abaixo:
                    </Text>
                    <Text style={[styles.blueTransferText, styles.boldText, styles.centerAlign]}>R$ {beautifulBalance(cashTransferDetails.get('amount'))}</Text>
                    <View style={styles.transferValue}>
                      <FontAwesome
                        name='arrow-down'
                        style={styles.transferDetailsIcon}
                      />
                      <Text style={styles.transferDetailsText}>Código Identificador: </Text>
                      <Text style={[styles.blueTransferText, styles.boldText]}>{cashTransferDetails.get('identification')}</Text>
                    </View>
                  </View>
                  <View>
                    <View style={styles.accountTitleContainer}>
                      <FontAwesome5
                        name='wallet'
                        style={styles.transferDetailsIcon}
                      />
                      <Text style={styles.accountTitle}>Nossa conta</Text>
                    </View>
                    <View style={styles.accountDetailsContainer}>
                      <Text style={[styles.blueTransferText, styles.boldText]}>PayMee Brasil</Text>
                      <Text style={[styles.blueTransferText, styles.boldText]}>Serviços de Pagamentos S.A.</Text>
                      <Text style={styles.blueTransferText}>{cashTransferDetails.get('creditBankName')}</Text>
                      <View style={styles.flexRow}>
                        <Text style={styles.blackTransferText}>Agência: <Text style={styles.blueTransferText}>{cashTransferDetails.get('creditAgency')}</Text></Text>
                        <TouchableOpacity
                          onPress={() => {
                            Clipboard.setString(cashTransferDetails.get('creditAgency'));
                            setShowCopiedTextMessage({ agency: true });
                            setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                          }}
                          style={styles.copyAndPasteTouchable}
                        >
                          <FontAwesome5
                            name='copy'
                            style={styles.transferDetailsIcon}
                          />
                        </TouchableOpacity>
                        {showCopiedTextMessage.agency ? <Text style={styles.copiedText}>(copiado)</Text> : null}
                      </View>
                      <View style={styles.flexRow}>
                        <Text style={styles.blackTransferText}>Conta Corrente: <Text style={styles.blueTransferText}>{cashTransferDetails.get('creditAccount')}</Text></Text>
                        <TouchableOpacity
                          onPress={() => {
                            Clipboard.setString(cashTransferDetails.get('creditAccount'));
                            setShowCopiedTextMessage({ account: true });
                            setTimeout(() => { setShowCopiedTextMessage({}); }, 1000);
                          }}
                          style={styles.copyAndPasteTouchable}
                        >
                          <FontAwesome5
                            name='copy'
                            style={styles.transferDetailsIcon}
                          />
                        </TouchableOpacity>
                        {showCopiedTextMessage.account ? <Text style={styles.copiedText}>(copiado)</Text> : null}
                      </View>
                    </View>
                  </View>
                  <View style={styles.transferBottomButtons}>
                    <Button
                      loadingProps={{ color: '#7FCFAB' }}
                      disabled={isCheckingTransferPayment}
                      disabledStyle={{ backgroundColor: 'transparent' }}
                      loading={isCheckingTransferPayment}
                      buttonStyle={styles.alreadyPaidButton}
                      title='JÁ PAGUEI'
                      titleStyle={styles.alreadyPaidButtonTitle}
                      onPress={() => { checkTransferPaymentProcessed(cashTransferDetails.id, cashTransferDetails.get('amount')) }}
                    />
                    <Button
                      buttonStyle={styles.cancelTransferButton}
                      title='CANCELAR'
                      disabled={cancelTransferLoading}
                      disabledStyle={{ backgroundColor: 'transparent' }}
                      loading={cancelTransferLoading}
                      loadingProps={{ color: '#EF6F6C' }}
                      titleStyle={styles.cancelTransferButtonTitle}
                      onPress={() => cancelCurrentTransferOrder(true)}
                    />
                  </View>
                </View>
                :
                null}
              {isProcessingBankTransfer ?
                <LoadingScreen />
                : null}
              {step !== 3 ?
                <Button
                  buttonStyle={styles.proceedButton}
                  title='Avançar'
                  loading={isProcessingBankTransfer}
                  disabledTitleStyle={{ color: '#FFFFFF' }}
                  disabled={creditsToBuy < 1 || (step > 1 && !selectedPaymentOption) || isProcessingBankTransfer}
                  disabledStyle={isProcessingBankTransfer ? styles.disabledProceedButtonStyle : styles.disabledProceedButtonStyleAlpha}
                  titleStyle={styles.proceedButtonTitle}
                  onPress={() => {
                    if (step === 1 && creditCardList && creditCardList.length > 0 && (selectedPaymentOption.id === 1 || !selectedPaymentOption.id)) { // user has saved credit card
                      //set credit card as default
                      setSelectedPaymentOption(paymentOptions[2]);
                      setMessageCadAvaibility(paymentOptions[2].messageCadAvaibility);
                      setMessageCadPayment(paymentOptions[2].messageCadPayment);

                      setStep(3);
                    }
                    else if (step === 2 && selectedPaymentOption.id === 3) { //user chose bank slip
                      processBankSlipPaymentOrder();
                    }
                    else if (step === 2 && selectedPaymentOption.id === 4) { //user chose ConectCar
                      getVehiclesForConectCarPayment();
                      setStep(step + 1);
                    }
                    else if (step === 2 && selectedPaymentOption.id === 7) { //user chose PIX
                      processPIXPaymentOrder();
                      setStep(step + 1);
                    }
                    else {
                      setStep(step + 1);
                    }
                  }}
                />
                : null}
            </View>
          </KeyboardAwareScrollView>
        )
      }
      {
        isProcessingCreditCardPayment ?
          <LoadingScreen />
          : null
      }
      <ConfirmDialog
        visible={showConfirmAccountDialog}
        title='Atenção'
        titleStyle={styles.dialogTitle}
        positiveButton={{
          title: 'Confirmar',
          titleStyle: { color: '#424242' },
          onPress: () => { checkSmsCode() }
        }}
        negativeButton={{
          title: 'Cancelar',
          titleStyle: { color: '#424242' },
          onPress: () => {
            setShowConfirmAccountDialog(false);
            setIsProcessingCreditCardPayment(false);
          }
        }}
      >
        <View>
          <Text style={styles.dialogText}>{`Um SMS com um código de confirmação de 4 dígitos foi enviado ao celular ${maskPhone(user.get('phone'))}. Insira o código abaixo:`}</Text>
          <Input
            containerStyle={styles.dialogFieldContainer}
            inputContainerStyle={styles.dialogInputContainer}
            inputStyle={styles.dialogInput}
            value={smsVerificationCode}
            onChangeText={(smsVerificationCode) => setSmsVerificationCode(smsVerificationCode)}
            onSubmitEditing={() => { checkSmsCode(); Keyboard.dismiss() }}
            autoCorrect={false}
            keyboardType='numeric'
            blurOnSubmit={false}
            maxLength={4}
          />
        </View>
      </ConfirmDialog>

      {
        loadingState === LOADED_WITH_ERROR ?
          <Button
            disabled={isProcessingBankTransfer}
            disabledStyle={styles.disabledProceedButtonStyle}
            loading={isProcessingBankTransfer}
            buttonStyle={styles.errorButton}
            title='Tentar Novamente'
            titleStyle={styles.buttonTitle}
            onPress={() => doInitialPrep()}
          />
          : null
      }
      {
        step === 3 && (selectedPaymentOption.id === 1 || (selectedPaymentOption.id === 4 && conectCarVehiclesLoadingState === LOADED && conectCarVehicles && conectCarVehicles.length > 0)) ? //final step and payment with credit card or conectCar
          <Button
            disabled={showExchangeCardList || isProcessingCreditCardPayment}
            loading={isProcessingCreditCardPayment}
            disabledStyle={styles.disabledProceedButtonStyleAlpha}
            buttonStyle={styles.proceedButton}
            title={`COMPRAR CRÉDITOS`}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              if (selectedPaymentOption.id === 4) processConectCarOrder();
              else processCreditCardOrder();

              // if (creditsToBuy > cadPriceProps.maxCreditCardPurchaseQty) {
              //   Alert.alert(
              //     'Oops!',
              //     `A compra máxima por cartão de crédito é de ${cadPriceProps.maxCreditCardPurchaseQty} cartões. Por favor, ajuste a quantidade a comprar e tente novamente.`,
              //     [
              //       { text: 'OK' },
              //     ],
              //     { cancelable: true }
              //   );
              // }
              // else processCreditCardOrder();
            }}
          />
          : null
      }

      <OfficialFooter />
    </View >
  );
};

const styles = StyleSheet.create({
  carIcon: {
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    color: '#424242'
  },
  vehiclePlateContainer: {
    flex: 3,
    marginLeft: 5,
    marginRight: 5
  },
  vehicleDescription: {
    alignSelf: 'center'
  },
  vehicleDescriptionText: {
    fontFamily: 'defaultFont',
    fontSize: 14,
    color: '#284D64',
    textAlign: 'center'
  },
  vehiclePlate: {
    alignSelf: 'center'
  },
  vehiclePlateText: {
    fontFamily: 'defaultFontBold',
    fontSize: 16,
    color: '#284D64',
    textAlign: 'center'
  },
  flex2: {
    flex: 2
  },
  flex1_5: {
    flex: 1.5
  },
  searchAddressButtonContainer: {
    justifyContent: 'flex-start',
    flex: 1.75,
    marginTop: 20
  },
  userAddressFormContainer: {
    marginTop: 20,
    marginLeft: 5,
    marginRight: 10,
    marginBottom: 20
  },
  dialogTitle: {
    fontFamily: 'defaultFontBold',
    fontSize: 18,
    color: '#424242'
  },
  dialogText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  dialogInput: {
    fontSize: 25,
    textAlign: 'center',
    color: '#424242',
    fontFamily: 'defaultFont',
    letterSpacing: 5
  },
  dialogInputContainer: {
    borderColor: '#EEEEEE',
    borderWidth: 1,
    padding: 5
  },
  dialogFieldContainer: {
    marginTop: 10
  },
  copiedTextCenter: {
    fontFamily: 'defaultFontItalic',
    fontSize: 16,
    color: '#424242',
    borderColor: 'transparent',
    borderWidth: 1,
    textAlign: 'center'
  },
  copyText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#F79256'
  },
  copiedText: {
    fontFamily: 'defaultFontItalic',
    fontSize: 16,
    color: '#424242',
    borderColor: 'transparent',
    borderWidth: 1
  },
  alreadyPaidButton: {
    borderColor: '#7FCFAB',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: 'transparent'
  },
  alreadyPaidButtonTitle: {
    color: '#7FCFAB',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  cancelTransferButton: {
    borderColor: '#EF6F6C',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: 'transparent'
  },
  cancelTransferButtonTitle: {
    color: '#EF6F6C',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  transferBottomButtons: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    borderTopColor: '#AAAAAA',
    borderTopWidth: 1,
    paddingTop: 10,
    marginTop: 10
  },
  copyAndPasteTouchable: {
    marginLeft: 10
  },
  transferDetailsDescription: {
    marginBottom: 5,
  },
  transferValue: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  centerAlign: {
    textAlign: 'center'
  },
  boldText: {
    fontFamily: 'defaultFontBold'
  },
  idDocIcon: {
    fontSize: 18,
    color: '#0992A5',
    alignSelf: 'center',
    marginRight: 5
  },
  blackTransferText: {
    color: '#424242',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  blueTransferText: {
    color: '#0992A5',
    fontSize: 16,
    fontFamily: 'defaultFont'
  },
  flexRow: {
    flexDirection: 'row'
  },
  accountDetailsContainer: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#0992A5',
    padding: 5
  },
  accountTitleContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 5
  },
  accountTitle: {
    fontSize: 16,
    color: '#F79256',
    fontFamily: 'defaultFontBold',
    marginLeft: 0
  },
  transferDetailsIcon: {
    fontSize: 18,
    alignSelf: 'center',
    color: '#F79256',
    marginRight: 5
  },
  transferDetailsIconBarcode: {
    fontSize: 30,
    alignSelf: 'center',
    color: '#F79256',
    marginTop: 10
  },
  transferDetailsText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#424242'
  },
  alteredMargin: {
    marginLeft: 0,
    marginBottom: 10
  },
  transferDetailsContainer: {
    backgroundColor: '#F3F3F3',
    borderColor: '#EEEEEE',
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 10,
    padding: 10
  },
  doneButton: {
    backgroundColor: '#7FCFAB'
  },
  accountFieldContainer: {
    marginBottom: 20
  },
  errorMessage: {
    color: '#EF6F6C',
    fontFamily: 'defaultFont',
    fontSize: 14
  },
  accountDataAndImageContainer: {
    flexDirection: 'row',
    width: '60%'
  },
  bankSmallIcon: {
    marginTop: 15,
    height: 40,
    width: 40
  },
  accountInputContainer: {
    borderColor: '#EEEEEE',
  },
  accountInputContainerError: {
    borderBottomColor: '#EF6F6C'
  },
  accountInput: {
    fontSize: 16,
    fontFamily: 'defaultFont',
    color: '#424242',
    outlineStyle: 'none'
  },
  accountDataContainer: {
    marginLeft: 10,
    marginRight: 10
  },
  accountLabel: {
    fontSize: 16,
    fontFamily: 'defaultFont',
    color: '#284D64',
    fontWeight: 'normal'
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1
  },
  modalTitle: {
    fontWeight: 'normal',
    fontSize: 20,
    color: '#284D64',
    fontFamily: 'defaultFont'
  },
  closeModalTouchable: {
    justifyContent: 'flex-end'
  },
  closeModalIcon: {
    color: '#EF6F6C',
    fontSize: 26,
    width: 50,
    textAlign: 'center'
  },
  modalContent: {
    marginTop: 10,
    marginRight: 15
  },
  bankText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#0992A5',
    textAlign: 'center',
    marginTop: 5
  },
  bankIcon: {
    borderRadius: 10,
    height: 45,
    width: 45,
    alignSelf: 'center'
  },
  bankIconContainer: {
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'space-evenly'
  },
  newCreditCardContainer: {
    marginLeft: 5
  },
  secureEnv: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 25,
    marginBottom: 5
  },
  secureEnvText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    fontSize: 12,
    marginLeft: 3
  },
  lockerIcon: {
    color: '#F79256',
    fontSize: 14,
    paddingTop: 2
  },
  errorButton: {
    backgroundColor: '#EF6F6C'
  },
  buttonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'defaultFont',
    fontSize: 16,
    textAlign: 'center',
    color: '#284D64'
  },
  mainContainer: {
    flex: 1
  },
  loadingState: {
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start'
  },
  creditCardContainer: {
    marginTop: 15,
    marginLeft: 5
  },
  selectedPaymentOptionIcon: {
    fontSize: 16,
    color: '#0992A5',
    alignSelf: 'center',
    marginRight: 5,
    marginTop: 2
  },
  selectedPaymentOptionText: {
    fontFamily: 'defaultFontBold',
    fontSize: 16,
    color: '#0992A5'
  },
  paymentOptionSummarized: {
    flexDirection: 'row',
    marginLeft: 15,
    marginTop: 10
  },
  infoIcon: {
    fontSize: 22,
    color: '#284D64',
    marginRight: 10
  },
  infoContainer: {
    marginTop: 10,
    paddingTop: 15,
    marginBottom: 30,
    marginLeft: 15,
    marginRight: 15,
    borderTopWidth: 1,
    borderTopColor: '#EEEEEE',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoText: {
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#284D64'
  },
  paymentOptionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 15,
    marginRight: 10,
  },
  touchablePaymentOption: {
    marginTop: 10,
    paddingBottom: 5,
    borderBottomColor: 'transparent',
    borderBottomWidth: 3,
    marginBottom: 10
  },
  touchablePaymentOptionActive: {
    borderBottomColor: '#0992A5'
  },
  touchablePaymentOptionIcon: {
    fontSize: 16,
    color: '#AAAAAA',
    alignSelf: 'center'
  },
  touchablePaymentOptionIconActive: {
    color: '#0992A5',
  },
  touchablePaymentOptionText: {
    marginTop: 5,
    fontFamily: 'defaultFont',
    fontSize: 16,
    color: '#AAAAAA',
    alignSelf: 'center'
  },
  touchablePaymentOptionTextActive: {
    color: '#0992A5',
  },
  changeOptionTextTouchable: {
    marginTop: 2,
    marginLeft: 7
  },
  changeOptionText: {
    color: '#F79256',
    fontFamily: 'defaultFont',
    textDecorationLine: 'underline',
    fontSize: 16
  },
  proceedButton: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0,
    marginTop: 15
  },
  proceedButtonTitle: {
    fontFamily: 'defaultFontBold',
    textTransform: 'uppercase'
  },
  disabledProceedButtonStyle: {
    backgroundColor: '#7FCFAB',
    borderRadius: 0
  },
  disabledProceedButtonStyleAlpha: {
    backgroundColor: 'rgba(127,207,171,0.5)',
    borderRadius: 0
  },
  priceAndAmountText: {
    color: '#0992A5',
    fontFamily: 'defaultFontBold',
    fontSize: 16
  },
  priceAndAmount: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10
  },
  priceAndAmountSummarized: {
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10
  },
  changeAmountText: {
    fontFamily: 'defaultFont',
    fontSize: 13,
    color: '#284D64'
  },
  amountButtonsContainer: {
    alignItems: 'center',
    flex: 1.4,
    justifyContent: 'space-evenly'
  },
  changeAmountButtonsContainer: {
    alignItems: 'center',
    flex: 1,
    borderLeftWidth: 1,
    borderLeftColor: '#AAAAAA',
    justifyContent: 'space-evenly'
  },
  twoButtonsContainer: {
    flexDirection: 'row'
  },
  buttonsContainer: {
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: 10,
    marginRight: 15
  },
  changeAmountToPurchaseButton: {
    borderRadius: 0,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: 'transparent',
    borderColor: '#AAA',
    borderWidth: 1,
    minWidth: 60,
    margin: 5
  },
  changeAmountToPurchaseButtonTitle: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 13
  },
  amountToPurchaseButton: {
    borderRadius: 0,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: 'transparent',
    borderColor: '#284D64',
    borderWidth: 1,
    minWidth: 70,
    margin: 5
  },
  amountToPurchaseButtonTitle: {
    color: '#284D64',
    fontFamily: 'defaultFontBold',
    fontSize: 16
  },
  bankTransferAccountInstructionContainer: {
    backgroundColor: '#EEEEEE',
    padding: 5,
    marginRight: 10,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    alignSelf: 'flex-start'
  },
  balanceContainer: {
    backgroundColor: '#EEEEEE',
    padding: 5,
    marginLeft: 15,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'flex-start'
  },
  bankTransferAccountInstruction: {
    color: '#284D64',
    fontFamily: 'defaultFont',
    fontSize: 16
  },
  balance: {
    color: '#284D64',
    fontFamily: 'defaultFontBold'
  },
  fieldContainer: {
    marginTop: 10
  },
  fieldTitleContainer: {
    borderLeftWidth: 2,
    borderLeftColor: '#F79256',
    marginLeft: 15
  },
  fieldTitle: {
    color: '#284D64',
    paddingLeft: 8,
    fontSize: 16,
    fontFamily: 'defaultFontBold'
  }
});

export default PurchaseCreditsScreen;
