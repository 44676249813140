import React from 'react';
import VehicleList from '../components/VehicleList';
import OfficialFooter from '../components/OfficialFooter';

const VehiclesScreen = ({ navigation }) => {

  return (
    <>
      <VehicleList
        navigation={navigation}
      />
      <OfficialFooter />
    </>
  );

};

export default VehiclesScreen;
