import Parse from 'parse';

export default () => {

  const getAddressDetails = async (query, reverse, lastUserLocation) => {

    return await Parse.Cloud.run('getAddressDetailsFromServer', {
      query, reverse, lastUserLocation
    });

  };

  return [getAddressDetails];

};
