import { useContext } from 'react';
import { Alert } from 'react-native';
import alert from './useAlert.js'
import Parse from "parse";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from '../context/AuthContext';

export default () => {

  const SESSION_MISSING = Parse.Error.SESSION_MISSING;
  const INVALID_SESSION_TOKEN = Parse.Error.INVALID_SESSION_TOKEN;
  const { setCurrentUser } = useContext(AuthContext);

  const showLoggedOutWarning = () => {
    alert(
      'Oops!',
      'A sessão de usuário está expirada. Por favor, faça login novamente.',
      null,
      true
    );

    // Alert.alert(
    //   'Oops!',
    //   'A sessão de usuário está expirada. Por favor, faça login novamente.',
    //   [
    //     { text: 'OK' },
    //   ],
    //   { cancelable: false }
    // );
  };

  const logOut = async () => {
    const userId = Parse.User.current().get('cpf');
    try {
      await AsyncStorage.setItem('lastUserLoggedInId', userId);
      await Parse.User.logOut();
      setCurrentUser(null, false, userId);
      showLoggedOutWarning();
    } catch (e) {
      setCurrentUser(null, false, userId);
      showLoggedOutWarning();
    }
  }

  const checkIfSessionExpired = (e) => {
    if (e && (e.code === SESSION_MISSING || e.code === INVALID_SESSION_TOKEN)) {
      logOut();
    }
  };

  return [checkIfSessionExpired];

};